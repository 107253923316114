import React from 'react';
import './style.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export default function EarlyBonuses() {

  return (
    <div className='early-bonuses-component'>
      <div className='container'>
        <div className='early-bonuses-container'>
          <h2>Claim Your <strong>Early Bird Bonuses</strong> Now - Before They're Gone <strong>Forever!</strong></h2>
          <p>This is only for the action-takers!</p>
          <p>We have a goldmine of rewards for those who seize great opportunities when they see it.</p>
          <p>But here's the catch - since these bonuses are valued at <strong>$975,</strong> they aren't going to stick around forever. They are an exclusive offer for proactive parents who act <strong>FAST.</strong></p>
          <p>These opportunities will be snapped up in the blink of an eye, potentially leaving out you and your child. </p>
          <p>So don't risk it! We want to see you succeed!</p>
          <p>Take a quick moment to secure your child's future by clicking below:</p>
          <AnchorLink
            className='btn-2 enroll-btn'
            href={"#section-enroll"}
          >
            Enroll Your Child For Summer Coding Challenge
          </AnchorLink>
        </div>
      </div>
    </div>
  );
}
