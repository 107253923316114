import React from 'react';
import './Footer.scss';
import facebook from '../../Assets/Icon/facebook.png';
import youtube from '../../Assets/Icon/youtube.png';
import twitter from '../../Assets/Icon/twitter.png';
import instagram from '../../Assets/Icon/instagram.png';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

class Footer extends React.Component {
  hideFooter = () => {
    const pathName = this.props.location.pathname;
    return (
      pathName === '/sign-in' || pathName === '/forgot-password' ||
      pathName === '/ads-signup' || pathName === '/ads-signup2' ||
      pathName === '/start-trial' || pathName === '/enroll' || pathName === '/enroll2' ||
      pathName === '/tutor-signup' ||
      pathName === '/parentingthefuture' ||
      pathName === '/studentsignup' ||
      pathName === '/thankyou' ||
      pathName === '/summer-camp-registration' || pathName === '/summer-camp-payment' || pathName === '/summer-camp-registration-success' ||
      pathName.split('/')[1] === 'course' ||
      pathName === '/freecodingchallenge' ||
      pathName === '/freecodingchallenge-1' || 
      pathName === '/iCode-iGenius-summer-camps/masterclass'
    )
  }

  render() {
    return (
      <div className="footer-component" style={{ display: this.hideFooter() && 'none' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 v-r sub-col">
              <h2>Follow Us</h2>
              <div className="v-c">
                <a href="https://www.facebook.com/ICode-Edu-105901597689621/?modal=admin_todo_tour" className="social" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook" /></a>
                <a href="https://twitter.com/EduIcode" className="social" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="twitter" /></a>
                <a href="https://www.youtube.com/channel/UCPMvnUxv3T3A5FBOJASIvvg?view_as=subscriber" className="social" target="_blank" rel="noopener noreferrer"><img src={youtube} alt="youtube" /></a>
                <a href="https://www.instagram.com/icodeedu/" className="social" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="instagram" /></a>
              </div>
              <NavLink exact to="/tutor-signup" className="shadow-object box-item v-c h-c">Become an Instructor</NavLink>
              {/* {this.props.token.length === 0 ? <NavLink exact to="/tutor-signup" className="shadow-object box-item v-c h-c">Become a tutor</NavLink>
                                                           : <a href={GetDashboardUrl(this.props.user, this.props.token)} className="shadow-object box-item v-c h-c" target="_blank" rel="noopener noreferrer">GO TO DASHBOARD</a>} */}
            </div>
            <div className="col-lg-3 v-r sub-col">
              <h2>Company</h2>
              <div className="link-list v-r">
                <NavLink exact to="/contact-us" className="footer-link">Contact us</NavLink>
                <NavLink exact to="/about-us" className="footer-link">About</NavLink>
                <NavLink exact to="/terms" className="footer-link">Terms & Privacy </NavLink>
                <NavLink exact to="/team" className="footer-link">Meet Our Team</NavLink>
                <NavLink exact to="/media" className="footer-link">Media</NavLink>
                <NavLink exact to="/summer-camps" className="footer-link">Summer Camp</NavLink>
                <a href="https://onlinemathtutor.com/" className="footer-link" target="_blank" rel="noopener noreferrer">Online Math Tutor</a>
              </div>
            </div>
            <div className="col-lg-3 v-r sub-col">
              <h2>Information</h2>
              <div className="link-list v-r">
                <NavLink exact to="/help-center" className="footer-link">Help Center</NavLink>
                <NavLink exact to="/faqs" className="footer-link">FAQs</NavLink>
                <div className="footer-link">Phone: (817) 984 5300</div>
              </div>
            </div>
          </div>
          <div className="copy-right v-c h-c">© Frog Tutoring Company. All rights reserved</div>
        </div>
      </div>
    )
  };
};

function mapStateToProps(state) {
  return {
    token: state.Auth.token,
    user: state.Auth.user,
  }
}

export default connect(mapStateToProps, {})(withRouter(Footer));
