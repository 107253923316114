import moment from 'moment-timezone';
import {
  GET_ALL_COURSES,
  GET_COURSE_DETAIL,
  GET_COURSES,
  GET_CAMP_COURSES,
  GET_SUMMER_CAMP_COURSES,
  SET_CAMP_CART_INFO,
} from '../Type';

export const getAllCourses = (timezone = moment.tz(moment.utc(), moment.tz.guess(true)).utcOffset() / 60) => {
  return {
    type: GET_ALL_COURSES,
    timezone
  }
}

export const getCourseDetail = (data) => {
  return {
    type: GET_COURSE_DETAIL,
    data
  }
}

export const getCourses = (type = "course") => {
  return {
    type: GET_COURSES,
    data: { type }
  }
}

export const getCampCourses = (type = "camp") => {
  return {
    type: GET_CAMP_COURSES,
    data: { type }
  }
}

export const getSummerCampCourses = (type = "summercamp") => {
  return {
    type: GET_SUMMER_CAMP_COURSES,
    data: { type }
  }
}

export const setCampCartInfo = (data) => {
  return {
    type: SET_CAMP_CART_INFO,
    data: { campCartInfo: data }
  }
}