import React from 'react';
import './Enroll2Page.scss';
import logoActive from '../../Assets/Icon/logo-active.png';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';
import check from '../../Assets/Icon/check.svg';
import confirm from '../../Assets/Icon/confirm.svg';
import { CheckBox, CheckoutForm } from '../../Components';
import { updatePlan } from '../../Redux/Actions';
import { PlanPrices, STRIPE_API_KEY } from '../../Constant';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

const Rules1 = [' per week.', 'Pause and cancel at any time. ', 'You can pause and cancel your lesson at any time.', 'You will be charged for the first month of lessons today and you wouldn’t be charge again until your first month is completed. '];
const Rules2 = ['Schedule an evaluation call with one of iCode Edu’s enrollment directors.', 'The student will be placed with their instructor.', 'The first lesson with the instructor is free!'];

const stripePromise = loadStripe(STRIPE_API_KEY);

class Enroll2Page extends React.Component {
    state = {
        step: 9,

        plan_id: 1,
        pay_per: 'monthly',
        
        payment_method: 'Card',
        agree_term: false,

        card_error: '',
    }

    componentDidMount() {
        this.cardElement = React.createRef();
        if (this.props.location.search.length > 0) {
            let splitted = this.props.location.search.split('&');
            let id = parseInt(splitted[0].split('=')[1], 10);
            let type = splitted[1].split('=')[1];
            this.setState({
                plan_id: id,
                pay_per: type
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.update_success && this.props.update_success) {
            this.props.history.push('/');
            return;
        }
        if (prevProps.update_error_msg.length === 0 && this.props.update_error_msg.length > 0) {
            alert(this.props.update_error_msg);
        }
    }

    selectItem = (field, val) => {
        this.setState({[field]: val});
    }

    SelectedDiv = () => <div className="selected"> <ReactSVG src={check} className="svg-icon" /> </div>

    enroll = async () => {
        if (!this.state.agree_term) {
            alert("Please accept Terms of Use!");
            return;
        }
        let result = await this.cardElement.current.handleSubmit();
        if (!result || (result.error && result.error.message.length > 0)) return;
        const { plan_id, pay_per } = this.state;
        let UpdateParam = {
            plan_id: plan_id,
            pay_per: pay_per,
            payment: {
                type: 0,
                data: {
                    token: result.token.id,
                }
            }
        }
        this.props.updatePlan(UpdateParam);
    }

    render() {
        const { payment_method, agree_term, plan_id, pay_per, card_error } = this.state;
        return (
            <div className="enroll2-page">
                <div className="container v-r">
                    <div className="logo-area">
                        <NavLink to="/"><img src={logoActive} alt="logo" /></NavLink>
                    </div>
                    <div className="main-content">
                        <div className="payment-area round-object v-r">
                            <h1>Payment Details</h1>
                            <h3>Payment Method</h3>
                            {/* <p className="referral">+ Add Referral Code</p> */}
                            <div className="sub-container">
                                <div className={`select-item ${payment_method === 'Card' ? 'active' : ''}`} onClick={e => this.setState({payment_method: 'Card'})}>{this.SelectedDiv()}Card</div>
                            </div>
                            <h3>{payment_method} Information</h3>
                            {(payment_method === 'Card' && stripePromise) && <Elements stripe={stripePromise}>
                                <ElementsConsumer>
                                    {({stripe, elements}) => (
                                        <CheckoutForm 
                                            stripe={stripe} 
                                            elements={elements} 
                                            updateError={e => this.setState({card_error: e})}
                                            ref={this.cardElement}
                                        />
                                    )}
                                </ElementsConsumer>
                            </Elements>}
                            <div className="check-area v-c">
                                <CheckBox value={agree_term} onChange={val => this.setState({agree_term: val})} />
                                <div className="check-label">
                                    Accept&nbsp;<NavLink to="/terms">Terms of Use</NavLink> 
                                </div>
                            </div>
                            <button className={`btn-2 btn-link ${this.props.update_loading || card_error.length > 0 ? 'disabled' : ''}`} onClick={e => this.enroll()}>
                                {this.props.update_loading ? <div className="spinner" /> : 'ENROLL'}
                            </button>
                        </div>
                        <div className="coding-class round-object private">
                            <div className="plan-container v-c h-c">YOUR PLAN</div>
                            <div className="sub-header v-r v-c">
                                <h2>{plan_id % 2 === 1 ? 'Private ' : 'Siblings '} Lessons</h2>
                                <h3>${PlanPrices[plan_id <= 2 ? 0 : 1][plan_id % 2 === 0 ? 1 : 0][pay_per === 'monthly' ? 0 : pay_per === 'half-yearly' ? 1 : 2]}<span>/mo</span></h3>
                            </div>
                            <div className="sub-body has-border v-r">
                                {Rules1.map((item, index) => <div className="sub-item" key={index}>
                                    <ReactSVG src={confirm} className="svg-icon" />
                                    <p>
                                        {index !== 0 ? item : plan_id <= 2 ? 'One 50 min lesson' + item : 'Two 50 min lessons' + item}
                                    </p>
                                </div>)}
                            </div>
                            <div className="sub-body v-r">
                                <h4>WHAT NEXT AFTER PAYMENT: </h4>
                                {Rules2.map((item, index) => <div className="sub-item" key={index}>
                                    <div className="circle" />
                                    <p>{item}</p>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        update_success: state.Plan.update_success,
        update_error_msg: state.Plan.update_error_msg,
        update_loading: state.Plan.update_loading,
    }
}

export default connect(mapStateToProps, {updatePlan})(withRouter(Enroll2Page));