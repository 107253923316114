import React from 'react';
import './style.scss';
import USStates from 'src/Assets/Common/usstates.json';

export default function OtherStatesLink({
  stateName,
}) {

  return (
    <div className='other-states-link-component'>
      <h2>Other States</h2>
      <div className=" row">
        {Object.keys(USStates).filter(x => x !== stateName).map((item, index) => {
          return <div
            key={index}
            className="col-lg-6">
            <a href={"/summer-camps/state/" + item}>
              {item} Summer Camp
            </a>
          </div>
        })}
      </div>
    </div>
  );
}
