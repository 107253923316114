import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SummerCampDetailHeader, SummerCampDetail } from 'src/Components';
import { getSCStateCityCategory } from 'src/Redux/Actions';
import { showSpinner, hideSpinner } from 'src/Utils/Helper';
import { getUpperCaseText } from 'src/Utils/Format';

export default function StateCityCategorySCPage() {
  const dispatch = useDispatch();

  const { state } = useParams()
  const { city } = useParams()
  const { category } = useParams()

  const getLoadingRef = useRef(false);
  const {
    scStateCityCategoryInfo, get_sc_state_city_category_success, get_sc_state_city_category_loading,
    scStateInfo
  } = useSelector(
    state => state.SummerCamp,
  );

  const [stateName, setStateName] = useState('')
  const [cityName, setCityName] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [camps, setCamps] = useState('')

  useEffect(() => {
    showSpinner()
    setStateName(state);
    setCityName(city);
    setCategoryName(category)
    dispatch(getSCStateCityCategory({ state, city, category }));
  }, [dispatch, state, city, category]);

  useEffect(() => {
    if (getLoadingRef.current && !get_sc_state_city_category_loading) {
      hideSpinner();
      if (get_sc_state_city_category_success) {
        setCamps(scStateCityCategoryInfo.data)
      }
    }
    getLoadingRef.current = get_sc_state_city_category_loading;
  }, [dispatch, scStateCityCategoryInfo, get_sc_state_city_category_loading, get_sc_state_city_category_success]);

  const getSubTitle = () => {
    const subTitle = `
      A Comprehensive Guide to ${categoryName} Summer Camps in ${cityName}, ${stateName} and Nearby Areas
    `
    return subTitle
  }

  const getSummary = () => {
    const summary = `
      Looking for the best ${categoryName} summer camps in ${cityName}, ${stateName} 
      and nearby areas for your kids? Look no further! Our 2023 Guide 
      For ${categoryName} Summer Camps has got you covered. 
      We've scoured the region to find the top-rated camps for kids of all ages and skill levels, 
      from kindergartners to teens. Our guide provides important details like camp dates, 
      locations, and pricing, so you can easily choose the right camp for your child. 
      Whether your kid is a budding basketball star, soccer champ, or football prodigy,
       our guide has got something for everyone. Get ready for a summer of fun, fitness, 
       and growth with our 2023 Guide For ${categoryName} Summer Camps In ${cityName}, ${stateName} 
       And Near Me For Kids, Kindergartens - Teens. Let's go!
    `
    return summary
  }

  const getTags = () => {
    const tags = [
      { name: stateName, link: `/summer-camps/state/${stateName}` },
      { name: cityName, link: `/summer-camps/state/${stateName}/city/${cityName}` },
      { name: categoryName, link: `/summer-camps/state/${stateName}/city/${cityName}/category/${categoryName}` },
    ]
    return tags
  }

  return (
    <div className="state-city-category-summer-camp-page">
      <div className="container">
        <a href="/summer-camps">Summer Camp Guide</a> |
        <a href={"/summer-camps/state/" + stateName}>{stateName}</a> |
        <a href={`/summer-camps/state/${stateName}/city/${cityName}`}>{cityName}</a> |
        <a href={`/summer-camps/state/${stateName}/city/${cityName}/category/${categoryName}`}>{getUpperCaseText(categoryName)}</a>
      </div>
      <SummerCampDetailHeader
        tags={getTags()}
        title={`
          2023 Guide For ${getUpperCaseText(categoryName)} Summer Camps In ${cityName}, ${stateName}, And Near Me For Kids, Kindergartens - Teens
        `}
        bg={scStateCityCategoryInfo.background_image}
      />
      {camps && <SummerCampDetail
        camps={camps}
        subTitle={getSubTitle()}
        summary={getSummary()}
        cityName={cityName}
        scStateInfo={scStateInfo}
      />}
    </div>
  );
}