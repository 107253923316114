import { GET_ALL_POSTS, GET_ALL_POSTS_FAIL, GET_ALL_POSTS_SUCCESS } from '../Type';

const INITIAL = {
  post_list: [],
  get_posts_success: false,
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case GET_ALL_POSTS:
    case GET_ALL_POSTS_FAIL: {
        return {
            post_list: [],
            get_posts_success: false,
        }
    }
    case GET_ALL_POSTS_SUCCESS: {
        let post_list = action.data || [];
        return {
            post_list: post_list,
            get_posts_success: true,
        }
    }

    default:
        return state;
  }
}