import React from 'react';
import './TermsPage.scss';
import { Privacy, Term } from './infos';

function innerFunc(item) {
    return {__html: item.content}
}

export default class TermsPage extends React.Component { 
    render() {
        return (
            <div className="siteinfo-page">
                <div className="container siteinfo-container">
                    <div className="title">Terms of Use</div>
                    <div className="siteinfo-list shadow-object">
                        {
                            Term.map((item, index) => {
                                return (
                                    <div className="siteinfo" key={index}>
                                        <div className="siteinfo-title">{item.title}</div>
                                        <div className="siteinfo-content" dangerouslySetInnerHTML={innerFunc(item)}></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="title">Privacy</div>
                    <div className="siteinfo-list shadow-object">
                        {
                            Privacy.map((item, index) => {
                                return (
                                    <div className="siteinfo" key={index}>
                                        <div className="siteinfo-title">{item.title}</div>
                                        <div className="siteinfo-content" dangerouslySetInnerHTML={innerFunc(item)}></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}