import React, { useState } from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg'
import plus from 'src/Assets/Icon/plus.svg';
import minus from 'src/Assets/Icon/minus.svg';

export default function FaqItem({
  className, title, content
}) {

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div className={`iGenus-fqs-component ${className}`}>
      <div className="title-area" onClick={() => toggleAccordion()}>
        <div className="iGenus-fqs-title">{title}</div>
        <ReactSVG src={isOpen ? minus : plus} className={`open-icon`} />
      </div>
      {content.map((item, index) => (
        <div className={`iGenus-fqs-content ${isOpen ? 'active' : ''}`} key={index}>{item}</div>
      ))}
    </div>
  );
}