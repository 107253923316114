import React from 'react';
import './CourseSidebar.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleCourseSidebar } from '../../Redux/Actions';
import { CourseDetail } from '../';

class CourseSidebar extends React.Component {

  state = {
    isCourseOpen: false,
    isPrevOpen: false,
    id: 1,
    minAge: 1,
    maxAge: 1000,
  }

  static getDerivedStateFromProps(props, state) {
    return {
      isCourseOpen: props.isCourseOpen,
      isPrevOpen: state.isCourseOpen,
      id: props.id,
      minAge: props.minAge,
      maxAge: props.maxAge,
    }
  }

  closeSidebar = () => {
    this.props.toggleCourseSidebar(false);
  }

  render() {
    const { isCourseOpen, isPrevOpen, id, minAge, maxAge } = this.state;
    return (
      <div className={`course-sidebar-component ${isCourseOpen ? 'active' : isPrevOpen ? 'inactive' : ''}`}>
        <div className="course-sidebar-wrapper">
          <div className="course-sidebar-content">
            <div className='container course-sidebar-detail'>
              {id && 
                <CourseDetail 
                  id={id} 
                  minAge={minAge} 
                  maxAge={maxAge} 
                  isSideBar={true} 
                  />
                }
            </div>
          </div>
          <div className="course-sidebar-block" onClick={() => this.closeSidebar()}></div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isCourseOpen: state.Sidebar.isCourseOpen,
    id: state.Sidebar.id,
    minAge: state.Sidebar.minAge,
    maxAge: state.Sidebar.maxAge,
  }
}

export default connect(mapStateToProps, { toggleCourseSidebar })(withRouter(CourseSidebar));