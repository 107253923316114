import React from 'react';
import './style.scss';
import Media from 'react-media';
import Loadable from '@loadable/component';
import { CarouselLeftButton, CarouselRightButton } from '../../../Constant';
import { Feedback } from '../../';
import { ReviewsInfo } from './ReviewsInfo';

const OwlCarousel = Loadable(() => import('react-owl-carousel'));

export default function IGeniusReviews() {

  return (
    <div className='iGenius-reviews-component'>
      <div className='container'>
        <div className='iGenius-reviews-container mx-auto'>
          <h2 className='mx-auto'>
            Our Parents & Students Love Us
            As Much We Love Them
          </h2>
          <div className='review-list-section'>
            <Media queries={{
              ex: "(min-width: 1200px)",
              lg: "(min-width: 992px)",
              md: "(min-width: 768px)",
            }}>
              {matches => (
                <OwlCarousel
                  className="owl-theme"
                  rewind
                  nav
                  stagePadding={0}
                  margin={matches.ex ? 30 : 15}
                  items={matches.lg ? 3 : matches.md ? 2 : 1}
                  dots={false}
                  loop={false}
                  navText={[CarouselLeftButton, CarouselRightButton]}
                >
                  {ReviewsInfo.map((item, index) => <Feedback info={item} index={index} key={index} />)}
                </OwlCarousel>
              )}
            </Media>
          </div>
        </div>
      </div>
    </div>
  );
}
