import React from 'react';
import './Header.scss';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg'
import { toggleMobileSidebar, signOut, getLevels } from '../../Redux/Actions';
import logoActive from '../../Assets/Icon/logo-active.png';
import logoInactive from '../../Assets/Icon/logo-inactive.png';
import hamburger from '../../Assets/Icon/hamburger.svg';
import triangle from '../../Assets/Icon/triangle.svg';
import _ from 'lodash';
import { AgeGroup } from 'src/Constant';

class Header extends React.Component {
  state = {
    transform: false,
  }

  componentDidMount() {
    if (_.isEmpty(this.props.levels)) {
      this.props.getLevels();
    };
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sign_out_error_msg.length === 0 && this.props.sign_out_error_msg.length > 0) {
      alert(this.props.sign_out_error_msg);
    }
  }

  handleScroll = (event) => {
    this.setState({
      transform: window.scrollY > 0 ? true : false
    });
  }

  showInactiveHeader = () => {
    const pathName = this.props.location.pathname;
    return (pathName === '/' || pathName === '/pricing') && !this.state.transform
  }

  hideHeader = () => {
    const pathName = this.props.location.pathname;
    return (
      pathName === '/sign-in' || pathName === '/forgot-password' ||
      pathName === '/ads-signup' || pathName === '/ads-signup2' ||
      pathName === '/start-trial' || pathName === '/enroll' || pathName === '/enroll2' ||
      pathName === '/tutor-signup' ||
      pathName === '/parentingthefuture' ||
      pathName === '/studentsignup' ||
      pathName === '/thankyou' ||
      pathName === '/summer-camp-registration' || pathName === '/summer-camp-payment' || pathName === '/summer-camp-registration-success' ||
      pathName.split('/')[1] === 'course' ||
      pathName.split('/')[1] === 'freecodingchallenge' ||
      pathName.split('/')[1] === 'freecodingchallenge-1' ||
      pathName === '/iCode-iGenius-summer-camps/masterclass' ||
      pathName.split('/')[1] === 'camp-list'
    )
  }

  signOut = () => {
    this.props.signOut();
  }

  render() {
    return (
      <div className={`header-component ${this.showInactiveHeader() ? 'inactive' : ''}`} style={{ display: this.hideHeader() && 'none' }}>
        <div className="container header-container v-c">
          <NavLink className="menu-item logo-active logo" exact to="/"><img src={logoActive} alt="logo-active" /></NavLink>
          <NavLink className="menu-item logo-inactive logo" exact to="/"><img src={logoInactive} alt="logo-inactive" /></NavLink>
          <div className="web-menu v-c show-web-flex">
            <NavLink className="menu-item text-menu" exact to="/">Home</NavLink>
            {/* <div className="pricing-menu">
              <div className="pricing-header v-c">
                <p>Courses</p>
                <ReactSVG src={triangle} className="svg-icon" />
              </div>
              <div className="menu-area v-r">
                <ReactSVG src={triangle} className="svg-icon" />
                <div className="sub-menu v-r">
                  {AgeGroup.map((item, index) =>
                    <NavLink className="sub-menu-item" exact to={`/course-list/${item.id}`} key={index}>
                      {item.period}
                    </NavLink>)}
                </div>
              </div>
            </div> */}
            <div className="pricing-menu">
              <div className="pricing-header v-c">
                <p>Camps</p>
                <ReactSVG src={triangle} className="svg-icon" />
              </div>
              <div className="menu-area v-r">
                <ReactSVG src={triangle} className="svg-icon" />
                <div className="sub-menu v-r">
                  {AgeGroup.map((item, index) =>
                    <NavLink className="sub-menu-item" exact to={`/camp-list/${item.id}`} key={index}>
                      {item.period}
                    </NavLink>)}
                </div>
              </div>
            </div>
            <NavLink className="menu-item text-menu" exact to="/pricing">Pricing</NavLink>
            <NavLink className="menu-item text-menu" exact to="/reviews">Reviews</NavLink>
            <NavLink className="menu-item text-menu" exact to="/faqs">FAQ's</NavLink>
            {/*<NavLink className="menu-item text-menu" exact to="/blog">Blog</NavLink>*/}
          </div>
          <div className="v-c show-web-flex">
            <NavLink className="menu-item text-menu" exact to="/sign-in">Sign In</NavLink>
            <NavLink className="box-menu" exact to="/start-trial">START YOUR TRIAL</NavLink>
          </div>
          <ReactSVG src={hamburger} className="svg-icon show-mobile-flex" onClick={e => this.props.toggleMobileSidebar(true)} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.Auth.token,
    user: state.Auth.user,
    sign_out_error_msg: state.Auth.sign_out_error_msg,
    levels: state.Levels.levels,
  }
}

export default connect(mapStateToProps, {
  toggleMobileSidebar,
  signOut,
  getLevels
})(withRouter(Header));
