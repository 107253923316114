import React from 'react';
import './GroupCourses.scss';
import { Course } from '../../';
import Media from 'react-media';
import Loadable from '@loadable/component';
import { CarouselLeftButton, CarouselRightButton } from '../../../Constant';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllCourses, getLevels } from '../../../Redux/Actions';

const OwlCarousel = Loadable(() => import('react-owl-carousel'));


const CourseType = [
  { name: 'course', label: 'Courses' }, 
  { name: 'camp', label: 'Camps' }
]
class GroupCourses extends React.Component {
  state = {
    active_level: 4,
    active_program_type: 'course',
    finish_get_course_data: false
  }

  componentDidMount() {
    if (this.props.levels.length === 0) {
      this.props.getLevels();
    };
    if (this.props.course_list.length === 0) {
      this.props.getAllCourses();
    } else this.setState({ finish_get_course_data: true });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.course_list.length !== this.props.course_list.length) {
      this.setState({ finish_get_course_data: true });
    }
  }


  render() {
    const { active_level, active_program_type } = this.state;
    const { course_list } = this.props;
    const courseList = this.props.course_list.filter(temp => (temp.level_id === active_level) && (temp.obj_type === active_program_type));
    const avail_levels = this.props.levels.filter(level => this.props.course_list.filter(course => course.obj_type === active_program_type && course.level_id === level.id).length > 0);
    return (
      <div className="groupcourses-component">
        <div className="container v-r v-c">
          <h1>Programs</h1>
          <div className="tech-row v-c">
            {CourseType.map((item, index) => 
              <div 
                key={index} 
                className={`tech v-c h-c ${active_program_type === item.name ? 'active' : ''}`} 
                onClick={e => this.setState({ active_program_type: item.name })}>
              {item.label}
            </div>)}
          </div>
          <div className="agegroup-row v-c">
            {avail_levels.map((item, index) => <div className={`age-group ${active_level === item.id ? 'active' : ''}`} key={index} onClick={e => this.setState({ active_level: item.id })}>
              {item.name}
            </div>)}
          </div>
          <div className="row course-slider-row">
            <Media queries={{
              lg: "(min-width: 992px)",
              md: "(min-width: 768px)",
            }}>
              {matches => (
                <OwlCarousel
                  key={active_level + '-' + active_program_type}
                  className="owl-theme"
                  rewind
                  nav
                  stagePadding={5}
                  margin={15}
                  items={matches.lg ? 3 : matches.md ? 2 : 1}
                  dots={false}
                  loop={false}
                  navText={[CarouselLeftButton, CarouselRightButton]}
                >
                  {courseList.map((item, index) => <Course key={index} info={item} />)}
                </OwlCarousel>
              )}
            </Media>
          </div>
        </div>
      </div>
    )
  };
};


function mapStateToProps(state) {
  return {
    course_list: state.Course.course_list,
    levels: state.Levels.levels,
  }
}

export default connect(mapStateToProps, { getAllCourses, getLevels })(withRouter(GroupCourses));
