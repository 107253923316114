import React from 'react';
import './StudentSignupFooter.scss';
import { NavLink } from 'react-router-dom';
import logoInactive from '../../../Assets/Icon/logo-inactive.png';

export default class StudentSignupFooter extends React.Component {
    
    render() {
        
        return (
            <div className="student-signup-footer-component">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 left-content">
                            <NavLink className="menu-item logo-active logo" exact to="/"><img src={logoInactive} alt="logo-active" /></NavLink>
                        </div>
                        <div className="col-lg-6 right-content">
                           <p>© Frog Tutoring Company. All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};