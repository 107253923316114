import React, { useState } from 'react';
import './style.scss';
import FaqItem from './FaqItem';
import { faqs } from './faqs';

export default function IGeniusFaqs() {

  const [isMore, setIsMore] = useState(false);

  const onMore = () => setIsMore(!isMore);

  return (
    <div className='iGenius-faqs-component'>
      <div className='container'>
        <div className='iGenius-faqs-container mx-auto'>
          <h2>FAQs</h2>
          <div className='faq-content shadow-object'>
            {faqs.map((item, index) => (
              ((!isMore && index < 10) || isMore) &&
              <FaqItem
                className="accordion"
                title={item.title}
                content={item.content}
                key={index}
              />
            ))}
          </div>
          <div
            className='btn-2 more-btn center mx-auto' 
            onClick={onMore}>
            {isMore ? 'Less' : 'More'}
          </div>
        </div>
      </div>
    </div>
  );
}
