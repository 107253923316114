import { 
  AUTH_SIGN_IN, 
  AUTH_SIGN_OUT, 
  AUTH_SIGN_UP, 
  TUTOR_SIGN_UP, 
  TUTOR_PROFILE_UPDATE, 
  AUTH_FORGOT_PASSWORD, 
  AUTH_RESET_PASSWORD 
} from '../Type';

export const signIn = (email, password) => {
  return {
    type: AUTH_SIGN_IN,
    data: {email, password}
  }
}

export const signOut = () => {
  return {
    type: AUTH_SIGN_OUT,
    data: {}
  }
}

export const signUp = (data) => {
  return {
    type: AUTH_SIGN_UP,
    data: data
  }
}

export const tutorSignUp = (data) => {
  return {
    type: TUTOR_SIGN_UP,
    data: data
  }
}

export const tutorProfileUpdate = (data) => {
  return {
    type: TUTOR_PROFILE_UPDATE,
    data: data
  }
}

export const forgotPassword = (data) => {
  return {
    type: AUTH_FORGOT_PASSWORD,
    data: data
  }
}

export const resetPassword = (data) => {
  return {
    type: AUTH_RESET_PASSWORD,
    data: data
  }
}