import React from 'react';
import './style.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export default function IGeniusLandingStart() {

  return (
    <div className="iGenius-landing-start-component">
      <div className='iGenius-landing-header-container container'>
        <h1 className='header-title'>
          Raise Tech-Savvy Kids To Be Founders And Creators In An AI-Enabled Future For
        </h1>
        <div className='description-section'>
          <div className='tags'>
            <div className='sub-description'>Future Job</div>
            <div className='sub-description'>Academic Growth</div>
            <div className='sub-description'>Entrepreneurial Opportunities</div>
          </div>
          <h3>Get a <strong>FREE</strong> 1-on-1 coding challenge lesson for 6 - 16 years old <br />with instructors from MIT, YALE, Harvard, Stanford, and <br />other top universities</h3>
          <div className='completed-students'><strong>5000</strong> students completed the challenge already this summer</div>
          <AnchorLink
            className='btn-2 start-btn'
            href={"#section-enroll"}
          >
            Enroll Your Child For Our Free 1-on-1 Coding Challenge
          </AnchorLink>
        </div>
      </div>
    </div>
  );
}
