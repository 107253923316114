import React from 'react';
import './style.scss';
import _ from 'lodash';
import { NavLink, withRouter, } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import Media from 'react-media';
import Loadable from '@loadable/component';
import { ReactSVG } from 'react-svg';
import left from '../../Assets/Icon/left.svg';
import out from '../../Assets/Icon/out.svg';
import confirm from '../../Assets/Icon/confirm.svg';
import { CourseCategories } from '../../Constant';
import { CarouselLeftButton, CarouselRightButton } from '../../Constant';
import { ScInstructors, SocialSharing } from 'src/Components';
import { toggleCourseSidebar, getAllCourses } from '../../Redux/Actions';
import CampListRightSection from './CampListRightSection';
import { DOMAIN } from 'src/Constant';

const OwlCarousel = Loadable(() => import('react-owl-carousel'));

function innerFunc(item) {
  return { __html: item }
}
class CourseDetail extends React.Component {

  state = {
    display_count: 7,
    selected_img: '',
    selected_img_index: 0,
    imgIndex: 0,
    CourseCategories: CourseCategories
  }

  componentDidMount() {
    if (_.isEmpty(this.props.course_list)) {
      this.props.getAllCourses();
    }
  }

  closeSidebar = () => {
    if (this.props.isSideBar) {
      this.props.toggleCourseSidebar(false);
    } else {
      this.props.history.goBack()
    }
  }

  onCart = () => {
    if (this.props.campCartInfo.length > 0) {
      this.props.history.push('/summer-camp-registration');
      this.props.toggleCourseSidebar(false);
    }
  }

  onNewWindow = () => {
    if (this.props.isSideBar) {
      this.props.toggleCourseSidebar(false);
    } else {
      this.props.history.goBack()
    }
  }

  clickImage = async (img, index, margin) => {
    const itemWidth = $(`#carousel${index}`)[0].offsetWidth;
    const transformValue = -Math.floor((($('.owl-stage')[0].style.transform.split("(")[1]).split(',')[0]).slice(0, -2));
    const passWidth = itemWidth + margin;
    const passedCount = Math.round(transformValue / passWidth);
    const itemCount = Math.round($(`#carousel${index}`)[0].offsetParent.offsetLeft / passWidth);
    const finalIndex = index - itemCount + passedCount;

    await this.setState({ selected_img: img, imgIndex: finalIndex, selected_img_index: index })
    await this.setState({ selected_img: img, imgIndex: finalIndex, selected_img_index: index })
  }

  hideHeader = () => {
    const pathName = this.props.location.pathname;
    return (
      pathName === '/tutor-signup'
    )
  }


  render() {
    const { className, id, isSideBar, campCartInfo, course_list } = this.props;
    const { display_count, selected_img, imgIndex, selected_img_index } = this.state;
    let index = this.props.course_list.findIndex(temp => temp.id === id);
    const info = this.props.course_list[index];
    // const categoryIndex = CourseCategories.findIndex(temp => temp.id === info.category_id);
    const sliderImages = _.isEmpty(info) ? [] :
      _.isEmpty(info.videos) ? info.images : [info.videos[0].thumbImg, ...info.images]
    const ShareURL = DOMAIN + `course/${info?.id}`;

    return (
      <>
        {!_.isEmpty(course_list) &&
          <div className={`coursedetail-component v-r ${className || ''}`} >
            <div className={`detail-header ${!isSideBar ? "course-header" : ''}`}>
              <div className="container v-c">
                <div className="back-btn v-c">
                  <ReactSVG src={left} className="svg-icon" onClick={e => this.closeSidebar()} />
                  {/* <h1>{CourseCategories[categoryIndex].name}</h1> */}
                  <h1>{info.name}</h1>
                </div>
                {(!this.hideHeader() && isSideBar) &&
                  <NavLink to={`/course/${id}`} className="header-link show-web-flex" onClick={e => this.onNewWindow()}>
                    <p>Open course details in a new window</p>
                    <ReactSVG src={out} className="svg-icon" />
                  </NavLink>}
                {!this.hideHeader() &&
                  <div
                    className={`btn-link cursor-pointer`}
                    onClick={e => this.onCart()}>
                    <div className='cart-text'>{`${campCartInfo.length} Cart`}</div>
                  </div>
                }
              </div>
            </div>
            <div className="main-content">
              <div className="course-content v-r">
                <div className="container v-r">
                  <div className="content-group">
                    {selected_img_index === 0 ? (
                      _.isEmpty(info.videos[0]) ? (
                        <div className="image-container selected_img">
                          <img src={info.images[0]} alt="selected_img" />
                        </div>
                      ) : (
                        <iframe
                          src={`https://muse.ai/embed/${info.videos[0].embedCode}`}
                          frameBorder="0"
                          allowFullScreen
                          style={{ width: '100%', height: '400px', }}
                          title="video1">
                        </iframe>
                      )
                    ) : (
                      <div className="image-container selected_img">
                        <img src={selected_img || info.images[0]} alt="selected_img" />
                      </div>
                    )}
                    <Media queries={{
                      lg: "(min-width: 992px)",
                      md: "(min-width: 768px)",
                    }}>
                      {matches => (
                        <OwlCarousel
                          className="owl-theme"
                          rewind
                          nav
                          stagePadding={0}
                          margin={matches.lg ? 10 : matches.md ? 13 : 16}
                          items={matches.lg ? 7 : matches.md ? 5 : 3}
                          dots={false}
                          navText={[CarouselLeftButton, CarouselRightButton]}
                          startPosition={imgIndex}
                        >
                          {sliderImages.map((item, index) =>
                            <div className="image-container thumb-container" key={index} id={`carousel${index}`}>
                              <img
                                src={item}
                                className={`thumb ${selected_img === item ? 'active' : ''}`}
                                alt="img"
                                onClick={e => this.clickImage(item, index, matches.lg ? 10 : matches.md ? 13 : 16)} />
                            </div>)
                          }
                        </OwlCarousel>
                      )}
                    </Media>
                    <SocialSharing
                      ShareURL={ShareURL}
                      info={info}
                    />
                  </div>
                  <div className="content-group">
                    <h2>Description</h2>
                    <div className="group-body" dangerouslySetInnerHTML={innerFunc(info.description)} />
                  </div>
                  <div className="content-group">
                    <h2>What Students Will Learn</h2>
                    <div className="group-body">
                      {info.what_student_learn.map((item, index) => (index < display_count) &&
                        <div className="learn-item v-c" key={index}>
                          <ReactSVG src={confirm} className="svg-icon" />
                          <p>{item}</p>
                        </div>
                      )}
                      {display_count < info.what_student_learn.length && <p className="show-more-btn" onClick={e => this.setState({ display_count: display_count + 3 })}>More</p>}
                    </div>
                  </div>
                  <div className="content-group">
                    {/* <h2>{_.upperFirst(info.obj_type)} Summary</h2> */}
                    <h2>Camp Summary</h2>
                    <div className="group-body">
                      <ul>
                        {(Array.isArray(info.summary) ? info.summary : Object.keys(info.summary)).map((item) =>
                          <li key={item}>
                            {Array.isArray(info.summary) ? item : info.summary[item] + ' ' + item}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="content-group">
                    <h2>Camp Includes</h2>
                    <div className="group-body" >
                      {info.course_include.map((item, index) => <div className="learn-item v-c" key={index}>
                        <ReactSVG src={confirm} className="svg-icon first-item" />
                        <p>{item}</p>
                      </div>)}
                    </div>
                  </div>
                  <div className="content-group">
                    <h2>Prerequisites</h2>
                    <div className="group-body">
                      <p className="raw-text">{info.prerequisite}</p>
                    </div>
                  </div>
                  <div className="content-group">
                    <h2>Technical Requirements</h2>
                    <div className="group-body">
                      <p className="raw-text">{info.technical_requirement}</p>
                    </div>
                  </div>
                  <div className="content-group">
                    <h2>Some of our iGenius Summer Coding Camp Instructors</h2>
                    <div className="group-body">
                      <ScInstructors />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`course-summary v-r ${!isSideBar ? 'give-padding' : ''}`}>
                <div className="container course-summary-container v-r">
                  <CampListRightSection
                    info={info}
                    minAge={this.props.minAge}
                    maxAge={this.props.maxAge}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  };
};


function mapStateToProps(state) {
  return {
    course_list: state.Course.course_list,
    campCartInfo: state.Course.campCartInfo
  }
}

export default connect(mapStateToProps, {
  toggleCourseSidebar, getAllCourses
})(withRouter(CourseDetail));