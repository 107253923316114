import React from 'react';
import './Medias.scss';
import Media from 'react-media';
import Loadable from '@loadable/component';
import { CarouselLeftButton, CarouselRightButton } from '../../Constant';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllCourses } from '../../Redux/Actions';
import { Course } from '../';

const OwlCarousel = Loadable(() => import('react-owl-carousel'));

class Medias extends React.Component {

    state = {
        showList: []
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.showList !== this.props.showList) {
            this.setState({showList: this.props.showList});
        }
    }

    render() {
        const { showList } = this.state;
        return (
            <div className="roww medias-component">
                <Media queries={{
                    lg: "(min-width: 992px)",
                    md: "(min-width: 768px)",
                    }}>
                    {matches => (
                        <OwlCarousel
                            className="owl-theme"
                            rewind
                            nav
                            stagePadding={5}
                            margin={15}
                            items={ matches.lg ? 3 : matches.md ? 2 : 1}
                            dots={false}
                            loop={false}
                            navText={[CarouselLeftButton, CarouselRightButton]}
                            >
                            {showList.map((item, index) => <Course key={index} info={item} />)}
                        </OwlCarousel>
                    )}
                </Media>
            </div>
        )
    };
};


function mapStateToProps(state) {
    return {
        course_list: state.Course.course_list
    }
}

export default connect(mapStateToProps, { getAllCourses })(withRouter(Medias));