import React from 'react';
import './style.scss';
import { useHistory, NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import logoActive from 'src/Assets/Icon/logo-active.png';
import confirm from 'src/Assets/Icon/confirm.svg';

const nextInfo = [
  'Check your email for camp details and add to your schedule ',
  'Your camp instructor will contact you one week before the start of camp to ensure that the student has all that is needed to take part in the camp',
]
export default function SCRegistrationSuccessPage() {
  let history = useHistory();

  const onPayment = () => {
    history.push('/summer-camp-registration-success');
  }

  return (
    <div className='sc-registration-success-page'>
      <div className='container'>
        <div className='logo-section'>
          <NavLink to="/"><img src={logoActive} alt="logo" /></NavLink>
        </div>
        <div className="row sc-success-main-content mx-auto">
          <div>
            <h2>Thank you for signing up for iCode Edu Summer Camp </h2>
            <h3>What is Next?</h3>
          </div>
          {nextInfo.map((item, index) => <div className="learn-item v-c" key={index}>
            <ReactSVG src={confirm} className="svg-icon first-item" />
            <p>{item}</p>
          </div>)}
        </div>
      </div>
    </div>
  );
}