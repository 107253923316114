import { Api } from './Api';

export function* getAllCourses(timezone) {    
    const jsonData = yield Api.get(`/courses?timezone=${timezone}`);
    return jsonData;
}

export function* getCourseDetail(data) {    
    const jsonData = yield Api.get(`/courses/${data.course_id}?timezone=${data.timezone}`);
    return jsonData;
}

export function* getCourses() {
    const jsonData = yield Api.get(`/courses?type=course`);
    return jsonData;
}

export function* getCampCourses() {
    const jsonData = yield Api.get(`/courses?type=camp`);
    return jsonData;
}

export function* getSummerCampCourses() {
    const jsonData = yield Api.get(`/courses?type=summercamp`);
    return jsonData;
}