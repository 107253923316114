import React from 'react';
import './TrialPage.scss';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { InlineWidget } from 'react-calendly';
import moment from 'moment-timezone';
import _ from 'lodash'
import logoActive from '../../Assets/Icon/logo-active.png';
import ads1 from '../../Assets/Signup/trial-1.png';
import ads2 from '../../Assets/Signup/trial-2.png';
import ads3 from '../../Assets/Signup/trial-3.png';
import ads4 from '../../Assets/Signup/trial-4.png';
import ads5 from '../../Assets/Signup/trial-5.png';
import ads6 from '../../Assets/Signup/trial-6.png';
import ads7 from '../../Assets/Signup/trial-7.png';
import ads8 from '../../Assets/Signup/trial-8.png';
import ads9 from '../../Assets/Signup/ads-8.png';
import check from '../../Assets/Icon/check.svg';
import left from '../../Assets/Icon/arrow-left.svg';
import avatar from '../../Assets/Icon/avatar.svg';
import emailsvg from '../../Assets/Icon/email.svg';
import book from '../../Assets/Icon/book.svg';
import key from '../../Assets/Icon/key.svg';
import { InputBox, SelectBox } from '../../Components';
import { getAllCourses, signUp, getLevels, getLandingData } from '../../Redux/Actions';
import { SCHEDULE_URL, GetDashboardUrl } from '../../Constant';
import Helper from '../../Utils/Helper';
import {EmailValidation, PhoneValidation} from '../../Utils/Validation';

const Data = [
    {
        step: 1,
        title: "What is the student's coding experience?",
        selections: ['Beginner', 'Intermediate', 'Advanced', 'Not Sure'],
        whole: true,
        state_val: 'experience',
        ads_img: ads1,
    },
    {
        step: 2,
        title: 'How old is the student?',
        selections: ['6 years old', '7 years old', '8 years old', '9 years old', '10 years old', '11 years old', '12 years old', '13 years old', '+14 years old'],
        whole: false,
        state_val: 'age',
        ads_img: ads2,
    },
    {
        step: 3,
        title: "What is the student's grade level?",
        selections: [],
        whole: false,
        state_val: 'grade',
        grade: true,        
        ads_img: ads3,
    },
    {
        step: 4,
        progress: 14,
        title: 'What course would you like the student to take?',
        selections: [],
        state_val: 'course',
        ads_img: ads4,
        ads_margin: true,
        select: true,
        icon: book,
        placeholder: 'Course',
        // options: ['Game Design 101', 'Game Design 102', 'Game Design 103', 'Animation  Studio', 'AR Studio', 'Starter Course', 'Python Coding I', 'Python Coding II', 'Python Coding III', 'Drone Coding Studio', 'Not sure (Let advisor decide)'],
        btns: ['continue', 'skip']
    },
    {
        step: 5,
        progress: 28,
        title: "Parents full name",
        state_val: 'parent_name',
        ads_img: ads5,
        ads_margin: true,
        input: true,
        icon: avatar,
        placeholder: 'Full name',
        btns: ['continue']
    },
    {
        step: 6,
        progress: 42,
        title: "Parents email",
        state_val: 'email',
        ads_img: ads6,
        ads_margin: true,
        input: true,
        icon: emailsvg,
        placeholder: 'Email',
        warning: 'We take your privacy very seriously. No junk!',
        btns: ['continue']
    },
    {
        step: 7,
        progress: 56,
        title: "Password",
        state_val: 'password',
        ads_img: ads6,
        ads_margin: true,
        input: true,
        icon: key,
        placeholder: 'Password',
        password: true,
        btns: ['continue']
    },
    {
        step: 8,
        progress: 70,
        title: "Parents phone number",
        state_val: 'phone',
        state_country: 'country',
        ads_img: ads7,
        ads_margin: true,
        phone: true,
        icon: emailsvg,
        placeholder: 'Phone',
        country: 'US',
        btns: ['continue']
    },
    {
        step: 9,
        progress: 75,
        title: "Referal code",
        state_val: 'referrer_code',
        ads_img: ads7,
        ads_margin: true,
        input: true,
        icon: key,
        placeholder: 'Referal code',
        btns: ['continue', 'skip']
    },
    {
        step: 10,
        progress: 84,
        title: "Student name",
        state_val: 'first_name',
        ads_img: ads8,
        ads_margin: true,
        input: true,
        icon: avatar,
        placeholder: 'First name',
        btns: ['continue'],
        is_student_name: true
    },
    {
        step: 11,
        progress: 100,
        title: 'Complete!',
        explain: `You’re all set. The next step is to schedule a call with one of our enrollment directors.`,
        schedule_plugin: true,
        ads_img: ads9,
        btn: 'continue'
	},
	{
        step: 12,
        progress: 100,
        title: 'Thank you!',
        explain: `You can now enjoy using iCodeEdu service.`,
        ads_img: ads9,
        btn: 'finish'
    },
];

function innerFunc(item) {
    return {__html: item}
}

class TrialPage extends React.Component {
    state = {
        step: 1,

        age: '',
        experience: '',
        grade: '',        
        course: '',
        parent_name: '',
        email: '',
        password: '',
        country: 'US',
        phone: '',
        student_name: '',
        referrer_code: '',

        agree_term: false,
        current_timezone: moment.tz.guess(true)

    }

    componentDidMount() {
        this.props.getLandingData();
        if (_.isEmpty(this.props.course_list)) {
            this.props.getAllCourses();
        };

        if (_.isEmpty(this.props.levels)) {
            this.props.getLevels();
        };
        
        if (this.props.location.search.length > 0) {
            let refer_id = this.props.location.search.split("=")[1];
            this.setState({
                referrer_code: parseInt(refer_id, 10)
            });
            localStorage.setItem("referrer_code", parseInt(refer_id, 10));
        } else {
            const referrer_code = localStorage.getItem("referrer_code");
            if(referrer_code){
                this.setState({referrer_code})
            }
        };

        window.addEventListener('message', this.onWindowMessage);
    };

    componentWillUnmount() {
        window.removeEventListener('message', this.onWindowMessage);
    };

    onWindowMessage = (e) => {
        if (e.data.event && e.data.event.indexOf('calendly') === 0) {
            if (e.data.event === 'calendly.event_scheduled') {
                // setTimeout(() => {
                //     this.props.history.push('/');
                // }, 1000);
            };
        };
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.signup_success && this.props.signup_success) {
            this.setState({
                step: 11
            });
        };
        if (prevProps.signup_error_msg.length === 0 && this.props.signup_error_msg.length > 0) {
            Helper.showToast('error', 3000, this.props.signup_error_msg);
            return;
        };
        if(prevProps.get_landing_loading && !this.props.get_landing_loading) {
            this.setState({
                country: this.props.LandingData.country_code
            });
        };
    };

    selectItem = async (field, val) => {
        await this.setState({[field]: val});
        setTimeout(() => {
            this.moveForward()
        }, 200);        
    }

    onChange = (field, val) => {
        this.setState({[field]: val});
	};

    moveForward = (flag = 'normal') => {
        const { step, email, phone } = this.state;
        if (flag === 'skip') {
            if (step === 3) this.setState({grade: ''})
            if (step === 4) this.setState({course: ''})
        } else {
            if (step === 4 && this.state.course.length === 0) {
                Helper.showToast('error', 3000, "Please select a course!");
                return;
            }
            if ((step === 5 && this.state.parent_name.length === 0) 
                || (step === 6 && !EmailValidation(email))
                || (step === 7 && this.state.password.length === 0)
                || (step === 8 && !PhoneValidation(phone))
                || (step === 10 && _.isElement(this.state.first_name.length) && _.isElement(this.state.last_name.length))
                ) {
                Helper.showToast('error', 3000, "Please input valid information!");
                return;
            }
        }

        if (step === 10) {
            let { age, experience, grade, course, parent_name, email, password, country, phone, first_name, last_name, referrer_code } = this.state;

            age = age.slice(0, -10);
            if (age[0] === '+') age = age.substring(1, age.length);
            age = parseInt(age, 10);

            let course_id = 0;
            if (course.length > 0 && course !== 'Not sure (Let advisor decide)') {
                let index = this.props.course_list.findIndex(temp => temp.name === course);
                course_id = index === -1 ? 0 : this.props.course_list[index].id;
            }

            let SignupParam = {
                coding_experience: experience,
                age,
                have_computer: 1,
                level_id: grade.id ,
                course_id,
                parent_name,
                first_name: first_name,
                last_name: last_name,
                country_code: country,
                phone: phone,
                email,
                password,
                plan_id: "",
                start_when: "",
                lesson_per_week: "",
                timezone: moment.tz(moment.utc(), this.state.current_timezone).utcOffset()/60,
                referrer_code: referrer_code,
            }
            this.props.signUp(SignupParam);
            return;
        } 
        if (step === 12) {
            window.location.href = GetDashboardUrl(this.props.user, this.props.token);
            return;
        }
        this.setState({step: step + 1})
    };

    goBack = () => {
        this.setState({step: this.state.step !== 1 ? this.state.step - 1 : this.state.step});
    };

    SelectedDiv = () => <div className="selected"> <ReactSVG src={check} className="svg-icon" /> </div>

    renderStep = (Datas) => {
        const { step } = this.state;
        // let Courses = Array.from(this.props.course_list, x => x.name);
        // Courses.push('Not sure (Let advisor decide)')

        return Datas.map((info, dataIndex) => step === info.step && <div className="row" id={`section-${info.step}`} key={dataIndex}>
            <div className="col-lg-7 order-lg-1 order-2 left-col">
                {info.progress && <div className="progress-bar h-c"><div className="current-progress" style={{width: `${info.progress}%`}} /></div>}
                <h1>{info.title}</h1>
                {info.explain && <p dangerouslySetInnerHTML={innerFunc(info.explain)} />}
                {(info.selections && info.selections.length > 0) && <div className={`item-container ${info.whole ? 'whole' : ''} ${info.web_whole ? 'web-whole' : ''}`}>
                    {info.selections.map((item, index) => <div className={`select-item ${item === this.state[info.state_val] ? 'active' : ''}`} key={index} onClick={e => this.selectItem(info.state_val, item)}>
                        {this.SelectedDiv()}
                        {item}
                    </div>)}
                    {info.bubble && <div className="bubble h-c v-c">{info.bubble}</div>}
                </div>}
                {info.grade && <div className={`item-container ${info.whole ? 'whole' : ''} ${info.web_whole ? 'web-whole' : ''}`}>
                    {this.props.levels.map((item, index) => <div className={`select-item ${item === this.state[info.state_val] ? 'active' : ''}`} key={index} onClick={e => this.selectItem(info.state_val, item)}>
                        {this.SelectedDiv()}
                        {item.name}
                    </div>)}
                    {info.bubble && <div className="bubble h-c v-c">{info.bubble}</div>}
                </div>}
                <div className="sub-section v-r">
                    {info.select && <SelectBox className="select-box" icon={info.icon} placeholder={info.placeholder} options={this.props.course_list} value={this.state[info.state_val]} onChange={e => this.onChange(info.state_val, e.name)} />}
                    {info.input && <InputBox className="input-box" type={info.password ? 'password' : ''} icon={info.icon} placeholder={info.placeholder} value={this.state[info.state_val]} onChange={e => this.onChange(info.state_val, e)} />}
                    {info.is_student_name && <InputBox className="input-box" icon={info.icon} placeholder={"Last Name"} value={this.state["last_name"]} onChange={e => this.onChange("last_name", e)} />}
                    {info.phone && <InputBox className="input-box" phone_input type="number" placeholder={info.placeholder} value={this.state[info.state_val]} country={this.state[info.state_country]} onSelect={e => this.onChange(info.state_country, e)} onChange={e => this.onChange(info.state_val, e)}/>}
                    {info.warning && <div className="warning">{info.warning}</div>}
                    {(info.btns && info.btns.length > 0) && <div className="sub-btn-area">
                        {info.btns.map((item, index) => <button className={`btn-2 primary-btn ${this.props.signup_loading ? 'disabled' : ''} ${item}`} key={index} onClick={e => this.moveForward(item)}>
                            {this.props.signup_loading ? <div className="spinner" /> : item}
                        </button> )}
                    </div>}
                </div>
                {info.schedule_plugin && <InlineWidget styles={{ minWidth:'320px',height:'630px', marginBottom:'10px' }} url={SCHEDULE_URL} />}
                {info.btn && <button className="btn-2 primary-btn" onClick={e => this.moveForward(info.btn)}> {info.btn} </button>}
                {(step > 1 && step < 11 && !this.props.signup_loading) && <div className="v-c back-btn" onClick={e => this.goBack()}><ReactSVG src={left} className="svg-icon" />&nbsp;Back</div>}
            </div>
            <div className={`col-lg-5 order-lg-2 order-1 right-col ${info.ads_margin ? 'ads-margin' : ''}`}>
                <img src={info.ads_img} alt="ads" className="ads-img" />
            </div>
        </div>)
    }

    render() {
        return (
            <div className="trial-page">
                <div className="container v-r">
                    <div className="logo-area">
                        <NavLink to="/"><img src={logoActive} alt="logo" /></NavLink>
                    </div>
                    <div className="main-content v-r">
                        {this.renderStep(Data)}
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        signup_loading: state.Auth.signup_loading,
        signup_success: state.Auth.signup_success,
        signup_error_msg: state.Auth.signup_error_msg,
		course_list: state.Course.course_list,
		levels: state.Levels.levels,
		
		token: state.Auth.token,
        user: state.Auth.user,
        LandingData: state.Landing.LandingData,
        get_landing_loading: state.Landing.get_landing_loading,
    }
}

export default connect(mapStateToProps, { 
    getAllCourses,
    getLevels,
    signUp,
    getLandingData
})(withRouter(TrialPage));