import { Api } from './Api';

export function* getLandingData(){
    const jsonData = yield Api.get("/landing");
    return jsonData;
}

export function* userSubmit(data){
    const jsonData = yield Api.post("/landing", data);
    return jsonData;
}