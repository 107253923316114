import React from 'react';
import './style.scss';
import { InputBox } from 'src/Components';
import AvatarIcon from 'src/Assets/Icon/avatar.svg';
import EmailIcon from 'src/Assets/Icon/email.svg';
import KeyIcon from 'src/Assets/Icon/key.svg';

export default function ParentForms({
  parentInfo,
  onChangeParentInfo
}) {

  return (
    <div className='parent-forms-component'>
      <InputBox
        className="input-box"
        icon={AvatarIcon}
        placeholder={"Parent First Name"}
        value={parentInfo.firstName}
        validation={parentInfo.firstNameValid}
        onChange={e => onChangeParentInfo('firstName', 'firstNameValid', e)}
      />
      <InputBox
        className="input-box"
        icon={AvatarIcon}
        placeholder={"Parent Last Name"}
        value={parentInfo.lastName}
        validation={parentInfo.lastNameValid}
        onChange={e => onChangeParentInfo('lastName', 'lastNameValid', e)}
      />
      <InputBox
        className="input-box"
        icon={EmailIcon}
        placeholder={"Email Address"}
        value={parentInfo.email}
        validation={parentInfo.emailValid}
        onChange={e => onChangeParentInfo('email', 'emailValid', e)}
      />
      <InputBox
        className="input-box"
        icon={KeyIcon}
        placeholder={"Password"}
        value={parentInfo.password}
        validation={parentInfo.passwordValid}
        type={'password'}
        onChange={e => onChangeParentInfo('password', 'passwordValid', e)}
      />
      <InputBox
        className="input-box"
        phone_input
        type="number"
        placeholder={"Phone Number"}
        value={parentInfo.phone}
        validation={parentInfo.phoneValid}
        country={parentInfo.country}
        onSelect={e => onChangeParentInfo('country', 'phoneValid', e)}
        onChange={e => onChangeParentInfo('phone', 'phoneValid', e)}
      />
    </div>
  );
}