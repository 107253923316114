import React from 'react';
import './GetMoreInfo.scss';
import Slider from "react-slick";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from 'lodash'

import important from '../../../Assets/StudentSignup/important.png';
import getMoreBg from '../../../Assets/StudentSignup/getMoreBg.png';
import { ReactSVG } from 'react-svg';

import avatar from '../../../Assets/Icon/avatar.svg';
import emailSVG from '../../../Assets/Icon/email.svg';
import calendar from '../../../Assets/StudentSignup/calendar.svg';
import check from '../../../Assets/Icon/check.svg';

import { InputBox, SelectBox } from '../../../Components';
import { userSubmit } from '../../../Redux/Actions';
import Helper from '../../../Utils/Helper';
import { EmailValidation } from '../../../Utils/Validation';

const Ages = [
    { id: 1, name: '7-9 years old' },
    { id: 2, name: '10-11 years old' },
    { id: 3, name: '12-13 years old' },
    { id: 4, name: '14-16 years old' },
    { id: 5, name: '17-18 years old' },
]

const haveComputerOption = ["Yes", "No"]

class GetMoreInfo extends React.Component {
    state = {
        name: '',
        age: '',
        email: '',
        country: 'US',
        phone: '',
        havePC: "Yes",
        loadingEnd: false
    }

    componentDidUpdate(prevProps) {
        const { submit_loading, submit_success, submit_message, get_landing_loading, LandingData } = this.props;
        if (prevProps.submit_loading && !submit_loading) {
            Helper.hideSpinner();
            if (submit_success) {
                Helper.showToast('success', 3000, submit_message);
                this.props.history.push('/thankyou');
            } else {
                Helper.showToast('error', 3000, submit_message)
                return;
            }
        }
        if (prevProps.get_landing_loading && !get_landing_loading) {
            this.setState({
                country: LandingData.country_code,
                loadingEnd: true
            });
        };
    };

    onChange = (field, val) => {
        this.setState({ [field]: val });
    };

    SelectedDiv = () => <div className="selected"> <ReactSVG src={check} className="svg-icon" /> </div>
    selectItem = async (field, val) => {
        await this.setState({ [field]: val });
    }

    submit = () => {
        const { name, age, email, country, phone, havePC } = this.state;
        const submitParam = {
            name,
            student_age: age,
            email,
            country_code: country,
            phone,
            have_computer: havePC === 'Yes' ? 1 : 0
        }
        if (name && age && EmailValidation(email) && phone) {
            Helper.showSpinner();
            this.props.userSubmit(submitParam);
        } else {
            Helper.showToast('error', 3000, "Please input valid information!");
        };
    };

    render() {
        const { LandingData } = this.props;
        const { name, age, email, phone, country, havePC, loadingEnd } = this.state;
        return (
            <div className="getMoreInfo-component">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 v-r sub-col">
                            <h1>Get More Information From An ICode Edu <stong className="stron-text">Advisor</stong> TODAY</h1>
                            <div className='mb-2'>
                                <img src={important} className="important-img" alt="important" />
                            </div>
                            <Slider
                                dots={true}
                                infinite={true}
                                speed={500}
                                slidesToShow={1}
                                slidesToScroll={1}
                                arrows={false}>
                                {!_.isEmpty(LandingData.expert_reviews) && LandingData.expert_reviews.map((item, index) => (
                                    <div key={index}>
                                        <p className='mb-3'>{item.content}</p>
                                        <div className='v-c'>
                                            <div className='mr-2'>
                                                <img src={item.picture} className="roland-photo" alt="Roland_photo" />
                                            </div>
                                            <div>
                                                <div className='roland-name'>{item.name}</div>
                                                <div className='roland-role'>{item.job}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                        <div className="col-lg-6 right-col">
                            <div className='show-web'>
                                <img src={getMoreBg} className="right-img" alt="right_img" />
                            </div>
                            <div className='from-content'>
                                <h2 className='mb-3'>Ge More info</h2>
                                <InputBox
                                    className="input-form"
                                    value={name}
                                    icon={avatar}
                                    placeholder="Full name"
                                    onChange={e => this.onChange('name', e)}
                                />
                                <SelectBox
                                    className="input-form"
                                    icon={calendar}
                                    placeholder={'Student age'}
                                    options={Ages}
                                    value={age}
                                    onChange={e => this.onChange('age', e.name)}
                                />
                                <InputBox
                                    className="input-form"
                                    value={email}
                                    icon={emailSVG}
                                    placeholder="Email"
                                    onChange={e => this.onChange('email', e)}
                                />
                                {loadingEnd && <InputBox
                                    className="input-form phone-input"
                                    icon={emailSVG}
                                    phone_input
                                    type="number"
                                    placeholder="Phone number"
                                    value={phone}
                                    country={country}
                                    onSelect={e => this.onChange('state_country', e)}
                                    onChange={e => this.onChange('phone', e)}
                                />}
                                <div className="have-computer-section">
                                    {haveComputerOption.map((item, index) =>
                                        <div
                                            className={`select-item ${item === havePC ? 'active' : ''}`}
                                            key={index}
                                            onClick={e => this.onChange('havePC', item)}>
                                            {this.SelectedDiv()}
                                            {item}
                                        </div>)}
                                </div>
                                <div className='sub-btn v-c h-c' onClick={this.submit}>
                                    SUBMIT
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        submit_loading: state.Landing.submit_loading,
        submit_success: state.Landing.submit_success,
        submit_message: state.Landing.submit_message,

        get_landing_loading: state.Landing.get_landing_loading,

    }
}

export default connect(mapStateToProps, {
    userSubmit
})(withRouter(GetMoreInfo));