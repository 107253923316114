import React from 'react';
import './Instructors.scss';
import { ReactSVG } from 'react-svg';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import check from '../../../Assets/Icon/checkmark.png';
import tcu from '../../../Assets/University/tcu.png';
import harvard from '../../../Assets/University/harvard.png';
import yale from '../../../Assets/University/yale.png';
import nyu from '../../../Assets/University/nyu.png';
import texas from '../../../Assets/University/texas.png';
import princeton from '../../../Assets/University/princeton.png';
import lsu from '../../../Assets/University/lsu.png';
import stanford from '../../../Assets/University/stanford.png';
import usc from '../../../Assets/University/usc.png';
import columbia from '../../../Assets/University/columbia.png';

import tutor1 from '../../../Assets/University/tutor1.png';
import tutor2 from '../../../Assets/University/tutor2.png';
import tutor3 from '../../../Assets/University/tutor3.png';
import tutor4 from '../../../Assets/University/tutor4.png';
import tutor5 from '../../../Assets/University/tutor5.png';
import tutor6 from '../../../Assets/University/tutor6.png';
import tutor7 from '../../../Assets/University/tutor7.png';
import tutor8 from '../../../Assets/University/tutor8.png';
import tutor9 from '../../../Assets/University/tutor9.png';
import tutor10 from '../../../Assets/University/tutor10.png';
import left from '../../../Assets/Icon/left.svg';
import right from '../../../Assets/Icon/right.svg';

import microsoft from '../../../Assets/Brand/microsoft.png';
import facebook from '../../../Assets/Brand/facebook.png';
import hotwheel from '../../../Assets/Brand/hot-wheels.png';
import barbie from '../../../Assets/Brand/barbie.png';
import minecraft from '../../../Assets/Brand/minecraft.png';
import googleedu from '../../../Assets/Brand/google-edu.png';
import apple from '../../../Assets/Brand/apple.png';
import mattel from '../../../Assets/Brand/mattel.png';
import pbs from '../../../Assets/Brand/pbs.png';
import lego from '../../../Assets/Brand/lego.png';
import brandbot from '../../../Assets/Brand/brand-bot.png';
//import { GetDashboardUrl } from '../../../Constant';

const Universities = [
    { id: 1, tutor: 'Roland O.', university: 'Texas Christian University', photo: tutor1, logo: tcu, },
    { id: 2, tutor: 'Daniel Ziblatt', university: 'Harvard University', photo: tutor2, logo: harvard, },
    { id: 3, tutor: 'Ramamurti Shankar', university: 'Yale University', photo: tutor3, logo: yale, },
    { id: 4, tutor: 'Louis S Karchin', university: 'New York University', photo: tutor4, logo: nyu, },
    { id: 5, tutor: 'Andrew Ellington', university: 'The University of Texas at Austin', photo: tutor5, logo: texas, },
    { id: 6, tutor: 'Imani Perry', university: 'Princeton University', photo: tutor6, logo: princeton, },
    { id: 7, tutor: 'Jeffrey Perry', university: 'Louisiana State University', photo: tutor7, logo: lsu, },
    { id: 8, tutor: 'Koski_William', university: 'Stanford University', photo: tutor8, logo: stanford, },
    { id: 9, tutor: 'Robert Schiller', university: 'University of Southern California', photo: tutor9, logo: usc, },
    { id: 10, tutor: 'Burcu Baykurt', university: 'Columbia University in the City of New York', photo: tutor10, logo: columbia, },
];

const Brands = [
    microsoft, facebook, hotwheel, barbie, minecraft, googleedu, apple, mattel, pbs, lego
];

const Instructions = [
    'All instructors are from top U.S universities', 
    'Our instructors endeavor to make sure they are not just teachers, but mentors to their students', 
    'All Instructors have extensive experience in programming and coding', 
    'Each instructor produces a detailed report to parents at the completion of each 50 min lesson, outlining progress, learning goals, and more!',
];

class Instructors extends React.Component {
    state = {
        active_index: 0,
        flow_direction: 'right'
    }

    changeUniversity = (direction) => {
        let { active_index } = this.state;
        direction === 'left' ? active_index-- : active_index++;
        active_index = direction === 'left' ? active_index < 0 ? Universities.length - 1 : active_index
                                            : active_index > Universities.length - 1 ? 0 : active_index;
        this.setState({active_index, flow_direction: direction});
    }

    render() {
        const { active_index, flow_direction } = this.state;

        return (
            <div className="instructors-component v-r">
                <div className="instructor-section">
                    <div className="container">
                        <h1>Our instructors are from top U.S universities </h1>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="instructor-content round-object v-c">
                                    <div className="photo-flow">
                                        {Universities.map((item, index) => <img src={item.photo} key={index} alt="tutor" className={`tutor-photo ${flow_direction} ${index === active_index? 'active' : 'inactive'}`} />)}
                                        <div className="tutor-info v-r">
                                            <h2>{Universities[active_index].tutor}</h2>
                                            <p>{Universities[active_index].university}</p>
                                        </div>
                                        <div className="icon-btn left v-c h-c circle" onClick={e => this.changeUniversity('left')}><ReactSVG src={left} className="svg-icon"/></div>
                                        <div className="icon-btn right v-c h-c circle" onClick={e => this.changeUniversity('right')}><ReactSVG src={right} className="svg-icon"/></div>
                                    </div>
                                    <div className="detail v-r">
                                        {Instructions.map((item, index) => <div className="detail-row" key={index}>
                                            <img src={check} alt="check" />
                                            <p>{item}</p>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 v-r">
                                {Universities.map((item, index) => (index % 2 === 0) && <div className="logo-row v-c" key={index}>
                                    <div className={`v-c h-c round-object university ${active_index === index ? 'active' : ''}`}><img src={Universities[index].logo} alt="university-logo" /></div>
                                    <div className={`v-c h-c round-object university ${active_index === index+1 ? 'active' : ''}`}><img src={Universities[index+1].logo} alt="university-logo" /></div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="brand-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 sub-col v-r">
                                <div className="brand-explain v-r">
                                    <h1>Our curriculum is trusted by these top brands </h1>
                                    <NavLink to="start-trial" className="btn-link">START YOUR TRIAL</NavLink>
                                    {/* {this.props.token.length === 0 ? <NavLink to="start-trial" className="btn-link">START YOUR TRIAL</NavLink>
                                                                   : <a href={GetDashboardUrl(this.props.user, this.props.token)} className="btn-link" target="_blank" rel="noopener noreferrer">GO TO DASHBOARD</a>} */}
                                </div>
                                <img src={brandbot} alt="brandbot" className="show-web" />
                            </div>
                            <div className="col-lg-6 v-r">
                                {Brands.map((item, index) => (index % 3 === 0) && <div className="brand-row show-web-flex" key={index}>
                                    <div className="brand-container v-c h-c round-object"> <img src={Brands[index]} alt="brand" /> </div>
                                    {Brands[index+1] && <div className="brand-container v-c h-c round-object"> <img src={Brands[index+1]} alt="brand" /> </div> }
                                    {Brands[index+2] && <div className="brand-container v-c h-c round-object"> <img src={Brands[index+2]} alt="brand" /> </div> }
                                </div>)}
                                {Brands.map((item, index) => (index % 2 === 0) && <div className="brand-row show-mobile-flex" key={index}>
                                    <div className="brand-container v-c h-c round-object"> <img src={Brands[index]} alt="brand" /> </div>
                                    <div className="brand-container v-c h-c round-object"> <img src={Brands[index+1]} alt="brand" /> </div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        token: state.Auth.token,
        user: state.Auth.user,
    }
}

export default connect(mapStateToProps, {})(withRouter(Instructors));
