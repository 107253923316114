import React from 'react';
import './CheckBox.scss';
import { ReactSVG } from 'react-svg';
import tick from '../../Assets/Icon/check.svg';

export default class CheckBox extends React.Component {
    state = {
        checked: this.props.value,
    }

    onChange = () => {
        this.props.onChange(!this.state.checked);
        this.setState({
            checked: !this.state.checked,
        })
    }

    render() {
        const { className } = this.props;
        const { checked } = this.state;

        return (
            <div className={`checkbox-component ${className || ''} ${checked ? 'checked' : ''}`} onClick={this.onChange}>
                {checked && <ReactSVG src={tick} className="svg-icon" /> }
            </div>
        )
    };
};