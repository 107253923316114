import React from 'react';
import './TechnologyArea.scss';

const Technologies = [
    'Collaboration', 'Learning'
];

export default class TechnologyArea extends React.Component {
    state = {
        active_technology: 'Collaboration'
    }

    render() {
        const { active_technology } = this.state;
        return (
            <div className="technologyarea-component">
                <div className="container">
                    <h1>Our Technology</h1>
                    <div className="tech-row v-c">
                        {Technologies.map((item, index) => <div className={`tech v-c h-c ${active_technology === item ? 'active' : ''}`} key={index} onClick={e=>this.setState({active_technology: item})}>
                            {item}
                        </div>)}
                    </div>
                    <h2>{active_technology === 'Collaboration' ? 'We use ZOOM for education  for our live 1:1 teaching sessions' : 'Learn, Practice, Create and Download'}</h2>
                    <div className="tech-content">
                        {active_technology === 'Collaboration' && <div className="tech-list left">
                            <div className="tech-item item-1 round-object">
                                <div className="border border-1" />Same technology use by the<br />largest companies in the work.
                            </div>
                            <div className="tech-item item-2 round-object">
                                <div className="border border-2" />Video call and voice call.
                            </div>
                            <div className="tech-item item-3 round-object">
                                <div className="border border-3" />Screen sharing.
                            </div>
                        </div>}
                        {active_technology === 'Learning' && <div className="tech-list left learning">
                            <div className="tech-item item-1 round-object">
                                <div className="border border-1" />Complete hundreds of<br />homework and activities.
                            </div>
                            <div className="tech-item item-2 round-object">
                                <div className="border border-2" />Learn and track progress. 
                            </div>
                        </div>}
                        <div className={`video-area ${active_technology === 'Learning' ? 'learning' : ''}`}>
                            <div className="player-area">
                            <iframe src="https://muse.ai/embed/97YV8Ph" frameBorder="0" allowFullScreen style={{ width: '100%', height: '100%',  display: active_technology === 'Learning' ? '' : 'none' }} title="video1"></iframe>
                            <iframe src="https://muse.ai/embed/bzbeL1Z" frameBorder="0" allowFullScreen style={{ width: '100%', height: '100%',  display: active_technology === 'Learning' ? 'none' : '' }} title="video2"></iframe>
                            </div>
                        </div>
                        {active_technology === 'Collaboration' && <div className="tech-list right">
                            <div className="tech-item item-1 round-object">
                                <div className="border border-3" />Extremely reliable and<br />dependable with no interruptions.
                            </div>
                            <div className="tech-item item-2 round-object">
                                <div className="border border-1" />Live chat. 
                            </div>
                            <div className="tech-item item-3 round-object">
                                <div className="border border-2" />Lesson recording.
                            </div>
                        </div>}
                        {active_technology === 'Learning' && <div className="tech-list right learning">
                            <div className="tech-item item-1 round-object">
                                <div className="border border-3" />Save and continue from<br />where you left off.
                            </div>
                            <div className="tech-item item-2 round-object">
                                <div className="border border-1" />Published completed projects.
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        )
    };
};
