import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import './CheckoutForm.scss'

export default class CheckoutForm extends React.Component {
    state = {
        error: ''
    }

    handleSubmit = async () => {
        const { stripe, elements } = this.props;
        const result = await stripe.createToken(elements.getElement(CardElement))
        return result;
    };

    handleChange = (event) => {
        let error = event.error ? event.error.message : ''
        this.setState({
            error: error
        })
        this.props.updateError(error);
    }

    render() {
        const { error } = this.state;
        return (
            <div className="checkout-form v-r">
                <CardElement 
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#330D63',
                                "::placeholder": {
                                    color: "#A898BD"
                                }
                            }
                        }
                    }}
                    onChange={e => this.handleChange(e)}
                />
                {error.length > 0 && <div className="check-error">{error}</div>}
            </div>
        );
    }
}