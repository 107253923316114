import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SummerCampDetailHeader, SummerCampDetail } from 'src/Components';
import { getSCStateType } from 'src/Redux/Actions';
import { showSpinner, hideSpinner } from 'src/Utils/Helper';

export default function StateTypeSCPage() {
  const dispatch = useDispatch();

  const { state } = useParams()
  const { type } = useParams()

  const getLoadingRef = useRef(false);
  const {
    scStateTypeInfo, get_sc_state_type_success, get_sc_state_type_loading
  } = useSelector(
    state => state.SummerCamp,
  );

  const [stateName, setStateName] = useState('')
  const [typeName, setTypeName] = useState('')
  const [camps, setCamps] = useState('')

  useEffect(() => {
    showSpinner()
    setStateName(state);
    setTypeName(type);
    dispatch(getSCStateType({ state, type }));
  }, [dispatch, state, type]);

  useEffect(() => {
    if (getLoadingRef.current && !get_sc_state_type_loading) {
      hideSpinner();
      if (get_sc_state_type_success) {
        setCamps(scStateTypeInfo)
      }
    }
    getLoadingRef.current = get_sc_state_type_loading;
  }, [dispatch, scStateTypeInfo, get_sc_state_type_loading, get_sc_state_type_success]);

  const getSummary = () => {
    const summary = `
      Welcome to our ${stateName} ${typeName} Summer Camp Guide 2023! 
      This guide provides a comprehensive list of the best ${typeName} camps available 
      in Texas for the year 2023. We have researched 
      and handpicked the top soccer camps that cater to players of all ages and skill levels. 
      Our guide includes detailed information 
      about each camp, such as dates, locations, and pricing, 
      to help parents choose the perfect summer camp for their child. 
      Get ready to score big and improve 
      your soccer skills with our ${stateName} ${typeName} Summer Camp Guide 2023!
    `
    return summary
  }
  
  const getTags = () => {
    const tags = [
      {name: stateName, link: `/summer-camps/state/${stateName}`},
      {name: typeName, link: `/summer-camps/state/${stateName}/type/${typeName}`},
    ]
    return tags
  }

  return (
    <div className="state-type-summer-camp-page">
      <div className="container">
        <a href="/summer-camps">Summer Camp Guide</a> 
        | <a href={'/summer-camps/state/' + stateName}>{stateName}</a> 
        | <a href={`/summer-camps/state/${stateName}/type/${typeName}`}>{typeName}</a>
      </div>
      <SummerCampDetailHeader
        tags={getTags()}
        title={`${stateName} ${typeName} Summer Camp Guide 2023`}
      />
      {camps && <SummerCampDetail
        camps={camps}
        summary={getSummary()}
      />}
    </div>
  );
}
