import React from 'react';
import './Benefits.scss';

const videoEmbedCode = 'fMMFU9NXBFo'

export default class Benefits extends React.Component {

    render() {
        return (
            <div className="benefits-component">
                <div className="container">
                    <h3>AS SEEN ON</h3>
                    <div className='show-web'>
                        <iframe
                            title="video1"
                            width="100%" 
                            height="450"
                            src={`https://www.youtube.com/embed/${videoEmbedCode}`} 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen>              
                        </iframe>
                    </div>
                    <div className='show-mobile'>
                        <iframe
                            title="video2"
                            width="100%" 
                            height="250"
                            src={`https://www.youtube.com/embed/${videoEmbedCode}`} 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen>              
                        </iframe>
                    </div>
                </div>
            </div>
        )
    };
};
