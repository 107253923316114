import React from 'react';
import './ReviewsArea.scss';
import Media from 'react-media';
import Loadable from '@loadable/component';
import { CarouselLeftButton, CarouselRightButton } from '../../../Constant';
import { NavLink } from 'react-router-dom';
import { Feedback } from '../../';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllReviews } from '../../../Redux/Actions';

const OwlCarousel = Loadable(() => import('react-owl-carousel'));

class ReviewsArea extends React.Component {
  state = {
    show_list: []
  }

  componentDidMount() {
    if (this.props.review_list.length === 0) {
      this.props.getAllReviews();
    } else {
      this.filterList();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.review_list.length === 0 && this.props.review_list.length > 0) {
      this.filterList();
    }
  }

  filterList = async () => {
    await this.setState({ show_list: this.props.review_list });
    await this.setState({ show_list: this.props.review_list });
  }

  render() {
    const { show_list } = this.state;
    return (
      <div className="reviewsarea-component">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 v-r sub-col">
              <h1>What Our Parents And Students have to say about iCode Edu</h1>
              <NavLink to="/reviews" className="btn-link">SEE MORE REVIEWS</NavLink>
            </div>
            <div className="col-lg-8">
              <Media queries={{
                ex: "(min-width: 1200px)",
                lg: "(min-width: 992px)",
                md: "(min-width: 768px)",
              }}>
                {matches => (
                  <OwlCarousel
                    className="owl-theme"
                    rewind
                    nav
                    stagePadding={0}
                    margin={matches.ex ? 30 : 15}
                    items={matches.lg ? 2 : 1}
                    dots={false}
                    loop={false}
                    navText={[CarouselLeftButton, CarouselRightButton]}
                  >
                    {show_list.map((item, index) => <Feedback info={item} index={index} key={index} />)}
                  </OwlCarousel>
                )}
              </Media>
            </div>
          </div>
        </div>
      </div>
    )
  };
};

function mapStateToProps(state) {
  return {
    review_list: state.Review.review_list
  }
}

export default connect(mapStateToProps, { getAllReviews })(withRouter(ReviewsArea));