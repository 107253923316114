import React from 'react';
import './StoryPreview.scss';
import mark from '../../Assets/Course/mark.png';
import { NavLink } from 'react-router-dom';
//import { CourseCategories } from '../../Constant';
import { ReactSVG } from 'react-svg';
import arrow from '../../Assets/Icon/right-arrow.svg';

export default class StoryPreview extends React.Component {
    render() {
        const { className, info } = this.props;
        // const categoryIndex = CourseCategories.findIndex(temp => temp.id === info.category_id);
        let tagString = '';
        // info.tags = info.keywords.split(',');
        for (let item of info.keywords) tagString += `#${item} `;
        return (
            <div className={`storypreview-component v-r ${className || ''}`} >
                <img src={mark} alt="mark" className="right-mark" />
                <div className="sub-block thumb-container round-object">
                    <img src={info.picture} className="course-thumb round-object" alt="thumb" />
                </div>
                <div className="sub-block info-container round-object">
                    <h2 className="sub-header-info">{info.title}</h2>
                    <div className="category h-c v-c round-object">{info.topic.name}</div>
                    <p className="description">{info.excerpt}</p>
                    <div className="preview-footer v-c">
                        <div className="tags v-c">{tagString}</div>
                        <NavLink to={`/story/${info.id}`} className="btn-link"><ReactSVG src={arrow} className="svg-icon" /></NavLink>
                    </div>
                </div>
            </div>
        )
    };
};