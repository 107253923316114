import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import _ from 'lodash';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import confirm from 'src/Assets/Icon/confirm.svg';
import { TimeZone, CampTimeItem, EnrollDetail } from 'src/Components';
import { setCampCartInfo, } from 'src/Redux/Actions';
import DropDownSelect from 'src/Components/Common/DropDownSelect';
import { getCourseDetail } from 'src/Redux/Actions';
import { showSpinner, hideSpinner } from 'src/Utils/Helper';

export default function CampListRightSection({
  info, minAge, maxAge
}) {
  const dispatch = useDispatch();
  const getLoadingRef = useRef(false);
  const {
    course_detail, get_course_detail_success, get_course_detail_loading
  } = useSelector(
    state => state.Course,
  );

  useEffect(() => {
    if (getLoadingRef.current && !get_course_detail_loading) {
      hideSpinner();
      if (get_course_detail_success) {
        setGroupclasses(course_detail.groupclasses)
        setCurrentDateTimes(course_detail.groupclasses[0])
      }
    }
    getLoadingRef.current = get_course_detail_loading;
  }, [dispatch, course_detail, get_course_detail_loading, get_course_detail_success]);

  const {
    campCartInfo,
  } = useSelector(
    state => state.Course,
  );

  const [currentTimezone, setCurrentTimezone] = useState(moment.tz.guess(true));
  const [currentDateTimes, setCurrentDateTimes] = useState(info.groupclasses[0] || [])
  const [groupclasses, setGroupclasses] = useState(info.groupclasses)

  const onSelectDate = (item) => {
    setCurrentDateTimes(item);
  }

  const onAddToCard = (date, time, available) => {
    const _newCampItem = {
      id: info.id,
      name: info.name,
      date,
      time,
      available: Number(available),
      count: 1,
      timezone: currentTimezone,
      minAge,
      maxAge
    }

    const _index = _.findIndex(campCartInfo, (camp) => camp.id === info.id && camp.time === time && camp.date === date);
    if (_index > -1) {
      campCartInfo.splice(_index, 1)
      dispatch(setCampCartInfo([...campCartInfo]));
    } else {
      dispatch(setCampCartInfo([
        ...campCartInfo,
        _newCampItem
      ]));
    }
  };

  const onChangeTimezone = (val) => {
    setCurrentTimezone(val);
    const payload = {
      course_id: info.id,
      timezone: moment.tz(moment.utc(), val).utcOffset() / 60
    }
    showSpinner();
    dispatch(getCourseDetail(payload));
  }

  return (
    <div className="camp-list-right-section-component">
      <h3>ENROLL TO CAMP</h3>
      {!_.isEmpty(groupclasses) &&
        <div className='date-time-content'>
          <div className='date-time-item'>
            <h4>Select Meeting Date</h4>
            <DropDownSelect
              dropListInfo={groupclasses}
              value={currentDateTimes.date}
              onSelect={onSelectDate}
            />
          </div>``
          <div className='date-time-item'>
            <h4>Select your timezone</h4>
            <TimeZone
              className={'timezone-content'}
              value={currentTimezone}
              onChange={val => onChangeTimezone(val)}
            />
          </div>
          <div className='date-time-item'>
            <h4>Select Camp Time</h4>
            {currentDateTimes.times.map((item, index) => (
              <CampTimeItem
                key={index}
                item={item}
                campId={info.id}
                campDate={currentDateTimes.date}
                campCartInfo={campCartInfo}
                onAddToCard={onAddToCard}
              />
            ))}
          </div>
        </div>
      }
      <div className='other-content'>
        {console.log("===info==", info)}
        {!_.isEmpty(info.discount.cost) &&
          <EnrollDetail
            className='mb-5'
            info={info.discount.cost}
          />}

        {(!_.isEmpty(info.discount.date) || !_.isEmpty(info.discount.general)) && (
          <>
            <h4>Discount</h4>
            <div className="group-body" >
              {!_.isEmpty(info.discount.date) && info.discount.date.map((item, index) =>
                <div className="check-list-item v-c" key={index}>
                  <ReactSVG src={confirm} className="svg-icon first-item" alt='check-icon' />
                  <p>{item}</p>
                </div>
              )}
              {!_.isEmpty(info.discount.general) && info.discount.general.map((item, index) =>
                <div className="check-list-item v-c" key={index}>
                  <ReactSVG src={confirm} className="svg-icon first-item" alt='check-icon' />
                  <p>{item}</p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}