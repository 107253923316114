import React from 'react';
import './Universities.scss';
import UniversityCarousel from "../UniversityCarousel";
import Loadable from "@loadable/component";
import Media from "react-media";
import { CarouselLeftButton, CarouselRightButton, UniversityList } from "../../../Constant";

const OwlCarousel = Loadable(() => import("react-owl-carousel"));



export default class Universities extends React.Component {
    state = {
       
    }

    render() {
        return (
            <div className="universities-component">
                <div className="container v-r">
                    <h1>Instructors from top U.S Universities</h1>
                    <div className="col-lg-12 main-row">
                        <Media
                            queries={{
                                lg: "(min-width: 1200px)",
                                md: "(min-width: 992px) and (max-width: 1199px)",
                                sm: "(min-width: 768px) and (max-width: 991px)",
                            }}
                        >
                            {(matches) => (
                                <OwlCarousel
                                    className="owl-theme"
                                    rewind
                                    nav
                                    stagePadding={matches.lg ? 10 : matches.md ? 10 : 5}
                                    margin={matches.lg ? 28 : matches.md ? 33 : 20}
                                    items={matches.lg ? 5 : 1}
                                    dots={false}
                                    navText={[CarouselLeftButton, CarouselRightButton]}
                                >
                                    {UniversityList.map((item, index) => (
                                        <UniversityCarousel key={index} item={item} />
                                    ))}
                                </OwlCarousel>
                            )}
                        </Media>
                    </div>
                </div>
            </div>
        )
    };
};