import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SummerCampDetailHeader, SummerCampDetail } from 'src/Components';
import { getSCStateCategory } from 'src/Redux/Actions';
import { showSpinner, hideSpinner } from 'src/Utils/Helper';

export default function StateCategorySCPage() {
  const dispatch = useDispatch();

  const { state } = useParams()
  const { category } = useParams()

  const getLoadingRef = useRef(false);
  const {
    scStateCategoryInfo, get_sc_state_category_success, get_sc_state_category_loading
  } = useSelector(
    state => state.SummerCamp,
  );

  const [stateName, setStateName] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [camps, setCamps] = useState('')

  useEffect(() => {
    showSpinner()
    setStateName(state);
    setCategoryName(category);
    dispatch(getSCStateCategory({ state, category }));
  }, [dispatch, state, category]);

  useEffect(() => {
    if (getLoadingRef.current && !get_sc_state_category_loading) {
      hideSpinner();
      if (get_sc_state_category_success) {
        setCamps(scStateCategoryInfo)
      }
    }
    getLoadingRef.current = get_sc_state_category_loading;
  }, [dispatch, scStateCategoryInfo, get_sc_state_category_loading, get_sc_state_category_success]);


  const getUpperCaseCategoryName = () => {
    return `${categoryName.charAt(0).toUpperCase()}${categoryName.slice(1)}`
  }

  const getSummary = () => {
    const summary = `
      Discover the best summer camps for ${categoryName} in ${stateName} with our comprehensive guide. 
      From STEM to arts and sports, 
      we have handpicked top-rated camps that cater specifically to girls. 
      Our guide includes camp details, locations, and pricing 
      to help parents find the perfect summer camp for their daughters. 
      Get ready for a summer of adventure, new friendships, 
      and unforgettable memories with our ${stateName} Category Summer Camp Guide 2023.
    `
    return summary
  }

  const getTags = () => {
    const tags = [
      {name: stateName, link: `/summer-camps/state/${stateName}`},
      {name: categoryName, link: `/summer-camps/state/${stateName}/category/${categoryName}`},
    ]
    return tags
  }

  return (
    <div className="state-category-summer-camp-page">
      <div className="container">
        <a href="/summer-camps">Summer Camp Guide</a> |
        <a href={'/summer-camps/state/' + stateName}>{stateName}</a> |
        <a href={`/summer-camps/state/${stateName}/category/${categoryName}`}>{getUpperCaseCategoryName()}</a>
      </div>
      <SummerCampDetailHeader
        tags={getTags()}
        title={`${stateName} ${getUpperCaseCategoryName()} Summer Camp Guide 2023`}
      />
      {camps && <SummerCampDetail
        camps={camps}
        summary={getSummary()}
      />}
    </div>
  );
}
