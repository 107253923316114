export const Privacy = [
    { 
        title: 'Clients', 
        content: `iCode Edu collects information such as names, addresses, email addresses, phone numbers, grade levels, and referrals. This information is collected for the purpose of facilitating the job duties of instructors and administrative staff. Instructors may use this information to contact their assigned families and make arrangements for one on one online lessons.. Administrative staff uses customer information for billing purposes. We do not disclose personal information to individuals outside of iCode Edu or instructors who are not assigned to clients. All online transactions are encrypted. iCode Edu may also send out surveys to current or previous customers to follow up on services and exercise quality assurance. Questions are related to previous or current iCode Edu services. This personal information is only disclosed to iCode Edu administrators.`
    },
    { 
        title: 'Instructors', 
        content: `Information provided by the instructor will not be disclosed to any entity outside of iCode Edu. Information apart from customer names will not be disclosed to other tutors. An instructor's name will be disclosed to the instructor’s assigned clients. Other personal information will be kept private unless authorized by the tutor.`
    },
    { 
        title: 'Applicants', 
        content: `Information provided by an applicant seeking a career with iCode Edu will be used to verify credentials and provide a basis upon which iCode Edu administrators will make a decision on hiring the individual. If the applicant is not hired, information is either locked or destroyed.`
    },
    { 
        title: 'Security', 
        content: `iCode Edu  is committed to making sure personal information is secure. We do not sell information to third parties unaffiliated with iCode Edu. Visitors under 13 years of age should not fill out provided contact forms without consent of a parent or guardian.`
    },
];

export const Term = [
    { 
        title: 'THE PARTIES TO THIS AGREEMENT ARE:', 
        content: `iCode Edu, (hereafter, “instructor”), and the “Student”/client. Services will be provided to Student through online lessons (the “Lessons”), conducted by individual teachers selected by the instructor (hereafter referred to as “Lesson instructor”) in its sole discretion.`
    },
    { 
        title: 'FEES PAYABLE TO THE INSTRUCTOR', 
        content: `The instructor's current rate plans are subject to the limitations and conditions expressed in the plan. No additional fees shall be charged for preparation time of the Lesson instructor. The fees charged by the Instructor may be adjusted from time to time and shall become effective only if the student is given thirty days prior notice. Acceptable notice is in writing.<br /><br />Student or client acknowledges and agrees to pay all costs associated with the instructor providing online teaching. These costs include, but are not limited to, 1) hourly rate listed, 2) travel fees, if applicable, and 3) cancellation fees as described below.`
    },
    { 
        title: 'PAYMENT', 
        content: `Payment for all lessons included in the plan must be made in advance, and the student agrees to pay all fees up front. Instructor will accept payment by check, bank transfer, check, or credit card. Before any contract is signed, or any instruction can begin, the method of payment must be agreed upon by both parties.  A nonnegotiable fee of $35.00 will be charged for any dishonored or bounced check. For any additional lessons requested by a Student not included in the plan, the instructor will invoice the student at the agreed upon hourly rate. Students must pay additional invoices within 14 days of the invoice date, and unpaid invoices are subject to a late fee of up to 18% per annum.`
    },
    { 
        title: 'CANCELLATION OF LESSONS BY STUDENT', 
        content: `The student may cancel a lesson only by giving at least 24 hours prior notice to the Instructor. If such notice is given, no lesson fees will be incurred. Lessons not attended by the student without giving 24 hours prior notice to the instructor shall be charged at 100% of hourly rate. If Student does not arrive at the online classroom within 15 minutes of Instructor’s arrival, Student will be billed 100% of the lesson.`
    },
    { 
        title: 'CANCELLATION OF LESSONS BY INSTRUCTOR', 
        content: `The Instructor may cancel lessons by giving 24 hours prior notice to the Student. In instances where a lesson was pre-paid, the Instructor must reschedule the appointment at a time agreeable to both parties. If no such time is agreed upon, the student can be issued a refund for the missed lesson, if prepaid. `
    },
    { 
        title: 'LATE ARRIVAL', 
        content: `Fees are calculated according to the times stipulated in the schedule and no adjustment shall be made for time lost because of late arrival by the Student. Any lost time because of the late arrival of the Instructor shall be compensated for by extending a lesson by mutual agreement and for the amount of time that was lost.`
    },
    { 
        title: 'OBLIGATIONS OF THE INSTRUCTOR', 
        content: `The Instructor and Lesson Instructors agree to do all preparation prior to lessons and to structure lessons to tutor students effectively.<br /><br />The Instructor and Lesson Instructors shall keep all information of the Student confidential and shall contact other parties involved in the education of the Student only if given written permission by the Student to do so.<br /><br />The  Instructors and Lesson Instructors shall not assign any obligations under this lesson contract to a third party not subject to this agreement without the written permission of the Student.<br /><br />The Instructor and Lesson Instructors shall at no time be required or obliged to execute homework or assignments on behalf of the Student.`
    },
    { 
        title: 'OBLIGATIONS OF THE STUDENT', 
        content: `The Student agrees to assist the Tutor and Lesson Tutors in identifying problem areas in which the Student needs specific tutoring.<br /><br />The Student agrees that assignments, exercises, or homework form an integral part of lessons and agrees to complete such work in a timely manner.`
    },
    { 
        title: 'INTELLECTUAL PROPERTY', 
        content: `The Student agrees that any intellectual property or other learning aids provided by the Instructor in connection with the Services may only be used by the Instructor and Student for the Student’s educational purposes, and that Student shall obtain rights to the intellectual property and learning aids, which shall remain the express property of the Instructor.`
    },
    { 
        title: 'PRIVACY', 
        content: `Student agrees not to discuss salary information or lesson fees with Lesson Instructors. By purchasing tutoring services through the Instructor, and with iCode EDU, Student agrees not to directly or indirectly compete with Instructor and its successors, both during the period of lesson Instructor use and for a period of one year following termination of this teaching agreement, by any of the following methods: 1) offering Lesson Instructors any teaching or related work outside of this agreement, 2) offering to pay Lesson Instructors less in order for them to work outside iCode EDU, 3) referring Lesson Instructors to other potential clients who intend to hire Lesson Instructors outside iCode EDU, or 4) any other direct propositioning of Lesson Instructor to do any work outside of the iCode EDU.`
    },
    { 
        title: 'NO WARRANTIES', 
        content: `The Instructor makes no promises or warranties with regards to a Student’s performance as a result of any lesson provided.`
    },
    { 
        title: 'TERMINATION', 
        content: `Students may terminate this contract at any time by giving Instructor seven (7) days prior written notice. In the event of such a termination, Student will forfeit any specials applied at the time of signing a contract, and lessons already provided will be assessed at a prorated rate based on the number of hours used, which will be subtracted from any prepayment provided to Instructor. Instructor shall refund the remaining balance, if any, to Student. A termination does not terminate any amounts owed by the Student to the Instructor.`
    },
    { 
        title: 'WAIVER OF TERMS', 
        content: `No relaxation, indulgence, waiver or release by any party of any of the rights in terms of this agreement on one occasion shall prevent the subsequent enforcement of such rights and shall not be deemed to be a waiver of any subsequent breach of any of the terms.`
    },
    { 
        title: 'WHOLE AGREEMENT', 
        content: `This agreement constitutes the entire understanding between the parties with regard to the subject matter hereof and the parties waive the right to rely on any alleged expressed or implied provision not contained herein. Any alteration to this agreement must be in writing and signed by both parties.`
    },
    { 
        title: 'LIABILITY', 
        content: `The Student understands that lessons will be received from Lesson Instructors who are independently contracted to, and are not employees of, Instructor. As such, Student waives and forever releases Instructor from any claims or damages which may result from actions or events outside the scope of this agreement, including but not limited to injuries, emotional damages, physical harm, and all other consequential and special damages.`
    },
    { 
        title: 'CHOICE OF LAW', 
        content: `Student agrees that the laws of Texas will govern the validity, interpretation, and effect of this Agreement, as well as any other disputes arising out of or relating to the contract between Student and Instructor.`
    },
    { 
        title: 'JURISDICTION AND VENUE', 
        content: `Student and Instructor agree to have all disputes arising out of this Agreement resolved in the courts of Tarrant County, Texas. Instructor and Student hereby consent to jurisdiction in said court(s). The Instructor and Student hereby waive all questions of personal jurisdiction and venue of such courts, including, without limitation, the claim or defense that such court(s) constitute an inconvenient forum.`
    },
    { 
        title: 'MINORS', 
        content: `Where the Student is a legal minor, the Parent/Guardian shall enter into this teaching contract on behalf of the Student and shall accept and agree to all the terms and conditions contained herein on behalf of the Student.`
    },
    { 
        title: 'LESSON PACKAGE', 
        content: `Student has one year from the date of purchase to use all the tutoring hours available in their tutoring package.`
    },
    { 
        title: 'REFUND', 
        content: `Students are entitled to one complimentary lesson with an assigned Instructor to determine if the relationship is a good fit. Should the Student be dissatisfied with the content and outcome of the first lesson, iCode EDU agrees to assign another Instructor to the Student to continue the agreement. In the event that the Student is still displeased, Student is entitled to a 100% refund for any unused hours within 30 days after discounted amount is subtracted from initial payment. `
    },
];