import React from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import PlayIcon from 'src/Assets/Icon/play-icon.svg';
import ChiefLearningImg from 'src/Assets/IGenius/chief-learning.png';
import { toggleVideoModal } from 'src/Redux/Actions';

export default function ChiefLearning() {
  const dispatch = useDispatch();

  const onPlayVideo = () => {
    dispatch(toggleVideoModal(true, { video: "MaDRZKb" }))
  }

  return (
    <div className='chief-learning-component'>
      <div className='container'>
        <h2>Chief Learning Officer</h2>
        <div className='row chief-learning-container mx-auto'>
          <div className="col-lg-6 h-c v-r left-content">
            <img
              src={ChiefLearningImg}
              alt='thumbnail-img'
              className='thumbnail-img'
            />
            {/* <img src={PlayIcon} alt="play-icon" className="play-icon" onClick={onPlayVideo} /> */}

          </div>
          <div className="col-lg-6 right-content">
            <h3><strong>Juli Troy</strong> is a USC graduate with 15+ years in education, specializing in youth creative development and lifelong learning.</h3>
            <p>
              She combines her love of creative computing and education, previously working as a web developer. Juli was the academic director of a coding school and later a non-profit program director during the pandemic, guiding staff, students, and parents to remote learning success. She believes remote computer science teaching empowers learners to code anytime, anywhere, <strong>developing skills</strong> in digital literacy, <strong>communication,</strong> and <strong>math.</strong> Juli now serves as the Chief Learning Officer and leads the iCode Edu Instructor team, working with families worldwide who place a high value on education
              and <strong>the success of future generations</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
