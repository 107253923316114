import React from 'react';
import './StoryHeader.scss';
import bot from '../../../Assets/Review/Icode_6.png';

export default class StoryHeader extends React.Component {
    render() {
        return (
            <div className="storyheader-component">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 left-col order-lg-first order-second">
                            <h1>Stories from our families and instructors about our award winning live 1:1 coding classes for kids </h1>
                        </div>
                        <div className="col-lg-5 right-col order-lg-second order-first">
                            <img src={bot} alt="icode_avatar" className="avatar"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};