import React from 'react';
import ReactDOM from 'react-dom';
import './Tutor.scss';
import bank from  '../../Assets/Icon/bank.svg';
import { ReactSVG } from 'react-svg';

export default class Tutor extends React.Component {
    getComponentRect = () => {
        var rect = ReactDOM.findDOMNode(this).getBoundingClientRect();
        if (this.props.onClick) {
            this.props.onClick(rect);
        }
    }

    render() {
        const { tutor_info, className } = this.props;
        return (
            <div className={`tutor-component ${className}`} onClick={this.getComponentRect}>
                <div className="info-area">
                    <img src={tutor_info.photo} alt="avatar" className="sub-info" />
                </div>
                <div className="info-area">
                    <div className="sub-info tutor-info">
                        <div className="name">{tutor_info.name}</div>
                        <div className="sub-text">{tutor_info.job}</div>
                        {(tutor_info.school && tutor_info.school.length > 0) && 
                            <div className="school">
                                <ReactSVG src={bank} className="svg-icon" />
                                <div className="sub-text">{tutor_info.school}</div>
                            </div>
                        }
                        {(tutor_info.description && tutor_info.description.length > 0) && 
                            <div className="description">{tutor_info.description}</div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
