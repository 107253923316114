/* @flow */

import { combineReducers } from 'redux';

import Sidebar from './Sidebar';
import Modal from './Modal';
import Course from  './Course';
import Review from './Review';
import Post from './Post';
import Auth from './Auth';
import Plan from './Plan';
import Faq from './Faq';
import Levels from './Levels';
import Schools from './Schools';
import City from './City';
import Landing from './Landing';
import Payment from './Payment';
import SummerCamp from './SummerCamp';
import IGenius from './IGenius';
import Other from './Other';

export default combineReducers ({
    Sidebar,
    Modal,
    Course,
    Review,
    Post,
    Auth,
    Plan,
    Faq,
    Levels,
    Schools,
    City,
    Landing,
    Payment,
    SummerCamp,
    IGenius,
    Other,
})