import { 
    GET_LANDING_DATA, 
    GET_LANDING_DATA_SUCCESS, 
    GET_LANDING_DATA_FAIL,
    USER_SUBMIT, 
    USER_SUBMIT_SUCCESS, 
    USER_SUBMIT_FAIL,
} from '../Type';

const INITIAL = {
    get_landing_loading: false,
    LandingData: {
        title: '1-on-1 coding lessons for students age ( 7 - 18) in Kenya',
        title_flag: 'https://api.icodeedu.com/storage/flags/kenya.svg',
        subtitle: 'Instructors from top US Universities',
        subtitle_flag: 'https://api.icodeedu.com/storage/flags/united-states-of-america.svg',
        country_code: 'US'
    },

    submit_loading: false,
    submit_success: false,
    submit_message: '',
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case GET_LANDING_DATA: {
            return {
                ...state,
                get_landing_loading: true,
            }
        }
        case GET_LANDING_DATA_SUCCESS: {
            return {
                ...state,
                get_landing_loading: false,
                LandingData: action.data
            }
        }
        case GET_LANDING_DATA_FAIL: {
            return {
                ...state,
                get_landing_loading: false,
                LandingData: null
            }
        }

        case USER_SUBMIT: {
            return {
                ...state,
                submit_loading: true,
                submit_success: false,
                submit_message: ''
            }
        }
        case USER_SUBMIT_SUCCESS: {
            return {
                ...state,
                submit_loading: false,
                submit_success: true,
                submit_message: action.data
            }
        }
        case USER_SUBMIT_FAIL: {
            return {
                ...state,
                submit_loading: false,
                submit_success: false,
                submit_message: action.data
            }
        }

        default:
            return state;
    }
}