import React from 'react';
import './style.scss';
import {
  IGeniusHeader,
  IGeniusLandingStart1,
  Transform,
  AdviceParents,
  RegistrationForm,
  Gifts,
  ChiefLearning,
  // IGeniusVideoReviews,
  IGeniusDetails,
  IGeniusInstructors,
  IGeniusFaqs,
  IGeniusFooter,
} from 'src/Components';

export default function IGeniusLandingPage1() {

  return (
    <div className="iGenius-landing1-page">
      <IGeniusHeader isFreeLesson />
      <IGeniusLandingStart1 />
      <Transform />
      <AdviceParents />
      <RegistrationForm />
      <Gifts isFreeLesson />
      <ChiefLearning />
      {/* <IGeniusVideoReviews /> */}
      <IGeniusDetails />
      <IGeniusInstructors />
      <IGeniusFaqs />
      <IGeniusFooter />
    </div>
  );
}