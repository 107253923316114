import React from 'react';
import './EnrollPage.scss';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';
import logoActive from '../../Assets/Icon/logo-active.png';
import ads1 from '../../Assets/Signup/trial-1.png';
import ads2 from '../../Assets/Signup/trial-2.png';
import ads3 from '../../Assets/Signup/trial-3.png';
//import ads4 from '../../Assets/Signup/trial-4.png';
import ads5 from '../../Assets/Signup/trial-5.png';
import ads6 from '../../Assets/Signup/trial-6.png';
import ads7 from '../../Assets/Signup/trial-7.png';
import ads8 from '../../Assets/Signup/trial-8.png';
import ads9 from '../../Assets/Signup/ads-8.png';
import check from '../../Assets/Icon/check.svg';
import left from '../../Assets/Icon/arrow-left.svg';
import confirm from '../../Assets/Icon/confirm.svg';
import avatar from '../../Assets/Icon/avatar.svg';
import emailsvg from '../../Assets/Icon/email.svg';
//import book from '../../Assets/Icon/book.svg';
import card from '../../Assets/Icon/credit-card.svg';
import key from '../../Assets/Icon/key.svg';
import { SCHEDULE_URL } from '../../Constant';
import { InputBox, SelectBox, CheckBox, CardInputBox } from '../../Components';
import { getAllCourses, signUp, getPlan, getBraintreekey, updateCard, getLevels, tutorProfileUpdate } from '../../Redux/Actions';
import CountryCodes from '../../Assets/Common/CountryCodes.json';

import { braintainService } from '../../Utils/paymentServices';

const Data = [
  {
    step: 1,
    title: "What is the student's coding experience?",
    selections: ['Beginner', 'Intermediate', 'Advanced', 'Not sure'],
    whole: true,
    state_val: 'experience',
    ads_img: ads1,
  },
  {
    step: 2,
    title: 'How old is the student?',
    selections: ['6 years old', '7 years old', '8 years old', '9 years old', '10 years old', '11 years old', '12 years old', '13 years old', '+14 years old'],
    whole: false,
    state_val: 'age',
    ads_img: ads2,
  },
  {
    step: 3,
    title: "What is the student's grade level?",
    selections: [],
    whole: false,
    state_val: 'grade',
    btn: 'skip',
    ads_img: ads3,
  },
  /*{
      step: 4,
      progress: 14,
      title: 'What course would you like the student to take?',
      selections: [],
      state_val: 'course',
      ads_img: ads4,
      ads_margin: true,
      select: true,
      icon: book,
      placeholder: 'Course',
      options: ['Game Design 101', 'Game Design 102', 'Game Design 103', 'Animation  Studio', 'AR Studio', 'Starter Course', 'Python Coding I', 'Python Coding II', 'Python Coding III', 'Drone Coding Studio', 'Not sure (Let advisor decide)'],
      btns: ['continue', 'skip']
  },*/
  {
    step: 4,
    progress: 14,
    title: "Parents full name",
    state_val: 'parent_name',
    ads_img: ads5,
    ads_margin: true,
    input: true,
    icon: avatar,
    placeholder: 'Full name',
    btns: ['continue']
  },
  {
    step: 5,
    progress: 28,
    title: "Parents email",
    state_val: 'email',
    ads_img: ads6,
    ads_margin: true,
    input: true,
    icon: emailsvg,
    placeholder: 'Email',
    warning: 'We take your privacy very seriously. No junk!',
    btns: ['continue']
  },
  {
    step: 6,
    progress: 42,
    title: "Password",
    state_val: 'password',
    ads_img: ads6,
    ads_margin: true,
    input: true,
    icon: key,
    placeholder: 'Password',
    password: true,
    btns: ['continue']
  },
  {
    step: 7,
    progress: 50,
    title: "Parents phone number",
    state_val: 'phone',
    state_country: 'country',
    ads_img: ads7,
    ads_margin: true,
    phone: true,
    icon: emailsvg,
    placeholder: 'Phone',
    country: 'US',
    btns: ['continue']
  },
  {
    step: 8,
    progress: 56,
    title: "Referral code",
    state_val: 'referrer_code',
    ads_img: ads7,
    ads_margin: true,
    input: true,
    icon: key,
    placeholder: 'Referral code',
    btns: ['continue', 'skip']
  },
  {
    step: 9,
    progress: 70,
    title: "Student name",
    state_val: 'student_name',
    ads_img: ads8,
    ads_margin: true,
    input: true,
    icon: avatar,
    placeholder: 'Full name',
    btns: ['continue']
  },
];

const Data2 = [
  {
    step: 11,
    progress: 100,
    title: 'Complete!',
    explain: `You’re all set. The next step is to schedule a call with one of our enrollment directors.`,
    schedule_plugin: true,
    ads_img: ads9,
    btn: 'continue'
  },
];

const Rules2 = ['Schedule an evaluation call with one of iCode Edu’s enrollment directors.', 'The student will be placed with their instructor.', 'The first lesson with the instructor is free!'];

const PeriodList = [
  { name: 'Monthly', length: 1 },
  { name: '6 Months', length: 6 },
  { name: 'Yearly', length: 12 }
];

function innerFunc(item) {
  return { __html: item }
}

class EnrollPage extends React.Component {
  state = {
    step: 1,

    plan_id: 0,
    pay_per: 'monthly',

    age: '',
    experience: '',
    grade: '',
    course: '',
    parent_name: '',
    email: '',
    password: '',
    country: 'US',
    phone: '',
    student_name: '',

    card_number: '',
    month: '',
    year: '',
    cvv2: '',
    zip_code: '',
    referrer_code: '',

    agree_term: false,
    active_period: 1,
  }
  getOffPercent = (plan_duration) => {
    const { plans } = this.props;
    const active_plans = plans.filter(plan => plan.length === plan_duration);
    if (active_plans.length > 0) {
      const discount_amount = active_plans[0].discount_amount
      const original_amount = active_plans[0].original_amount
      const offPercent = (1 - discount_amount / original_amount) * 100
      if (offPercent > 0) {
        return (
          `(${Math.round(offPercent)} % Off)`
        )
      }
    }
    return ''
  };
  componentDidMount() {
    this.cardElement = React.createRef();
    // if (this.props.location.search.length > 0) {
    //     let splitted = this.props.location.search.split('&');
    //     let id = parseInt(splitted[0].split('=')[1], 10);
    //     let type = splitted[1].split('=')[1];
    //     this.setState({
    //         plan_id: id,
    //         pay_per: type
    //     })
    // }
    if (this.props.course_list.length === 0) {
      this.props.getAllCourses();
    }
    if (this.props.plans.length === 0) {
      this.props.getPlan();
    };
    if (this.props.levels.length === 0) {
      this.props.getLevels();
    };
    if (this.props.location.search.length > 0) {
      let refer_id = this.props.location.search.split("=")[1];
      this.setState({
        referrer_code: parseInt(refer_id, 10)
      });
      localStorage.setItem("referrer_code", parseInt(refer_id, 10));
    } else {
      const referrer_code = localStorage.getItem("referrer_code");
      if (referrer_code) {
        this.setState({ referrer_code })
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.signup_success && this.props.signup_success) {
      this.props.getBraintreekey();
      this.setState({ step: 10 });
    }
    if (prevProps.signup_error_msg.length === 0 && this.props.signup_error_msg.length > 0) {
      alert(this.props.signup_error_msg);
    }
    if (prevProps.update_error_msg.length === 0 && this.props.update_error_msg.length > 0) {
      alert(this.props.update_error_msg);
    }
  }

  doUpdatePlan = async () => {
    const { card_number, month, year, cvv2, zip_code } = this.state;
    const { braintreeKey } = this.props;
    const cardDetail = {
      card_number,
      expDate: `${month}/${year}`,
      cvv2,
      zip_code
    };
    try {
      const nonce = await braintainService(braintreeKey, cardDetail);
      const formData = new FormData();
      formData.append('todo', 'new');
      formData.append('nonce', nonce);
      this.props.updateCard(formData);
      // not tutor, but same api, so we can call this one
      this.props.tutorProfileUpdate({ plan_id: this.state.plan_id });
      this.setState({ step: 11 });
    } catch (e) {
      alert(e.details.originalError.error.message);
    }
  }

  selectItem = async (field, val) => {
    await this.setState({ [field]: val });
    setTimeout(() => {
      this.moveForward()
    }, 200);
  }

  onChange = (field, val) => {
    this.setState({ [field]: val });
  }

  moveForward = (flag = 'normal') => {
    const { step } = this.state;
    if (flag === 'skip') {
      if (step === 3) this.setState({ grade: '' })
      if (step === 4) this.setState({ course: '' })
    } else {
      if ((step === 4 && this.state.parent_name.length === 0)
        || (step === 6 && this.state.password.length === 0)
        || (step === 7 && this.state.phone.length === 0)
        || (step === 9 && this.state.student_name.length === 0)
      ) {
        alert("Please input valid information!");
        return;
      }
      if (step === 5) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.state.email.length === 0 || !re.test(this.state.email.toLowerCase())) {
          alert("Please input valid information!");
          return;
        }
      }
      if (step === 10 && !this.state.agree_term) {
        alert("Please accept Terms of Use!");
        return;
      }
    }
    if (step === 9 && !this.props.signup_success) {
      let { age, experience, grade, parent_name, email, password, country, phone, student_name, referrer_code } = this.state;

      age = age.slice(0, -10);
      if (age[0] === '+') age = age.substring(1, age.length);
      age = parseInt(age, 10);

      let course_id = 0;
      /*if (course.length > 0 && course !== 'Not sure (Let advisor decide)') {
          let index = this.props.course_list.findIndex(temp => temp.name === course);
          course_id = index === -1 ? 0 : this.props.course_list[index].id;
      }*/
      let split_name = student_name.indexOf(' ');
      let first_name = student_name, last_name = '';
      if (split_name > -1) {
        first_name = student_name.substring(0, split_name);
        last_name = student_name.substring(split_name + 1);
      }

      let SignupParam = {
        coding_experience: experience,
        age: age,
        level_id: grade,
        course_id: course_id,
        first_name: first_name,
        last_name: last_name,
        email: email,
        password: password,
        phone: phone,
        country_code: country,
        parent_name: parent_name,
        referrer_code: referrer_code,
      }
      this.props.signUp(SignupParam);
      return;
    }
    if (step === 10) {
      this.doUpdatePlan();
      return;
    }
    if (step === 11) {
      this.props.history.push('/');
      return;
    }
    this.setState({ step: step + 1 })
  }

  goBack = () => {
    this.setState({ step: this.state.step !== 1 ? this.state.step - 1 : this.state.step });
  }

  SelectedDiv = () => <div className="selected"> <ReactSVG src={check} className="svg-icon" /> </div>

  renderStep = (Datas) => {
    const { step } = this.state;
    let Courses = [{ id: 0, name: 'Not sure (Let advisor decide)' }, ...this.props.course_list];

    return Datas.map((info, dataIndex) => step === info.step && <div className="row" id={`section-${info.step}`} key={dataIndex}>
      <div className="col-lg-7 order-lg-1 order-2 left-col">
        {info.progress && <div className="progress-bar h-c"><div className="current-progress" style={{ width: `${info.progress}%` }} /></div>}
        <h1>{info.title}</h1>
        {info.explain && <p dangerouslySetInnerHTML={innerFunc(info.explain)} />}
        {(info.selections && info.selections.length > 0) && <div className={`item-container ${info.whole ? 'whole' : ''} ${info.web_whole ? 'web-whole' : ''}`}>
          {info.selections.map((item, index) => <div className={`select-item ${item === this.state[info.state_val] ? 'active' : ''}`} key={index} onClick={e => this.selectItem(info.state_val, item)}>
            {this.SelectedDiv()}
            {item}
          </div>)}
          {info.bubble && <div className="bubble h-c v-c">{info.bubble}</div>}
        </div>}
        {(info.step === 3) && <div className={`item-container ${info.whole ? 'whole' : ''} ${info.web_whole ? 'web-whole' : ''}`}>
          {this.props.levels.map((item, index) => <div className={`select-item ${item.id === this.state[info.state_val] ? 'active' : ''}`} key={index} onClick={e => this.selectItem(info.state_val, item.id)}>
            {this.SelectedDiv()}
            {item.name}
          </div>)}
          {info.bubble && <div className="bubble h-c v-c">{info.bubble}</div>}
        </div>}
        <div className="sub-section v-r">
          {info.select && <SelectBox className="select-box" icon={info.icon} placeholder={info.placeholder} options={Courses} value={this.state[info.state_val]} onChange={e => this.onChange(info.state_val, e)} />}
          {info.input && <InputBox className="input-box" type={info.password ? 'password' : ''} icon={info.icon} placeholder={info.placeholder} value={this.state[info.state_val]} onChange={e => this.onChange(info.state_val, e)} />}
          {info.phone && <InputBox className="input-box" phone_input placeholder={info.placeholder} value={this.state[info.state_val]} country={this.state[info.state_country]} onSelect={e => this.onChange(info.state_country, e)} onChange={e => this.onChange(info.state_val, e)} />}
          {info.warning && <div className="warning">{info.warning}</div>}
          {(info.btns && info.btns.length > 0) && <div className="sub-btn-area">
            {info.btns.map((item, index) => <button className={`btn-2 primary-btn ${item}`} key={index} onClick={e => this.moveForward(item)}>{item}</button>)}
          </div>}
        </div>
        {info.schedule_plugin && <div dangerouslySetInnerHTML={{ __html: '<iframe src="' + SCHEDULE_URL + '" width="540" height="900"></iframe>' }} />}
        {info.btn && <button className="btn-2 primary-btn" onClick={e => this.moveForward(info.btn)}>{info.btn}</button>}
        {(step > 1 && step < 11) && <div className="v-c back-btn" onClick={e => this.goBack()}><ReactSVG src={left} className="svg-icon" />&nbsp;Back</div>}
      </div>
      <div className={`col-lg-5 order-lg-2 order-1 right-col ${info.ads_margin ? 'ads-margin' : ''}`}>
        <img src={info.ads_img} alt="ads" className="ads-img" />
      </div>
    </div>)
  }

  render() {
    const { step, agree_term, active_period, card_number, month, year, cvv2, zip_code, plan_id } = this.state;
    const { plans } = this.props;
    const current_plan_list = plans.filter(plan => plan.length === active_period);
    return (
      <div className="enroll-page">
        <div className="container v-r">
          <div className="logo-area">
            <NavLink to="/"><img src={logoActive} alt="logo" /></NavLink>
          </div>
          <div className="main-content v-r">
            {this.renderStep(Data)}
            {step === 10 && <div className="row" id="section-9">
              <div className="col-lg-12">
                <div className="progress-bar h-c show-web-flex"><div className="current-progress" style={{ width: `84%` }} /></div>
                <h1>Choose your Plan</h1>
                <div className="container plan-area v-r v-c">
                  <div className="period-row v-c">
                    {PeriodList.map((item, index) => <div className={`period v-c h-c ${active_period === item.length ? 'active' : ''}`} key={index} onClick={e => this.setState({ active_period: item.length })}>
                      {`${item.name} ${this.getOffPercent(item.length)}`}
                    </div>)}
                  </div>
                  <div className="pricing-body">
                    {current_plan_list.map(
                      plan => <div className="coding-class round-object private v-r" key={plan.id}>
                        <div className="sub-header v-r v-c">
                          <h2>{plan.name}</h2>
                          <h3>
                            {plan.original_amount !== plan.discount_amount && <span className="ignore">{plan.original_amount}</span>}
                            ${plan.discount_amount}<span className="unit">/{plan.pay_per}</span>
                          </h3>
                        </div>
                        <div className="sub-body v-r">
                          {plan.description.map((item, index) => <div className="sub-item v-c" key={index}>
                            <ReactSVG src={confirm} className="svg-icon" />
                            <p> {item} </p>
                          </div>)}
                          <button className={`btn-2 btn-link ${plan_id === plan.id ? 'disabled' : ''}`} onClick={e => this.onChange('plan_id', plan.id)}>
                            {plan_id === plan.id ? 'Selected' : 'Choose Plan'}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-lg-7 order-lg-1 order-2 left-col'>
                <div className="detail-info v-r">
                  <h1>Payment Details</h1>
                  <div className="row">
                    <div className="col-lg-12 form-group">
                      <label>Card Number</label>
                      <CardInputBox
                        icon={card}
                        type="number"
                        value={card_number}
                        onChange={val => this.onChange('card_number', val)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 form-group">
                      <label>Month</label>
                      <input className="custom-input" type="number" placeholder="Month" value={month} onChange={e => this.onChange('month', e.target.value)} />
                    </div>
                    <div className="col-lg-4 form-group">
                      <label>Year</label>
                      <input className="custom-input" type="number" placeholder="Year" value={year} onChange={e => this.onChange('year', e.target.value)} />
                    </div>
                    <div className="col-lg-4 form-group">
                      <label>CVV2</label>
                      <input className="custom-input" type="number" placeholder="CVV2" value={cvv2} onChange={e => this.onChange('cvv2', e.target.value)} />
                    </div>
                    <div className="col-lg-4 form-group">
                      <label>Zipcode</label>
                      <input className="custom-input" type="number" placeholder="Zipcode" value={zip_code} onChange={e => this.onChange('zip_code', e.target.value)} />
                    </div>
                  </div>
                  <div className="check-area v-c">
                    <CheckBox value={agree_term} onChange={val => this.setState({ agree_term: val })} />
                    <div className="check-label">
                      Accept&nbsp;<a target='_blank' href='/terms'>Terms of Use</a>
                    </div>
                  </div>
                  <button className={`btn-2 btn-link ${this.props.signup_loading || this.props.update_loading || !card_number || !month || !year || !cvv2 || !agree_term || !plan_id ? 'disabled' : ''}`} onClick={e => this.moveForward()}>
                    {(this.props.signup_loading || this.props.update_loading) ? <div className="spinner" /> : 'CONTINUE'}
                  </button>
                </div>
              </div>
              <div className='col-lg-5 order-lg-2 order-1 right-col'>
                <div className='coding-class round-object private'>
                  <div className="sub-body v-r">
                    <h4>WHAT NEXT AFTER PAYMENT: </h4>
                    {Rules2.map((item, index) => <div className="sub-item sub" key={index}>
                      <div className="circle" />
                      <p>{item}</p>
                    </div>)}
                  </div>
                </div>
              </div>

            </div>}
            {this.renderStep(Data2)}
          </div>
        </div>
      </div>
    )
  };
};

function mapStateToProps(state) {
  return {
    course_list: state.Course.course_list,

    signup_loading: state.Auth.signup_loading,
    signup_success: state.Auth.signup_success,
    signup_error_msg: state.Auth.signup_error_msg,

    update_error_msg: state.Plan.update_error_msg,
    update_loading: state.Plan.update_loading,
    plans: state.Plan.plans,

    braintreeKey: state.Payment.braintreeKey,
    levels: state.Levels.levels,
  }
}

export default connect(mapStateToProps, {
  getAllCourses,
  signUp,
  getPlan,
  getBraintreekey,
  updateCard,
  getLevels,
  tutorProfileUpdate,
})(withRouter(EnrollPage));