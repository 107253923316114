import React from 'react';
import './MobileSidebar.scss';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleMobileSidebar, signOut } from '../../Redux/Actions';
import { ReactSVG } from 'react-svg'
import { CSSTransition } from 'react-transition-group';
import close from '../../Assets/Icon/close.svg';
import logoActive from '../../Assets/Icon/logo-active.png';

import home from '../../Assets/Icon/home.svg';
import book from '../../Assets/Icon/book.svg';
import percent from '../../Assets/Icon/percent.svg';
import star from '../../Assets/Icon/star.svg';
import question from '../../Assets/Icon/question.svg';
import pen from '../../Assets/Icon/pen.svg';
import triangle from '../../Assets/Icon/triangle.svg';
import { AgeGroup } from '../../Constant';

class MobileSidebar extends React.Component {

  state = {
    isMobileOpen: false,
    isPrevOpen: false,
    opened: false,
  }

  static getDerivedStateFromProps(props, state) {
    return {
      isMobileOpen: props.isMobileOpen,
      isPrevOpen: state.isMobileOpen,
    }
  }

  closeSidebar = () => {
    this.props.toggleMobileSidebar(false);
  }

  doSignOut = () => {
    this.props.signOut();
    this.closeSidebar()
  }

  render() {
    const { isMobileOpen, isPrevOpen, opened } = this.state;
    return (
      <div className={`mobilesidebar-component ${isMobileOpen ? 'active' : isPrevOpen ? 'inactive' : ''}`}>
        <div className="mobilesidebar-wrapper">
          <div className="mobilesidebar-content v-r">
            <div className="container v-r">
              <div className="sidebar-header v-c">
                <NavLink className="menu-item logo" exact to="/"><img src={logoActive} alt="logo-active" /></NavLink>
                <ReactSVG src={close} className="svg-icon" onClick={e => this.closeSidebar()} />
              </div>
              <NavLink className="box-menu trial v-c h-c" exact to="/start-trial" onClick={e => this.closeSidebar()}>START YOUR TRIAL</NavLink>
              {/* {this.props.token.length === 0 ? <NavLink className="box-menu trial v-c h-c" exact to="/start-trial" onClick={e => this.closeSidebar()}>START YOUR TRIAL</NavLink>
                                                         : <a className="box-menu trial v-c h-c" href={GetDashboardUrl(this.props.user, this.props.token)} target="_blank" rel="noopener noreferrer" >GO TO DASHBOARD</a>} */}
              <div className="menu-list v-r">
                <NavLink to="/" exact className="sub-menu v-c" onClick={e => this.closeSidebar()}> <ReactSVG src={home} className="svg-icon" /> <p>Home</p> </NavLink>
                <div className={`sub-list-header sub-menu v-c ${opened ? 'opend' : 'closed'}`} onClick={e => this.setState({ opened: !opened })}>
                  <ReactSVG src={book} className="svg-icon" /> <p>Camps</p> <ReactSVG src={triangle} className={`svg-icon last-icon ${opened ? 'opend' : 'closed'}`} />
                </div>
                <CSSTransition in={opened} timeout={200} classNames="sub-list">
                  <div className={`sub-list v-r`}>
                    {AgeGroup.map((item, index) => <NavLink exact to={`/course-list/${item.id}`} key={index} className="sub-list-item" onClick={e => this.closeSidebar()}>
                      Age {item.period}
                    </NavLink>)}
                  </div>
                </CSSTransition>
                <NavLink to="/pricing" exact className="sub-menu v-c" onClick={e => this.closeSidebar()}> <ReactSVG src={percent} className="svg-icon" /> <p>Pricing</p> </NavLink>
                <NavLink to="/reviews" exact className="sub-menu v-c" onClick={e => this.closeSidebar()}> <ReactSVG src={star} className="svg-icon" /> <p>Reviews</p> </NavLink>
                <NavLink to="/faqs" exact className="sub-menu v-c" onClick={e => this.closeSidebar()}> <ReactSVG src={question} className="svg-icon" /> <p>FAQ's</p> </NavLink>
                <NavLink to="/blog" exact className="sub-menu v-c" onClick={e => this.closeSidebar()}> <ReactSVG src={pen} className="svg-icon" /> <p>Blog</p> </NavLink>
              </div>
              {this.props.token.length === 0 ? <NavLink className="box-menu signin v-c h-c" exact to="/sign-in" onClick={e => this.closeSidebar()}>SIGN IN</NavLink>
                : <p className="box-menu signin v-c h-c" onClick={e => this.doSignOut()}>SIGN OUT</p>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isMobileOpen: state.Sidebar.isMobileOpen,
    sign_in_success: state.Auth.sign_in_success,
    token: state.Auth.token,
    user: state.Auth.user,
  }
}

export default connect(mapStateToProps, { toggleMobileSidebar, signOut })(withRouter(MobileSidebar));