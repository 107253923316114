import React from 'react';
import './style.scss';
import { NavLink, useHistory } from 'react-router-dom';
import logoActive from 'src/Assets/Icon/logo-active.png';
import { ScPlan } from 'src/Components';
import left from 'src/Assets/Icon/left.svg';
import { ReactSVG } from 'react-svg';

export default function SCSignupContainer({
  className = '', title = '', children, isBack = false
}) {
  let history = useHistory();

  const onBack = () => history.goBack()

  return (
    <div className={`sc-signup-container-component ${className}`}>
      <div className='container'>
        <div className='logo-section v-c'>
          {isBack && <ReactSVG src={left} alt="logo" className='back-icon' onClick={onBack} />}
          <NavLink to="/"><img src={logoActive} alt="logo" /></NavLink>
        </div>
        <div className='title-section'>
          <h2>{title}</h2>
        </div>
        <div className="row sc-signup-main-content mx-auto">
          <div className="col-lg-7 left-content">
            {children}
          </div>
          <div className="col-lg-5 right-content">
            <ScPlan />
          </div>
        </div>
      </div>
    </div>
  );
}
