import React from 'react';
import './VideoModal.scss';
import { Modal } from 'react-bootstrap';
import { toggleVideoModal } from '../../Redux/Actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import close_icon from '../../Assets/Icon/close.svg';

import { ReactSVG } from 'react-svg'

class VideoModal extends React.Component {
    state = {
        isOpen: true,
        info: ''
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: props.isOpen,
            info: props.info
        }
    }

    closeModal = () => {
        this.props.toggleVideoModal(false);
    }

    onHide = () => {
    }

    render() {
        let { isOpen, info } = this.state;
        return (
            <Modal show={isOpen} onHide={this.onHide} className="video-modal-component">
                <div className="video-content v-r">
                    <div className="close-btn" onClick={() => this.closeModal()}>
                        <ReactSVG src={close_icon} className='icon-close' />
                    </div>
                    {info && <iframe src={`https://muse.ai/embed/${info.video}`} height="600" frameborder="0" title="video" allowfullscreen></iframe>}
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        isOpen: state.Modal.isVideoModalOpen,
        info: state.Modal.info,
    }
}

export default connect(mapStateToProps, {
    toggleVideoModal
})(withRouter(VideoModal));
