import { Api } from './Api';

export function* getIGeniusLandingInfo(data) {
  const jsonData = yield Api.get(`/summercamp/landing?name=${data.state}`);
  return jsonData;
}

export function* masterClassSignUp(data){
  const jsonData = yield Api.post("/summercamp/masterclass", data);
  return jsonData;
}
