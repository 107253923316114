import React from 'react';
import './HowItWorks.scss';
/*import cnn from '../../../Assets/Home/cnn.png';
import bbc from '../../../Assets/Home/bbc.png';
import cbs from '../../../Assets/Home/cbs.png';
import nyt from '../../../Assets/Home/nyt.png';
import nytM from '../../../Assets/Home/nyt-mobile.png';
import tech from '../../../Assets/Home/techcrunch.png';
import techM from '../../../Assets/Home/techcrunch-mobile.png';
import abc from '../../../Assets/Home/abc.png';*/
import work1 from '../../../Assets/Home/how-works-1.png';
import work2 from '../../../Assets/Home/how-works-2.png';
import work3 from '../../../Assets/Home/how-works-3.png';

const WorkBlocks = [
    { id: 1, img: work1, title: 'Evaluation', description: 'You’ll be assigned an enrollment director who will provide a free evaluation to determine which course is right for you!' },
    { id: 2, img: work2, title: 'Placement', description: 'Based on the evaluation, students are paired with one of our top-rated instructors from top universities around the country for one-on-one coding lessons.' },
    { id: 3, img: work3, title: 'Certificate', description: 'Courses can take anywhere from 4-6 months to complete. Once the student has finished the course, they will be awarded a Certificate of Completion to celebrate their achievement!' },
]

export default class HowItWorks extends React.Component {
    render() {
        return (
            <div className="howitworks-component v-r">
                {/* <div className="brand-area">
                    <div className="container">
                        <div className="row v-c">
                            <img src={cnn} alt="brand" />
                            <img src={bbc} alt="brand" />
                            <img src={cbs} alt="brand" />
                            <img src={nyt} alt="brand" className="show-web" />
                            <img src={nytM} alt="brand" className="show-mobile" />
                            <img src={tech} alt="brand" className="show-web" />
                            <img src={techM} alt="brand" className="show-mobile" />
                            <img src={abc} alt="brand" />
                        </div>
                    </div>
                </div> */}
                <div className="container v-r">
                    <h1>How it works : </h1>
                    <div className="row">
                        {WorkBlocks.map((item, index) => <div className="col-lg-4 sub-col v-c h-c" key={index}>
                            <div className="work-block">
                                <div className="show-web-flex v-c h-c circle number-outter">
                                    <div className="v-c h-c circle number-in">{item.id}</div>
                                </div>
                                <div className="work-content v-r v-c">
                                    <img src={item.img} alt="work-img" />
                                    <h2>{item.title}</h2>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        )
    };
};