import React from 'react';
import AdviceItem from './AdviceItem';
import './style.scss';

const info = [
  "Why must your kids learn to code to excel in life?",
  "How to see if your kid is incline to coding",
  "How your kids can secure a future job and entrepreneurial opportunities by preparing now",
  "Which iCode iGenius Coding Camp program is good fit for your child",
]
export default function AdviceParents() {

  return (
    <div className='advice-parents-component'>
      <div className='container'>
        <div className='advice-parents-container'>
          <h2 className='mx-auto'>We Advice Parents <strong>To Attend</strong> Our MasterClass <strong>Before</strong> Picking iCode iGenius Camp For Their Kid!</h2>
          <div className='learn-content'>
            {info.map((item, index) => (
              <AdviceItem
                key={index}
                title={`You will learn #${index + 1}`}
                description={item}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
