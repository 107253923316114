import React from 'react';
import './StoryPage.scss';
import { StoryPreview } from '../../Components';
import facebookBtn from '../../Assets/Icon/facebook-share.png';
import twitterBtn from '../../Assets/Icon/twitter-share.png';
import linkedinBtn from '../../Assets/Icon/linkedin-share.png';
import { NavLink } from 'react-router-dom';
import { Avatars, ONLINE_URL } from '../../Constant';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllPosts } from '../../Redux/Actions';
import moment from 'moment';
import ReactFlagsSelect from 'react-flags-select';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";

function innerFunc(item) {
    return {__html: item}
}

class StoryPage extends React.Component {
    state = {
        story: {},
        ShareFloat: false,
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onWindowScroll);
        this.BottomWrapper = React.createRef();
        this.ShareArea = React.createRef();

        if (this.props.post_list.length === 0) {
            this.props.getAllPosts();
        } else {
            const index = this.props.post_list.findIndex(item => item.id === parseInt(this.props.match.params.id, 10));
            if (index === -1) {
                this.props.getAllPosts();
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        const index = props.post_list.findIndex(item => item.id === parseInt(props.match.params.id, 10));
        return {
            story: index !== -1 ? props.post_list[index] : { author: { country: {}} }
        };
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onWindowScroll);
    }
    
    onWindowScroll = (e) => {
        if (window.outerWidth >= 992) {
            this.setState({
                ShareFloat: window.scrollY >= 420 && window.scrollY < this.BottomWrapper.current.offsetTop - 300,
            })
        }
    }

    render() {
        const { story, ShareFloat } = this.state;
        story.tags = (story.meta_keywords || '').split(",");
        const ShareURL = ONLINE_URL + `/story/${this.props.match.params.id}`;
        return (
            <div className="story-page v-r">
                <div className="story-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="image-container">
                                    <img src={story.image} alt="thumb" className="thumb-img" />
                                </div>
                            </div>
                            <div className="col-lg-7 v-r right-col">
                                <h1>{story.title}</h1>
                                <p>{story.author.name}&nbsp;&nbsp;&middot;&nbsp;&nbsp;{moment(story.created_at).format('MMM D, YYYY')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="story-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 order-lg-1 order-3 parent-area v-r">
                                <div className="parent-container v-r round-object">
                                    <div className="parent-header v-r v-c sub-item">
                                        <img src={Avatars[0]} alt="parent" className="parent" />
                                        <h3 className="v-c"> {story.author.name} <span>Parent</span> </h3>
                                    </div>
                                    <div className="sub-group sub-item">
                                        <h2>Customer since</h2>
                                        <p>{story.author.created_at}</p>
                                    </div>
                                    <div className="sub-group sub-item">
                                        <h2>Country</h2>
                                        {(story.author.country.country_code && story.author.country.country_code.length > 0) &&<ReactFlagsSelect 
                                            className="custom-flag"
                                            defaultCountry={story.author.country.country_code} 
                                            showSelectedLabel={false} 
                                            disabled={true}/>}
                                        <p>{story.author.country.name}</p>
                                    </div>
                                    <div className="sub-group sub-item">
                                        <h2>Student</h2>
                                        <p>{story.author.student}</p>
                                    </div>
                                    <div className="sub-group">
                                        <h2>Course completed</h2>
                                        {(story.author.courses || []).map((item, index) => <div className="complete-item v-c" key={index}> 
                                            <div className="middot circle" /><p>{item.course}</p> 
                                        </div>)}
                                    </div>
                                    <NavLink to="/start-trial" className="btn-link">START YOUR TRIAL</NavLink>
                                    {/* {this.props.token.length === 0 ? <NavLink to="/start-trial" className="btn-link">START YOUR TRIAL</NavLink>
                                                                 : <a href={GetDashboardUrl(this.props.user, this.props.token)} className="btn-link" target="_blank" rel="noopener noreferrer">GO TO DASHBOARD</a>} */}
                                </div>
                            </div>
                            <div className="col-lg-7 order-lg-2 order-2 v-r">
                                <div className="main-content v-r" dangerouslySetInnerHTML={innerFunc(story.body)}/>
                                <p style={{display:'flex',flexWrap:'wrap'}}>{story.tags.map((item, index) => <span key={index}>#{item}&nbsp;&nbsp;&nbsp;</span>)}</p>
                            </div>
                            <div className="col-lg-1 order-lg-3 order-1 share-area" ref={this.ShareArea}>
                                <div className={`share-container ${ShareFloat ? 'floating' : ''}`} style={{marginLeft: ShareFloat && `${this.ShareArea.current.offsetWidth-60}px`}}>
                                    <FacebookShareButton url={ShareURL} className="share-btn" >
                                        <img src={facebookBtn} alt="facebook" />
                                    </FacebookShareButton>
                                    <TwitterShareButton url={ShareURL} className="share-btn" >
                                        <img src={twitterBtn} alt="twitter" />
                                    </TwitterShareButton>
                                    <LinkedinShareButton url={ShareURL} className="share-btn" >
                                        <img src={linkedinBtn} alt="linkedin" />
                                    </LinkedinShareButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relation-stories" ref={this.BottomWrapper}>
                    <div className="container v-r">
                        <h1>More related stories</h1>
                        <div className="row">
                            {this.props.post_list[0] && <div className="col-lg-4 sub-col"> <StoryPreview info={this.props.post_list[0]} /> </div>}
                            {this.props.post_list[1] && <div className="col-lg-4 sub-col"> <StoryPreview info={this.props.post_list[1]} /> </div>}
                            {this.props.post_list[2] && <div className="col-lg-4 sub-col"> <StoryPreview info={this.props.post_list[2]} /> </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        post_list: state.Post.post_list,
        token: state.Auth.token,
        user: state.Auth.user,
    }
}

export default connect(mapStateToProps, { getAllPosts })(withRouter(StoryPage));