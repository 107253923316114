import { MOBILE_SIDEBAR_TOGGLE, COURSE_SIDEBAR_TOGGLE } from '../Type';

export const toggleMobileSidebar = (isMobileOpen) => {
  return {
    type: MOBILE_SIDEBAR_TOGGLE,
    data: { isMobileOpen }
  }
}

export const toggleCourseSidebar = (isCourseOpen, id = 0, minAge, maxAge) => {
  return {
    type: COURSE_SIDEBAR_TOGGLE,
    data: { isCourseOpen, id, minAge, maxAge }
  }
}