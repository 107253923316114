import React, { useEffect } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';

export default function AdelolaPage() {
  let history = useHistory();

  useEffect(() => {
    history.push('/freecodingchallenge?referrer_code=1388')
  }, [history]);

  return (
    <div className="adelola-page">

    </div>
  );
}