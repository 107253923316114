import { MOBILE_SIDEBAR_TOGGLE, COURSE_SIDEBAR_TOGGLE } from '../Type';

const INITIAL = {
  isMobileOpen: false,
  isCourseOpen: false,
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case MOBILE_SIDEBAR_TOGGLE: {
      const { isMobileOpen } = action.data;
      return { ...state, isMobileOpen };
    }
    case COURSE_SIDEBAR_TOGGLE: {
      const { isCourseOpen, id, minAge, maxAge } = action.data;
      return { ...state, isCourseOpen, id, minAge, maxAge };
    }
    default:
      return state;
  }
}