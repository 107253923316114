import React from 'react';
import './style.scss';
import moment from 'moment-timezone';
import EnrollItem from './EnrollItem';

export default function EnrollDetail({
  info,
  className = '',
  campLength = 1
}) {

  const getOriginPrice = () => {
    return 399 * campLength
  }

  const getOff1 = () => {
    return 100 * campLength
  }

  const getOff2 = () => {
    return ((getOriginPrice() - getOff1()) * (campLength === 1 ? 0 : campLength === 2 ? 0.05 : 0.1)).toFixed(1)
  }

  const getOff3 = () => {
    return 50 * campLength
  }

  const getPrice = () => {
    return `${(getOriginPrice() - getOff1() - getOff2()).toFixed(0)}`
  }

  return (
    <div className={`enroll-detail-component ${className}`}>
      {/* <EnrollItem
        title={'TODAY’s Enroll'}
        price={`$${getPrice()}`}
        className={'today-enroll'}
      />
      <EnrollItem
        title={`After ${moment(info[1].expire).format("MMM Do")}`}
        price={`$${getOriginPrice() - getOff3()}`}
      />
      <EnrollItem
        title={`After ${moment(info[2].expire).format("MMM Do")}`}
        price={`$${getOriginPrice()}`}
      /> */}
      {info.map((item, index) => (
        <EnrollItem
          key={index}
          title={index === 0? "TODAY’s Enroll": `After ${moment(item.expire).format("MMM Do")}`}
          price={`$${item.discount}`}
          className={index === 0 ? 'today-enroll' : ""}
        />
      ))}
    </div>
  );
}
