import React, { useState } from 'react';
import './InputBox.scss';
import _ from 'lodash';
import { ReactSVG } from 'react-svg';
import eye_open from '../../Assets/Icon/eye_open.svg';
import eye_close from '../../Assets/Icon/eye_close.svg';
import { NavLink } from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';

export default function InputBox({
  className = '',
  country,
  validation,
  value = '',
  icon = '',
  placeholder = '',
  type = 'text',
  show_forgot = false,
  phone_input = false,
  onChange,
  onSelect
}) {

  const [isFocused, setIsFocused] = useState(false);
  const [inputType, setInputType] = useState(type);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const onSelectFlag = (val) => {
    onSelect(val);
  }

  return (
    <div
      className={`
        inputbox-component v-c round-object 
        ${className} 
        ${isFocused ? 'focused' : ''}
        ${!isFocused && validation === false && !_.isEmpty(value) ? 'error-input' : ''}
        `}>
      {phone_input &&
        <ReactFlagsSelect
          className="custom-flag"
          selected={country}
          onSelect={val => onSelectFlag(val)}
          searchable={true}
          showSelectedLabel={false}
        />}
      {(icon && !phone_input) && <ReactSVG src={icon} className="svg-icon" />}
      <div className="input-area v-r">
        {(isFocused || value) && <p className="upon-placeholder">{placeholder}</p>}
        <input
          className="custom-input"
          type={inputType}
          placeholder={isFocused ? '' : placeholder}
          value={value}
          onChange={e => onChange(e.currentTarget.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
      {(type === 'password' && !show_forgot) &&
        <ReactSVG
          src={inputType === 'text' ? eye_open : eye_close}
          className="svg-icon last-icon"
          onClick={e => setInputType(inputType === 'text' ? 'password' : 'text' )}
        />
      }
      {show_forgot && <NavLink to="/forgot-password" className="forgot-password">Forgot password?</NavLink>}
    </div>
  )
};
