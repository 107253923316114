import React from 'react';
import './SelectBox.scss';
import { ReactSVG } from 'react-svg';
import triangle from '../../Assets/Icon/triangle.svg';

export default class SelectBox extends React.Component {
    state = {
        className: this.props.className || '',
        value: this.props.value || '',
        icon: this.props.icon || '',
        placeholder: this.props.placeholder || '',
        type: this.props.type || 'text',
        focused: false,
    }

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    onChange = (e) => {
        this.setState({value: e.target.value});
        if(this.props.isCity){
            this.props.changeTxt(e.target.value);
        }
    }

    selectItem = (val) => {
        this.setState({
            value: val.name,
            focused: false
        });
        this.props.onChange(val);
    }

    render() {
        const { value, icon, placeholder, type, focused } = this.state;
        const { className, options } = this.props;

        const list = options.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
        return (
            <div className={`selectbox-component v-c round-object ${className} ${focused ? 'focused' : ''}`}>
                {icon && <ReactSVG src={icon} className="svg-icon" />}
                <div className="input-area v-r">
                    {(focused || value) && <p className="upon-placeholder">{placeholder}</p>}
                    <input className="custom-input" type={type} placeholder={focused ? 'Search...' : placeholder} value={value} ref={this.inputRef}
                        onChange={e => this.onChange(e)} 
                        onFocus={e => this.setState({focused: true})}
                        onBlur={e => this.setState({focused: false})}
                    />
                </div>
                <ReactSVG src={triangle} className="svg-icon last-icon" onClick={e => {this.state.focused ? this.inputRef.current.blur() : this.inputRef.current.focus()}}/>
                {focused && <div className="options v-r">
                    {list.map((item, index) => <div className="option v-c" key={index} onMouseDown={e => this.selectItem(item)}>{item.name}</div>)}
                </div>}
            </div>
        )
    };
};
