import {
	GET_SHOOLS, 
	GET_SHOOLS_FAIL, 
	GET_SHOOLS_SUCCESS, 
} from '../Type';


import { takeLatest, put } from 'redux-saga/effects';
import {
	getSchools as getSchoolsApi,
} from '../../Api';

function* getSchools(){
	try {
		const result = yield getSchoolsApi();
		if (result.data) {
			yield put({ type: GET_SHOOLS_SUCCESS, data: result.data})
		} else {
			yield put({ type: GET_SHOOLS_FAIL, data: result})			
		}
	} catch (err) {
		yield put({ type: GET_SHOOLS_FAIL, data: {msg: 'NET ERROR'} })
	}
}

export function* watchgetSchools(){
	yield takeLatest(GET_SHOOLS, getSchools)
}
