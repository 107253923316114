import {
  GET_BRAINTREE_KEY,
  GET_BRAINTREE_KEY_SUCCESS,
  GET_BRAINTREE_KEY_FAIL,
  UPDATE_CARD,
  UPDATE_CARD_SUCCESS,
  UPDATE_CARD_FAIL,
  SC_PAYMENT,
  SC_PAYMENT_SUCCESS,
  SC_PAYMENT_FAIL,

} from '../Type';

import _ from 'lodash'
import { takeLatest, put } from 'redux-saga/effects';
import {
  getBraintreekey as getBraintreekeyApi,
  updateCard as updateCardApi,
  SCPayment as SCPaymentApi,
} from '../../Api';

/**
 * GET Braintree Key
 */
function* getBraintreekey() {
  try {
    const result = yield getBraintreekeyApi();
    if (!result.error) {
      yield put({ type: GET_BRAINTREE_KEY_SUCCESS, data: result.token })
    } else {
      yield put({ type: GET_BRAINTREE_KEY_FAIL, data: null })
    }
  } catch (err) {
    yield put({ type: GET_BRAINTREE_KEY_FAIL, data: null })
  }
}

export function* watchGetBraintreekey() {
  yield takeLatest(GET_BRAINTREE_KEY, getBraintreekey)
}

/**
 * Update Card
 */
function* updateCard(payload) {
  try {
    const result = yield updateCardApi(payload.data);
    if (result && !result.error) {
      yield put({ type: UPDATE_CARD_SUCCESS, data: result })
    } else {
      yield put({ type: UPDATE_CARD_FAIL, data: result })
    }
  } catch (err) {
    yield put({ type: UPDATE_CARD_FAIL, data: { message: 'NET ERROR' } })
  }
}

export function* watchUpdateCard() {
  yield takeLatest(UPDATE_CARD, updateCard)
}

/**
 * Summer Camp Payment
 */
function* SCPayment(payload) {
  try {
    const result = yield SCPaymentApi(payload.data);
    if (result && _.isEmpty(result.errors)) {
      yield put({ type: SC_PAYMENT_SUCCESS, data: result })
    } else {
      yield put({ type: SC_PAYMENT_FAIL, data: result })
    }
  } catch (err) {
    yield put({ type: SC_PAYMENT_FAIL, data: { message: 'NET ERROR' } })
  }
}

export function* watchSCPayment() {
  yield takeLatest(SC_PAYMENT, SCPayment)
}