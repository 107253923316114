import React from 'react';
import './style.scss';
import Media from 'react-media';
import Loadable from '@loadable/component';
import { CarouselLeftButton, CarouselRightButton } from '../../../Constant';
import { Instructor } from 'src/Components'
import instructor1 from 'src/Assets/SummerCamp/instructor1.png';
import instructor2 from 'src/Assets/SummerCamp/instructor2.png';
import instructor3 from 'src/Assets/SummerCamp/instructor3.png';
import instructor4 from 'src/Assets/SummerCamp/instructor4.png';
import instructor5 from 'src/Assets/SummerCamp/instructor5.png';
import instructor6 from 'src/Assets/SummerCamp/instructor6.png';
import university1 from 'src/Assets/SummerCamp/University/university1.png';
import university2 from 'src/Assets/SummerCamp/University/berkeley.png';
// import university3 from 'src/Assets/SummerCamp/University/harvard.png';
import university4 from 'src/Assets/SummerCamp/University/lsu.png';
import university5 from 'src/Assets/SummerCamp/University/brown.png';
import university6 from 'src/Assets/SummerCamp/University/massachusetts.png';

const OwlCarousel = Loadable(() => import('react-owl-carousel'));

const InstructorInfo = [
  { id: "1", photo: instructor1, subject: "Computer Science", university: university1, name: "Jimmy M.", hours: "", role: "", other: "iCode Edu Lead Instructor" },
  { id: "2", photo: instructor2, subject: "Computer Science", university: university2, name: "Spencer S.", hours: "400+", role: "Teaching Coding", other: '' },
  { id: "3", photo: instructor3, subject: "Computer Science", university: university2, name: "Baljinder S.", hours: "", role: "", other: 'iCode Edu Instructor' },
  { id: "4", photo: instructor4, subject: "Computer Science", university: university4, name: "Annabelle K.", hours: "", role: "", other: 'iCode Edu Instructor' },
  { id: "5", photo: instructor5, subject: "Computer Science", university: university5, name: "Peter M.", hours: "200+", role: "Teaching Coding", other: '' },
  { id: "6", photo: instructor6, subject: "Computer Science", university: university6, name: "Ashlee N.", hours: "750+", role: "Teaching Coding", other: '' },
]

export default function ScInstructors() {

  return (
    <div className="sc-instructors-component">
      <Media queries={{
        ex: "(min-width: 1200px)",
        lg: "(min-width: 992px)",
        md: "(min-width: 768px)",
      }}>
        {matches => (
          <OwlCarousel
            className="owl-theme"
            rewind
            nav
            stagePadding={0}
            margin={matches.ex ? 30 : 15}
            items={matches.lg ? 3 : matches.md ? 2 : 1}
            dots={false}
            loop={false}
            navText={[CarouselLeftButton, CarouselRightButton]}
          >
            {InstructorInfo.map((item, index) =>
              <Instructor
                key={index}
                info={item}
                index={index}
              />
            )}
          </OwlCarousel>
        )}
      </Media>
    </div>
  );
}
