import React from "react";
import "./TutorSignUpPage.scss";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import arrow from "../../Assets/Common/left-arrow.svg";
import { ReactSVG } from "react-svg";
import user from "../../Assets/Common/user.svg";
import email_ico from "../../Assets/Common/email.svg";
import key from "../../Assets/Common/key.svg";
import place from "../../Assets/Common/place.svg";

import {
  SelectBox,
  StartButton,
  InputBox,
  TrainingVideo,
  Availability,
  Course,
  WelcomeHeader,
  TutorSignUpHeader
} from "../../Components";
import {
  tutorSignUp,
  getLevels,
  getSchools,
  getCities,
  getAllCourses
} from "../../Redux/Actions";
import { GetDashboardUrl } from "../../Constant";
import Helper from '../../Utils/Helper';

import { EmailValidation, PhoneValidation } from '../../Utils/Validation';
import _ from 'lodash'

import Media from 'react-media';
import { CarouselLeftButton, CarouselRightButton, finalTrainingNum } from '../../Constant';
import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel'));

const DegreeType = [
  { id: 1, name: "Bachelor" },
  { id: 2, name: "Masters" },
  { id: 3, name: "PhD" },
  { id: 4, name: "Dr" }
]

class TutorSignUpPage extends React.Component {
  state = {
    currentProgress: 2,

    photo: '',
    upload_picture: '',

    first_name: "",
    last_name: "",
    email: "",
    password: "",
    address_street: "",
    zipcode: "",
    region_id: "",
    phone: "",
    deliver: "",
    referal_code: "",

    country: 'US',

    computer_interest: "",

    school1_id: 1,
    major: "",
    gpa: "",
    degree1: '',

    school2_id: 1,
    degree2: "",
    degree2_type: "",

    school3_id: 1,
    degree3: "",
    degree3_type: "",

    profile_title: "",
    fan_facts: "",
    about_me: "",
    experience: "",
    approach: "",
    showPopUp: false,

    schedule_confirm: false,

    parms_validation: null,
    training_status: 0,
    schedule_data: '',

    courses: [],
    classifications: '',
    birth_date: '',

    isWelcomeDescription: false,
    selected_level: 2,
    selected_program_type: 'course'

  };

  constructor(props) {
    super(props);
    this.workContainer = React.createRef();
  }

  componentDidMount = () => {
    window.addEventListener("keydown", this.checkEnterPress);
    if (_.isEmpty(this.props.levels)) {
      this.props.getLevels()
    };

    if (_.isEmpty(this.props.schools)) {
      this.props.getSchools()
    };

    if (_.isEmpty(this.props.course_list)) {
      this.props.getAllCourses()
    };

    const { isUser, user, signup_progress } = this.props.location;

    if (isUser) {
      this.setState({
        training_status: user.training_status,
        currentProgress: signup_progress
      });
    } else {
      this.setState({ currentProgress: 1 });
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tutor_signup_loading && !this.props.tutor_signup_loading) {
      Helper.hideSpinner()
      if (this.props.tutor_signup_success) {
        this.setState({ currentProgress: 2 });
      } else {
        Helper.showToast('error', 3000, this.props.tutor_signup_error_msg)
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener("keydown", this.checkEnterPress);
  }

  checkEnterPress = (e) => {
    if (e.keyCode !== 13) return;
  };

  goBack = () => {
    this.props.history.push("/");
  };

  changeProgress_1 = (property, value, movePart = 0, subject_level) => {
    this.setState({ [property]: value });
    // movePart === 0 ? this.scrollToDom(0, 0) : this.scrollToDom(1, movePart);
  };

  inputProgress_1 = (property, value) => {
    if (value && value.id) {
      this.setState({ [property]: value.id });
    } else {
      this.setState({ [property]: value });
    }
  };

  scrollToDom = (section, part) => {
    const heightFromTop =
      (window.outerHeight -
        this.refs[`section${section}${part}`].offsetHeight) /
      2;
    const yToReach =
      this.refs[`section${section}${part}`].offsetTop - heightFromTop;
    this.workContainer.current.scrollTo({
      left: 0,
      top: yToReach,
      behavior: "smooth",
    });
  };

  pickFileForPhoto = (e) => {
    e.preventDefault();
    if (!e.target.files[0]) return;
    this.setState({
      progress_4: {
        ...this.state.progress_4,
        photo: URL.createObjectURL(e.target.files[0]),
      },
    });
  };

  updateCourse = (e) => {
    const { courses } = this.state;
    if (e.checked) {
      courses.push(e.id)
    } else {
      courses.splice(_.indexOf(courses, e.id), 1)
    }
  }

  tutorSignUp = () => {
    this.setState({ parms_validation: true });

    const {
      upload_picture, first_name, last_name, email, password, zipcode, region_id, phone, country,
      school1_id, major, major_gpa, degree1,
      school2_id, degree2, degree2_type,
      school3_id, degree3, degree3_type,
      profile_title, fan_facts, about_me, experience, approach, referal_code,
      courses, birth_date, address_street
    } = this.state;

    const signup_data = {
      upload_picture, first_name, last_name, phone, country, email, password, zipcode, region_id,
      school1_id, major, major_gpa, degree1,
      school2_id, degree2, degree2_type,
      school3_id, degree3, degree3_type,
      profile_title, fan_facts, about_me, experience, approach, referal_code, address_street,
      birth_date, classifications: JSON.stringify({ "1": courses })
    }
    if (
      upload_picture && first_name && last_name && EmailValidation(email) && password && zipcode && region_id && PhoneValidation(phone) &&
      school1_id > 1 && major && major_gpa &&
      profile_title && fan_facts && about_me && experience && approach
      // && !_.isEmpty(courses)           //Will be added this function in the further
    ) {
      this.tutorSignUp_server(signup_data);

    } else {
      Helper.showToast('error', 3000, "Please fill all fields correctly!")
    }
  };

  tutorSignUp_server = (signup_data) => {
    Helper.showSpinner();
    const formData = new FormData()
    formData.append('first_name', signup_data.first_name);
    formData.append('last_name', signup_data.last_name);
    formData.append('email', signup_data.email);
    formData.append('password', signup_data.password);
    formData.append('address_street', signup_data.address_street);
    formData.append('address_zipcode', signup_data.zipcode);
    formData.append('region_id', signup_data.region_id);
    formData.append('country_code', signup_data.country);
    formData.append('phone', signup_data.phone);
    formData.append('referrer_code', signup_data.referal_code);

    formData.append('classifications', signup_data.classifications);   //Will be added this function in the further
    formData.append('school1_id', signup_data.school1_id);
    formData.append('major', signup_data.major);
    formData.append('major_gpa', signup_data.major_gpa);
    // formData.append('degree1', signup_data.degree1);

    formData.append('school2_id', signup_data.school2_id);
    formData.append('degree2', signup_data.degree2);
    formData.append('degree2_type', signup_data.degree2_type);
    formData.append('school3_id', signup_data.school3_id);
    formData.append('degree3', signup_data.degree3);
    formData.append('degree3_type', signup_data.degree3_type);
    formData.append('profile_title', signup_data.profile_title);
    formData.append('fun_facts', signup_data.fan_facts);
    formData.append('about_me', signup_data.about_me);
    formData.append('experience', signup_data.experience);
    formData.append('approach', signup_data.approach);
    formData.append('picture', signup_data.upload_picture);
    formData.append('birth_date', signup_data.birth_date);

    this.props.tutorSignUp(formData);
  };

  scheduleConfirm = (status) => {
    this.setState({ schedule_confirm: true });
  };

  goToAvailability = () => {
    if (this.state.training_status === finalTrainingNum) {
      this.setState({ currentProgress: 3 });
    }
  };

  goToDashboard = () => {
    if (!_.isEmpty(this.state.schedule_data)) {
      window.location.href = GetDashboardUrl(this.props.user, this.props.token)
    }
  }

  pickFileForPhoto = (e) => {
    e.preventDefault();
    if (!e.target.files[0]) return;
    this.setState({ photo: URL.createObjectURL(e.target.files[0]) });
    this.setState({ upload_picture: e.target.files[0] });
  }

  cityTxt = (cityName) => {
    if (cityName.length % 2 === 0) {
      this.props.getCities(cityName);
    };
  };

  updateTrainingStatus = (training_status) => {
    this.setState({ training_status })
  };

  availabilityStatus = (schedule_data) => {
    this.setState({ schedule_data });
  };

  render() {
    const {
      photo, upload_picture, currentProgress, first_name, last_name, email, password, zipcode, region_id, phone, country, referal_code,
      computer_interest, classifications, courses,
      school1_id, major, major_gpa, degree1, degree2, degree3,
      profile_title, fan_facts, about_me, experience, approach,
      parms_validation, selected_level, selected_program_type,
      training_status, schedule_data,
      isWelcomeDescription, birth_date, address_street
    } = this.state;
    const { schools, levels, cities, course_list } = this.props;
    const courseList = this.props.course_list.filter(temp => (temp.level_id === selected_level) && (temp.obj_type === selected_program_type));

    return (
      <div className="tutorsignup-page">
        <div className="progress-explain">
          <ReactSVG
            src={arrow}
            style={{
              fill: "white",
              cursor: "pointer",
              top: "32px",
              left: "31px",
              position: "absolute",
            }}
            onClick={(e) => this.goBack()}
          />
          {/* <div className="bg-dot custom-bg" /> */}
          <div className="explain-content">
            {currentProgress === 1 && (
              <div id="section-explain-1">
                <h1>Become An Instructor</h1>
                <p>
                  iCode Edu is an education platform that empowers kids globally with the tools and skills they will need for the future digital world they will live and work in.<br />
                  We’re looking for individuals who are passionate about education and coding. iCode Edu will be offering one-on-one online coding lessons to kids aged 7 - 14. You will have the opportunity to teach coding to kids across the country and also in different countries around the world.
                </p>

              </div>
            )}
            {currentProgress === 2 && (
              <div id="section-explain-2">
                <h2>Complete training</h2>
                <p>

                </p>
              </div>
            )}
            {currentProgress === 3 && (
              <div id="section-explain-3">
                <h2>Set Availability</h2>
                <p>

                </p>
              </div>
            )}
          </div>
          <div className="support-contact">
            <p>Support:</p>
            <Link
              to='#'
              onClick={(e) => {
                window.location.href = `mailto:rebecca@icodeedu.com`;
              }}
            >
              <p>rebecca@icodeedu.com</p>
            </Link>
            <p>(817) 984 5300</p>
          </div>
        </div>
        <div className="work-area" ref={this.workContainer}>
          <div className="container work-container">
            <TutorSignUpHeader
              currentProgress={currentProgress}
            />
            {currentProgress === 1 && (
              <div className="work-content" id="section-1">
                <div className="row show-mobile-flex" id="section-1-title">
                  <div className="col-lg-12">
                    <h2>Become a tutor</h2>
                  </div>
                </div>
                <WelcomeHeader
                  isWelcomeDescription={isWelcomeDescription}
                  toggleWelcomeMore={() => this.setState({ isWelcomeDescription: !isWelcomeDescription })}
                />
                <div className="welcome-read-btn" onClick={() => this.setState({ isWelcomeDescription: !isWelcomeDescription })}>
                  {isWelcomeDescription ? 'Collapse Content' : 'Continue Reading'}
                </div>
                <div className="row section-1-row" id="section-1-1">
                  <div className="col-lg-12">
                    <h1>Personal Info</h1>
                  </div>

                  <div className="col-lg-12">
                    <div className="photo-area v-c my-2">
                      <div className="initial-name h-c v-c">
                        {photo && <img src={photo} className="avatar" alt="avatar" />}
                      </div>
                      <input type="file" accept="image/*" id="image_picker" style={{ display: 'none' }} onChange={e => this.pickFileForPhoto(e)}></input>
                      <label htmlFor="image_picker"><div className={`upload_picture btn-2 btn-2-outline upload_btn v-c h-c ${parms_validation && !upload_picture ? "upload-picture-error" : ""}`}>Upload Photo</div></label>
                      <div className="ml-2 show-web">(Linkedin-like profile pic)</div>
                    </div>
                  </div>

                  <div className="col-lg-6 profile-col">
                    {" "}
                    <InputBox
                      icon={user}
                      placeholder="First Name"
                      value={first_name}
                      onChange={(e) => this.inputProgress_1("first_name", e)}
                      className={`input-box ${parms_validation && !first_name ? "inputbox-container-error" : ""}`}
                    />{" "}
                  </div>
                  <div className="col-lg-6 profile-col">
                    {" "}
                    <InputBox
                      placeholder="Last Name"
                      value={last_name}
                      onChange={(e) => this.inputProgress_1("last_name", e)}
                      className={`input-box ${parms_validation && !last_name ? "inputbox-container-error" : ""}`}
                    />{" "}
                  </div>

                  <div className="col-lg-6 profile-col">
                    {" "}
                    <InputBox
                      icon={email_ico}
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => this.inputProgress_1("email", e)}
                      className={`input-box ${parms_validation && !EmailValidation(email) ? "inputbox-container-error" : ""}`}
                    />{" "}
                  </div>
                  <div className="col-lg-6 profile-col">
                    {" "}
                    <InputBox
                      icon={key}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => this.inputProgress_1("password", e)}
                      type={'password'}
                      className={`input-box ${parms_validation && !password ? "inputbox-container-error" : ""}`}
                    />{" "}
                  </div>
                  <div className="col-lg-6 profile-col">
                    {" "}
                    <InputBox
                      icon={place}
                      placeholder="Street Address"
                      value={address_street}
                      onChange={(e) => this.inputProgress_1("address_street", e)}
                      className={`input-box`}
                    />{" "}
                  </div>
                  <div className="col-lg-6 profile-col">
                    {" "}
                    <InputBox
                      icon={place}
                      placeholder="Zipcode"
                      value={zipcode}
                      onChange={(e) => this.inputProgress_1("zipcode", e)}
                      className={`input-box ${parms_validation && !zipcode ? "inputbox-container-error" : ""}`}
                    />{" "}
                  </div>
                  <div className="col-lg-6 profile-col">
                    {" "}
                    <SelectBox
                      placeholder="City"
                      show_arrow
                      options={cities || []}
                      onChange={(e) =>
                        this.inputProgress_1("region_id", e.id)
                      }
                      className={`input-box ${parms_validation && !region_id ? "selectbox-container-error" : ""}`}
                      isCity={true}
                      changeTxt={(e) => this.cityTxt(e)}
                    />
                    {" "}
                  </div>
                  <div className="col-lg-6 profile-col">
                    <InputBox
                      className={`input-box ${parms_validation && !PhoneValidation(phone) ? "inputbox-container-error" : ""}`}
                      phone_input
                      type="number"
                      placeholder={"Phone number"}
                      value={phone}
                      country={country}
                      onSelect={e => this.inputProgress_1("country", e)}
                      onChange={e => this.inputProgress_1("phone", e)} />
                  </div>
                  <div className="col-lg-6 profile-col">
                    Birth Date
                    <InputBox
                      className={`input-box`}
                      type="date"
                      placeholder={"Date of Birth"}
                      value={birth_date}
                      onChange={(e) => this.inputProgress_1("birth_date", e)} />
                  </div>
                  <div className="col-lg-6 profile-col">
                    Referal code (optional)
                    <InputBox
                      placeholder="Have a referal code (optional)"
                      value={referal_code}
                      onChange={(e) => this.inputProgress_1("referal_code", e)}
                      className="input-box"
                    />{" "}
                  </div>

                </div>

                {/* ------------------------College level subjects------------------------ */}
                <div>

                  {/* //Will be added this function in the further */}

                  {/* <div className="row section-1-row" id="section-1-2" ref="section12">
                    <div className="col-lg-12">                      
                      <h1>
                        Are you interested in tutoring computer science subjects for kids?
                      </h1>
                      <div className="subject-error-txt">{parms_validation && _.isEmpty(courses)? "Need 1 interested subject at least!" : ""}</div>
                    </div>
                    {['Yes', 'No'].map((item, index) => (
                      <div
                        className="col-lg-6 col-md-6 col-sm-6 col-6 grade-level-col"
                        key={index}
                        style={{ position: "relative" }}
                        onClick={(e) =>
                          this.changeProgress_1("computer_interest", item, index + 3, 2)
                        }
                      >
                        <div
                          className={`grade-level center-item ${
                            computer_interest === item ? "active" : ""
                          }`}
                        >
                          {item}
                        </div>
                        <div className="under-dot grade-level-bg" />
                      </div>
                    ))}
                  </div> */}
                  <div id="section-1-3" ref="section13">
                    {courseList.length > 0 && (
                      <div className="row section-1-row">
                        <div className="col-lg-12">
                          <h1>Select Courses and Camps you want to Teach</h1>
                          <div className="tech-row v-c">
                            {[{ name: 'course', label: 'Courses' }, { name: 'camp', label: 'Camps' }].map((item, index) => <div className={`tech v-c h-c ${selected_program_type === item.name ? 'active' : ''}`} key={index} onClick={e => this.setState({ selected_program_type: item.name })}>
                              {item.label}
                            </div>)}
                          </div>
                          <div className="agegroup-row v-c">
                            {levels.map((item, index) => <div className={`age-group ${selected_level === item.id ? 'active' : ''}`} key={index} onClick={e => this.setState({ selected_level: item.id })}>
                              {item.name}
                            </div>)}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <Media queries={{
                            lg: "(min-width: 992px)",
                            md: "(min-width: 768px)",
                          }}>
                            {matches => (
                              <OwlCarousel
                                key={selected_level + '-' + selected_program_type}
                                className="owl-theme"
                                rewind
                                nav
                                stagePadding={5}
                                margin={15}
                                items={matches.lg ? 3 : matches.md ? 2 : 1}
                                dots={false}
                                loop={false}
                                navText={[CarouselLeftButton, CarouselRightButton]}
                              >
                                {courseList.map((item, index) =>
                                  <Course key={index} info={item} isSignup={true} isSelect={courses.includes(item.id)} updateCourse={(e) => this.updateCourse(e)} />
                                )}
                              </OwlCarousel>
                            )}
                          </Media>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* ------------------------ Education Part------------------------ */}
                <div className="row section-1-row" id="section-1-4" ref="section14">
                  <div className="col-lg-12">
                    <h1>Education</h1>
                  </div>
                  {/* ------------shcool1---------------   */}
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <SelectBox
                      // icon={shoppingList}
                      placeholder="Undergraduate School"
                      show_arrow
                      options={schools || []}
                      onChange={(e) =>
                        this.inputProgress_1("school1_id", e.id)
                      }
                      className={`input-box ${parms_validation && school1_id < 2 ? "selectbox-container-error" : ""}`}
                    />{" "}
                  </div>
                  {/* <div className="col-lg-12 profile-col">
                    {" "}
                    <InputBox
                      placeholder="Degree"
                      onChange={(e) => this.inputProgress_1("degree1", e)}
                      className={`input-box ${parms_validation && !degree1 ? "selectbox-container-error" : ""}`}
                    />{" "}
                  </div> */}
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <InputBox
                      placeholder="Major"
                      value={major}
                      onChange={(e) => this.inputProgress_1("major", e)}
                      className={`input-box ${parms_validation && !major ? "inputbox-container-error" : ""}`}
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col third-col">
                    {" "}
                    <InputBox
                      placeholder="What is your major GPA?"
                      value={major_gpa}
                      onChange={(e) => this.inputProgress_1("major_gpa", e)}
                      className={`input-box ${parms_validation && !major_gpa ? "inputbox-container-error" : ""}`}
                    />{" "}
                  </div>
                  {/* ------------shcool2---------------   */}
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <SelectBox
                      placeholder="Graduate School (optional)"
                      show_arrow
                      options={schools || []}
                      onChange={(e) =>
                        this.inputProgress_1("school2_id", e.id)
                      }
                      className="input-box"
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <InputBox
                      placeholder="Degree"
                      value={degree2}
                      onChange={(e) => this.inputProgress_1("degree2", e)}
                      className="input-box"
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col third-col">
                    <SelectBox
                      placeholder="Please select a type"
                      show_arrow
                      options={DegreeType || []}
                      onChange={(e) => this.inputProgress_1("degree2_type", e.name)}
                      className="input-box"
                    />
                  </div>
                  {/* ------------shcool3---------------   */}
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <SelectBox
                      placeholder="Graduate School (optional)"
                      show_arrow
                      options={schools || []}
                      onChange={(e) =>
                        this.inputProgress_1("school3_id", e.id)
                      }
                      className="input-box"
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <InputBox
                      placeholder="Degree"
                      value={degree3}
                      onChange={(e) => this.inputProgress_1("degree3", e)}
                      className="input-box"
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col">
                    <SelectBox
                      placeholder="Please select a type"
                      show_arrow
                      options={DegreeType || []}
                      onChange={(e) => this.inputProgress_1("degree3_type", e.name)}
                      className="input-box"
                    />
                  </div>
                </div>

                {/* ------------------------ profile Part------------------------ */}
                <div
                  className="row section-1-row"
                  id="section-1-6"
                  ref="section116"
                >
                  <div className="col-lg-12">
                    <h1>Profile Title</h1>
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      placeholder="Experienced Math & Science Ivy League Tutor."
                      value={profile_title}
                      maxLength="45"
                      onChange={(e) =>
                        this.inputProgress_1("profile_title", e.target.value)
                      }
                      className={parms_validation && !profile_title ? "textarea-error" : ""}
                    />
                    <div className="under-dot additional-bg" />
                  </div>
                </div>
                <div
                  className="row section-1-row"
                  id="section-1-6"
                  ref="section116"
                >
                  <div className="col-lg-12">
                    <h1>Fun Facts</h1>
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      placeholder="One passion people quickly learn about me is that I LOVE golf! Any time I have a chance, you will find me playing or studying the sport. I am the oldest of seven, and have never been out of Texas."
                      value={fan_facts}
                      // maxLength="50"
                      onChange={(e) =>
                        this.inputProgress_1("fan_facts", e.target.value)
                      }
                      className={parms_validation && !fan_facts ? "textarea-error" : ""}
                    />
                    <div className="under-dot additional-bg" />
                  </div>
                </div>
                <div
                  className="row section-1-row"
                  id="section-1-6"
                  ref="section116"
                >
                  <div className="col-lg-12">
                    <h1>About Me</h1>
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      placeholder="I am a senior Accounting major at Texas Christian University. I've been on the Dean's List for 2 years in a row! When I graduate, I hope to attend the University of Texas is Arlington to earn my Masters."
                      value={about_me}
                      onChange={(e) =>
                        this.inputProgress_1("about_me", e.target.value)
                      }
                      className={parms_validation && !about_me ? "textarea-error" : ""}
                    />
                    <div className="under-dot additional-bg" />
                  </div>
                </div>
                <div
                  className="row section-1-row"
                  id="section-1-6"
                  ref="section116"
                >
                  <div className="col-lg-12">
                    <h1>Experience</h1>
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      placeholder="As a member of National Junior Honor Society, I had the opportunity to tutor students bi-weekly in all subject areas during my senior year in high school."
                      value={experience}
                      onChange={(e) =>
                        this.inputProgress_1("experience", e.target.value)
                      }
                      className={parms_validation && !experience ? "textarea-error" : ""}
                    />
                    <div className="under-dot additional-bg" />
                  </div>
                </div>
                <div
                  className="row section-1-row"
                  id="section-1-6"
                  ref="section116"
                >
                  <div className="col-lg-12">
                    <h1>Approach</h1>
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      placeholder="As a child, I struggled in school and found learning to be difficult and demoralizing. In middle school, I was fortunate enough to have a teacher who, through positive encouragement and persistence."
                      value={approach}
                      onChange={(e) =>
                        this.inputProgress_1("approach", e.target.value)
                      }
                      className={parms_validation && !approach ? "textarea-error" : ""}
                    />
                    <div className="under-dot additional-bg" />
                  </div>
                </div>
              </div>
            )}
            {currentProgress === 2 && (
              <div className="work-content v-r" id="section-2">
                <div className="row show-mobile-flex" id="section-2-title">
                  <div className="col-lg-12">
                    <h2>Become a tutor</h2>
                  </div>
                </div>
                <h4>
                  Thank you for taking the first step in becoming an iCode Edu.
                  instructor Please watch the training videos below to understand
                  how the platform works. Once all videos are watched, a member of our onboarding
                  team will contact you to conduct an interview and communicate the next step
                  in the process.
                </h4>
                <br />

                <TrainingVideo
                  training_status={training_status}
                  updateTrainingStatus={(e) => this.updateTrainingStatus(e)}
                />

                <div className="start-button-container">
                  <StartButton
                    className="start-btn"
                    height="65px"
                    startText="Set Availability"
                    onClick={() => this.goToAvailability()}
                    isReady={training_status === finalTrainingNum ? true : false}
                  />
                </div>
              </div>
            )}
            {currentProgress === 3 && (
              <div className="work-content v-r" id="section-2">
                <h4 className="text-center">Set Your Weekly Availability</h4>
                <br />

                <Availability
                  availabilityStatus={(e) => this.availabilityStatus(e)}
                />

                <div className="start-button-container">
                  <StartButton
                    className="start-btn"
                    height="65px"
                    startText="GO TO DASHBOARD"
                    onClick={() => this.goToDashboard()}
                    isReady={!_.isEmpty(schedule_data) ? true : false}
                  />
                </div>
              </div>
            )}
          </div>

          {currentProgress === 1 && (
            <div className={`submit-area`}>
              <div
                className="container submit-container"
                style={{ paddingBottom: 100 }}
              >
                <div className="btn-area" ref="section00">
                  <div
                    className="back-btn"
                    onClick={(e) => this.goBack()}
                  >
                    <div className="back-icon center-item">
                      <ReactSVG src={arrow} style={{ fill: "#C0C5C9" }} />
                    </div>
                    <div className="back-text center-item">Back</div>
                  </div>
                  <div>
                    <StartButton
                      className="start-btn show-web-flex"
                      height="65px"
                      startText="Continue"
                      onClick={(e) => this.tutorSignUp()}
                      isReady={true}
                    />
                    <StartButton
                      className="start-btn show-mobile-flex"
                      height="50px"
                      startText="Continue"
                      onClick={(e) => this.tutorSignUp()}
                      isReady={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  levels: state.Levels.levels,
  schools: state.Schools.schools,
  cities: state.City.cities,
  course_list: state.Course.course_list,

  token: state.Auth.token,
  user: state.Auth.user,
  tutor_signup_success: state.Auth.tutor_signup_success,
  tutor_signup_loading: state.Auth.tutor_signup_loading,
  tutor_signup_error_msg: state.Auth.tutor_signup_error_msg,
});

export default connect(mapStateToProps, {
  tutorSignUp,
  getLevels,
  getSchools,
  getCities,
  getAllCourses,
})(withRouter(TutorSignUpPage));


