import React from 'react';
import './AboutIcode.scss';

export default class AboutIcode extends React.Component {

    render() {
        return (
            <div className="aboutIcode-component">
                <div className="container">
                    <div className='main-content'>
                        <h3>ABOUT ICODE EDU</h3>
                        <p>
                            iCode Edu is an educational technology platform that offers coding lessons 
                            and fun mentorship programs to kids 7-17 that parents can incorporate as part of their extracurricular activities to  prepare them for the world of tomorrow. Our lessons are one-on-one , delivered online by tutors from top US universities using our world renown curriculum. iCode also has an online community where kids can carry out projects, participate in competitions with kids all over the world.  iCode is based in Dallas Texas and is a program of Frogtutoring LLC with more than 8000 tutors 
                            and millions of tutoring hours delivered.
                        </p>
                    </div>
                </div>
            </div>
        )
    };
};
