import React from 'react';
import './style.scss';
import moment from 'moment';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import GiftBG from 'src/Assets/IGenius/gift-bg.png';
import Gift1 from 'src/Assets/IGenius/gift-1.svg';
import Gift2 from 'src/Assets/IGenius/gift-2.svg';
import Gift3 from 'src/Assets/IGenius/gift-3.svg';
import GiftItem from './GiftItem';

const GiftsInfo = [
  { id: "1", logo: Gift1, title: "FREE E-book", description: "RAISING FUTURE FOUNDERS.", subDes: 'A PARENT’s GUIDE to coding and technology edu for kids', },
  { id: "1", logo: Gift2, title: "Parent Guide Checklist:", description: "Jobs being eliminated & created by technology", subDes: '', },
  { id: "1", logo: Gift3, title: "$250 CODING SCHOLARSHIP", description: "Every student who attends our iGenius camp is eligible for a $250 scholarship to continue their computer science education with our one - on one or small group lessons after camp", subDes: '', },
]

export default function Gifts({
  isFreeLesson
}) {

  const date = moment(new Date()).format('MMMM YYYY');
  const Month = date.split(' ')[0]
  const Year = date.split(' ')[1]

  return (
    <div className='gifts-component'>
      <div className='container'>
        <h2><strong>GIFTS FOR</strong> ALL ATTENDEES</h2>
        <div className='gifts-container mx-auto'>
          <img src={GiftBG} alt='gift-bg' className='gift-bg show-web' />
          <div className='gifts-content mx-auto'>
            <div className='date center show-web'>{`${Month} \n${Year}`}</div>
            <div className='gift-header v-r v-c'>
              <div className='sub-title'>masterclass for parents</div>
              <div className='title'>$FREE</div>
            </div>
            {GiftsInfo.map((item, index) => (
              <GiftItem
                key={index}
                index={index}
                info={item}
              />
            ))}
            <AnchorLink
              className="signUp-btn center"
              href={"#section-enroll"}>
              Enroll Your Child For FREE Coding Challenge
            </AnchorLink>
          </div>
        </div>
      </div>
    </div>
  );
}
