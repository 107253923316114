import {
  SET_REFERRAL_CODE,
} from '../Type';

const INITIAL = {
  referral_code: '',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * Set Referral Code
     */
    case SET_REFERRAL_CODE: {
      const { referral_code } = action.data;
      return {
        ...state,
        referral_code,
      };
    }

    default:
      return state;
  }
}