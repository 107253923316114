import {
  GET_BRAINTREE_KEY,
  GET_BRAINTREE_KEY_SUCCESS,
  GET_BRAINTREE_KEY_FAIL,
  UPDATE_CARD,
  UPDATE_CARD_SUCCESS,
  UPDATE_CARD_FAIL,
  SC_PAYMENT,
  SC_PAYMENT_SUCCESS,
  SC_PAYMENT_FAIL,

} from '../Type';

const INITIAL = {
  braintreeKey: null,
  get_braintreeKey_loading: true,
  update_card_loading: true,
  update_card_success: false,
  update_card_res: '',
  sc_payment_loading: true,
  sc_payment_success: false,
  sc_payment_res: ''
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * GET_BRAINTREE_KEY
     */
    case GET_BRAINTREE_KEY: {
      return {
        ...state,
        braintreeKey: null,
        get_braintreeKey_loading: true
      }
    }
    case GET_BRAINTREE_KEY_FAIL: {
      return {
        ...state,
        braintreeKey: null,
        get_braintreeKey_loading: false
      }
    }
    case GET_BRAINTREE_KEY_SUCCESS: {
      return {
        ...state,
        braintreeKey: action.data,
        get_braintreeKey_loading: false
      }
    }

    /**
     * UPDATE_CARD
     */
    case UPDATE_CARD: {
      return {
        ...state,
        update_card_loading: true,
        update_card_success: false,
        update_card_res: '',
      }
    }
    case UPDATE_CARD_FAIL: {
      return {
        ...state,
        update_card_loading: false,
        update_card_success: false,
        update_card_res: action.data.message,
      }
    }
    case UPDATE_CARD_SUCCESS: {
      return {
        ...state,
        update_card_loading: false,
        update_card_success: true,
        update_card_res: action.data.message,
      }
    }

    /**
     * Summer Camp Payment
     */
    case SC_PAYMENT: {
      return {
        ...state,
        sc_payment_loading: true,
        sc_payment_success: false,
        sc_payment_res: '',
      }
    }
    case SC_PAYMENT_FAIL: {
      return {
        ...state,
        sc_payment_loading: false,
        sc_payment_success: false,
        sc_payment_res: "Failed, Please again!",
      }
    }
    case SC_PAYMENT_SUCCESS: {
      return {
        ...state,
        sc_payment_loading: false,
        sc_payment_success: true,
        sc_payment_res: "Success!",
      }
    }

    default:
      return state;
  }
}