import {
  FEEDBACK_MODAL_TOGGLE,
  TUTOR_MODAL_TOGGLE,
  VIDEO_MODAL_TOGGLE,
  SC_NOTIFICATION_MODAL,
} from '../Type';

const INITIAL = {
  isFeedbackModalOpen: false,
  isTutorModalOpen: false,

  isVideoModalOpen: false,

  isScModalOpen: false,
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case FEEDBACK_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isFeedbackModalOpen: isOpen, info };
    }

    case TUTOR_MODAL_TOGGLE: {
      const { isOpen, info, rect } = action.data;
      return { ...state, isTutorModalOpen: isOpen, info, rect };
    }

    case VIDEO_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isVideoModalOpen: isOpen, info };
    }

    case SC_NOTIFICATION_MODAL: {
      const { isOpen, } = action.data;
      return { ...state, isScModalOpen: isOpen, };
    }

    default:
      return state;
  }
}