import React from 'react';
import './StudentSignupPage.scss';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  StudentSignupStart,
  HowItWorks,
  Universities,
  WhyStudyCoding,
  WhyIcodeEdu,
  ReviewsArea,
  OurFun,
  //NationalMedia,
  GetMoreInfo,
  StudentSignupFooter
} from '../../Components';
import { getLandingData } from '../../Redux/Actions';

class StudentSignupPage extends React.Component {

  componentDidMount() {
    this.props.getLandingData();
  };
  render() {
    const { LandingData } = this.props;
    return (
      <div className="home-page">
        <StudentSignupStart LandingData={LandingData} />
        <HowItWorks />
        <Universities />
        <WhyStudyCoding />
        <WhyIcodeEdu />
        <ReviewsArea />
        <OurFun />
        {/* {LandingData?.video_reviews && 
          <NationalMedia 
          videoData={LandingData.video_reviews}/> // will be enable in the further
        } */}
        <GetMoreInfo LandingData={LandingData} />
        <StudentSignupFooter />
      </div>
    )
  };
};

function mapStateToProps(state) {
  return {
    LandingData: state.Landing.LandingData,
  }
}

export default connect(mapStateToProps, {
  getLandingData
})(withRouter(StudentSignupPage));