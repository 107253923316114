import React from 'react';
import './style.scss';
import _ from 'lodash'

export default function TypeCategoryFilter({
  camps,
  cityName,
  scStateInfo
}) {

  const getFilterType = (type) => {
    const _filter = camps.filter(camp => camp.types === type);
    return _filter
  }

  const getFilterCategory = (category) => {
    var _filterList = [];
    // eslint-disable-next-line array-callback-return
    camps.map((camp, index) => {
      const _filter = camp.categories.filter(item => item === category);
      if (!_.isEmpty(_filter)) {
        _filterList[index] = camp
      }
    })
    return _filterList
  }

  return (
    <div className="type-category-filter-component">
      <div className='filter-section'>
        <h3>{`${cityName} Summer Camps guide By Type`}</h3>
        <div className="category-list row">
          {scStateInfo.types.map((type, index) => (
            !_.isEmpty(getFilterType(type)) && <div className="col-lg-6" key={index}>
              <h4>{`${cityName} ${type} Summer Camps`}</h4>
              <ul>
                {getFilterType(type).map((camp, c_index) => (
                  <li className="" key={c_index}>
                    <a href={`#camp_${camp.id}`} className='camp-name'>{camp.name}</a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className='filter-section'>
        <h3>{`${cityName} Summer Camps guide By Category`}</h3>
        <div className="category-list row">
          {scStateInfo.categories.map((category, index) => (
            !_.isEmpty(getFilterCategory(category)) && <div className="col-lg-6" key={index}>
              <h4>{`${cityName} ${category} Summer Camps`}</h4>
              <ul>
                {getFilterCategory(category).map((camp, c_index) => (
                  <li className="" key={c_index}>
                    <a href={`#camp_${camp.id}`} className='camp-name'>{camp.name}</a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
