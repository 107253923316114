import React from 'react';
import './style.scss';
import facebook from 'src/Assets/Common/facebook-logo.png';
import linkedin from 'src/Assets/Common/linked-in-logo.png';
import twitter from 'src/Assets/Common/twitter-logo.png';
// import pinterest from 'src/Assets/Common/pinterest-logo.png';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  // PinterestShareButton,
} from "react-share";

export default function SocialSharing({
  ShareURL, info
}) {

  console.log("==info==", info)

  return (
    <div className="sharing-area">
      <FacebookShareButton url={ShareURL} className="share-btn" >
        <div className='share-logo'>
          <img src={facebook} alt="facebook" />
        </div>
      </FacebookShareButton>
      <LinkedinShareButton url={ShareURL} className="share-btn">
        <div className='share-logo'>
          <img src={linkedin} alt="linkedin" />
        </div>
      </LinkedinShareButton>
      <TwitterShareButton url={ShareURL} className="share-btn" >
        <div className='share-logo'>
          <img src={twitter} alt="twitter" />
        </div>
      </TwitterShareButton>
      {/* <PinterestShareButton url={ShareURL} className="share-btn" >
        <div className='share-logo'>
          <img src={pinterest} alt="pinterest" />
        </div>
      </PinterestShareButton> */}
      {/* <a href="https://www.facebook.com/ICode-Edu-105901597689621/?modal=admin_todo_tour" className="share-logo" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook" /></a>
      <a href="https://www.linkedin.com/company/icode-edu/" className="share-logo" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="facebook" /></a>
      <a href="https://twitter.com/EduIcode" className="share-logo" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="twitter" /></a>
      <a href="https://www.pinterest.com/" className="share-logo" target="_blank" rel="noopener noreferrer"><img src={pinterest} alt="pinterest" /></a> */}
    </div>
  );
}
