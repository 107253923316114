import React from 'react';
import './style.scss';

export default function CampListFilter({
  camps
}) {

  return (
    <div className='camp-list-filter-component'>
      <ul className="row">
        {camps.map((camp, index) => (
          <li className="col-lg-6" key={index}>
            <a href={`#camp_${camp.id}`} className='camp-name'>{camp.name}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}
