import React from 'react';
import './style.scss';

export default function GiftItem({
  info, index
}) {

  const {
    logo,
    title,
    description,
    subDes
  }= info

  return (
    <div className='gift-item-component'>
      <div className={`img-content center`} >
        <img src={logo} className={`logo-img  ${index === 1 ? 'second-img': ''}`} alt='left-icon' />
      </div>
      <div className='description-content'>
        <div className='title' >{title}</div>
        <div className='description' >{description}</div>
        <div className='sub-description' >{subDes}</div>
      </div>
    </div>
  );
}
