import React from 'react';
import './Accordion.scss';
import plus from '../../Assets/Icon/plus.svg';
import minus from '../../Assets/Icon/minus.svg';
import { ReactSVG } from 'react-svg'

export default class Accordion extends React.Component {

    state = {
        isOpen: false
    }

    toggleAccordion = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        const { className, title, children } = this.props;
        const { isOpen } = this.state;
        return (
            <div className={`accordion-component v-r ${className} ${isOpen ? 'active' : ''}`}>
                <div className="title-area" onClick={ () => this.toggleAccordion() }>
                    <ReactSVG src={!isOpen ? plus : minus} className={`svg-icon ${isOpen ? 'opened' : ''}`} />
                    <div className="accordion-title">{title}</div>
                </div>
                <div className={`accordion-content ${isOpen ? 'active' : ''}`}>
                    {children}
                </div>
            </div>
        );
    }
}
