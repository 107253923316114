import arrowLeft from './Assets/Icon/arrow-left.png';
import arrowRight from './Assets/Icon/arrow-right.png';

import game from './Assets/Course/game.png';
import machine from './Assets/Course/machine.png';
import python from './Assets/Course/python.png';
import javascript from './Assets/Course/javascript.png';
import html5 from './Assets/Course/html5.png';
import imac from './Assets/Course/imac.png';
import document from './Assets/Course/document.png';
import ice from './Assets/Course/ice.png';

import avatar1 from './Assets/Review/avatar1.png';
import avatar2 from './Assets/Review/avatar2.png';
import avatar3 from './Assets/Review/avatar3.png';
import avatar4 from './Assets/Review/avatar4.png';
import avatar5 from './Assets/Review/avatar5.png';

import university1 from './Assets/StudentSignup/U_harvard.png';
import university2 from './Assets/StudentSignup/U_Yale.png';
import university3 from './Assets/StudentSignup/U_columbia.png';
import university4 from './Assets/StudentSignup/U_massachusetts.png';
import university5 from './Assets/StudentSignup/U_berkeley.png';
import university6 from './Assets/StudentSignup/U_carnegie.png';
import university7 from './Assets/StudentSignup/U_cornell.png';
import university8 from './Assets/StudentSignup/U_pennState.png';


export const StarSVGViewBox = "0 0 16 15";
export const StarSVGIconPath = "M7.90577 0.587492L9.82622 5.13287L14.7428 5.55524C15.0838 5.58469 15.2225 6.01017 14.9637 6.23414L11.2344 9.46512L12.3519 14.2717C12.4294 14.6057 12.0675 14.8684 11.7746 14.6909L7.54927 12.1427L3.32397 14.6909C3.03025 14.8676 2.6691 14.6049 2.7466 14.2717L3.86415 9.46512L0.134072 6.23337C-0.124778 6.00939 0.0131721 5.58392 0.354947 5.55447L5.27155 5.13209L7.192 0.587492C7.3253 0.271292 7.77247 0.271292 7.90577 0.587492Z";

export const CarouselLeftButton = `
    <div style='width:45px;height:45px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 17px rgba(42, 26, 127, 0.2);'>
        <img src='${arrowLeft}' alt='nav-arrow' />
    </div>
`;
export const CarouselRightButton = `
    <div style='width:45px;height:45px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 7px rgba(42, 26, 127, 0.2);'>
        <img src='${arrowRight}' alt='nav-arrow' />
    </div>
`;

export const API_URL = "https://api.icodeedu.com/api";

export const SCHEDULE_URL = "https://calendly.com/roland-21/meeting-with-icodeteam";
export const STRIPE_API_KEY = "pk_test_1mmpj1OCbdawd5tVNv8IiRHh";
export const ONLINE_URL = "https://icode.sugarman.now.sh";
export const DOMAIN = "https://icodeedu.com/";

export const STUDENT_DASHBOARD_URL = "https://student.icodeedu.com/auth?token=";
export const TUTOR_DASHBOARD_URL = "https://tutor.icodeedu.com/auth?token=";

export const PlanPrices = [
    [
        [199, 179.1, 159.2],
        [299, 269.1, 239.2]
    ],
    [
        [350, 315, 280],
        [450, 405, 360]
    ]
];

export const AgeGroup = [
    { id: 1, period: '7 - 8 Yrs', min: 7, max: 8, gradeLevel: "7-8" },
    { id: 2, period: '9 - 10 Yrs', min: 9, max: 10, gradeLevel: "9-10" },
    { id: 3, period: '11 - 12 Yrs', min: 11, max: 12, gradeLevel: "11-12" },
    { id: 4, period: '+ 13 Yrs', min: 13, max: 1000, gradeLevel: "13+" },
];

export const GradeList = [
    { id: 1, grade: '0 - 2', age_period: '5 - 7', age_min: 5, age_max: 7 },
    { id: 2, grade: '3 - 5', age_period: '8 - 10', age_min: 8, age_max: 10 },
    { id: 3, grade: '3 - 9', age_period: '8 - 14', age_min: 8, age_max: 14 },
    { id: 4, grade: '5 - 9', age_period: '10 - 14', age_min: 10, age_max: 14 },
    { id: 5, grade: '6 - 9', age_period: '11 - 14', age_min: 11, age_max: 14 },
    { id: 6, grade: '8+', age_period: '13+', age_min: 13, age_max: null },
    { id: 7, grade: '9+', age_period: '14+', age_min: 14, age_max: null },
]

export const CourseCategories = [
    { id: 1, logo: game, name: 'Game Design 101' },
    { id: 2, logo: machine, name: 'AR Studio' },
    { id: 3, logo: python, name: 'Python' },
    { id: 4, logo: javascript, name: 'Javascript' },
    { id: 5, logo: html5, name: 'Html5' },
    { id: 6, logo: imac, name: 'Desktop Software' },
    { id: 7, logo: document, name: 'Script' },
    { id: 8, logo: ice, name: 'Icon Coding' },
];

export const CourseLevels = [
    '', 'Beginner', 'Intermediate', 'Advanced'
];

export const Avatars = [
    avatar1, avatar2, avatar3, avatar4, avatar5
];

export const GetDashboardUrl = (user, token) => {
    return (
        user.role === 'student'
            ? process.env.REACT_APP_STUDENT_DASHBOARD_URL
            : process.env.REACT_APP_TUTOR_DASHBOARD_URL) + 'auth?token=' + token;
}

export const finalTrainingNum = 9;

export const UniversityList = [
    university1, university2, university3, university4, university5, university6, university7, university8
]