import React from 'react';
import './AboutUsPage.scss';
import book from '../../Assets/Common/book.svg';
import target from '../../Assets/Common/target.svg';
import value from '../../Assets/Common/value.svg';
import img1 from '../../Assets/Signup/ads-2.png';
import img2 from '../../Assets/Signup/ads-3.png';
import img3 from '../../Assets/Signup/ads-6.png';
import { ReactSVG } from 'react-svg';

const contents = [
    { left_img: book, right_img: img1, title: 'About us', explain: 'iCode Edu was founded under the belief that all students deserve to learn to code regardless of age or experience level. We believe the first step in a successful learning experience for kids is finding the "right" instructor. That is why we only hire the top students and graduates from the top U.S Universities. Each one of our well-trained instructors specializes in at least one area of technology and coding and is eager to aid in the academic success of their students.'},
    { left_img: target, right_img: img2, title: 'Mission', explain: `The purpose of our organization is to make a positive difference in the lives of students through personalized coding lessons and mentoring. First and foremost, we are a family! We focus on mentoring and building relationships between students and instructors because we believe that a person-to-person connection is the best way to grow students into motivated programmers and achievers.In everything we do, iCode Edu strives to perform to the highest degree of quality, accountability, and transparency.`},
    { left_img: value, right_img: img3, title: 'Our Story', explain: `Traditional coding programs focus on small-group learning centers and summer camps. However, studies have shown that students learn more effectively in a one-on-one setting in the comfort of their own home. So rather than having you come to us, we maximize your child’s learning potential by bringing coding lessons to your home through our customized online coding lessons.<br />iCode Edu also understands that it’s more than teaching, it’s mentoring. Our instructors make a conscious effort to get to know their students, build relationships with them, and motivate and encourage each student individually.`},
];

function innerFunc(item) {
    return {__html: item.explain}
}

export default class AboutUsPage extends React.Component {
    render() {
        return (
            <div className="aboutus-page">
                <div className="container aboutus-container">
                    <div className="title">About Us</div>
                    {
                        contents.map((item, index) => {
                            return (
                                <div className={`row main-row ${index === 0 && 'first-row'}`} key={index}>
                                    <div className="col-lg-6 order-lg-first order-second left-area">
                                        {/* <img src={item.left_img} alt="tutor_mean" /> */}
                                        <ReactSVG src={item.left_img} className="svg-icon" />
                                        <h1>{item.title}</h1>
                                        <p dangerouslySetInnerHTML={innerFunc(item)}></p>
                                    </div>
                                    <div className="col-lg-6 order-lg-second order-first right-area ">
                                        <img src={item.right_img} alt="tutor_content" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}
