import React from 'react';
import './style.scss';
import {
  TypeCategoryFilter,
  CampListFilter,
  StateCityTypeLink,
  StateCityCategoryLink
} from 'src/Components';

export default function SummerCampDetail({
  camps,
  subTitle = '',
  summary = '',
  isStateCity = false,
  cityName = '',
  stateName = '',
  scStateInfo
}) {

  return (
    <div className='summer-camp-detail-component'>
      <div className="container">
        <div className="summer-camp-detail-content">
          {subTitle && <h2 className='summary'>{subTitle}</h2>}
          {summary && <p className='summary'>{summary}</p>}

          <h2>Table of Content</h2>
          {isStateCity ? (
            <>
              <TypeCategoryFilter
                camps={camps}
                cityName={cityName}
                scStateInfo={scStateInfo}
              />
              <StateCityTypeLink
                cityName={cityName}
                stateName={stateName}
                scStateInfo={scStateInfo}
              />
              <StateCityCategoryLink
                cityName={cityName}
                stateName={stateName}
                scStateInfo={scStateInfo}
              />
            </>
          ) : (
            <CampListFilter
              camps={camps}
            />
          )}
          {camps.map((camp, index) => (
            <div key={index} className="camp-section" id={`camp_${camp.id}`}>
              <hr />
              <h2>{camp.name}</h2>
              <div className='h-r'>
                <p className='key'>Category:</p>
                <div className='h-r'>
                  {camp.categories.map((item, index) => (
                    <p key={index}>
                      {item}
                    </p>
                  ))}
                </div>
              </div>
              {camp.gender && <div className='h-r'>
                <p className='key'>Gender:</p>
                <div>{camp.gender}</div>
              </div>}
              {camp.types && <div className='h-r'>
                <p className='key'>Type:</p>
                <p>{camp.types}</p>
              </div>}
              {camp.classification && <div className='h-r'>
                <p className='key'>Classification:</p>
                <p>{camp.classification}</p>
              </div>}
              {camp.fee && <div className='h-r'>
                <p className='key'>Fee:</p>
                <p>{camp.fee}</p>
              </div>}

              {camp.about && <div className='sub-section'>
                <h3>About Camp</h3>
                <div
                  className='description'
                  dangerouslySetInnerHTML={{ __html: camp.about }} 
                />
              </div>}
              {camp.pricing && <div className='sub-section'>
                <h3>Pricing</h3>
                <div
                  className='description'
                  dangerouslySetInnerHTML={{ __html: camp.pricing }} 
                />
              </div>}
              {camp.schedule && <div className='sub-section'>
                <h3>Schedule and Program</h3>
                <div
                  className='description'
                  dangerouslySetInnerHTML={{ __html: camp.schedule }} 
                />
              </div>}
              <div className='sub-section'>
                <h3>Contact</h3>
                {camp.website && <div className='h-r'>
                  <p className='key'>Website:</p>
                  <a target={'_blank'} rel="noopener noreferrer" href={camp.website}>
                    {camp.website}
                  </a>
                </div>}
                {camp.phone && <div className='h-r'>
                  <p className='key'>Phone:</p>
                  <p>{camp.phone}</p>
                </div>}
                {camp.email && <div className='h-r'>
                  <p className='key'>Email:</p>
                  <p>{camp.email}</p>
                </div>}
                {(camp.address || camp.city || camp.zipcode) && <div className='h-r'>
                  <p className='key'>Address:</p>
                  <p>{camp.address}, {camp.city}, {camp.zipcode}</p>
                </div>}
              </div>
              <div>
                Star rating from Google: {camp.google_review ? camp.google_review : 'N/A'} <br />
                Star rating from Yelp: {camp.yelp_review ? camp.yelp_review : 'N/A'} <br />
                Star rating from Trust Pilot: {camp.trustpilot_review ? camp.trustpilot_review : 'N/A'} <br />
              </div>
              {(camp.review1 || camp.review2 || camp.review3) && <div className='sub-section'>
                <h3>Reviews</h3>
                <p>{camp.review1} {camp.review2} {camp.review3}</p>
              </div>}
              {camp.faq && <div className='sub-section'>
                <h3>FAQ</h3>
                <p>{camp.faq}</p>
              </div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
