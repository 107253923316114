import React from 'react';
import './HelpCenterPage.scss';

export default class HelpCenterPage extends React.Component {
    render() {
        return (
            <div className="helpcenter-page">
                <div className="container contactus-container">
                    <div className="row shadow-object main-row round-object">
                        <div className="col-lg-12 v-c h-c">
                            <h2>We are Coming Soon!</h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}