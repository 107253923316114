import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import { NavLink } from 'react-router-dom';
import logoActive from 'src/Assets/Icon/logo-active.png';
import logoInactive from 'src/Assets/Icon/logo-inactive.png';

export default function LogoHeader() {

  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = useCallback(() => {
    setIsScroll(window.scrollY > 0 ? true : false);
  }, [])

  const showHeader = () => {
    const pathName = window.location.pathname.split('/')[1];
    if(pathName === 'camp-list') return true;
    return false;
  }

  return (
    <div 
      className={`
        logo-header-component 
        ${!isScroll ? 'inActive' : ''}
        ${showHeader() ? '' : 'hidden'}
      `
    }>
      <div className='logo-header-container container'>
        <NavLink className="menu-item logo-active logo" exact to="/">
          <img src={isScroll ? logoActive : logoActive} alt="logo-active" />
        </NavLink>
      </div>
    </div>
  );
}
