import {
  GET_IGENIUS_LANDING_INFO,
  MASTER_CLASS_SIGNUP
} from '../Type';

export const getIGeniusLandingInfo = (data) => {
  return {
    type: GET_IGENIUS_LANDING_INFO,
    data
  }
}

export const masterClassSignUp = (data) => {
  return {
    type: MASTER_CLASS_SIGNUP,
    data
  }
}


