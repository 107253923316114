import React from 'react';
import './SignInPage.scss';
import logoActive from '../../Assets/Icon/logo-active.png';
import { NavLink, withRouter } from 'react-router-dom';
import bg from '../../Assets/Auth/signinbg.png';
import mobilebg from '../../Assets/Auth/signinbg-mobile.png';
import emailSVG from '../../Assets/Icon/email.svg';
import key from '../../Assets/Icon/key.svg';
import { InputBox } from '../../Components';
import { connect } from 'react-redux';
import { signIn, getPlan } from '../../Redux/Actions';
import { GetDashboardUrl, finalTrainingNum } from '../../Constant';
import Helper from '../../Utils/Helper';
import _ from 'lodash'

class SignInPage extends React.Component {
    state = {
        email: '',
        password: ''
    }

    doSignIn = () => {
        const { email, password } = this.state;
        if (email.length === 0 || password.length === 0) return;
        this.props.signIn(email, password)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sign_in_error_msg.length === 0 && this.props.sign_in_error_msg.length > 0) {
            // alert(this.props.sign_in_error_msg)
            Helper.showToast('error', 3000, this.props.sign_in_error_msg);
        }
        if (!prevProps.sign_in_success && this.props.sign_in_success) {
            const {user, token} = this.props
            if(user.role === 'tutor'){
                if(_.isEmpty(user.schedule)){
                    let signup_progress = 2;
                    if(user.training_status === finalTrainingNum){
                        signup_progress = 3;
                    }
                    this.props.history.push({
                      pathname: "/tutor-signup",
                      isUser: true,
                      user,
                      signup_progress
                    });
                } else {
                  window.location.href = GetDashboardUrl(user, token);     
                };
            } else {
                window.location.href = GetDashboardUrl(user, token);     
            }
            // this.props.getPlan()
            // this.props.history.push('/');

            // let win = window.open(GetDashboardUrl(this.props.user, this.props.token), '_blank');
            // if (win) win.focus();
        }
    }

    render() {
        const { email, password } = this.state;
        return (
            <div className="signin-page">
                <div className="container v-r">
                    <div className="logo-area">
                        <NavLink to="/"><img src={logoActive} alt="logo" /></NavLink>
                    </div>
                    <div className="main-content v-r v-c h-c">
                        <div className="sign-in-area">
                            <img src={bg} alt="bg" className="show-web"/>
                            <img src={mobilebg} alt="bg" className="show-mobile mobile-bg"/>
                            <div className="sign-in-form v-r v-c">
                                <h1>Sign in</h1>
                                <InputBox className="input-form" value={email} icon={emailSVG} placeholder="Email" onChange={val => this.setState({email: val})}/>
                                <InputBox className="input-form" type="password" show_forgot value={password} icon={key} placeholder="Password" onChange={val => this.setState({password: val})}/>
                                <button className={`btn-2 primary-btn ${this.props.sign_in_loading ? 'disabled' : ''}`} onClick={e => this.doSignIn()}>
                                    {this.props.sign_in_loading ? <div className="spinner" /> : 'SIGN IN'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        sign_in_success: state.Auth.sign_in_success,
        sign_in_loading: state.Auth.sign_in_loading,
        token: state.Auth.token,
        user: state.Auth.user,
        sign_in_error_msg: state.Auth.sign_in_error_msg
    }
}

export default connect(mapStateToProps, { signIn, getPlan })(withRouter(SignInPage));