import React from 'react';
import './ReviewStart.scss';
import startbg from '../../../Assets/Review/bg_start.png'

export default class ReviewStart extends React.Component {
    render() {
        return (
            <div className="reviewstart-component">
                <div className="container">
                    <h1>Our Parents and Students Can’t Stop Loving iCode Edu</h1>
                    <img src={startbg} alt="start_bg" />
                </div>
            </div>
        )
    };
};