import { GET_SHOOLS, GET_SHOOLS_SUCCESS, GET_SHOOLS_FAIL } from '../Type';

const INITIAL = {
    schools: [],
    get_schools_loading: false,
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case GET_SHOOLS: {				
            return {
                get_schools_loading: true,
                schools: []
            }
        }
        case GET_SHOOLS_SUCCESS: {
            return {
                ...state,
                get_schools_loading: false,
                schools: action.data,
            }
        }
        case GET_SHOOLS_FAIL: {
            return {
                ...state,
                get_schools_loading: false,
                schools: []
            }
        }
        default:
            return state;
    }
}