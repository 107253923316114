import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SummerCampDetailHeader, SummerCampDetail} from 'src/Components';
import { getSCStateCityType } from 'src/Redux/Actions';
import { showSpinner, hideSpinner } from 'src/Utils/Helper';

export default function StateCityTypeSCPage() {
  const dispatch = useDispatch();

  const { state } = useParams()
  const { city } = useParams()
  const { type } = useParams()

  const getLoadingRef = useRef(false);
  const {
    scStateCityTypeInfo, get_sc_state_city_type_success, get_sc_state_city_type_loading,
    scStateInfo
  } = useSelector(
    state => state.SummerCamp,
  );

  const [stateName, setStateName] = useState('')
  const [cityName, setCityName] = useState('')
  const [typeName, setTypeName] = useState('')
  const [camps, setCamps] = useState('')

  useEffect(() => {
    showSpinner()
    setStateName(state);
    setCityName(city);
    setTypeName(type);
    dispatch(getSCStateCityType({ state, city, type }));
  }, [dispatch, state, city, type]);

  useEffect(() => {
    if (getLoadingRef.current && !get_sc_state_city_type_loading) {
      hideSpinner();
      if (get_sc_state_city_type_success) {
        setCamps(scStateCityTypeInfo)
      }
    }
    getLoadingRef.current = get_sc_state_city_type_loading;
  }, [dispatch, scStateCityTypeInfo, get_sc_state_city_type_loading, get_sc_state_city_type_success]);

  const getSubTitle = () => {
    const subTitle = `
      ${typeName} Summer Camps In ${cityName}, ${stateName}
    `
    return subTitle
  }

  const getSummary = () => {
    const summary = `
      Welcome to our 2023 Guide For ${typeName} Summer Camps In ${cityName}, ${stateName} 
      And Near Me For Kids Kindergartens - Teens! Our guide provides 
      a comprehensive list of the best dance summer camps in Dallas 
      and the surrounding areas for children of all ages, 
      from kindergarteners to teenagers. We have researched and handpicked 
      the top-rated dance camps that offer quality instruction 
      and a fun and safe learning environment. 
      Our guide includes important details such as camp dates, 
      locations, and pricing to make it easier for parents 
      to choose the right summer camp for their children. 
      Whether your child is a beginner or an advanced dancer, 
      our guide has something for everyone. Get ready for a summer of 
      learning, growth, and fun with our 2023 Guide For ${typeName} Summer Camps 
      In ${cityName}, ${stateName} And Near Me For Kids Kindergartens - Teens!
    `
    return summary
  }

  const getTags = () => {
    const tags = [
      { name: stateName, link: `/summer-camps/state/${stateName}` },
      { name: cityName, link: `/summer-camps/state/${stateName}/city/${cityName}` },
      { name: typeName, link: `/summer-camps/state/${stateName}/city/${cityName}/type/${typeName}` },
    ]
    return tags
  }

  return (
    <div className="state-city-type-summer-camp-page">
      <div className="container">
        <a href="/summer-camps">Summer Camp Guide</a> | 
        <a href={"/summer-camps/state/" + stateName}>{stateName}</a> | 
        <a href={`/summer-camps/state/${stateName}/city/${cityName}`}>{cityName}</a> |
        <a href={`/summer-camps/state/${stateName}/city/${cityName}/type/${typeName}`}>{typeName}</a>
      </div>
      <SummerCampDetailHeader
        tags={getTags()}
        title={`2023 Guide For ${typeName} Summer Camps In ${cityName}, ${stateName}, And Near Me For Kids, Kindergartens - Teens`}
      />
      {camps && <SummerCampDetail
        camps={camps}
        subTitle={getSubTitle()}
        summary={getSummary()}
        cityName={cityName}
        scStateInfo={scStateInfo}
      />}
    </div>
  );
}