import React from 'react';
import './HomePage.scss';
import {
  HomeStart,
  HowItWorks,
  Instructors,
  GroupCourses,
  ReviewsArea,
  TechnologyArea,
  MonthlyPlanArea,
} from '../../Components';
export default class HomePage extends React.Component {
  render() {
    return (
      <div className="home-page">
        <HomeStart />
        <HowItWorks />
        <Instructors />
        <GroupCourses />
        <ReviewsArea />
        <TechnologyArea />
        <MonthlyPlanArea />
      </div>
    )
  };
};