import React from 'react';
import './StudentSignupStart.scss';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import phoneIcon from '../../../Assets/StudentSignup/phone.png';
import home_right from '../../../Assets/StudentSignup/home_right.png';
import logoInactive from '../../../Assets/Icon/logo-inactive.png';
import arrow from '../../../Assets/StudentSignup/arrow.png';
import US_flag from '../../../Assets/StudentSignup/US-flag.png';

import avatar from '../../../Assets/Icon/avatar.svg';
import emailSVG from '../../../Assets/Icon/email.svg';
import check from '../../../Assets/Icon/check.svg';
import { ReactSVG } from 'react-svg';

import { InputBox } from '../../../Components';
import { userSubmit } from '../../../Redux/Actions';
import Helper from '../../../Utils/Helper';
import { EmailValidation } from '../../../Utils/Validation';

const Ages = ["7-9", "10-11", "12-13", "14-16", "17-18"]

class StudentSignupStart extends React.Component {
    
    state = {
        age: '7-9',
        parentsName: '',
        email: '',
        country: 'US',
        phone: '',
        loadingEnd: false
    }

    componentDidUpdate(prevProps) {
        const { get_landing_loading, LandingData } = this.props;
        if(prevProps.get_landing_loading && !get_landing_loading) {
            this.setState({
                country: LandingData.country_code, 
                loadingEnd: true
            });
        };
    };

    onChange = (field, val) => {
        this.setState({[field]: val});
	};

    SelectedDiv = () => <div className="selected"> <ReactSVG src={check} className="svg-icon" /> </div>
    selectItem = async (field, val) => {
        await this.setState({[field]: val});
    };

    submit = () => {
        const { age, parentsName, email, country, phone } = this.state;
        const submitParam = {
            name: parentsName,
            student_age: age,
            email,
            country_code: country,
            phone,
            // have_computer: havePC === 'Yes'? 1 : 0
        };
        if(age && parentsName && EmailValidation(email) && phone) {
            Helper.showSpinner();
            this.props.userSubmit(submitParam);
        } else {
            Helper.showToast('error', 3000, "Please input valid information!");
        };
    };

    render() {
        const { LandingData } = this.props;
        const { parentsName, age, email, phone, country, loadingEnd } = this.state;
        return (
            <div className="studentSignupStart-component" id="request">
                <div className="container">
                    <div className='header'>
                        <NavLink className="menu-item logo-active logo" exact to="/"><img src={logoInactive} alt="logo-active" /></NavLink>
                        <a href='tel:+1 817 904 0134' className='call-btn v-c'>
                            <img src={phoneIcon} className="phone mr-2" alt="phone" />
                            <div className='phone-num v-c'>
                                <div className='show-web mr-1'>CALL NOW </div>
                                <div>817 904 0134</div>
                            </div>
                        </a>
                    </div>
                    <div className="row main-content">
                        <div className="col-lg-6">
                            <h1>
                                {LandingData.title} 
                                <strong><img src={LandingData.title_flag} className="kenya-flag ml-3" alt="kenya" /></strong> 
                            </h1>
                            <div className='mt-4'>
                                <p>
                                    {/* {LandingData.subtitle} */}
                                    Instructors from top U.S Universities
                                    <strong>
                                        <img src={US_flag} className="us-flag ml-2 mr-2" alt="us" />
                                    </strong>
                                </p>                            
                            </div>
                            <div className='show-web'>
                                <img src={arrow} className="arrow" alt="arrow" />
                            </div>
                        </div>
                        <div className="col-lg-4" style={{zIndex: 3}}>
                            <div className='form-content shadow-object'>
                                <h2 className='mb-3'>Request Free Trial Lesson</h2>
                                <div className='select-age-title'>Select age</div>
                                <div className="age-section">
                                    {Ages.map((item, index) => 
                                        <div 
                                            className={`select-item ${item === age ? 'active' : ''}`} 
                                            key={index} 
                                            onClick={e => this.onChange('age', item)}>
                                            {this.SelectedDiv()}
                                        {item}
                                    </div>)}
                                </div>
                                <InputBox 
                                    className="input-form" 
                                    value={parentsName} 
                                    icon={avatar} 
                                    placeholder="Parents full name" 
                                    onChange={e => this.onChange('parentsName', e)}
                                />                               
                                <InputBox 
                                    className="input-form" 
                                    value={email} 
                                    icon={emailSVG} 
                                    placeholder="Email" 
                                    onChange={e => this.onChange('email', e)}
                                />                                
                                {loadingEnd && <InputBox 
                                    className="input-form phone-input" 
                                    icon={emailSVG} 
                                    phone_input 
                                    type="number" 
                                    placeholder="Phone number" 
                                    value={phone} 
                                    country={country}
                                    onSelect={e => this.onChange('country', e)} 
                                    onChange={e => this.onChange('phone', e)}
                                />}
                                <div className='sub-btn v-c h-c' onClick={this.submit}>
                                    BOOK FREE CLASS NOW
                                </div>
                            </div>
                        </div>                        
                        <div className="col-lg-2 show-web v-c h-c right-img-content">
                            <img src={home_right} className="right-img" alt="right_img" />
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        submit_loading: state.Landing.submit_loading,
        submit_success: state.Landing.submit_success,
        submit_message: state.Landing.submit_message,

        get_landing_loading: state.Landing.get_landing_loading,
    }
}

export default connect(mapStateToProps, {
    userSubmit
})(withRouter(StudentSignupStart));
