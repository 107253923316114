import { GET_LANDING_DATA, USER_SUBMIT } from '../Type';

export const getLandingData = () => {
  return {
    type: GET_LANDING_DATA,
    data: {}
  }
}

export const userSubmit = (data) => {
  return {
    type: USER_SUBMIT,
    data: data
  }
}