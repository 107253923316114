import { GET_FAQ, GET_FAQ_SUCCESS, GET_FAQ_FAIL } from '../Type';

const INITIAL = {
    faq_loading: false,
    faps: null
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case GET_FAQ: {
            return {
                ...state,
                faq_loading: true,
                faps: null
            }
        }
        case GET_FAQ_SUCCESS: {
            return {
                ...state,
                faq_loading: false,
                faps: action.data
            }
        }
        case GET_FAQ_FAIL: {
            return {
                ...state,
                faq_loading: false,
                faps: null
            }
        }
        default:
            return state;
    }
}