import React from 'react';
import './ReviewsPage.scss';
import { ReviewStart, MonthlyPlanArea, UserReviews, StoryHeader /*, UserStories*/ } from '../../Components';

export default class ReviewsPage extends React.Component {
    render() {
        return (
            <div className="reviews-page">
                <ReviewStart />
                <UserReviews />
                <StoryHeader />
                {/*<UserStories />*/}
                <MonthlyPlanArea />
            </div>
        )
    };
};