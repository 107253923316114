import React from 'react';
import './AboutAuthor.scss';

export default class AboutAuthor extends React.Component {

    render() {
        return (
            <div className="aboutauthor-component">
                
            </div>
        )
    };
};
