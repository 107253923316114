import React from 'react';
import './style.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import CheckIcon from 'src/Assets/IGenius/check-icon.svg';

export default function IGeniusLandingStart1() {

  return (
    <div className="iGenius-landing-start1-component">
      <div className='iGenius-landing-header-container container'>
        <h1 className='header-title'>
          Learn How <strong>Parents of Kids Aged  7 - 18</strong> Are Using iCode iGenius Summer Coding Camps <strong>To 10X Their Kids</strong> Hidden Coding Genius For:
        </h1>
        <div className='description-section'>
          <div className='v-c description-item'>
            <img src={CheckIcon} alt='check-icon' />
            <div className='sub-description'>Entrepreneurial Opportunities</div>
          </div>
          <div className='v-c description-item'>
            <img src={CheckIcon} alt='check-icon' />
            <div className='sub-description'>Academic Growth</div>
          </div>
          <div className='v-c description-item'>
            <img src={CheckIcon} alt='check-icon' />
            <div className='sub-description'>Future Job</div>
          </div>
          <AnchorLink
            className='btn-2 start-btn'
            href={"#section-enroll"}
          >
            Enroll Your Child For Our Free 1-on-1 Coding Challenge
          </AnchorLink>
        </div>
      </div>
    </div>
  );
}
