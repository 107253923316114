import React from 'react';
import './UserStories.scss';
import { StoryPreview } from '../../';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllPosts } from '../../../Redux/Actions';

class UserStories extends React.Component {
    state = {
        display_count: 6,
        display_list: []
    }

    static getDerivedStateFromProps(props, state) {
        return {
            display_list: props.post_list
        }
    }

    componentDidMount() {
        if (this.props.post_list.length === 0) {
            this.props.getAllPosts();
        }
        this.setState({
            display_count: window.outerWidth >= 992 ? 6 : 3
        })
    }

    render() {
        const { display_count, display_list } = this.state;
        return (
            <div className="userstories-component">
                <div className="container v-r">
                    {display_list.map((item, index) => (index % 3 === 0 && index < display_count) && <div className="row story-row" key={index}>
                        <div className="col-lg-4 sub-col">
                            <StoryPreview info={display_list[index]} />
                        </div>
                        {display_list[index+1] && <div className="col-lg-4 sub-col">
                             <StoryPreview info={display_list[index+1]} /> 
                        </div>}
                        {display_list[index+2] && <div className="col-lg-4 sub-col">
                            <StoryPreview info={display_list[index+2]} /> 
                        </div>}
                    </div>)}
                    {display_count < display_list.length && <div className="btn-link load-more-btn" onClick={e => this.setState({display_count : display_count + 3})}>LOAD MORE</div>}
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        post_list: state.Post.post_list
    }
}

export default connect(mapStateToProps, { getAllPosts })(withRouter(UserStories));