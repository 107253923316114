import React from 'react';
import './style.scss';

export default function CodingItem({
  info
}) {

  const {
    headerIcon,
    // footerIcon,
    listInfo
  } = info

  return (
    <div className='coding-item-component'>
      <div className='img-content center' >
        <img src={headerIcon} alt='header-icon' />
      </div>
      <div className='description-content'>
        {listInfo.map((item, index) => (
          <div
            key={index}
            className={`description-item center bg-${item.categoryId}`} >
            {item.name}
          </div>
        ))}
      </div>
      {/* <img src={footerIcon} className='footer-icon' alt='footer-icon' /> */}
    </div>
  );
}
