import { Api } from './Api';

export function* getSCState(data) {
  const jsonData = yield Api.get(`/summercamp/state?name=${data.state}`);
  return jsonData;
}

export function* getSCStateType(data) {
  const jsonData = yield Api.get(`/summercamp/statedetail?name=${data.state}&types=${data.type}`);
  return jsonData;
}

export function* getSCStateCity(data) {
  const jsonData = yield Api.get(`/summercamp/statedetail?name=${data.state}&city=${data.city}`);
  return jsonData;
}

export function* getSCStateCategory(data) {
  const jsonData = yield Api.get(`/summercamp/statedetail?name=${data.state}&categories=${data.category}`);
  return jsonData;
}

export function* getSCStateCityType(data) {
  const jsonData = yield Api.get(`/summercamp/statedetail?name=${data.state}&city=${data.city}&types=${data.type}`);
  return jsonData;
}

export function* getSCStateCityCategory(data) {
  const jsonData = yield Api.get(`/summercamp/statedetail?name=${data.state}&city=${data.city}&categories=${data.category}`);
  return jsonData;
}

export function* campSignUp(data){
  const jsonData = yield Api.post("/camp_signup", data);
  return jsonData;
}

export function* campHubspot(data){
  const jsonData = yield Api.post("/hubspot", data);
  return jsonData;
}
