import React from 'react';
import './Course.scss';
import mark from '../../Assets/Course/mark.png';
import { CourseCategories } from '../../Constant';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleCourseSidebar } from '../../Redux/Actions';
import { Check } from '@material-ui/icons';

class Course extends React.Component {

  state = {
    isSelect: false
  }

  componentDidMount() {
    const { isSelect } = this.props;
    this.setState({ isSelect: isSelect });
  }

  toggleCheck = () => {
    this.setState({ isSelect: !this.state.isSelect });
    const deploy = {
      id: this.props.info.id,
      checked: !this.state.isSelect
    }
    this.props.updateCourse(deploy);
  }

  showPreview = (e) => {
    this.props.toggleCourseSidebar(true, this.props.info.id, this.props.minAge, this.props.maxAge);
  }

  render() {
    const { isSelect } = this.state;
    const { className, info, isSignup, minAge, maxAge } = this.props;
    let categoryIndex = CourseCategories.findIndex(temp => temp.id === info.category_id);
    return (
      <div className={`course-component v-r ${className || ''}`} >
        {isSignup && <div className="select-picker" onClick={() => this.toggleCheck()}>{isSelect && <Check className="check-icon" />}</div>}
        {!isSignup && <img src={mark} alt="mark" className="right-mark" />}
        <div className="sub-block thumb-container round-object">
          <img src={info.images[0]} className="course-thumb round-object" alt="thumb" />
          {/* <div className="age-container v-c h-c">Grade&nbsp;{info.age_min-5}&nbsp;{info.age_max !== null ? `- ${info.age_max-5}` : '+'}&nbsp;YEARS</div> */}
          {minAge && maxAge && (
            <div className="age-container v-c h-c">
              {`AGE ${minAge} ${minAge < 13 ? `- ${maxAge}` : '+'} YEARS`}
            </div>
          )}

        </div>
        <div className="sub-block info-container round-object">
          <div className="course-header v-c">
            <img src={CourseCategories[categoryIndex].logo} alt="category" className="category" />
            <div className="header-info v-r">
              <h2 className="sub-header-info">{info.name}</h2>
            </div>
          </div>
          <p className="description">{info.short_description}</p>
          <div
            className="cursor-pointer btn-link show-mobile-flex"
            onClick={e => this.showPreview(e)}>
            DETAILS
          </div>
        </div>
      </div>
    )
  };
};

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps, { toggleCourseSidebar })(withRouter(Course));