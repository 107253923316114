import {
    GET_FAQ, GET_FAQ_SUCCESS, GET_FAQ_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    getFaq as getFaqApi
} from '../../Api';

function* getFaq(){
    try {
        const result = yield getFaqApi()
        if (result.length > 0) {
            yield put({ type: GET_FAQ_SUCCESS, data: result })
        } else {
            yield put({ type: GET_FAQ_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_FAQ_FAIL, data: [] })
    }
}

export function* watchgetFaq(){
    yield takeLatest(GET_FAQ, getFaq)
}
