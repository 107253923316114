import React from 'react';
import BonusesItem from './BonusesItem';
import './style.scss';

const info = [
  "$250 Coding scholarship certificate - Ignite your child's tech spark with 1:1 kid-friendly coding lessons",
  "Up to $600 tutoring voucher - 1: 1 private tutoring in any academic subject for K - 12 students with our partners at FrogTutoring",
  "eBook - Raising Future Founders - A Parent Guide to Coding and Technology Education For Kids",
  "eBook - Jobs Being Eliminated & Created by Technology - A Parent Guide Checklist:",
  "Masterclass on how you prep your kid for future entrepreneurial and job opportunities.",
  "A 50 % discount on SAT & ACT Prep with GradeGetter; This can be claimed now for lifetime access(value: $150)",
]
export default function Bonuses() {

  return (
    <div className='bonuses-component'>
      <div className='container'>
        <div className='bonuses-container'>
          <h2 className='mx-auto'>5 bonuses worth over <strong>$970</strong> if your kid participates in this free challenge:</h2>
          <div className='learn-content'>
            {info.map((item, index) => (
              <BonusesItem
                key={index}
                title={`FREE Bonus ${index + 1}`}
                description={item}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
