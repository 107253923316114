import React from 'react';
import './Feedback.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Avatars } from '../../Constant';
import verified from '../../Assets/Review/verified.png';
import StarRatings from 'react-star-ratings';
import { StarSVGViewBox, StarSVGIconPath } from '../../Constant';
import { toggleFeedbackModal } from '../../Redux/Actions';
import ReactFlagsSelect from 'react-flags-select';

function innerFunc(item) {
  return { __html: item.review_content }
}

class Feedback extends React.Component {

  readOnModal = () => {
    this.props.toggleFeedbackModal(true, { ...this.props.info, index: this.props.index || 0 });
  }

  render() {
    const { className, info, index } = this.props;
    const showAll = this.props.showAll || false;

    // let country_code = info.Student.country_code;
    // country_code = country_code === 'UK' ? 'GB' : country_code;
    return (
      <div className={`feedback-component v-r round-object ${className || ''}`}>
        <div className="feedback-header">
          <img src={Avatars[index % Avatars.length]} alt="avatar" className="avatar" />
          <div className="reviewer-info v-r">
            <h3 className="v-c">{info.student.name}<img src={verified} alt="verify-mark" /></h3>
            <div className="address v-c">
              {info.student.country_code && <ReactFlagsSelect
                className="custom-flag"
                selected={info.student.country_code}
                showSelectedLabel={true}
                disabled={true} />}
            </div>
            <div className="star-area">
              <StarRatings
                rating={info.rating}
                starDimension="15px"
                starSpacing="2.14px"
                starRatedColor="#FFC634"
                starEmptyColor="#D3EDFC"
                svgIconViewBox={StarSVGViewBox}
                svgIconPath={StarSVGIconPath}
              />
            </div>
          </div>
        </div>
        {/* <h2>{info.name}</h2> */}
        <p className="feedback" dangerouslySetInnerHTML={innerFunc(info)} />
        {!showAll && <p className="continue-btn" onClick={e => this.readOnModal()}>Continue Reading</p>}
      </div>
    )
  };
};

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps, { toggleFeedbackModal })(withRouter(Feedback));
