export const faqs = [
  {
    title: "How much does the camp cost?",
    content: [
      "The camp fee is $399, although early bird discounts of $99 are available to those who complete enrollment by April 15th.",
    ],
  },
  {
    title: "Where is the camp taking place?",
    content: [
      "Our camps are conducted online, with students working in small groups alongside peers from around the country and the world.",
    ],
  },
  {
    title: "When is the camp happening?",
    content: [
      "The camp begins on June 19, 2023, but you don’t have to start on this date since we’re running several camps with varying start dates, and each camp runs for one week, with three hours of activities per day. Reg",
    ],
  },
  {
    title: "How do I enroll my kids in the camp?",
    content: [
      "To enroll your child, complete the enrollment form and attend the parent masterclass. Following this, you will be granted access to the various camp details, which will allow you to complete the enrollment process.",
    ],
  },
  {
    title: "What are the camp dates?",
    content: [
      "Camp runs from June 19th through August 11th, with each camp lasting one week. You can view the complete camp schedule after completing the sign-up process.",
    ],
  },
  {
    title: "Who are the camp instructors?",
    content: [
      "Our camp instructors are computer science majors from esteemed universities including MIT, Berkeley, Princeton, Columbia, Stanford, Harvard, and more. These instructors are highly trained at both the undergraduate and graduate levels.",
    ],
  },
  {
    title: "How do I claim my camp scholarship?",
    content: [
      "Following enrollment, you will be prompted to enter your scholarship code to claim your discount.",
    ],
  },
  {
    title: "How many students are in each camp?",
    content: [
      "Each camp is limited to a maximum of 4-6 students to ensure optimal instructor engagement.",
    ],
  },
  {
    title: "Can my kid be in the same camp as their friends?",
    content: [
      "Absolutely! We encourage you to enroll your child and their friends in the same camp during registration.",
    ],
  },
  {
    title: "Can my kid attend multiple camps?",
    content: [
      "YddddYes! We offer a discounted rate for multiple camp sign-ups.",
    ],
  },
  {
    title: "Can my kid continue coding lessons after the camp is over?",
    content: [
      "Yes! Our expert instructors are available to provide one-on-one and small group coding lessons, with a personalized learning path tailored to each student.",
    ],
  },
  {
    title: "Do I have to attend the parent masterclass to register my child for the camp?",
    content: [
      "Yes, we highly recommend attending the parent masterclass to ensure that you register your child for the appropriate camp class.",
    ],
  },
  {
    title: "What age range is the camp appropriate for?",
    content: [
      "Our camp is designed for students between the ages of 7 and 18.",
    ],
  },
  {
    title: "What level of coding experience is required to attend the camp?",
    content: [
      "Our camp is designed for students with all levels of coding experience, from beginners to advanced learners.",
    ],
  },
  {
    title: "What types of coding languages are taught at the camp?",
    content: [
      "Our camp offers instruction in a variety of coding languages, including Python, Java, HTML/CSS, JavaScript, Scratch, and more.",
    ],
  },
  {
    title: "What types of activities can my child expect to participate in at the camp?",
    content: [
      "Our camp offers a range of activities, including coding challenges, team projects, coding games, and other fun coding-related activities.",
    ],
  },
  {
    title: "How are students grouped together in the camp?",
    content: [
      "Students are grouped together based on their age, skill level, and coding experience to ensure the best possible learning experience for everyone.",
    ],
  },
  {
    title: "What types of support are available to students during the camp?",
    content: [
      "Our expert instructors are available to provide support and answer questions throughout the camp. Additionally, students can access our online resources and receive feedback on their coding projects.",
    ],
  },
  {
    title: "What types of devices are required to participate in the camp?",
    content: [
      "Students will need access to a computer or tablet with a stable internet connection to participate in the camp. Specific software requirements will be provided prior to the start of the camp.",
    ],
  },
  {
    title: "What types of projects will my child work on during the camp?",
    content: [
      "Students will work on a variety of projects during the camp, including developing apps, creating websites, designing games, and more.",
    ],
  },
  {
    title: "Will my child receive a certificate of completion at the end of the camp?",
    content: [
      "Yes, all students who successfully complete the camp will receive a certificate of completion.",
    ],
  },
  {
    title: "What is the refund policy for the camp?",
    content: [
      "We understand that plans can change, and we offer a full refund for cancellations made at least three weeks before the start of the camp.",
    ],
  },
  {
    title: "Can I cancel my child's enrollment in the camp after it has started?",
    content: [
      "Unfortunately, we cannot offer refunds for cancellations made after the start of the camp. However, we will work with you to find a solution that works best for your family.",
    ],
  },
  {
    title: "What happens if my child misses a day or session of the camp?",
    content: [
      "We understand that unexpected events can arise, and we will do our best to help your child catch up on missed work. However, we cannot offer refunds or credits for missed days or sessions.",
    ],
  },
  {
    title: "What happens if the camp is cancelled due to unforeseen circumstances?",
    content: [
      "In the unlikely event that we need to cancel a camp session, we will provide a full refund to all enrolled students. We will also work to provide alternative options for your child to continue their coding education.",
    ],
  },
];