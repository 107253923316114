import {
  FEEDBACK_MODAL_TOGGLE,
  TUTOR_MODAL_TOGGLE,
  VIDEO_MODAL_TOGGLE,
  SC_NOTIFICATION_MODAL,
} from '../Type';

export const toggleFeedbackModal = (isOpen, info = {}) => {
  return {
    type: FEEDBACK_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}

export const toggleTutorModal = (isOpen, info = null, rect = null) => {
  return {
    type: TUTOR_MODAL_TOGGLE,
    data: { isOpen, info, rect }
  }
}

export const toggleVideoModal = (isOpen, info = null) => {
  return {
    type: VIDEO_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}

export const toggleScNotificationModal = (isOpen) => {
  return {
    type: SC_NOTIFICATION_MODAL,
    data: { isOpen }
  }
}