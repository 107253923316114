import React, { useEffect } from 'react';
import './style.scss';
import { useDispatch } from 'react-redux';
import {
  IGeniusHeader,
  IGeniusLandingStart,
  Bonuses,
  Transform,
  RegistrationForm,
  ChiefLearning,
  IGeniusReviews,
  IGeniusDetails,
  IGeniusInstructors,
  EarlyBonuses,
  IGeniusFooter,
  // IGeniusFaqs,
  // AdviceParents,
  // Gifts,
} from 'src/Components';
import {
  setReferralCode,
} from 'src/Redux/Actions';

export default function IGeniusLandingPage() {
  const dispatch = useDispatch();

  //Get referral code
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const _referralCode = params.get('referrer_code');
    if (_referralCode) {
      dispatch(setReferralCode(_referralCode));
    }
  }, [dispatch]);

  return (
    <div className="iGenius-landing-page">
      <IGeniusHeader />
      <IGeniusLandingStart />
      <Bonuses />
      <RegistrationForm />
      <IGeniusReviews />
      <Transform />
      <IGeniusDetails />
      <ChiefLearning />
      <IGeniusInstructors />
      <EarlyBonuses />
      <IGeniusFooter />

      {/* <IGeniusFaqs /> */}
      {/* <AdviceParents /> */}
      {/* <Gifts /> */}
    </div>
  );
}