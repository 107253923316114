import React from 'react';
import './WhyDid.scss';
import { Cached, Work, LayersClear, PhonelinkLock, Rowing, CastForEducation, EmojiPeople, Code, School, Gavel } from '@material-ui/icons';

const LearnData = [
    {icon: Cached, description: "What is the 4th Industrial Revolution and why should you care?"},
    {icon: Work, description: "Becoming a champion for  developing future skills in your childs live today"},
    {icon: LayersClear, description: "What are the technologies driving change and how can you leverage them without being a techie?"},
    {icon: PhonelinkLock, description: "Safety of your kids Online"},
    {icon: Rowing, description: "What extracurricular activities should you be involving your kids in that rae fun , yet prepares them for the future?"},
    {icon: CastForEducation, description: "Modern teaching and learning Aids"},
    {icon: EmojiPeople, description: "How to transition to parenting in a digital age"},
    {icon: Code, description: "Is coding the language of the future and what lessons and STEM programs should your kid be involved in?"},
    {icon: School, description: "How can you be involved in Public policy in education at your local schools and community?"},
    {icon: Gavel, description: "The role of governments, business and civil society in creating an enabling environment for its citizens"},
    // {icon: PlayArrow, description: "And more……………"},
]

export default class WhyDid extends React.Component {

    render() {
        return (
            <div className="whyDid-component">
                <div className="container">
                    <div className='main-content'>
                        <div className='why-write-content'>
                            <h3 className='title'>Why did I write this book?</h3>
                            <p>
                                Watching my kids interact with technology pushed me to take some time to think about my ability to guide them in this age so I decided to take a closer look at the social construct within which they will live their lives as adults.  They are already studying remotely using zoom and e-learning platforms, interacting with their friends on social media platforms like Facebook, Instagram etc ,  PlayStation consoles with friends they have never met is now their new playground and they even skipped a day at school because of a trending video on TiTok titled ‘bring your guns to school”. As adults they  may meet their spouse on tinder, order all their groceries and dinner using delivery apps , go to work in their bedroom with their PJs on, bank in a Blockchain ecosystem driven by cryptocurrency, have a driverless car pickup their kids from school, have their home printed using 3D technology, own property in the metaverse,  have their Doctor edit genes and cure diseases within the family line, share their space with holograms or even robots helping out with house chores, have their refrigerator decide what the grocery list should look like and use voice recognition to access all government services. What about their workplace and jobs??  If you notice everything about the scenario above lives or is created within a computer coding ecosystem. A key question that still baffles me is Why is every kid today not taking Coding lessons because clearly it is the language of the future regardless of career choice!! 
                            </p>
                            <p className='mt-3'> The 4th Industrial Revolution is here and as a parent, I am very worried about my kids' preparedness to live and work in the world of tomorrow. My concerns are real as I look at the incredible rate of change both in the workplace and society at large, all within my own lifetime.</p>
                        </div>

                        <div className='more-write-content'>
                            <p> This industrial revolution is essentially a human revolution fuelled by human ingenuity with impact on humans. While there are negative impacts that can be connected to the use of technology, there are many positives that parents can capitalize on to ensure the 4th Industrial Revolution is a blessing, not a curse. With the constant increase in the rate of internet penetration world-wide and a mobile device in the hands of billions around the world connected to each other by the internet, the power to decide the future lies in the hands of ordinary people: the awakening needs to happen now! Hopefully some kid’s destiny somewhere in the world will be changed by this revolution because their parents read this book.</p>
                        </div>

                        <div className='learn-content mt-5'>
                            <h3 className='text-center'>WHAT YOU'LL LEARN</h3>
                            <div className='row' >
                                {LearnData.map((item, index)=> (
                                    <div className='col-lg-4 col-md-6 col-sm-12 mb-3' key={index}>
                                        <div className='logo-content v-c h-c  mb-2'>
                                            <item.icon className="item-icon"/>
                                        </div>
                                        <p className='ml-2 description'>{item.description}</p>
                                    </div>                            
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};
