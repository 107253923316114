import React from 'react';
import './style.scss';

export default function EnrollItem({
  className = '',
  title,
  price
}) {

  return (
    <div className={`enroll-item-component ${className}`}>
      <div className='title'>{title}</div>
      <div className='price'>{price}</div>
    </div>
  );
}
