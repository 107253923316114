import React from 'react';
import { ScInstructors } from 'src/Components/SummerCamp';
import './style.scss';

export default function IGeniusInstructors() {

  return (
    <div className='iGenius-instructors-component'>
      <div className='container'>
        <div className='iGenius-instructors-container mx-auto'>
          <h2>Some of our instructor from top US Universities</h2>
          <ScInstructors />
        </div>
      </div>
    </div>
  );
}
