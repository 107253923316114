import React from 'react';
import './style.scss';

export default function PriceItem({
  className = '',
  title,
  description,
  subDes1,
  subDes2,
  originPrice,
  realPrice
}) {

  return (
    <div className={`price-item-component ${className}`}>
      <div className='title-section'>
        <div className='title'>{title}</div>
        <div className='description'>{description}</div>
        <div className='sub-desc'>{subDes1}</div>
        <div className='sub-desc'>{subDes2}</div>
      </div>
      <div className='price-section'>
        {originPrice && <div className='origin-price'>{originPrice}</div>}
        <div className='real-price ml-4'>{realPrice}</div>
      </div>
    </div>
  );
}
