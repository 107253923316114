import React from 'react';
import './Join.scss';

export default class Join extends React.Component {

    state = {
       email: ''
    }

    submit = () => {
        window.open('mailto:info@parentingthefuturebook.com')
    }

    render() {
        const {email} = this.state
        return (
            <div className="join-component">
                <div className="container">
                    <div className='main-content'>
                        <h3>JOIN OUR NEWSLETTER & GET FREE EBOOK PREVIEW</h3>
                        <p>eBook preview is sent to email address submitted here</p>
                        <div className='row submit-content'>
                            <div className="col-lg-8">
                                <input
                                    placeholder='Enter your valid e-mail'
                                    onChange={e => this.setState({email: e.target.value})} 
                                    value={email}
                                />
                            </div>
                            <div className="col-lg-4" onClick={()=>this.submit()}>
                                <div className='go-btn v-c h-c'>GO!</div>
                            </div>
                        </div>
                        <div className='privacy'>© We respect your privacy</div>
                    </div>
                </div>
            </div>
        )
    };
};
