import React from 'react';
import { getUpperCaseText } from 'src/Utils/Format';
import './style.scss';

export default function StateCityCategoryLink({
  cityName,
  stateName,
  scStateInfo,
}) {

  return (
    <div className='state-city-type-category-link-component'>
      <h3>{cityName} Summer Camps Guide by Category</h3>
      <ul className="row">
        {scStateInfo.categories && scStateInfo.categories.map((category, index) => (
          <li
            key={index}
            className="col-lg-6">
            <a href={`/summer-camps/state/${stateName}/city/${cityName}/category/${category}`}>
              {`${getUpperCaseText(category)} Summer Camps In ${cityName}, ${stateName}`}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
