import {
  GET_IGENIUS_LANDING_INFO, GET_IGENIUS_LANDING_INFO_SUCCESS, GET_IGENIUS_LANDING_INFO_FAIL,
  MASTER_CLASS_SIGNUP, MASTER_CLASS_SIGNUP_SUCCESS, MASTER_CLASS_SIGNUP_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
  getIGeniusLandingInfo as getIGeniusLandingInfoApi,
  masterClassSignUp as masterClassSignUpApi,
} from '../../Api';

/**
 * GET iGenius Landing Info
 */
function* getIGeniusLandingInfo() {
  try {
    const result = yield getIGeniusLandingInfoApi();
    if (result && result.data) {
      yield put({ type: GET_IGENIUS_LANDING_INFO_SUCCESS, data: result.data })
    } else {
      yield put({ type: GET_IGENIUS_LANDING_INFO_FAIL, data: result })
    }
  } catch (err) {
    yield put({ type: GET_IGENIUS_LANDING_INFO_FAIL, data: { msg: 'NET ERROR' } })
  }
}

export function* watchGetIGeniusLandingInfo() {
  yield takeLatest(GET_IGENIUS_LANDING_INFO, getIGeniusLandingInfo)
}

/**
 * Camp SignUp
 */
function* masterClassSignUp(payload) {
  try {
    const result = yield masterClassSignUpApi(payload.data);
    if (result) {
      yield put({ type: MASTER_CLASS_SIGNUP_SUCCESS, data: result })
    } else {
      let message = result.message;
      let errors = result.errors || {};
      for (let item of Object.keys(errors)) {
        let error_part = errors[item];
        for (let msg of error_part) {
          message += ' ' + msg;
        }
      }
      yield put({ type: MASTER_CLASS_SIGNUP_FAIL, data: { msg: message } })
    }
  } catch (err) {
    yield put({ type: MASTER_CLASS_SIGNUP_FAIL, data: { msg: "NET ERROR" } });
  }
}
export function* watchMasterClassSignUp() {
  yield takeLatest(MASTER_CLASS_SIGNUP, masterClassSignUp)
}