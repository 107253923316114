import React from 'react';
import './FAQsPage.scss';
import { Avatars } from '../../Constant';
import { Accordion } from '../../Components';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    getFaq
} from "../../Redux/Actions";
//import Helper from '../../Utils/Helper';
import _ from 'lodash'

function innerFunc(item) {
    return {__html: item}
};

class FAQsPage extends React.Component {

    componentDidMount(){
        this.props.getFaq();
    };

    componentDidUpdate(prevProps) {
        // if (!prevProps.faq_loading && this.props.faq_loading) {
        //     Helper.showSpinner();
        // };
        // if (prevProps.faq_loading && !this.props.faq_loading) {
        //     Helper.hideSpinner();
        // };
    };

    render() {
        const { faps, faq_loading } = this.props;
        return (
            <div className="faqs-page">
                <div className="container v-r v-c">
                    <h1>FAQ's</h1>
                    <div className="v-c avatar-row">
                        <img src={Avatars[0]} alt="avatar" />
                        <img src={Avatars[1]} alt="avatar" />
                        <img src={Avatars[2]} className="last-img" alt="avatar" />
                        <img src={Avatars[3]} className="show-web" alt="avatar" />
                        <img src={Avatars[4]} className="show-web" alt="avatar" />
                    </div>
                    {faq_loading && <div className="spinner" /> }
                    {!_.isEmpty(faps) && faps.map((parent, parentIndex) => <Accordion className="accordion parent" title={parent.title} key={parentIndex}>
                        {parent.children.map((child, childIndex) => <Accordion className="accordion second" title={child.title} key={childIndex}>
                            <p dangerouslySetInnerHTML={innerFunc(child.content)} />
                        </Accordion>)}
                    </Accordion>)}
                </div>
            </div>
        )
    };
};

const mapStateToProps = (state) => ({
    faps: state.Faq.faps,
    faq_loading: state.Faq.faq_loading,
});
  
export default connect(mapStateToProps, {
    getFaq
})(withRouter(FAQsPage));