import React from 'react';
import './ParentingthefuturePage.scss';
import { ParentingHeader, ParentingStart, WhyDid, /*Brand,*/ AboutAuthor, AboutRoland, Benefits, PricingTables, AboutIcode, Join} from '../../Components';

export default class ParentingthefuturePage extends React.Component {
  render() {
    return (
      <div className="home-page">
        <ParentingHeader />
        <ParentingStart />
        <WhyDid />
        {/* <Brand/> */}
        <AboutAuthor/>
        <AboutRoland />
        <Benefits />
        <PricingTables />
        <AboutIcode />
        <Join/>
      </div>
    )
  };
};