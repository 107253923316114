import React from 'react';
import './style.scss';
import PriceItem from './PriceItem';

export default function PriceContent({
  className,
  campLength
}) {

  const getOriginPrice = () => {
    return 399 * campLength
  }

  const getOff1 = () => {
    return 100 * campLength
  }

  const getOff2 = () => {
    return ((getOriginPrice() - getOff1()) * (campLength === 1 ? 0 : campLength === 2 ? 0.05 : 0.1)).toFixed(1)
  }

  const getOff3 = () => {
    return 50 * campLength
  }

  const getPrice = () => {
    return `${(getOriginPrice() - getOff1() - getOff2()).toFixed(0)}`
  }

  return (
    <div className={`price-content-component ${className}`}>
      <h3>Early Bird Discount</h3>
      <div className='price-main-content'>
        <PriceItem
          title={'Regular price'}
          description={'If Enroll After June 15th'}
          originPrice={null}
          realPrice={`$${getOriginPrice()}`}
        />
        <PriceItem
          title={'Limited Discount'}
          description={'If Enroll by May 15th'}
          originPrice={`$${getOriginPrice()}`}
          realPrice={`$${getOriginPrice() - getOff3()}`}
        />
        <PriceItem
          title={'TODAY’s Enroll'}
          description={'Going away soon hurry'}
          subDes1={`$${getOff1()} OFF: Early bird discount applied`}
          subDes2={campLength > 1 ? `$${getOff2()} OFF: One additional camp discount applied` : ''}
          originPrice={`$${getOriginPrice()}`}
          realPrice={`$${getPrice()}`}
          className={'today-enroll'}
        />
      </div>
    </div>
  );
}
