import {
    GET_ALL_COURSES, GET_ALL_COURSES_SUCCESS, GET_ALL_COURSES_FAIL,
    GET_COURSE_DETAIL, GET_COURSE_DETAIL_SUCCESS, GET_COURSE_DETAIL_FAIL,
    GET_COURSES, GET_COURSES_SUCCESS, GET_COURSES_FAIL,
    GET_CAMP_COURSES, GET_CAMP_COURSES_SUCCESS, GET_CAMP_COURSES_FAIL,
    GET_SUMMER_CAMP_COURSES, GET_SUMMER_CAMP_COURSES_SUCCESS, GET_SUMMER_CAMP_COURSES_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    getAllCourses as getAllCoursesApi,
    getCourseDetail as getCourseDetailApi,
    getCourses as getCoursesApi,
    getCampCourses as getCampCoursesApi,
    getSummerCampCourses as getSummerCampCoursesApi,
} from '../../Api';

function* getAllCourses(payload) {
    try {
        const result = yield getAllCoursesApi(payload.timezone)
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_ALL_COURSES_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_ALL_COURSES_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_ALL_COURSES_FAIL, data: [] })
    }
}

export function* watchGetAllCourses() {
    yield takeLatest(GET_ALL_COURSES, getAllCourses)
}

function* getCourseDetail(payload) {
    try {
        const result = yield getCourseDetailApi(payload.data)
        if (result && result.data) {
            yield put({ type: GET_COURSE_DETAIL_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_COURSE_DETAIL_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_COURSE_DETAIL_FAIL, data: [] })
    }
}

export function* watchGetCourseDetail() {
    yield takeLatest(GET_COURSE_DETAIL, getCourseDetail)
}

function* getCourses(payload) {
    try {
        const result = yield getCoursesApi(payload.data.type)
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_COURSES_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_COURSES_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_COURSES_FAIL, data: [] })
    }
}

export function* watchGetCourses() {
    yield takeLatest(GET_COURSES, getCourses)
}

function* getCampCourses(payload) {
    try {
        const result = yield getCampCoursesApi(payload.data.type)
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_CAMP_COURSES_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_CAMP_COURSES_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_CAMP_COURSES_FAIL, data: [] })
    }
}

export function* watchGetCampCourses() {
    yield takeLatest(GET_CAMP_COURSES, getCampCourses)
}

function* getSummerCampCourses(payload) {
    try {
        const result = yield getSummerCampCoursesApi(payload.data.type)
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_SUMMER_CAMP_COURSES_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_SUMMER_CAMP_COURSES_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_SUMMER_CAMP_COURSES_FAIL, data: [] })
    }
}

export function* watchGetSummerCampCourses() {
    yield takeLatest(GET_SUMMER_CAMP_COURSES, getSummerCampCourses)
}