import { GET_CITIES, GET_CITIES_SUCCESS, GET_CITIES_FAIL } from '../Type';

const INITIAL = {
    get_cities_loading: false,
    get_cities_success: false,
    cities: []
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case GET_CITIES: {
            return {
                ...state,
                get_cities_loading: true,
                get_cities_success: false,
                cities: []
            }
        }
        case GET_CITIES_SUCCESS: {
            return {
                ...state,
                get_cities_loading: false,
                get_cities_success: true,
                cities: action.data
            }
        }
        case GET_CITIES_FAIL: {
            return {
                ...state,
                get_cities_loading: false,
                get_cities_success: true,
                cities: []
            }
        }
        default:
            return state;
    }
}