/**
 * Sidebar
 */
export const MOBILE_SIDEBAR_TOGGLE = 'MOBILE_SIDEBAR_TOGGLE';
export const COURSE_SIDEBAR_TOGGLE = 'COURSE_SIDEBAR_TOGGLE';

/**
 * Modal
 */
export const FEEDBACK_MODAL_TOGGLE = 'FEEDBACK_MODAL_TOGGLE';
export const TUTOR_MODAL_TOGGLE = 'TUTOR_MODAL_TOGGLE';
export const VIDEO_MODAL_TOGGLE = 'VIDEO_MODAL_TOGGLE';
export const SC_NOTIFICATION_MODAL = 'SC_NOTIFICATION_MODAL';

/**
 * Auth
 */
export const AUTH_SIGN_IN = 'AUTH_SIGN_IN';
export const AUTH_SIGN_IN_SUCCESS = 'AUTH_SIGN_IN_SUCCESS';
export const AUTH_SIGN_IN_FAIL = 'AUTH_SIGN_IN_FAIL';

export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
export const AUTH_SIGN_OUT_SUCCESS = 'AUTH_SIGN_OUT_SUCCESS';
export const AUTH_SIGN_OUT_FAIL = 'AUTH_SIGN_OUT_FAIL';

export const AUTH_SIGN_UP = 'AUTH_SIGN_UP';
export const AUTH_SIGN_UP_SUCCESS = 'AUTH_SIGN_UP_SUCCESS';
export const AUTH_SIGN_UP_FAIL = 'AUTH_SIGN_UP_FAIL';

export const TUTOR_SIGN_UP = 'TUTOR_SIGN_UP';
export const TUTOR_SIGN_UP_SUCCESS = 'TUTOR_SIGN_UP_SUCCESS';
export const TUTOR_SIGN_UP_FAIL = 'TUTOR_SIGN_UP_FAIL';

export const TUTOR_PROFILE_UPDATE = 'TUTOR_PROFILE_UPDATE';
export const TUTOR_PROFILE_UPDATE_SUCCESS = 'TUTOR_PROFILE_UPDATE_SUCCESS';
export const TUTOR_PROFILE_UPDATE_FAIL = 'TUTOR_PROFILE_UPDATE_FAIL';

export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS';
export const AUTH_FORGOT_PASSWORD_FAIL = 'AUTH_FORGOT_PASSWORD_FAIL';

export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_FAIL = 'AUTH_RESET_PASSWORD_FAIL';

export const AUTH_UPDATE_PLAN = 'AUTH_UPDATE_PLAN';

/**
 * Course
 */
export const GET_ALL_COURSES = 'GET_ALL_COURSES';
export const GET_ALL_COURSES_SUCCESS = 'GET_ALL_COURSES_SUCCESS';
export const GET_ALL_COURSES_FAIL = 'GET_ALL_COURSES_FAIL';

export const GET_COURSE_DETAIL = 'GET_COURSE_DETAIL';
export const GET_COURSE_DETAIL_SUCCESS = 'GET_COURSE_DETAIL_SUCCESS';
export const GET_COURSE_DETAIL_FAIL = 'GET_COURSE_DETAIL_FAIL';


export const GET_COURSES = 'GET_COURSES';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_FAIL = 'GET_COURSES_FAIL';

export const GET_CAMP_COURSES = 'GET_CAMP_COURSES';
export const GET_CAMP_COURSES_SUCCESS = 'GET_CAMP_COURSES_SUCCESS';
export const GET_CAMP_COURSES_FAIL = 'GET_CAMP_COURSES_FAIL';

export const GET_SUMMER_CAMP_COURSES = 'GET_SUMMER_CAMP_COURSES';
export const GET_SUMMER_CAMP_COURSES_SUCCESS = 'GET_SUMMER_CAMP_COURSES_SUCCESS';
export const GET_SUMMER_CAMP_COURSES_FAIL = 'GET_SUMMER_CAMP_COURSES_FAIL';

export const SET_CAMP_CART_INFO = 'SET_CAMP_CART_INFO';

/**
 * Levels
 */
export const GET_SHOOLS = 'GET_SHOOLS';
export const GET_SHOOLS_SUCCESS = 'GET_SHOOLS_SUCCESS';
export const GET_SHOOLS_FAIL = 'GET_SHOOLS_FAIL';

/**
 * Schools
 */
export const GET_LEVELS = 'GET_LEVELS';
export const GET_LEVELS_SUCCESS = 'GET_LEVELS_SUCCESS';
export const GET_LEVELS_FAIL = 'GET_LEVELS_FAIL';

/**
 * City
 */
export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAIL = 'GET_CITIES_FAIL';

/**
 * Review
 */
export const GET_ALL_REVIEWS = 'GET_ALL_REVIEWS';
export const GET_ALL_REVIEWS_SUCCESS = 'GET_ALL_REVIEWS_SUCCESS';
export const GET_ALL_REVIEWS_FAIL = 'GET_ALL_REVIEWS_FAIL';

/**
 * Post
 */
export const GET_ALL_POSTS = 'GET_ALL_POSTS';
export const GET_ALL_POSTS_SUCCESS = 'GET_ALL_POSTS_SUCCESS';
export const GET_ALL_POSTS_FAIL = 'GET_ALL_POSTS_FAIL';

/**
 * Plan
 */
export const UPDATE_PLAN = 'UPDATE_PLAN';
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_FAIL = 'UPDATE_PLAN_FAIL';

export const GET_PLAN = 'GET_PLAN';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export const GET_PLAN_FAIL = 'GET_PLAN_FAIL';

/**
 * FAQ
 */
export const GET_FAQ = 'GET_FAQ';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_FAIL = 'GET_FAQ_FAIL';

/**
 * Landing
 */
export const GET_LANDING_DATA = 'GET_LANDING_DATA';
export const GET_LANDING_DATA_SUCCESS = 'GET_LANDING_DATA_SUCCESS';
export const GET_LANDING_DATA_FAIL = 'GET_LANDING_DATA_FAIL';

export const USER_SUBMIT = 'USER_SUBMIT';
export const USER_SUBMIT_SUCCESS = 'USER_SUBMIT_SUCCESS';
export const USER_SUBMIT_FAIL = 'USER_SUBMIT_FAIL';

export const GET_BRAINTREE_KEY = 'GET_BRAINTREE_KEY';
export const GET_BRAINTREE_KEY_SUCCESS = 'GET_BRAINTREE_KEY_SUCCESS';
export const GET_BRAINTREE_KEY_FAIL = 'GET_BRAINTREE_KEY_FAIL';

export const UPDATE_CARD = 'UPDATE_CARD';
export const UPDATE_CARD_SUCCESS = 'UPDATE_CARD_SUCCESS';
export const UPDATE_CARD_FAIL = 'UPDATE_CARD_FAIL';

export const SC_PAYMENT = 'SC_PAYMENT';
export const SC_PAYMENT_SUCCESS = 'SC_PAYMENT_SUCCESS';
export const SC_PAYMENT_FAIL = 'SC_PAYMENT_FAIL';

/**
 * Landing
 */
export const GET_SC_STATE = 'GET_SC_STATE';
export const GET_SC_STATE_SUCCESS = 'GET_SC_STATE_SUCCESS';
export const GET_SC_STATE_FAIL = 'GET_SC_STATE_FAIL';

export const GET_SC_STATE_TYPE = 'GET_SC_STATE_TYPE';
export const GET_SC_STATE_TYPE_SUCCESS = 'GET_SC_STATE_TYPE_SUCCESS';
export const GET_SC_STATE_TYPE_FAIL = 'GET_SC_STATE_TYPE_FAIL';

export const GET_SC_STATE_CITY = 'GET_SC_STATE_CITY';
export const GET_SC_STATE_CITY_SUCCESS = 'GET_SC_STATE_CITY_SUCCESS';
export const GET_SC_STATE_CITY_FAIL = 'GET_SC_STATE_CITY_FAIL';

export const GET_SC_STATE_CATEGORY = 'GET_SC_STATE_CATEGORY';
export const GET_SC_STATE_CATEGORY_SUCCESS = 'GET_SC_STATE_CATEGORY_SUCCESS';
export const GET_SC_STATE_CATEGORY_FAIL = 'GET_SC_STATE_CATEGORY_FAIL';

export const GET_SC_STATE_CITY_TYPE = 'GET_SC_STATE_CITY_TYPE';
export const GET_SC_STATE_CITY_TYPE_SUCCESS = 'GET_SC_STATE_CITY_TYPE_SUCCESS';
export const GET_SC_STATE_CITY_TYPE_FAIL = 'GET_SC_STATE_CITY_TYPE_FAIL';

export const GET_SC_STATE_CITY_CATEGORY = 'GET_SC_STATE_CITY_CATEGORY';
export const GET_SC_STATE_CITY_CATEGORY_SUCCESS = 'GET_SC_STATE_CITY_CATEGORY_SUCCESS';
export const GET_SC_STATE_CITY_CATEGORY_FAIL = 'GET_SC_STATE_CITY_CATEGORY_FAIL';

export const CAMP_SIGNUP = 'CAMP_SIGNUP';
export const CAMP_SIGNUP_SUCCESS = 'CAMP_SIGNUP_SUCCESS';
export const CAMP_SIGNUP_FAIL = 'CAMP_SIGNUP_FAIL';

export const CAMP_HUBSPOT = 'CAMP_HUBSPOT';
export const CAMP_HUBSPOT_SUCCESS = 'CAMP_HUBSPOT_SUCCESS';
export const CAMP_HUBSPOT_FAIL = 'CAMP_HUBSPOT_FAIL';

/**
 * iGenius
 */
export const GET_IGENIUS_LANDING_INFO = 'GET_IGENIUS_LANDING_INFO';
export const GET_IGENIUS_LANDING_INFO_SUCCESS = 'GET_IGENIUS_LANDING_INFO_SUCCESS';
export const GET_IGENIUS_LANDING_INFO_FAIL = 'GET_IGENIUS_LANDING_INFO_FAIL';

export const MASTER_CLASS_SIGNUP = 'MASTER_CLASS_SIGNUP';
export const MASTER_CLASS_SIGNUP_SUCCESS = 'MASTER_CLASS_SIGNUP_SUCCESS';
export const MASTER_CLASS_SIGNUP_FAIL = 'MASTER_CLASS_SIGNUP_FAIL';

/**
 * Other
 */
export const SET_REFERRAL_CODE = 'SET_REFERRAL_CODE';



