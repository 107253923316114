import React, { useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader'
import { EventEmitter } from './Utils/events';
import { css } from "@emotion/core";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import {
  Header,
  MobileSidebar,
  Footer,
  FeedbackModal,
  CourseSidebar,
  SignOut,
  VideoModal,
  PushNotification,
  AppNotification,
} from './Components';
import {
  HomePage, ReviewsPage, StoryPage, PricingPage, FAQsPage, SignInPage, ForgotPasswordPage, AdsSignupPage, AdsSignup2Page,
  TrialPage, TutorSignupPage, EnrollPage, Enroll2Page, ContactUsPage, AboutUsPage, TermsPage, TeamPage, BlogListPage, BlogArticlePage, HelpCenterPage, MediaPage,
  CourseListPage, CoursePage,
  ParentingthefuturePage, StudentSignupPage, ThankyouPage, SummerCampPage,
  SCRegistrationPage, SCPaymentPage, SCRegistrationSuccessPage,
  StateSCPage, StateTypeSCPage, StateCategorySCPage, StateCitySCPage, StateCityTypeSCPage, StateCityCategorySCPage,
  IGeniusLandingPage, IGeniusLandingPage1, SCMasterclassPage,
  AdelolaPage,
} from './Pages';
//import { GetDashboardUrl } from "./Constant";
import { toggleScNotificationModal } from 'src/Redux/Actions';

function ScrollToTop() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    //disable iCode pop up
    const pathName = window.location.pathname.split('/')[1];
    if (
      pathName !== 'camp-list' &&
      pathName !== 'course' &&
      pathName !== 'summer-camp-registration' &&
      pathName !== 'summer-camp-payment' &&
      pathName !== 'summer-camp-registration-success' &&
      pathName !== 'freecodingchallenge' &&
      pathName !== 'freecodingchallenge-1' &&
      pathName !== 'iCode-iGenius-summer-camps'
    ) {
      dispatch(toggleScNotificationModal(true))
    } 
  }, [dispatch, pathname]);

  return null;
}

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

class App extends React.Component {
  state = {
    isBlur: false,
    isSidebarOpen: true,
    isSidebarPrevOpen: true,
    isResize: false,
    loading: false
  }

  componentDidMount() {
    // const { user, token } = this.props;
    // if (user && token ){
    //   if(user.role === 'tutor'){
    //     if(!_.isEmpty(user.schedule)){
    //       window.location.href = GetDashboardUrl(user, token);       
    //     };
    //   };

    //   if(user.role === 'student'){
    //     if(user.can_book_trial || user.plan_frequency || user.trial_lesson.status === 'pending'){
    //       window.location.href = GetDashboardUrl(user, token)
    //     };
    //   };
    // };
  };

  static getDerivedStateFromProps(props, state) {
    return {
      isBlur: props.isMobileSidebarOpen || props.isModalOpen || props.isCourseSidebarOpen,
    }
  }

  render() {
    const { isBlur, loading } = this.state;
    EventEmitter.subscribe('isLoading', (event) => this.setState({ loading: event }));

    if (isBlur) {
      if (document.body) {
        const body = document.body
        body.classList.add('modal-open');
      }
    } else {
      if (document.body) {
        const body = document.body
        body.classList.remove('modal-open');
      }
    }

    return (
      <BrowserRouter>
        <ScrollToTop />
        <div className="App">
          <div id="main" className={`${isBlur ? 'blur' : ''}`}>
            <MobileSidebar />
            <CourseSidebar />
            <PushNotification />
            <Header />
            <div className="content">
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/reviews" component={ReviewsPage} />
                <Route exact path="/story/:id" component={StoryPage} />
                <Route exact path="/pricing" component={PricingPage} />
                <Route exact path="/course-list/:id" component={(props) => <CourseListPage {...props} key={window.location.pathname} />} />
                <Route exact path="/camp-list/:id" component={(props) => <CourseListPage {...props} key={window.location.pathname} />} />
                <Route exact path="/course/:id" component={CoursePage} />
                <Route exact path="/faqs" component={FAQsPage} />
                <Route exact path="/sign-in" component={SignInPage} />
                <Route exact path="/forgot-password" component={ForgotPasswordPage} />
                <Route exact path="/ads-signup" component={AdsSignupPage} />
                <Route exact path="/ads-signup2" component={AdsSignup2Page} />
                <Route exact path="/start-trial" component={TrialPage} />
                <Route exact path="/tutor-signup" component={TutorSignupPage} />
                <Route exact path="/enroll" component={EnrollPage} />
                <Route exact path="/enroll2" component={Enroll2Page} />
                <Route exact path="/contact-us" component={ContactUsPage} />
                <Route exact path="/about-us" component={AboutUsPage} />
                <Route exact path="/terms" component={TermsPage} />
                <Route exact path="/team" component={TeamPage} />
                <Route exact path="/blog" component={BlogListPage} />
                <Route exact path="/blog/:url" component={BlogArticlePage} />
                <Route exact path="/help-center" component={HelpCenterPage} />
                <Route exact path="/media" component={MediaPage} />
                <Route exact path="/parentingthefuture" component={ParentingthefuturePage} />
                <Route exact path="/studentsignup" component={StudentSignupPage} />
                <Route exact path="/thankyou" component={ThankyouPage} />
                <Route exact path="/signout" component={SignOut} />

                <Route exact path="/summer-camps" component={SummerCampPage} />
                <Route exact path="/summer-camps/state/:state" component={StateSCPage} />
                <Route exact path="/summer-camps/state/:state/type/:type" component={StateTypeSCPage} />
                <Route exact path="/summer-camps/state/:state/category/:category" component={StateCategorySCPage} />
                <Route exact path="/summer-camps/state/:state/city/:city" component={StateCitySCPage} />
                <Route exact path="/summer-camps/state/:state/city/:city/type/:type" component={StateCityTypeSCPage} />
                <Route exact path="/summer-camps/state/:state/city/:city/category/:category" component={StateCityCategorySCPage} />

                <Route exact path="/summer-camp-registration" component={SCRegistrationPage} />
                <Route exact path="/summer-camp-payment" component={SCPaymentPage} />
                <Route exact path="/summer-camp-registration-success" component={SCRegistrationSuccessPage} />

                <Route exact path="/freecodingchallenge" component={IGeniusLandingPage} />
                <Route exact path="/freecodingchallenge-1" component={IGeniusLandingPage1} />
                <Route exact path="/iCode-iGenius-summer-camps/masterclass" component={SCMasterclassPage} />

                <Route exact path="/adeola" component={AdelolaPage} />
              </Switch>
            </div>
            <Footer />
            <FeedbackModal />
            <VideoModal />
            <AppNotification />
          </div>
        </div>
        <div className={loading ? "overlay-loader" : "d-none"}>
          <BeatLoader
            css={override}
            size={30}
            color={"#FFF"}
            loading={loading}
          />
        </div>
        {/* <div className={"overlay-loader"}>
          <BeatLoader
            css={override}
            size={30}
            color={"#FFF"}
            loading={true}
          />
        </div> */}
        <NotificationContainer />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobileSidebarOpen: state.Sidebar.isMobileOpen,
    isCourseSidebarOpen: state.Sidebar.isCourseOpen,
    isModalOpen: state.Modal.isFeedbackModalOpen,

    token: state.Auth.token,
    user: state.Auth.user,
  }
}

export default connect(mapStateToProps, {})(App);
