import {
  SET_REFERRAL_CODE,
} from '../Type';

export const setReferralCode = (referral_code) => {
  return {
    type: SET_REFERRAL_CODE,
    data: { referral_code }
  }
}

