import React from 'react';
import './WhyIcodeEdu.scss';
import work1 from '../../../Assets/StudentSignup/w1.png';
import work2 from '../../../Assets/StudentSignup/w2.png';
import work3 from '../../../Assets/StudentSignup/w3.png';
import work4 from '../../../Assets/StudentSignup/w4.png';
import work5 from '../../../Assets/StudentSignup/w5.png';
import work6 from '../../../Assets/StudentSignup/w6.png';
import work7 from '../../../Assets/StudentSignup/w7.png';
import work8 from '../../../Assets/StudentSignup/w8.png';
import work9 from '../../../Assets/StudentSignup/w9.png';
import work10 from '../../../Assets/StudentSignup/w10.png';
import work11 from '../../../Assets/StudentSignup/w11.png';
import brandbot from '../../../Assets/Brand/brand-bot.png';

const WorkBlocks = [
    { id: 1, img: work1, title: 'Evaluation', description: '1-on-1 customize and personalize coding lessons with instructors who are also mentors from top U.S universities.' },
    { id: 2, img: work2, title: 'Placement', description: 'Students complete each course with a project presentation to families and friends and iCode Edu awards certification of completion.' },
    { id: 3, img: work3, title: 'Certificate', description: 'All Instructors are background checked and have extensive experience in programming and coding' },
    {id: 4, img: work4, title: '', description: 'All instructors are U.S based and from top U.S universities. Your child will learn from the very best.'},
    {id: 5, img: work5, title: '', description: 'Our instructors endeavor to make sure they are not just teachers, but mentors to their students'},
    {id: 6, img: work6, title: '', description: 'Opportunity for students to compete in international coding and game design competitions'},
    {id: 7, img: work7, title: '', description: 'Structured curriculum used by over 100,000 students worldwide.'},
    {id: 8, img: work8, title: '', description: 'The student starts as a beginner and graduates as an expert coder.'},
    {id: 9, img: work9, title: '', description: "24/7 access to the learning community where students practice what they are learning, complete projects, build new projects and share with family and friends. It's a 24/7 learning ecosystem."},
    {id: 10, img: work10, title: '', description: 'Each instructor produces a detailed report to parents at the completion of each 50 min lesson, outlining progress, learning goals, and more!'},
    {id: 11, img: work11, title: '', description: 'All instructors are trained on how to implement our structured curriculum successfully.'},
]

export default class WhyIcodeEdu extends React.Component {
    state = {
        isMore: false
    }

    loadMore = () => this.setState({isMore: !this.state.isMore});

    render() {
        const { isMore } = this.state
        return (
            <div className="whyIcodeEdu-component">               
                <div className="container">
                    <h1>Why iCode Edu</h1>
                    <div className="row">
                        {WorkBlocks.map((item, index) => 
                            (isMore || (!isMore && index < 6)) &&
                                <div className="col-lg-4 col-md-6 col-sm-12 sub-col" key={index}>
                                    <div className="work-block">                               
                                        <div className="work-content h-r">
                                            <div className='logo-container'>
                                                <div className='logo-content v-c h-c'>
                                                    <img src={item.img} alt="work-img" />
                                                </div>
                                            </div>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                            </div>
                        )}
                    </div>
                    <div className='h-c v-c'>
                        <div className='load-more-btn v-c h-c' onClick={this.loadMore}>
                            {isMore? "LOAD LESS" : "LOAD MORE"}
                        </div>
                    </div>
                    <div className='brandbot-container'>
                        <img src={brandbot} alt="brandbot" className="brandbot show-web" />
                    </div>
                </div>
            </div>
        )
    };
};