import React from 'react';
import './style.scss';
import _ from 'lodash'
import { getUpperCaseText } from 'src/Utils/Format';

export default function StateCategoryLink({
  stateName,
  info,
}) {

  return (
    <div className='state-category-link-component'>
      <h2>{stateName} Summer Camp Guide by Categories</h2>
      <div className="row">
        {!_.isEmpty(info) && info.map((category, index) => (
          <div
            key={index}
            className="col-lg-6">
            <a href={`/summer-camps/state/${stateName}/category/${category}`}>
              {`${stateName} ${getUpperCaseText(category)} Summer Camps`}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
