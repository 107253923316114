import React from 'react';
import './style.scss';
import _ from 'lodash'

export default function StateCityLink({
  stateName,
  info,
}) {

  return (
    <div className='state-city-link-component'>
      <h2>{stateName} Summer Camp Guide by Cities</h2>
      <div className="row">
        {!_.isEmpty(info) && info.map((city, index) => (
          <div
            key={index}
            className="col-lg-6">
            <a href={`/summer-camps/state/${stateName}/city/${city}`}>
              {`${city} ${stateName} Summer Camps`}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
