import {
  GET_IGENIUS_LANDING_INFO, GET_IGENIUS_LANDING_INFO_SUCCESS, GET_IGENIUS_LANDING_INFO_FAIL,
  MASTER_CLASS_SIGNUP, MASTER_CLASS_SIGNUP_SUCCESS, MASTER_CLASS_SIGNUP_FAIL,
} from '../Type';

const INITIAL = {
  get_masterclass_landing_info_loading: true,
  get_masterclass_landing_info_success: false,
  masterclassLandingInfo: [],

  masterclass_signup_res: '',
  masterclass_signup_loading: true,
  masterclass_signup_success: false,
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * GET iGenius Landing
     */
    case GET_IGENIUS_LANDING_INFO: {
      return {
        ...state,
        get_masterclass_landing_info_loading: true,
        get_masterclass_landing_info_success: true,
        masterclassLandingInfo: []
      }
    }
    case GET_IGENIUS_LANDING_INFO_FAIL: {
      return {
        ...state,
        get_masterclass_landing_info_loading: false,
        get_masterclass_landing_info_success: false,
        masterclassLandingInfo: []
      }
    }
    case GET_IGENIUS_LANDING_INFO_SUCCESS: {
      return {
        ...state,
        get_masterclass_landing_info_loading: false,
        get_masterclass_landing_info_success: true,
        masterclassLandingInfo: action.data
      }
    }

    /**
     * Camp Signup
     */
    case MASTER_CLASS_SIGNUP: {
      return {
        ...state,
        masterclass_signup_loading: true,
        masterclass_signup_success: false,
        masterclass_signup_res: '',
      }
    }
    case MASTER_CLASS_SIGNUP_SUCCESS: {
      return {
        ...state,
        masterclass_signup_loading: false,
        masterclass_signup_success: true,
        masterclass_signup_res: action.data.message,
      }
    }
    case MASTER_CLASS_SIGNUP_FAIL: {
      return {
        ...state,
        masterclass_signup_loading: false,
        masterclass_signup_success: false,
        masterclass_signup_res: action.data.msg,
      }
    }

    default:
      return state;
  }
}