import React from 'react';
import './NationalMedia.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import playBtn from '../../../Assets/Icon/music-player-play.png';
import important from '../../../Assets/StudentSignup/important.png';
import { toggleVideoModal } from '../../../Redux/Actions';


class NationalMedia extends React.Component {
    state = {
        current_index: 0,
        current_media: '',
    }

    componentDidMount(){
        this.setState({current_media: this.props.videoData[0]})
    };

    showVideo = () => {
        this.props.toggleVideoModal(true, this.state.current_media);
    }

    selectBrand =(item, index) => {
        this.setState({
            current_media: item,
            current_index: index
        })
    };

    render() {
        const { videoData } = this.props;
        const { current_index, current_media } = this.state
        return (
            <div className="nationalMedia-component">               
                <div className="container">
                    <h1>Featured On Local And National Media</h1>
                    <div className="row">
                        <div className='col-lg-6 left-area'>
                            <div className="video-player-content" onClick={()=>this.showVideo()}>
                                <img src={current_media.thumbnail} alt="video" className="video-prview-img" />                    
                                <img className="play-icon" src={playBtn} alt="play-btn"/>                  
                            </div>
                        </div>
                        <div className='col-lg-6 right-area'>
                            <div className='right-content'>
                                <img src={important} className="important-img mb-3" alt="important" />
                                <p>{current_media.description}</p>
                                <div className='v-c mt-3'>
                                    <div className="logo-content v-c h-c">
                                        <img src={current_media.logo} alt="brand" className="brand" />
                                    </div>
                                    <div className='ml-3'>
                                        <div className='logo-name'>{current_media.title}</div>
                                        <div className='logo-des'>{current_media.subtitle}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='logo-list-container'>
                        {videoData.map((item, index)=> (
                            <div key={index} className={`logo-item v-c h-c ${current_index === index? 'active-logo' : ''}`} onClick={()=>this.selectBrand(item, index)}>
                                <img src={item.logo} className="company-logo" alt="logo" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, {
    toggleVideoModal
})(withRouter(NationalMedia));