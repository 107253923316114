import { all, fork } from 'redux-saga/effects';

import {
  watchGetAllCourses,
  watchGetCourseDetail,
  watchGetCourses,
  watchGetCampCourses,
  watchGetSummerCampCourses,
 } from './Course';
import { watchGetLevels } from './Levels';
import { watchgetSchools } from './Schools';
import { watchGetCities } from './City';
import { watchGetAllReviews } from './Review';
import { watchGetAllPosts } from './Post';
import {
  watchGetBraintreekey,
  watchUpdateCard,
  watchSCPayment
} from './Payment';
import { watchSignIn, watchSignOut, watchSignUp, watchtutorSignUp, watchtutorProfileUpdate, watchForgotPassword, watchResetPassword } from './Auth';
import { watchUpdatePlan, watchGetPlan } from './Plan';
import { watchgetFaq } from './Faq';
import { watchgetLanding, watchUserSubmit } from './Landing';
import {
  watchgetSCState,
  watchgetSCStateType,
  watchgetSCStateCity,
  watchgetSCStateCategory,
  watchGetSCStateCityType,
  watchGetSCStateCityCategory,
  watchCampSignUp,
  watchCampHubspot,
} from './SummerCamp';

import {
  watchGetIGeniusLandingInfo,
  watchMasterClassSignUp,
} from './IGenius';

export default function* rootSaga() {
  yield all([
    fork(watchGetAllCourses),
    fork(watchGetCourseDetail),    
    fork(watchGetCourses),
    fork(watchGetCampCourses),
    fork(watchGetSummerCampCourses),

    fork(watchGetLevels),

    fork(watchgetSchools),

    fork(watchGetBraintreekey),
    fork(watchUpdateCard),
    fork(watchSCPayment),

    fork(watchGetCities),
    fork(watchGetAllReviews),
    fork(watchGetAllPosts),

    fork(watchSignIn),
    fork(watchSignOut),
    fork(watchSignUp),
    fork(watchtutorSignUp),
    fork(watchtutorProfileUpdate),
    fork(watchForgotPassword),
    fork(watchResetPassword),

    fork(watchUpdatePlan),
    fork(watchGetPlan),
    fork(watchgetFaq),

    fork(watchgetLanding),
    fork(watchUserSubmit),

    fork(watchgetSCState),
    fork(watchgetSCStateType),
    fork(watchgetSCStateCity),
    fork(watchgetSCStateCategory),
    fork(watchGetSCStateCityType),
    fork(watchGetSCStateCityCategory),
    fork(watchCampSignUp),
    fork(watchCampHubspot),

    fork(watchGetIGeniusLandingInfo),
    fork(watchMasterClassSignUp),
    
  ]);
}