import React from 'react';
import './style.scss';
import _ from 'lodash';
import SpotBg from 'src/Assets/Course/spot-bg.png';

export default function CampTimeItem({
  item, campId, campDate, campCartInfo, onAddToCard
}) {

  const getSelectedBtn = (date, time) => {
    const _index = _.findIndex(campCartInfo, (camp) => camp.id === campId && camp.time === time && camp.date === date);
    if (_index > -1)
      return true
    return false
  }

  return (
    <div className="camp-time-item-component">
      <div className='date-item center'>
        <div className='date'>{`${item.time}`}</div>
        <img src={SpotBg} alt='spot-bg' className='spot-bg' />
        <div className='spot-text'>Spot Available <strong>{`[ ${item.available} ]`}</strong></div>
      </div>
      <div
        className={`add-to-cut-btn center ${getSelectedBtn(campDate, item.time) ? 'selected' : ''}`}
        onClick={() => onAddToCard(campDate, item.time, item.available)}>
        ADD TO CART
      </div>
    </div>
  );
}