import React from 'react';
import './UserReviews.scss';
import Media from 'react-media';
import Loadable from '@loadable/component';
import { CarouselLeftButton, CarouselRightButton } from '../../../Constant';
import { Feedback } from '../../';
import cnn from '../../../Assets/Home/cnn.png';
import bbc from '../../../Assets/Home/bbc.png';
import cbs from '../../../Assets/Home/cbs.png';
import nyt from '../../../Assets/Home/nyt.png';
import nytM from '../../../Assets/Home/nyt-mobile.png';
import tech from '../../../Assets/Home/techcrunch.png';
import techM from '../../../Assets/Home/techcrunch-mobile.png';
import abc from '../../../Assets/Home/abc.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllReviews } from '../../../Redux/Actions';

const OwlCarousel = Loadable(() => import('react-owl-carousel'));

class UserReviews extends React.Component {
    state = {
        show_list: []
    }

    componentDidMount() {
        if (this.props.review_list.length === 0) {
            this.props.getAllReviews();
        } else {
            this.filterList();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.review_list.length === 0 && this.props.review_list.length > 0) {
            this.filterList();
        }
    }

    filterList = async () => {
        await this.setState({show_list: this.props.review_list});
        await this.setState({show_list: this.props.review_list});
    }

    render() {
        const { show_list } = this.state;
        return (
            <div className="userreviews-component">
                <div className="container v-r">
                    <h1>iCode Edu user reviews</h1>
                    <div className="reviews-row">
                        <Media queries={{
                            ex: "(min-width: 1200px)",
                            lg: "(min-width: 992px)",
                            md: "(min-width: 768px)",
                            }}>
                            {matches => (
                                <OwlCarousel
                                    className="owl-theme"
                                    rewind
                                    nav
                                    stagePadding={0}
                                    margin={matches.ex ? 30 : 15}
                                    items={ matches.lg ? 3 : matches.md ? 2 : 1}
                                    dots={false}
                                    loop={false}
                                    navText={[CarouselLeftButton, CarouselRightButton]}
                                    >
                                    { show_list.map((item, index) => <Feedback info={item} index={index} key={index} />)}
                                </OwlCarousel>
                            )}
                        </Media>
                    </div>
                    <div className="brand-row v-c">
                        <img src={cnn} alt="brand" />
                        <img src={bbc} alt="brand" />
                        <img src={cbs} alt="brand" />
                        <img src={nyt} alt="brand" className="show-web" />
                        <img src={nytM} alt="brand" className="show-mobile" />
                        <img src={tech} alt="brand" className="show-web" />
                        <img src={techM} alt="brand" className="show-mobile" />
                        <img src={abc} alt="brand" />
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        review_list: state.Review.review_list
    }
}

export default connect(mapStateToProps, { getAllReviews })(withRouter(UserReviews));