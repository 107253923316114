import React from 'react';
import './TutorSignUpHeader.scss';
import { NavLink } from "react-router-dom";
import logoActive from '../../../Assets/Icon/logo-active.png';

export default class TutorSignUpHeader extends React.Component {
  render() {
    const { currentProgress } = this.props;

    return (
      <div className="tutorSignupHeader-component">
        <div className='main-content'>
          <NavLink to="/"><img src={logoActive} alt="logo" /></NavLink>
          {currentProgress === 1 && 
            <NavLink exact to="/sign-in" className="login-button v-c h-c">SIGN IN</NavLink>
          }
        </div>
      </div>
    );
  }
}