import { Api } from './Api';

export function* signIn(email, password){
    const jsonData = yield Api.post("/login", {email, password});
    return jsonData;
}

export function* signOut() {
    const jsonData = yield Api.post("/logout");
    return jsonData;
}

export function* signUp(data){
    const jsonData = yield Api.post("/client_signup", data);
    return jsonData;
}

export function* tutorSignUp(data){
    const jsonData = yield Api.formPost("/tutor_signup", data);
    return jsonData;
}

export function* tutorProfileUpdate(data){
    const jsonData = yield Api.post("/me", data);
    return jsonData;
}

export function* forgotPassword(data) {
    const jsonData = yield Api.post("/forgotpassword", data);
    return jsonData;
}

export function* resetPassword(data) {
    const jsonData = yield Api.post("/resetpassword", data);
    return jsonData;
}