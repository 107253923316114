import React from 'react';
import './CourseListPage.scss';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import bg from '../../Assets/Course/Icode_5.png';
import { Course, LogoHeader } from 'src/Components';
import { getAllCourses } from '../../Redux/Actions';
import { AgeGroup } from 'src/Constant';
class CourseListPage extends React.Component {
  state = {
    active_age_id: 1,
    display_count: 6,
    data: [],
    program_type: 'course'
  }

  componentDidMount() {
    let program_type = 'course';
    if (this.props.location.pathname.includes('/camp-list/')) program_type = 'summercamp';

    const ageID = this.props.match.params.id ? parseInt(this.props.match.params.id, 10) : 2;
    this.setState({
      display_count: window.outerWidth >= 992 ? 6 : 3,
      active_age_id: ageID,
      program_type: program_type
    })
    if (this.props.course_list.length === 0) {
      this.props.getAllCourses();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const data = props.course_list.filter(temp => (temp.obj_type === state.program_type));
    // const display_count = window.outerWidth >= 992 ? 6 : 3;
    return {
      data: data,
      // display_count: display_count
    }
  }

  selectLevel = (id) => {
    // this.setState({
    //   active_age_id: id,
    //   display_count: window.outerWidth >= 992 ? 6 : 3
    // })
    this.props.history.push(`/camp-list/${id}`)
  }

  getDisplayList = (data) => {
    const { active_age_id } = this.state;
    const selectedGradeLevel = AgeGroup.filter(age => age.id === active_age_id)[0].gradeLevel;

    let _data = [];
    let i = 0
    data.map((item, index) => {
      item.grade && item.grade.map((gradeItem) => {
        if (selectedGradeLevel === gradeItem) {
          _data[i] = item
          i += 1;
        }
      })
    })
    return _data
  }


  render() {
    const { active_age_id, display_count, data, } = this.state;
    const minAge = AgeGroup.filter(age => age.id === active_age_id)[0].min;
    const maxAge = AgeGroup.filter(age => age.id === active_age_id)[0].max;
    // const displayList = data.filter(temp => ((temp.age_min >= min_age && temp.age_min <= max_age) || temp.age_max >= min_age && temp.age_max <= max_age));
    const displayList = this.getDisplayList(data);
    return (
      <>
        <LogoHeader />
        <div className="courselist-page">

          <div className="container v-r">
            <div className="row courselist-header">
              <div className="col-lg-8 order-lg-1 order-2 left-col v-r h-c">
                <div className="courselist-title v-c">
                  <h1>{displayList.length}</h1>
                  <p>
                    {`iCode iGenius Summer Coding Camp For ${AgeGroup.filter(age => age.id === active_age_id)[0].period} old`}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 order-lg-2 order-1 right-col">
                <img src={bg} alt="bg" className="bg" />
              </div>
            </div>
            <div className="courselist-content v-r v-c">
              <div className="agegroup-row v-c">
                {AgeGroup.map((item, index) =>
                  <div
                    className={`age-group ${active_age_id === item.id ? 'active' : ''}`}
                    key={index}
                    onClick={e => this.selectLevel(item.id)}>
                    {item.period}
                  </div>)}
              </div>
              {!_.isEmpty(data) &&
                <div className='course-list-container'>
                  {displayList.map((item, index) =>
                    <Course
                      key={index}
                      info={displayList[index]}
                      minAge={minAge}
                      maxAge={maxAge}
                    />)
                  }
                  {/* {displayList.map((item, index) => (index < display_count) &&
                  <Course
                    key={index}
                    info={displayList[index]}
                    minAge={minAge}
                    maxAge={maxAge}
                  />)
                } */}
                </div>
              }
              {/* {(displayList.length > 0 && display_count < displayList.length) &&
              <div
                className="btn-link load-more-btn"
                onClick={e => this.setState({ display_count: display_count + 3 })}>
                LOAD MORE
              </div>} */}
            </div>
          </div>
        </div>
      </>
    )
  };
};

function mapStateToProps(state) {
  return {
    course_list: state.Course.course_list,
    levels: state.Levels.levels
  }
}

export default connect(mapStateToProps, { getAllCourses })(withRouter(CourseListPage));