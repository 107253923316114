import {
  GET_ALL_COURSES, GET_ALL_COURSES_SUCCESS, GET_ALL_COURSES_FAIL,
  GET_COURSE_DETAIL, GET_COURSE_DETAIL_SUCCESS, GET_COURSE_DETAIL_FAIL,
  GET_COURSES, GET_COURSES_SUCCESS, GET_COURSES_FAIL,
  GET_CAMP_COURSES, GET_CAMP_COURSES_SUCCESS, GET_CAMP_COURSES_FAIL,
  GET_SUMMER_CAMP_COURSES, GET_SUMMER_CAMP_COURSES_SUCCESS, GET_SUMMER_CAMP_COURSES_FAIL,
  SET_CAMP_CART_INFO,
} from '../Type';

const INITIAL = {
  course_list: [],
  get_course_success: false,

  course_detail: {},
  get_course_detail_loading: false,
  get_course_detail_success: false,

  normal_course_list: [],
  get_normal_course_success: false,

  camp_course_list: [],
  get_camp_course_success: false,

  summer_camp_course_list: [],
  get_summer_camp_course_success: false,

  campCartInfo: []
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    //All Courses
    case GET_ALL_COURSES: {
      return {
        ...state
      }
    }
    case GET_ALL_COURSES_FAIL: {
      return {
        ...state,
        course_list: [],
        get_course_success: false,
      }
    }
    case GET_ALL_COURSES_SUCCESS: {
      let course_list = action.data || [];
      for (let item of course_list) {
        item.age_min = item.grade.min === 'Pre-K' ? 5 : item.grade.min + 5;
        item.age_max = item.grade.max === null ? null : item.grade.max + 5;
      }
      return {
        ...state,
        course_list: course_list,
        get_course_success: true,
      }
    }

    //Get Course Detail
    case GET_COURSE_DETAIL: {
      return {
        ...state,
        get_course_detail_loading: true,
      }
    }
    case GET_COURSE_DETAIL_FAIL: {
      return {
        ...state,
        course_detail: {},
        get_course_detail_loading: false,
        get_course_detail_success: false,
      }
    }
    case GET_COURSE_DETAIL_SUCCESS: {
      return {
        ...state,
        course_detail: action.data,
        get_course_detail_loading: false,
        get_course_detail_success: true,
      }
    }

    //Normal Courses
    case GET_COURSES: {
      return {
        ...state
      }
    }
    case GET_COURSES_FAIL: {
      return {
        ...state,
        normal_course_list: [],
        get_normal_course_success: false,
      }
    }
    case GET_COURSES_SUCCESS: {
      let normal_course_list = action.data || [];
      for (let item of normal_course_list) {
        item.age_min = item.grade.min === 'Pre-K' ? 5 : item.grade.min + 5;
        item.age_max = item.grade.max === null ? null : item.grade.max + 5;
      }
      return {
        ...state,
        normal_course_list: normal_course_list,
        get_normal_course_success: true,
      }
    }

    //Camp Courses
    case GET_CAMP_COURSES: {
      return {
        ...state
      }
    }
    case GET_CAMP_COURSES_FAIL: {
      return {
        ...state,
        camp_course_list: [],
        get_camp_course_success: false,
      }
    }
    case GET_CAMP_COURSES_SUCCESS: {
      let camp_course_list = action.data || [];
      for (let item of camp_course_list) {
        item.age_min = item.grade.min === 'Pre-K' ? 5 : item.grade.min + 5;
        item.age_max = item.grade.max === null ? null : item.grade.max + 5;
      }
      return {
        ...state,
        camp_course_list: camp_course_list,
        get_course_success: true,
      }
    }

    //Summer Camp Courses
    case GET_SUMMER_CAMP_COURSES: {
      return {
        ...state
      }
    }
    case GET_SUMMER_CAMP_COURSES_FAIL: {
      return {
        ...state,
        summer_camp_course_list: [],
        get_summer_camp_course_success: false,
      }
    }
    case GET_SUMMER_CAMP_COURSES_SUCCESS: {
      let summer_camp_course_list = action.data || [];
      for (let item of summer_camp_course_list) {
        item.age_min = item.grade.min === 'Pre-K' ? 5 : item.grade.min + 5;
        item.age_max = item.grade.max === null ? null : item.grade.max + 5;
      }
      return {
        ...state,
        summer_camp_course_list: summer_camp_course_list,
        get_course_success: true,
      }
    }

    // Set Camp Cart Info
    case SET_CAMP_CART_INFO: {
      const { campCartInfo } = action.data;
      return { ...state, campCartInfo };
    }

    default:
      return state;
  }
}