import React from 'react';
import './AboutRoland.scss';
import roland from '../../../Assets/Parenting/roland.jpg';


export default class AboutRoland extends React.Component {

    render() {
        return (
            <div className="aboutRoland-component">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 h-c v-c">
                            <img src={roland} className="roland-image" alt="roland_img" />
                        </div>
                        <div className="col-lg-6">
                            <h4>About Roland Chebefuh (MBA/ SPC/ PMP/CSM)</h4>
                            <p>
                                Roland is a Business Agility professional, 
                                SAFe program consultant and transition coach with 
                                more than two decades experience within IT, Education, 
                                Skills Development program and project management space.
                            </p>
                            <p>
                                Roland was born in Cameroon in west Africa where he started his career 
                                in IT with a small internet service provider before moving to South Africa 
                                where he spent more than a decade in post-apartheid South Africa supporting 
                                the public service transformation, skills development and executive support 
                                efforts of the South African Government aimed at creating a more 
                                responsive public service machinery that caters for the service delivery 
                                needs of its citizens including e- government initiatives.
                            </p>
                            <p>
                                Roland was amongst the first team of 11 selected to provide 
                                executive support nationwide in project management to public service 
                                executives and project teams through the National School of Government.
                                 During his time with them, he supported a host of project teams across different 
                                 departments while   training/coaching thousands of public service executives 
                                 on behalf of the National Treasury of South Africa and 
                                 other skills development initiatives and programs.
                            </p>
                            <p>
                                Roland created a support network for accredited IT and skills development startups owned by women and previously disenfranchised individuals, many of whom are still training thousands across Southern Africa every year while creating jobs.
                            </p>
                            <p>
                                Roland is a Co-founder and Student Success Advocate for iCode Edu based in Dallas Texas, a provider of  online coding lessons and futuristic courses aimed at preparing kids for a digital future.
                            </p>
                            <p>Roland currently Lives and works with Silicon Valley Bank in the USA in Tech.</p>
                            <p>
                                Roland has been invited to speak at many workshops and conferences with a combined audience in the tens of thousands.
                            </p>
                            <p>
                                Roland is a change agent/activist and has advocated for many causes including tech rights, good governance in the Public service, Employment equity, gender equity, children rights and the right to self-determination. A firm believer in the rights of every human being to be free and have equal opportunity to take charge of their own destiny, Roland has been greatly influenced by the life and teachings of Nelson Mandela and the writings of world-renowned economist Klaus Schwab  author of The Fourth Industrial Revolution and founder and executive chairman of the World Economic Forum.
                            </p>
                            <p>
                                Roland holds an MBA, is an SPC, PMP and ETDP practitioner. Roland is also a member of   many professional organizations including Project Management South Africa and Project management institute in the USA.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};