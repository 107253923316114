import React from 'react';
import './style.scss';
import blogHeaderBG from '../../../Assets/Blog/headerbg.png'

export default function SummerCampStartArea() {
  return (
    <div className="summer-camp-start-area-component">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 left-area">
            <h1>Best Summer Camp Guide 2023!</h1>
            <p>We did an extensive research to create a resource that parents can use to find the right summer camp for their kids.</p>
          </div>
          <div className="col-lg-5 right-area">
            <img src={blogHeaderBG} alt="right-bg" className="header-right-img" />
          </div>
        </div>
      </div>
    </div>
  );
}
