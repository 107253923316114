import React from 'react';
import './ThankyouPage.scss';
import logoActive from '../../Assets/Icon/logo-active.png';
import ads9 from '../../Assets/Signup/ads-8.png';
import { NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import check from '../../Assets/Icon/check.svg';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InlineWidget } from 'react-calendly';
import { SCHEDULE_URL } from '../../Constant';
import Helper from '../../Utils/Helper';

const Data = [
    {
        step: 11,
        progress: 100,
        title: 'Thank you for requesting a trial lesson. One of our Enrollment Advisor will be in touch to complete the next step in the process.',
        // explain: `You’re all set. The next step is to schedule a call with one of our enrollment directors.`,
        schedule_plugin: true,
        ads_img: ads9,
        btn: 'continue'
    },
];

class ThankyouPage extends React.Component {
    state = {
        step: 11,
    }

    componentDidUpdate(prevProps) {
        if (prevProps.signup_error_msg.length === 0 && this.props.signup_error_msg.length > 0) {
            Helper.showToast('error', 3000, this.props.signup_error_msg);
            return;
        };
    };

    moveForward = () => {
        this.props.history.push('/');
    };

    SelectedDiv = () => <div className="selected"> <ReactSVG src={check} className="svg-icon" /> </div>

    renderStep = (Datas) => {
        const { step } = this.state;

        return Datas.map((info, dataIndex) => step === info.step && <div className="row" id={`section-${info.step}`} key={dataIndex}>
            <div className="col-lg-7 order-lg-1 order-2 left-col">
                <h1>{info.title}</h1>
                {/* {info.explain && <p dangerouslySetInnerHTML={innerFunc(info.explain)} />} */}
                {info.schedule_plugin && <InlineWidget styles={{ minWidth: '320px', height: '630px', marginBottom: '10px' }} url={SCHEDULE_URL} />}
                {info.btn && <button className="btn-2 primary-btn" onClick={e => this.moveForward(info.btn)}> {info.btn} </button>}
            </div>
            <div className={`col-lg-5 order-lg-2 order-1 right-col ${info.ads_margin ? 'ads-margin' : ''}`}>
                <img src={info.ads_img} alt="ads" className="ads-img" />
            </div>
        </div>)
    }

    render() {
        return (
            <div className="thankyou-page">
                <div className="container v-r">
                    <div className="logo-area">
                        <NavLink to="/"><img src={logoActive} alt="logo" /></NavLink>
                    </div>
                    <div className="main-content v-r">
                        {this.renderStep(Data)}
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {

    }
}

export default connect(mapStateToProps, {

})(withRouter(ThankyouPage));