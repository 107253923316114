import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../Redux/Actions';

class SignOut extends React.Component {
    componentDidMount() {
		if (this.props.token.length > 0) this.props.signOut();
        this.props.history.push('/');
    }

    render() {
        return (
            <div style={{position:'fixed', left:0, right:0, top:0, bottom:0, background:'white', zIndex:100}}>
                <div className="spinner" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
		token: state.Auth.token
	}
}

export default connect(mapStateToProps, { signOut })(withRouter(SignOut));