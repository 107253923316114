import React from 'react';
import './ContactUsPage.scss';
// import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

export default class ContactUsPage extends React.Component {
    render() {
        return (
            <div className="contactus-page">
                <div className="container contactus-container">
                    <div className="title">Contact Us</div>
                    <div className="row shadow-object main-row round-object">
                        <div className="col-lg-6 left-area">
                            iCode Edu <br />
                            1751 River Run Suite 200 <br />
                            Fort Worth, TX 76107 <br /> <br />
                            <b>Phone: (817) 984 5300</b><br />
                            <b>Email: info@icodeedu.com</b>
                        </div>
                        <div className="col-lg-6 right-area">
                            <div className="map-wrapper">
                                <div className="map-container">
                                    {/* <Map
                                        google={this.props.google}
                                        zoom={8}
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                        initialCenter={{ lat: 32.726789, lng: -97.359885 }}
                                    >
                                        <Marker position={{ lat: 32.726789, lng: -97.359885 }} />
                                    </Map> */}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// export default GoogleApiWrapper(
//     (props) => ({
//         apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
//         language: 'en',
//         region: 'us'
//     }
// ))(ContactUsPage)