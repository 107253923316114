import React from 'react';
import './style.scss';
import _ from 'lodash'
import { getUpperCaseText } from 'src/Utils/Format';

export default function StateTypeLink({
  stateName,
  info,
}) {

  return (
    <div className='state-type-link-component'>
      <h2>{stateName} Summer Camp Guide by Type</h2>
      <div className="row">
        {!_.isEmpty(info) && info.map((type, index) => (
          <div
            key={index}
            className="col-lg-6">
            <a href={`/summer-camps/state/${stateName}/type/${type}`}>
              {`${stateName} ${getUpperCaseText(type)} Summer Camps`}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
