import React from 'react';
import './style.scss';
import { InputBox, SelectBox } from 'src/Components';
import AvatarIcon from 'src/Assets/Icon/avatar.svg';
import BookIcon from 'src/Assets/Icon/book.svg';

const AgeList = [
  { id: "1", name: '6 years old' },
  { id: "2", name: '7 years old' },
  { id: "3", name: '8 years old' },
  { id: "4", name: '9 years old' },
  { id: "5", name: '10 years old' },
  { id: "6", name: '11 years old' },
  { id: "7", name: '12 years old' },
  { id: "8", name: '13 years old' },
  { id: "9", name: '+14 years old' },
]

export default function StudentInfoForms({
  className = '',
  studentNumber,
  levels,
  studentInfo,
  onChangeStudentInfo,
  onRemove
}) {

  return (
    <div className={`student-forms-component ${className}`}>
      <InputBox
        className="input-box"
        icon={AvatarIcon}
        placeholder={"First Name"}
        value={studentInfo.firstName}
        validation={studentInfo.firstNameValid}
        onChange={e => onChangeStudentInfo('firstName', 'firstNameValid', e)}
      />
      <InputBox
        className="input-box"
        icon={AvatarIcon}
        placeholder={"Last Name"}
        value={studentInfo.lastName}
        validation={studentInfo.lastNameValid}
        onChange={e => onChangeStudentInfo('lastName', 'lastNameValid', e)}
      />
      <SelectBox
        className="select-box"
        icon={BookIcon}
        placeholder={"Age"}
        options={AgeList}
        value={studentInfo.age}
        onChange={e => onChangeStudentInfo('age', 'ageValid', e.name)}
      />
      <SelectBox
        className="select-box"
        icon={BookIcon}
        placeholder={"Grade Level"}
        options={levels}
        value={studentInfo.gradeLevel.name}
        onChange={e => onChangeStudentInfo('gradeLevel', 'gradeLevelValid', e)}
      />
      {studentNumber > 1 &&
        <div className='remove-btn' onClick={onRemove}>Remove</div>
      }
    </div>
  );
}