import React from 'react';
import './ForgotPasswordPage.scss';
import logoActive from '../../Assets/Icon/logo-active.png';
import { NavLink } from 'react-router-dom';
import bg from '../../Assets/Auth/signinbg.png';
import mobilebg from '../../Assets/Auth/signinbg-mobile.png';
import emailSVG from '../../Assets/Icon/email.svg';
import key from '../../Assets/Icon/key.svg';
import { InputBox } from '../../Components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { forgotPassword, resetPassword } from '../../Redux/Actions';

class ForgotPasswordPage extends React.Component {
    state = {
        email: '',
        codes: ['', '', '', ''],
        new_password: '',
        confirm_password: '',

        step: 1,
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.forgot_password_success && this.props.forgot_password_success) {
            this.setState({ step: 2 });
            return;
        }
        if (!prevProps.reset_password_success && this.props.reset_password_success) {
            this.props.history.push('/sign-in');
            return;
        }
        if (prevProps.forgot_password_error_msg.length === 0 && this.props.forgot_password_error_msg.length > 0) {
            alert(this.props.forgot_password_error_msg);
            return;
        }
        if (prevProps.reset_password_error_msg.length === 0 && this.props.reset_password_error_msg.length > 0) {
            alert(this.props.reset_password_error_msg);
            return;
        }
    }

    inputCode = (e, index) => {
        let { codes } = this.state;
        if (e.target.value.length >= 1) {
            codes[index] = parseInt(e.target.value[e.target.value.length-1], 10);
            if (index < codes.length - 1) {
                const codeRef = `code${index+1}`
                this.refs[codeRef].focus();
            } else {
                const codeRef = `code${index}`
                this.refs[codeRef].blur();
            }
        } else if (e.target.value.length === 0) {
            codes[index] = ''
        }
        this.setState({
            codes: codes
        })
    }

    moveForward = (action = 'normal') => {
        let { step, email, codes, new_password, confirm_password } = this.state;
        if (step === 1) {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (email.length === 0 || !re.test(email.toLowerCase())) {
                alert("Please input valid information!");
                return;
            }
            this.props.forgotPassword({ email: email });
            return;
        }
        if (step === 2) {
            if (action === 'forgot') {
                this.setState({codes: ['', '', '', '']})
                this.props.forgotPassword({ email: email });
                return;
            }

            if (codes[0].length === 0 || codes[1].length === 0 || codes[2].length === 0 || codes[3].length === 0 ) {
                alert("Please input valid information!");
                return;
            }

            this.setState({ step: step + 1});
            return;
        }
        if (step === 3) {
            if (new_password.length === 0 || confirm_password.length === 0 || new_password !== confirm_password) {
                alert("Please input valid information!");
                return;
            }
            let param = {
                email: email,
                code: codes.join(''),
                password: new_password
            };
            this.props.resetPassword(param);
        }
    }

    render() {
        const { email, step, codes, new_password, confirm_password } = this.state;
        const { forgot_password_loading, reset_password_loading } = this.props;
        return (
            <div className="forgotpassword-page">
                <div className="container v-r">
                    <div className="logo-area">
                        <NavLink to="/"><img src={logoActive} alt="logo" /></NavLink>
                    </div>
                    <div className="main-content v-r v-c h-c">
                        <div className="sign-in-area">
                            <img src={bg} alt="bg" className="show-web"/>
                            <img src={mobilebg} alt="bg" className="show-mobile mobile-bg"/>
                            {step === 1 && <div className="sign-in-form v-r v-c" id="section-1">
                                <h1>Forgot password?</h1>
                                <p className="explain">Enter your registered email and we will send a verification code to you.</p>
                                <InputBox className="input-form" value={email} icon={emailSVG} placeholder="Email" onChange={val => this.setState({email: val})}/>
                                <button className={`btn-2 primary-btn ${forgot_password_loading ? 'disabled' : ''}`} onClick={e => this.moveForward()}>
                                    {forgot_password_loading ? <div className="spinner" /> : 'Send Password Link'}
                                </button>
                            </div>}
                            {step === 2 && <div className="sign-in-form v-r v-c" id="section-2">
                                <h1>Verification</h1>
                                <p className="explain">We have sent a verification code to your email. Please enter this code below.</p>
                                <div className="input-form v-c">
                                    {codes.map((item, index) => <input type="number" ref={`code${index}`} className="code-input" placeholder="*" value={item} key={index} onChange={e => this.inputCode(e, index)}/>)}
                                </div>
                                <div className="btn-area v-c">
                                    <button className={`btn-2 primary-btn v-c h-c ${forgot_password_loading ? 'disabled' : ''}`} onClick={e => this.moveForward('forgot')}>
                                        {forgot_password_loading ? <div className="spinner" /> : <p className="v-c h-c"><span>RESEND&nbsp;</span><span className="show-web-flex">CODE</span></p>}
                                    </button>
                                    <button className="btn-2 primary-btn" onClick={e => this.moveForward()}>NEXT</button>
                                </div>
                            </div>}
                            {step === 3 && <div className="sign-in-form v-r v-c" id="section-3">
                                <h1>New password</h1>
                                <InputBox className="input-form" type="password" value={new_password} icon={key} placeholder="New Password" onChange={val => this.setState({new_password: val})}/>
                                <InputBox className="input-form" type="password" value={confirm_password} icon={key} placeholder="Confirm Password" onChange={val => this.setState({confirm_password: val})}/>
                                <button className={`btn-2 primary-btn ${reset_password_loading ? 'disabled' : ''}`} onClick={e => this.moveForward()}>
                                    {reset_password_loading ? <div className="spinner" /> : 'Reset Password'}
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        forgot_password_success: state.Auth.forgot_password_success,
        forgot_password_error_msg: state.Auth.forgot_password_error_msg,
        forgot_password_loading: state.Auth.forgot_password_loading,

        reset_password_success: state.Auth.reset_password_success,
        reset_password_error_msg: state.Auth.reset_password_error_msg,
        reset_password_loading: state.Auth.reset_password_loading,
    }
}

export default connect(mapStateToProps, { forgotPassword, resetPassword })(withRouter(ForgotPasswordPage));