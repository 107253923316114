import React from 'react';
import './ParentingHeader.scss';
import { NavLink } from 'react-router-dom';
import facebook from '../../../Assets/Parenting/facebook.png';
import twitter from '../../../Assets/Parenting/twitter.png';
import youtube from '../../../Assets/Parenting/youtube.png';
import instagram from '../../../Assets/Parenting/instagram.png';
//import logoActive from '../../../Assets/Icon/logo-active.png';
import logoInactive from '../../../Assets/Icon/logo-inactive.png';


export default class ParentingHeader extends React.Component {

    render() {
        return (
            <div className="parentingHeader-component">
                <div className="container header-container">
                    <NavLink className="menu-item logo-active logo" exact to="/"><img src={logoInactive} alt="logo-active" /></NavLink>
                    <div className="v-c">
                        <a href="https://www.facebook.com/ICode-Edu-105901597689621/?modal=admin_todo_tour" className="social" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook" /></a>
                        <a href="https://twitter.com/EduIcode" className="social" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="twitter" /></a>
                        <a href="https://www.youtube.com/channel/UCPMvnUxv3T3A5FBOJASIvvg?view_as=subscriber" className="social" target="_blank" rel="noopener noreferrer"><img src={youtube} alt="youtube" /></a>
                        <a href="https://www.instagram.com/icodeedu/" className="social" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="instagram" /></a>
                    </div>
                </div>
            </div>
        )
    };
};
