import React from 'react';
import './UniversityCarousel.scss';

export default class UniversityCarousel extends React.Component {
    
    render() {
        const { item } = this.props;

        return (
            <div className="universityCarousel-component">
                <div className="university-item v-c h-c">
                    <img src={item} className="university-logo" alt="university" />
                </div>
            </div>
        )
    };
};