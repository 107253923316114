import React, { useState, useEffect } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import logoInactive from 'src/Assets/Icon/logo-inactive.png';
import ArrowLeft from 'src/Assets/IGenius/arrow-left.svg';
import ArrowRight from 'src/Assets/IGenius/arrow-right.svg';
// import Countdown from 'react-countdown';

export default function SCMasterclassPage() {
  let history = useHistory();

  const [showBtn, setIsShowBtn] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsShowBtn((true))
    }, 1000 * 59);
  }, []);

  const onHome = () => {
    history.push('/')
  };

  // const onSchedule = (courseId) => {
  //   history.push(`/course/${courseId}`)
  // };

  // const renderer = ({ hours, minutes, seconds }) => {
  //   return (
  //     <div className='v-c mx-auto'>
  //       <div className='time-content center'>{hours}</div>
  //       <div className='split'>:</div>
  //       <div className='time-content center'>{minutes}</div>
  //       <div className='split'>:</div>
  //       <div className='time-content center'>{seconds}</div>
  //     </div>
  //   )
  // };

  return (
    <div className='sc-master-class-page'>
      <div className='logo-section'>
        <img src={logoInactive} alt="logo-active" className='cursor-pointer' onClick={onHome} />
      </div>
      <div className='master-class-content'>
        <h1>iGenius 1:1 Free Coding Challenge Lesson</h1>
        <div className='step step-1'>Step 1: Watch Video</div>
        <div className='master-video-content'>
          <iframe
            src={`https://muse.ai/embed/WqAyWp2`}
            frameBorder="0"
            allowFullScreen
            style={{ width: '100%', height: '350px', }}
            title="video1">
          </iframe>
        </div>
        <div className='time-count-down-section center'>
          {/* <Countdown
            date={Date.now() + 1000 * 59}
            renderer={renderer}
          /> */}
          <img src={ArrowRight} alt='arrow-icon' className={`show-web arrow-right ${showBtn ? '' : 'hidden'}`} />
          <img src={ArrowLeft} alt='arrow-icon' className={`show-web arrow-left ${showBtn ? '' : 'hidden'}`} />
        </div>
        <div className='step step-2'>Step 2: Click button below to speak with enrollment advisor</div>
        <a
          href="https://meetings.hubspot.com/romene/enrollment-advisory"
          className="btn-2 schedule-btn mx-auto center" target="_blank"
          rel="noopener noreferrer">
          Schedule Free Coding Lesson Advisory Call
        </a>
      </div>
    </div>
  );
}