import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import { NavLink } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import logoActive from 'src/Assets/Icon/logo-active.png';
import logoInactive from 'src/Assets/Icon/logo-inactive.png';

export default function IGeniusHeader({
  isFreeLesson
}) {

  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = useCallback(() => {
    setIsScroll(window.scrollY > 0 ? true : false);
  }, [])

  return (
    <div className={`iGenius-header-component ${!isScroll ? 'inActive' : ''}`}>
      <div className='iGenius-header-container container'>
        <NavLink className="menu-item logo-active logo" exact to="/">
          <img src={isScroll ? logoActive : logoInactive} alt="logo-active" />
        </NavLink>
        {isFreeLesson && <div className='free-session show-web'>- - FREE SESSION - -</div>}
        <AnchorLink 
          className="box-menu" 
          href={'#section-enroll'}>
          Enroll Now
        </AnchorLink>
      </div>
    </div>
  );
}
