import React from 'react';
import './HomeStart.scss';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
//import { GetDashboardUrl } from '../../../Constant';

const Titles = [
    'Private one-on-one online coding lessons for kids',
    'Private Game Design coding lessons for kids',
    'Private one-on-one Icon Coding lessons for kids',
    'Private one-on-one online creative coding lessons for kids',
    'One-on-one MicroPython Studio coding lesson for kids',
    'One-on-one online Drone Coding Studio lessons for kids',
    'One-on-one Minecraft Modding coding lesson for kids',
    'Private Animation Studio coding lesson for kids',
    'One-on-one AR Studio coding lessons for kids',
    'Private WeDo Coding Studio lessons for kids',
    'One-on-one micro:bit Studio coding lessons for kids',
    'One-on-one online  JavaScript Coding lessons for kids',
    'One-on-one online Python Coding lessons for kids',
    'One-on-one Web Development Studio coding lessons for kids',
    'One-on-one online Python Coding II lessons for kids',
]

class HomeStart extends React.Component {
    _timer = null;
    state = {
        counting: 0,
        limit: 5,
        titleIndex: 0,
    }

    componentDidMount() {
        setTimeout(() => {
            this._timer = setInterval(() => {
                let { counting, titleIndex, limit } = this.state;
                counting++;
                if (counting === limit) {
                    counting = 0;
                    titleIndex++;
                    if (titleIndex === Titles.length) {
                        titleIndex = 1;
                    }
                }
                this.setState({ counting, titleIndex, limit });
            }, 1000);
        }, 20000);
    }

    componentWillUnmount() {
        clearInterval(this._timer);
        this._timer = null;
    }

    render() {
        const { titleIndex } = this.state;
        return (
            <div className="homestart-component">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            {Titles.map((item, index) => index === titleIndex && <h1 key={index}>{item}</h1>)}
                            <div className="sub-title">starting at age 6</div>
                            <p>Activate and nurture your student’s passion for learning and development with personalized coding lessons!</p>
                            <NavLink exact to="/start-trial" className="shadow-object box-item v-c h-c">START YOUR TRIAL</NavLink>
                            {/* {this.props.token.length === 0 ? <NavLink exact to="/start-trial" className="shadow-object box-item v-c h-c">START YOUR TRIAL</NavLink>
                                                           : <a href={GetDashboardUrl(this.props.user, this.props.token)} className="shadow-object box-item v-c h-c" target="_blank" rel="noopener noreferrer">GO TO DASHBOARD</a>} */}
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        token: state.Auth.token,
        user: state.Auth.user,
    }
}

export default connect(mapStateToProps, {})(withRouter(HomeStart));
