import { Api } from './Api';

export function* updatePlan(data){
    const jsonData = yield Api.post("/plans", data);
    return jsonData;
}

export function* getPlan() {
    const jsonData = yield Api.get("/plans");
    return jsonData;
}