import React from 'react';
import './Brand.scss';
import cnn from '../../../Assets/Home/cnn.png';
import bbc from '../../../Assets/Home/bbc.png';
import cbs from '../../../Assets/Home/cbs.png';
import nyt from '../../../Assets/Home/nyt.png';
import nytM from '../../../Assets/Home/nyt-mobile.png';
import tech from '../../../Assets/Home/techcrunch.png';
import techM from '../../../Assets/Home/techcrunch-mobile.png';
import abc from '../../../Assets/Home/abc.png';

export default class Brand extends React.Component {
    render() {
        return (
            <div className="brand-component v-r">
                <div className="brand-area">
                    <div className="container">
                        <div className="row v-c">
                            <img src={cnn} alt="brand" />
                            <img src={bbc} alt="brand" />
                            <img src={cbs} alt="brand" />
                            <img src={nyt} alt="brand" className="show-web" />
                            <img src={nytM} alt="brand" className="show-mobile" />
                            <img src={tech} alt="brand" className="show-web" />
                            <img src={techM} alt="brand" className="show-mobile" />
                            <img src={abc} alt="brand" />
                        </div>
                    </div>
                </div>               
            </div>
        )
    };
};