import React from 'react';
import './style.scss';
import { CampInfo } from './info';
import CodingItem from './CodingItem';

export default function IGeniusDetails() {

  return (
    <div className='iGenius-details-component'>
      <div className='container'>
        <h2>iGenius Coding Classes</h2>
        <h3>Register for 1:1 Free Coding Challenge Lesson To Get Details</h3>
        <div className='iGenius-details-container mx-auto'>
          {CampInfo.map((item, index)=> (
            <CodingItem 
              key={index}
              info={item}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
