import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showSpinner, hideSpinner } from 'src/Utils/Helper';
import {
  SummerCampDetailHeader,
  StateTypeLink,
  StateCategoryLink,
  StateCityLink,
  OtherStatesLink,
} from 'src/Components';
import { getSCState } from 'src/Redux/Actions';

export default function StateSCPage() {
  const dispatch = useDispatch();
  const { state } = useParams()

  const getLoadingRef = useRef(false);
  const {
    scStateInfo, get_sc_state_success, get_sc_state_loading
  } = useSelector(
    state => state.SummerCamp,
  );

  const [stateName, setStateName] = useState('')
  const [campByTypes, setCampByTypes] = useState('')
  const [campByCategories, setCampByCategories] = useState('')
  const [campByCities, setCampByCities] = useState('')

  useEffect(() => {
    setStateName(state)
    showSpinner()
    dispatch(getSCState({ state }));
  }, [dispatch, state]);

  useEffect(() => {
    if (getLoadingRef.current && !get_sc_state_loading) {
      hideSpinner();
      if (get_sc_state_success) {
        setCampByTypes(scStateInfo.types)
        setCampByCategories(scStateInfo.categories)
        setCampByCities(scStateInfo.cities)
      }
    }
    getLoadingRef.current = get_sc_state_loading;
  }, [dispatch, scStateInfo, get_sc_state_loading, get_sc_state_success]);

  const getSummary = () => {
    const summary = `
      Welcome to our ${stateName} here Summer Camp Guide & Directory 2023! 
      This guide is your ultimate resource for finding the best summer camps 
      available for your children in ${stateName} for 2023. 
      We have compiled a comprehensive list of ${stateName} summer camps catering 
      to various interests, such as sports, arts, and sciences. 
      Our guide includes important information such as camp dates, locations, 
      and pricing to make it easier for parents to choose the right summer camp 
      for their children. Get ready for a summer filled with adventure, new experiences, 
      and memories that will last a lifetime with our ${stateName} Summer Camp Guide 7 Directory 2023!
    `
    return summary
  }

  const getTags = () => {
    const tags = [
      {name: stateName, link: `/summer-camps/state/${stateName}`}
    ]
    return tags
  }

  return (
    <div className="state-summer-camp-page">
      <div className="container">
        <a href="/summer-camps">Summer Camp Guide</a> |
        <a href={`/summer-camps/state/${stateName}`}>{stateName}</a>
      </div>
      <SummerCampDetailHeader
        tags={getTags()}
        title={`${stateName} Summer Camp Guide & Directory 2023`}
        bg={scStateInfo.background_image}
      />
      <div className="container">
        <div className="state-summer-camp-content">
          <p>{getSummary()}</p>
          <StateTypeLink
            stateName={stateName}
            info={campByTypes}
          />
          <StateCategoryLink
            stateName={stateName}
            info={campByCategories}
          />

          {/* <h2>{stateName} Summer Camp Guide by Gender</h2>
          <div className="category-list row">
            {['Boys', 'Girls'].map((item, index) => {
              return <div className="col-lg-6" key={index}>{stateName} {item} Summer Camps</div>
            })}
          </div> */}

          <StateCityLink
            stateName={stateName}
            info={campByCities}
          />
          <OtherStatesLink
            stateName={stateName}
          />
        </div>
      </div>
    </div>
  );
}