import React from 'react';
import './style.scss';
import LeftIcon from 'src/Assets/IGenius/learn-icon.svg';

export default function AdviceItem({
  title,
  description
}) {

  return (
    <div className='advice-item-component shadow-object'>
      <div className='img-content' >
        <img src={LeftIcon} alt='left-icon' />
      </div>
      <div className='description-content'>
        <div className='title' >{title}</div>
        <div className='description' >{description}</div>
      </div>
    </div>
  );
}
