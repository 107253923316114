import {
    GET_CITIES, GET_CITIES_FAIL, GET_CITIES_SUCCESS,    
} from '../Type';
import _ from 'lodash'

import { takeLatest, put } from 'redux-saga/effects';
import {
    getCities as getCitiesApi,
} from '../../Api';


function* getCities(payload) {
    try {
        const result = yield getCitiesApi(payload.data);
        if (!_.isEmpty(result.data)) {
            yield put({ type: GET_CITIES_SUCCESS, data: result.data})
        } else {            
            yield put({ type: GET_CITIES_FAIL, data: result})
        }
    } catch (err) {
        yield put({ type: GET_CITIES_FAIL, data: {msg: "NET ERROR"}});
    }
}

export function* watchGetCities(){
    yield takeLatest(GET_CITIES, getCities)
}