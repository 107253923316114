import React from 'react';
import './AdsSignupPage.scss';
import logoActive from '../../Assets/Icon/logo-active.png';
import ads1 from '../../Assets/Signup/ads-1.png';
import ads2 from '../../Assets/Signup/ads-2.png';
import ads3 from '../../Assets/Signup/ads-3.png';
import ads4 from '../../Assets/Signup/ads-4.png';
import ads5 from '../../Assets/Signup/ads-5.png';
import ads6 from '../../Assets/Signup/ads-6.png';
import ads7 from '../../Assets/Signup/ads-7.png';
import ads8 from '../../Assets/Signup/ads-8.png';
import { NavLink, withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import check from '../../Assets/Icon/check.svg';
import left from '../../Assets/Icon/arrow-left.svg';
import { InputBox } from '../../Components';
import avatar from '../../Assets/Icon/avatar.svg';
import emailsvg from '../../Assets/Icon/email.svg';
import key from '../../Assets/Icon/key.svg';
import { InlineWidget } from 'react-calendly';
import { connect } from 'react-redux';
import { getAllCourses, signUp } from '../../Redux/Actions';
import CountryCodes from '../../Assets/Common/CountryCodes.json';
import moment from 'moment';
import { SCHEDULE_URL, GetDashboardUrl } from '../../Constant';

const Data = [
    {
        step: 1,
        title: 'Welcome',
        explain: `Hi! We’re iCode Edu, the #1 online academy for kids<br class="show-web"/>ages 6-18 to take online computer coding classes<br class="show-web"/>with a private instructor from a top US university.`,
        selections: [],
        btn: 'continue',
        ads_img: ads1,
    },
    {
        step: 2,
        title: "What is your child's age?",
        selections: ['6 years old', '7 years old', '8 years old', '9 years old', '10 years old', '11 years old', '12 years old', '13 years old', '+14 years old'],
        whole: false,
        state_val: 'age',
        ads_img: ads1,
    },
    {
        step: 3,
        title: "What is the student's coding experience?",
        selections: ['Beginner', 'Intermediate', 'Advanced', 'Not sure (need explanation)'],
        whole: true,
        state_val: 'experience',
        ads_img: ads2,
    },
    {
        step: 4,
        title: 'Which of these courses are you most interested in?',
        selections: ['Not sure (Let advisor decide)'],
        whole: true,
        state_val: 'course',
        ads_img: ads3,
    },
    {
        step: 5,
        title: 'Do you have a computer?',
        explain: 'Computer (PC or Mac) that your child can use for classes. All iCode Edu classes are conducted online with a private instructor',
        selections: ['Yes', 'No'],
        whole: true,
        web_whole: true,
        state_val: 'have_pc',
        ads_img: ads4,
    },
    {
        step: 6,
        title: 'When do you want to start lessons?',
        selections: ['Immediately', moment(new Date().toISOString()).format('MMMM YYYY').toString(), moment(new Date().toISOString()).add(1, 'M').format('MMMM YYYY')],
        whole: true,
        web_whole: true,
        state_val: 'start',
        ads_img: ads5,
    },
    {
        step: 7,
        title: 'Schedule evaluation',
        explain: `Great, you’re all set!Based on your answers, we suggest getting started by scheduling a free evaluation with one of iCode Edu’s enrollment directors to help determine which program is right for you and your student!`,
        selections: [],
        btn: 'schedule evaluation',
        ads_img: ads6,
    },
    {
        step: 8,
        title: 'Pick date and time',
        explain: `Pick date and time below to schedule time<br class="show-web"/> to speak with an enroll director.`,
        selections: [],
        schedule_plugin: true,
        ads_img: ads7,
        btn: 'continue',
	}
];

const Data2 = [
	{
        step: 10,
        title: 'Thank you!',
        explain: `You can now enjoy using iCodeEdu service.`,
        selections: [],
        ads_img: ads7,
        btn: 'finish',
    },
]

function innerFunc(item) {
    return {__html: item}
}

class AdsSignupPage extends React.Component {
    state = {
        step: 1,

        age: '',
        experience: '',
        course: '',
        have_pc: '',
        start: '',
        
        name: '',
        email: '',
        password: '',
        phone: '',
        country: 'US',
        student_name: '',
    }

    componentDidMount() {
        if (this.props.course_list.length === 0) {
            this.props.getAllCourses();
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.signup_success && this.props.signup_success) {
            this.setState({step: 10})
        }
        if (prevProps.signup_error_msg.length === 0 && this.props.signup_error_msg.length > 0) {
            alert(this.props.signup_error_msg);
            return;
        }
    }

    selectItem = async (field, val) => {
        await this.setState({[field]: val});
        setTimeout(() => {
            this.moveForward()
        }, 200);        
	}

    moveForward = () => {
        let { step, age, experience, course, have_pc, start, name, email, password, phone, country, student_name } = this.state;
        if (step === 9) {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (name.length === 0 || email.length === 0 || password.length === 0 || phone.length === 0 || student_name.length === 0 || !re.test(email.toLowerCase())) {
                alert("Please input valid information!");
                return;
            }

            age = age.slice(0, -10);
            if (age[0] === '+') age = age.substring(1, age.length);
            age = parseInt(age, 10);

            let course_id = 0;
            if (course.length > 0 && course !== 'Not sure (Let advisor decide)') {
                let index = this.props.course_list.findIndex(temp => temp.name === course);
                course_id = index === -1 ? 0 : this.props.course_list[index].id;
            }

            let countryIndex = CountryCodes.findIndex(temp => temp.code === country);
            let SignupParam = {
                coding_experience: experience,
                age: age,
                grade_level: 0,
                course_id: course_id,
                name: name,
                email: email,
                password: password,
                phone_number: {
                    country_code: country,
                    number: phone,
                    phone_code: countryIndex === -1 ? '' : CountryCodes[countryIndex].dial_code
                },
                student_name: student_name,
                have_computer: have_pc === 'YES' ? true : false,
                start_when: start
            }
            this.props.signUp(SignupParam);
            return;
		}
		if (step === 10) {
			let win = window.open(GetDashboardUrl(this.props.user, this.props.token), '_blank');
			if (win) win.focus();
			
            this.props.history.push('/');
			return;
		}
        this.setState({step: step + 1})
    }

    goBack = () => {
        this.setState({step: this.state.step !== 1 ? this.state.step - 1 : this.state.step});
    }

    SelectedDiv = () => <div className="selected"> <ReactSVG src={check} className="svg-icon" /> </div>

    renderStep = (Datas) => {
        const { step } = this.state;
        let Courses = Array.from(this.props.course_list, x => x.name);
        Courses.push('Not sure (Let advisor decide)')
        return Datas.map((info, dataIndex) => step === info.step && <div className="row" id={`section-${info.step}`} key={dataIndex}>
            <div className="col-lg-7 order-lg-1 order-2 left-col">
                <h1>{info.title}</h1>
                {info.explain && <p dangerouslySetInnerHTML={innerFunc(info.explain)} />}
                {info.selections.length > 0 && <div className={`item-container ${info.whole ? 'whole' : ''} ${info.web_whole ? 'web-whole' : ''}`}>
                    {step !== 4 ? info.selections.map((item, index) => <div className={`select-item ${item === this.state[info.state_val] ? 'active' : ''}`} key={index} onClick={e => this.selectItem(info.state_val, item)}>
                        {this.SelectedDiv()}
                        {item}
                    </div>) : Courses.map((item, index) => <div className={`select-item ${item === this.state[info.state_val] ? 'active' : ''}`} key={index} onClick={e => this.selectItem(info.state_val, item)}>
                        {this.SelectedDiv()}
                        {item}
                    </div>)}
                    {info.bubble && <div className="bubble h-c v-c">{info.bubble}</div>}
                </div>}
                {info.schedule_plugin && <InlineWidget styles={{ minWidth:'320px',height:'630px', marginBottom: '10px' }} url={SCHEDULE_URL} />}
                {info.btn && <button className={`btn-2 primary-btn ${info.schedule_plugin ? 'full-btn' : ''}`} onClick={e => this.moveForward()}>{info.btn}</button>}
            </div>
            <div className="col-lg-5 order-lg-2 order-1 right-col">
                <img src={info.ads_img} alt="ads" className="ads-img" />
            </div>
        </div>)
    }

    render() {
        const { step, name, email, country, password, phone, student_name } = this.state;
        return (
            <div className="adssignup-page">
                <div className="container v-r">
                    <div className="logo-area">
                        <NavLink to="/"><img src={logoActive} alt="logo" /></NavLink>
                    </div>
                    <div className="main-content v-r">
                        {this.renderStep(Data)}
                        {step === 9 && <div className="row" id="section-12">
                            <div className="col-lg-7 order-lg-1 order-2 left-col">
                                <div className="detail-info v-r">
                                    <h1>Verify your identity</h1>
                                    {/* <p>A FREE evaluation with enrollment director</p> */}
                                    {/* <h3>Parent Details</h3> */}
                                    <InputBox className="input-box" icon={avatar} placeholder="Parent Name" value={name} onChange={val => this.setState({name: val})}/>
                                    <InputBox className="input-box" icon={emailsvg} placeholder="Email" value={email} onChange={val => this.setState({email: val})}/>
                                    <InputBox className="input-box" phone_input placeholder="Phone" value={phone} country={country} onSelect={val => this.setState({country: val})} onChange={val => this.setState({phone: val})}/>
                                    <InputBox className="input-box" icon={key} placeholder="Password" type="password" value={password} onChange={val => this.setState({password: val})}/>
                                    <InputBox className="input-box" icon={avatar} placeholder="Student Name" value={student_name} onChange={val => this.setState({student_name: val})}/>
                                    <button className={`btn-2 btn-link ${this.props.signup_loading ? 'loading' : ''}`} onClick={e => this.moveForward()}>
                                        {this.props.signup_loading ? <div className="spinner" /> : 'CONTINUE'}
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-5 order-lg-2 order-1 right-col">
                                <img src={ads8} alt="ads" className="ads-img" />
                            </div>
                        </div>}
						{this.renderStep(Data2)}
                        {(step !== 1 && step !== 10 && !this.props.signup_loading) && <div className="v-c back-btn" onClick={e => this.goBack()}><ReactSVG src={left} className="svg-icon" />&nbsp;Back</div>}
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        course_list: state.Course.course_list,

        signup_loading: state.Auth.signup_loading,
        signup_success: state.Auth.signup_success,
		signup_error_msg: state.Auth.signup_error_msg,
		
		token: state.Auth.token,
        user: state.Auth.user,
    }
}

export default connect(mapStateToProps, {getAllCourses, signUp})(withRouter(AdsSignupPage));