import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SummerCampDetailHeader, SummerCampDetail } from 'src/Components';
import { getSCStateCity } from 'src/Redux/Actions';
import { showSpinner, hideSpinner } from 'src/Utils/Helper';

export default function StateCitySCPage() {
  const dispatch = useDispatch();

  const { state } = useParams()
  const { city } = useParams()

  const getLoadingRef = useRef(false);
  const {
    scStateCityInfo, get_sc_state_city_success, get_sc_state_city_loading,
    scStateInfo
  } = useSelector(
    state => state.SummerCamp,
  );

  const [stateName, setStateName] = useState('')
  const [cityName, setCityName] = useState('')
  const [camps, setCamps] = useState('')

  useEffect(() => {
    showSpinner()
    setStateName(state);
    setCityName(city);
    dispatch(getSCStateCity({ state, city }));
  }, [dispatch, state, city]);

  useEffect(() => {
    if (getLoadingRef.current && !get_sc_state_city_loading) {
      hideSpinner();
      if (get_sc_state_city_success) {
        setCamps(scStateCityInfo)
      }
    }
    getLoadingRef.current = get_sc_state_city_loading;
  }, [dispatch, scStateCityInfo, get_sc_state_city_loading, get_sc_state_city_success]);

  const getSummary = () => {
    const summary = `
      Are you looking for the perfect summer camp for your child in ${cityName}? 
      Our comprehensive guide features the best summer camps in the area for 2023. 
      From day camps to overnight camps, we have researched and handpicked camps 
      that cater to kids of all ages and interests. Our guide includes camp details, 
      locations, and pricing to help parents find the right camp for their children. 
      Whether your child loves sports, music, technology, or nature, 
      our ${cityName} Summer Camp Guide 2023 has something for everyone. 
      Get ready for a summer of adventure, learning, 
      and memories that will last a lifetime with our ${cityName} Summer Camp Guide 2023.
    `
    return summary
  }

  const getTags = () => {
    const tags = [
      { name: stateName, link: `/summer-camps/state/${stateName}` },
      { name: cityName, link: `/summer-camps/state/${stateName}/city/${cityName}` },
    ]
    return tags
  }

  return (
    <div className="state-city-summer-camp-page">
      <div className="container">
        <a href="/summer-camps">Summer Camp Guide</a> |
        <a href={"/summer-camps/state/" + stateName}>{stateName}</a> |
        <a href={`/summer-camps/state/${stateName}/city/${cityName}`}>{cityName}</a>
      </div>
      <SummerCampDetailHeader
        tags={getTags()}
        title={`2023 Guide For ${cityName} Summer Camps Near Me For Kids, Kindergarten - Teen`}
      />
      {camps && <SummerCampDetail
        camps={camps}
        summary={getSummary()}
        isStateCity
        cityName={cityName}
        stateName={stateName}
        scStateInfo={scStateInfo}
      />}
    </div>
  );
}