import {
  GET_SC_STATE, GET_SC_STATE_SUCCESS, GET_SC_STATE_FAIL,
  GET_SC_STATE_TYPE, GET_SC_STATE_TYPE_SUCCESS, GET_SC_STATE_TYPE_FAIL,
  GET_SC_STATE_CITY, GET_SC_STATE_CITY_SUCCESS, GET_SC_STATE_CITY_FAIL,
  GET_SC_STATE_CATEGORY, GET_SC_STATE_CATEGORY_SUCCESS, GET_SC_STATE_CATEGORY_FAIL,
  GET_SC_STATE_CITY_TYPE, GET_SC_STATE_CITY_TYPE_SUCCESS, GET_SC_STATE_CITY_TYPE_FAIL,
  GET_SC_STATE_CITY_CATEGORY, GET_SC_STATE_CITY_CATEGORY_SUCCESS, GET_SC_STATE_CITY_CATEGORY_FAIL,
  CAMP_SIGNUP, CAMP_SIGNUP_SUCCESS, CAMP_SIGNUP_FAIL,
  CAMP_HUBSPOT, CAMP_HUBSPOT_SUCCESS, CAMP_HUBSPOT_FAIL,
} from '../Type';

const INITIAL = {
  get_sc_state_loading: true,
  get_sc_state_success: false,
  scStateInfo: [],

  get_sc_state_type_loading: true,
  get_sc_state_type_success: false,
  scStateTypeInfo: [],

  get_sc_state_city_loading: true,
  get_sc_state_city_success: false,
  scStateCityInfo: [],

  get_sc_state_category_loading: true,
  get_sc_state_category_success: false,
  scStateCategoryInfo: [],

  get_sc_state_city_type_loading: true,
  get_sc_state_city_type_success: false,
  scStateCityTypeInfo: [],

  get_sc_state_city_category_loading: true,
  get_sc_state_city_category_success: false,
  scStateCityCategoryInfo: [],

  token: '',
  user: {},
  camp_signup_res: '',
  camp_signup_loading: true,
  camp_signup_success: false,

  camp_hubspot_res: '',
  camp_hubspot_loading: true,
  camp_hubspot_success: false,
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * GET Summer Camp State
     */
    case GET_SC_STATE: {
      return {
        ...state,
        get_sc_state_loading: true,
        get_sc_state_success: true,
        scStateInfo: []
      }
    }
    case GET_SC_STATE_FAIL: {
      return {
        ...state,
        get_sc_state_loading: false,
        get_sc_state_success: false,
        scStateInfo: []
      }
    }
    case GET_SC_STATE_SUCCESS: {
      return {
        ...state,
        get_sc_state_loading: false,
        get_sc_state_success: true,
        scStateInfo: action.data
      }
    }

    /**
     * GET Summer Camp Type
     */
    case GET_SC_STATE_TYPE: {
      return {
        ...state,
        get_sc_state_type_loading: true,
        get_sc_state_type_success: true,
        scStateTypeInfo: []
      }
    }
    case GET_SC_STATE_TYPE_FAIL: {
      return {
        ...state,
        get_sc_state_type_loading: false,
        get_sc_state_type_success: false,
        scStateTypeInfo: []
      }
    }
    case GET_SC_STATE_TYPE_SUCCESS: {
      return {
        ...state,
        get_sc_state_type_loading: false,
        get_sc_state_type_success: true,
        scStateTypeInfo: action.data
      }
    }

    /**
     * GET Summer Camp City
     */
    case GET_SC_STATE_CITY: {
      return {
        ...state,
        get_sc_state_city_loading: true,
        get_sc_state_city_success: true,
        scStateCityInfo: []
      }
    }
    case GET_SC_STATE_CITY_FAIL: {
      return {
        ...state,
        get_sc_state_city_loading: false,
        get_sc_state_city_success: false,
        scStateCityInfo: []
      }
    }
    case GET_SC_STATE_CITY_SUCCESS: {
      return {
        ...state,
        get_sc_state_city_loading: false,
        get_sc_state_city_success: true,
        scStateCityInfo: action.data
      }
    }

    /**
     * GET Summer Camp Category
     */
    case GET_SC_STATE_CATEGORY: {
      return {
        ...state,
        get_sc_state_category_loading: true,
        get_sc_state_category_success: true,
        scStateCategoryInfo: []
      }
    }
    case GET_SC_STATE_CATEGORY_FAIL: {
      return {
        ...state,
        get_sc_state_category_loading: false,
        get_sc_state_category_success: false,
        scStateCategoryInfo: []
      }
    }
    case GET_SC_STATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        get_sc_state_category_loading: false,
        get_sc_state_category_success: true,
        scStateCategoryInfo: action.data
      }
    }

    /**
     * GET Summer Camp State City Type
     */
    case GET_SC_STATE_CITY_TYPE: {
      return {
        ...state,
        get_sc_state_city_type_loading: true,
        get_sc_state_city_success: true,
        scStateCityTypeInfo: []
      }
    }
    case GET_SC_STATE_CITY_TYPE_FAIL: {
      return {
        ...state,
        get_sc_state_city_type_loading: false,
        get_sc_state_city_type_success: false,
        scStateCityTypeInfo: []
      }
    }
    case GET_SC_STATE_CITY_TYPE_SUCCESS: {
      return {
        ...state,
        get_sc_state_city_type_loading: false,
        get_sc_state_city_type_success: true,
        scStateCityTypeInfo: action.data
      }
    }

    /**
     * GET Summer Camp State City Category
     */
    case GET_SC_STATE_CITY_CATEGORY: {
      return {
        ...state,
        get_sc_state_city_category_loading: true,
        get_sc_state_city_category_success: true,
        scStateCityCategoryInfo: []
      }
    }
    case GET_SC_STATE_CITY_CATEGORY_FAIL: {
      return {
        ...state,
        get_sc_state_city_category_loading: false,
        get_sc_state_city_category_success: false,
        scStateCityCategoryInfo: []
      }
    }
    case GET_SC_STATE_CITY_CATEGORY_SUCCESS: {
      return {
        ...state,
        get_sc_state_city_category_loading: false,
        get_sc_state_city_category_success: true,
        scStateCityCategoryInfo: action.data
      }
    }

    /**
     * Camp Signup
     */
    case CAMP_SIGNUP: {
      return {
        ...state,
        camp_signup_loading: true,
        camp_signup_success: false,
        camp_signup_res: '',
        token: '',
        user: {},
      }
    }
    case CAMP_SIGNUP_SUCCESS: {
      const { token, user } = action.data;
      sessionStorage.setItem('token', token);
      return {
        ...state,
        camp_signup_loading: false,
        camp_signup_success: true,
        camp_signup_res: '',
        token: token,
        user: user,
      }
    }
    case CAMP_SIGNUP_FAIL: {
      return {
        ...state,
        camp_signup_loading: false,
        camp_signup_success: false,
        camp_signup_res: action.data.msg,
      }
    }

    /**
     * Summer Camp Hubspot
     */
     case CAMP_HUBSPOT: {
      return {
        ...state,
        camp_hubspot_loading: true,
        camp_hubspot_success: false,
        camp_hubspot_res: '',
      }
    }
    case CAMP_HUBSPOT_FAIL: {
      return {
        ...state,
        camp_hubspot_loading: false,
        camp_hubspot_success: false,
        camp_hubspot_res: action.data.message,
      }
    }
    case CAMP_HUBSPOT_SUCCESS: {
      return {
        ...state,
        camp_hubspot_loading: false,
        camp_hubspot_success: true,
        camp_hubspot_res: action.data.message,
      }
    }

    default:
      return state;
  }
}