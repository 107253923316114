import React from 'react';
import './OurFun.scss';
import blox from '../../../Assets/StudentSignup/OurFun/blox.png';
import mix from '../../../Assets/StudentSignup/OurFun/mix.png';
import text from '../../../Assets/StudentSignup/OurFun/text.png';

const WorkBlocks = [
    { id: 1, img: blox, title: 'Blox', description: 'All our students start coding in BLOX mode where they can build cool games, using colorful digital blocks. This is the easiest way to learn the basics of programming and acquire analytical thinking skills.' },
    { id: 2, img: mix, title: 'Mix', description: 'In MIX mode, we bring the acquired knowledge to the next level. The well-known blocks will stay with the students, but children will also be able to see, interpret, and edit the written codes of their programs.' },
    { id: 3, img: text, title: 'Text', description: 'After two semesters in MIX mode, our students can leave the blocks behind and start programming only with written code (StageScript)' },
]

export default class OurFun extends React.Component {
    render() {
        return (
            <div className="ourfun-component v-r">
                <div className="container v-r">
                    <h1>Our Fun Curriculum Road Map</h1>
                    <div className="row">
                        {WorkBlocks.map((item, index) => <div className="col-lg-4 sub-col v-c h-c" key={index}>
                            <div className="work-block">
                                <div className="show-web-flex v-c h-c circle number-outter">
                                    <div className="v-c h-c circle number-in">{item.id}</div>
                                </div>
                                <div className="work-content v-r v-c">
                                    <img src={item.img} alt="work-img" />
                                    <h2>{item.title}</h2>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        )
    };
};