import React, { useState, useEffect } from 'react';
import './style.scss';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import calendar from 'src/Assets/Icon/calendar-red-icon.svg';
import clock from 'src/Assets/Icon/clock-icon.svg';
import plus from 'src/Assets/Icon/plus-green-icon.svg';
import minus from 'src/Assets/Icon/minus-green-icon.svg';
import { setCampCartInfo, } from 'src/Redux/Actions';
import PriceContent from '../PriceContent';

export default function ScPlan() {
  const dispatch = useDispatch();

  const {
    campCartInfo,
  } = useSelector(
    state => state.Course,
  );

  const [campLength, setCampLength] = useState(campCartInfo.length);

  useEffect(() => {
    let _campLength = 0
    campCartInfo.map((item, index) => {
      _campLength += item.count
    })
    setCampLength(_campLength);
  }, [campCartInfo]);

  const onChangeCount = (index, isPlus) => {
    const newCampItem = {
      ...campCartInfo[index],
      count: isPlus ? campCartInfo[index].count + 1 : campCartInfo[index].count > 1 ? campCartInfo[index].count - 1 : campCartInfo[index].count
    }
    campCartInfo[index] = newCampItem
    dispatch(setCampCartInfo([
      ...campCartInfo
    ]));
  }

  return (
    <div className="sc-plan-component">
      <PriceContent
        campLength={campLength}
      />
      {!_.isEmpty(campCartInfo) && <div className='camp-list-content'>
        {campCartInfo.map((item, index) => (
          <div className='camp-item' key={index}>
            <div className='camp-detail-content'>
              <div className='camp-detail'>
                <div className='name-age'>{`${item.name} | Age ${item.minAge} ${item.minAge <13 ? `- ${item.maxAge}` : "+"}`}</div>
                <div className='v-c'>
                  <img
                    src={calendar}
                    alt='calendar-icon'
                    className='time-icon'
                  />
                  <div className='date-time'>{`${item.date}`}</div>
                </div>
                <div className='v-c'>
                  <img
                    src={clock}
                    alt='clock-icon'
                    className='time-icon'
                  />
                  <div className='date-time'>{`${item.time}(${item.timezone})`}</div>
                </div>
              </div>
            </div>
            <div className='camp-count-control'>
              <div className='camp-count center'>{item.count}</div>
              <div className='count-btn-section v-c'>
                <img
                  src={plus}
                  alt='toggle-icon'
                  className='count-btn'
                  onClick={() => onChangeCount(index, true)}
                />
                <img
                  src={minus}
                  alt='toggle-icon'
                  className='count-btn'
                  onClick={() => onChangeCount(index, false)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>}
    </div>
  );
}
