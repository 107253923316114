import React from 'react';
import './WelcomeHeader.scss';

export default class WelcomeHeader extends React.Component {
  render() {
    const { isWelcomeDescription } = this.props;

    return (
      <div className="welcome-component">
        <div className="welcome-tutor">
          <div className={`welcome-header ${isWelcomeDescription ? null : 'gradient-header'}`}>
            <div className="welcome-title">
              <h6>
                Become An iCode Edu Instructor (Blox code -Mix code - Pure code)
              </h6>
              <p>
                Are you passionate about education,
                do you love working with kids,
                iCode Edu provides one-on-one coding lessons to students ages 7 - 18 globally. All lessons are taught online using digital tools and curriculum provided by iCode Edu.
              </p>
              {isWelcomeDescription && (
                <div>
                  <p>
                    The starting pay for instructors is $20/hr and is subject to
                    increase based on experience. We've designed the instructor
                    role to be a flexible part-time position. Instructors can set
                    their availability and generally work with their students at a regular time,
                    once a week. We seek instructors that can commit at least 5 hours of
                    teaching per week. Each lesson last for 50 minutes over a 6 months Period.
                  </p>
                  <p>
                    Our students are located both in the US and around the world so Instructors can work
                    a 24 hrs calendar depending on their availability.
                  </p>
                </div>
              )}
            </div>
          </div>
          {isWelcomeDescription && <div>
            <div className="welcome-over-view mt-5">
              <h6>Quick overview on how it works:</h6>
            </div>
            <p>
              1. We provide training on how to use our curriculum. It’s designed for kids at different age groups and academic levels. It allows kids to progress from blox code to mix code and finally to pure code. It’s one of a kind curriculum license from the largest coding school in Europe.
            </p>
            <p>
              2. You’re assigned students based on your availability and you can work with as many students as you’re available
            </p>
            <p>
              3. Each student enrolled in the course and assigned to you is required to complete one 1-on-1 online lesson with you per week for a period of six months. It’s, for this reason, we need a minimum of six months commitment from you for each new student you accept to teach.
            </p>
            <p>
              4. Students will create a final project based on what they have learned and you would also have the opportunity to present them with a certificate of completion.
            </p>

            <div className='mt-5'>
              <h6>REQUIREMENTS</h6>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>Instructors will use the curriculum and teaching tools developed by iCode Edu. Each course is 50 minutes long, taught online, and may be a private (1-1) or small group class (maximum 4 students).</p>
              </div>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>
                  We look for instructors with a great attitude and desire to help kids build resilience and grit alongside their coding skills.
                </p>
              </div>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>
                  Treat all students and parents with respect.
                </p>
              </div>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>
                  Foster a safe community and act in accordance with a high standard of ethics while maintaining family and student confidentiality/ privacy.
                </p>
              </div>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>
                  Take initiative to solve or anticipate problems early or refer to relevant Icode employees.
                </p>
              </div>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>
                  Flexibility to changing needs, and ability to collaborate with co-workers for any issues that may arise.
                </p>
              </div>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>
                  Serve as an ambassador for iCode Edu and maintain high-quality teaching and professionalism.
                </p>
              </div>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>
                  Promote our values and mission, and serve as a role model for kids of all backgrounds and ages.
                </p>
              </div>
            </div>

            <div className='mt-5'>
              <h6>QUALIFICATIONS</h6>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>
                  Beginner courses do not require instructors to have a Computer Science (CS) background but intermediate and advanced courses require College-level coursework in computer science (at minimum completion of one CS course or equivalent).
                </p>
              </div>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>
                  Instructors will be assigned different students and courses to teach based on their experience.
                </p>
              </div>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>
                  Experience working with young students and track record in improving student outcomes.
                </p>
              </div>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>
                  Passion for building interest in computer science in kids and teenagers (ages 7-18).
                </p>
              </div>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>
                  Teaching a minimum of 5 hours per week and a commitment of at least 6 months.
                </p>
              </div>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>
                  Ability to work well with students of different ages.
                </p>
              </div>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>
                  Strong communication skills, especially with families (both children and adults).
                </p>
              </div>
              <div className="requirements-row">
                <p className='mr-2'>•</p>
                <p>
                  Must be authorized to work in the US .
                </p>
              </div>
            </div>
          </div>}
        </div>
      </div>
    );
  }
}