import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SCSignupContainer } from 'src/Components';
import StudentInfoForms from './StudentInfoForms';
import ParentForms from './ParentForms';
import {
  NameValidation,
  EmailValidation,
  PasswordValidation,
  PhoneValidation,
} from 'src/Utils/Validation';
import { showSpinner, hideSpinner, showToast } from 'src/Utils/Helper';
import { campSignUp, campHubspot } from 'src/Redux/Actions';
import { getCountryCode } from 'src/Utils/Other';

export default function SCRegistrationPage() {
  let history = useHistory();
  const dispatch = useDispatch();

  const getLoadingRef = useRef(false);
  const getHubspotLoadingRef = useRef(false);

  const {
    levels,
  } = useSelector(
    state => state.Levels,
  );

  const {
    user, token, camp_signup_res, camp_signup_success, camp_signup_loading,
    camp_hubspot_res, camp_hubspot_success, camp_hubspot_loading,
  } = useSelector(
    state => state.SummerCamp,
  );

  const {
    campCartInfo,
  } = useSelector(
    state => state.Course,
  );

  const {
    referral_code
  } = useSelector(
    state => state.Other,
  );

  const [parentInfo, setParentInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    country: 'US',
    firstNameValid: '',
    lastNameValid: '',
    emailValid: '',
    passwordValid: '',
    phoneValid: '',
  });

  const [studentNumber, setStudentNumber] = useState(1);
  const [student1Info, setStudent1Info] = useState({
    firstName: '',
    lastName: '',
    age: '',
    gradeLevel: {
      id: "",
      name: ""
    },
  });
  const [student2Info, setStudent2Info] = useState({
    firstName: '',
    lastName: '',
    age: '',
    gradeLevel: {
      id: "",
      name: ""
    },
  });
  const [student3Info, setStudent3Info] = useState({
    firstName: '',
    lastName: '',
    age: '',
    gradeLevel: {
      id: "",
      name: ""
    },
  });

  //Camp Registration
  useEffect(() => {
    if (getLoadingRef.current && !camp_signup_loading) {
      if (camp_signup_success) {
        // showToast('success', 3000, camp_signup_res);
        // history.push('/summer-camp-payment');
        onHubspot()
      } else {
        hideSpinner();
        showToast('error', 3000, camp_signup_res);
      };
    }
    getLoadingRef.current = camp_signup_loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camp_signup_loading, camp_signup_success, camp_signup_res, user, token, history]);

  //Camp HubSpot
  useEffect(() => {
    if (getHubspotLoadingRef.current && !camp_hubspot_loading) {
      hideSpinner();
      if (camp_hubspot_success) {
        showToast('success', 3000, camp_hubspot_res);
        history.push('/summer-camp-payment');
      } else {
        showToast('error', 3000, camp_hubspot_res);
      };
    }
    getHubspotLoadingRef.current = camp_hubspot_loading;
  }, [camp_hubspot_loading, camp_hubspot_success, camp_hubspot_res, history]);

  const onHubspot = () => {
    const payload = {
      first_name: `${parentInfo.firstName} ${parentInfo.lastName}`,
      last_name: '',
      email: parentInfo.email,
      phone: parentInfo.phone,
      camps: campCartInfo,
      camp_enrollment_status: 'Account Creation',
      contact_type: 'Camp Lead',
      country: parentInfo.country,
      country_code: getCountryCode(parentInfo.country),
      student_first_name: `${student1Info.firstName} ${student1Info.lastName}`,
      student_last_name: '',
      age_range: student1Info.age,
      grade_level: student1Info.gradeLevel.name,
    }
    console.log("===payload===", payload)
    dispatch(campHubspot(payload));
  }

  const onChangeParentInfo = (field, validationField, value) => {
    setParentInfo({
      ...parentInfo,
      [field]: value,
      [validationField]: getValidation(field, value),
    });
  };

  const getValidation = (field, value) => {
    if (field === 'firstName' || field === 'lastName')
      return NameValidation(value);
    if (field === 'email') return EmailValidation(value);
    if (field === 'password') return PasswordValidation(value);
    if (field === 'phone') return PhoneValidation(value);
    return !!value;
  };

  const onChangeStudent1Info = (field, validationField, value) => {
    setStudent1Info({
      ...student1Info,
      [field]: value,
      [validationField]: getValidation(field, value),
    });
  };

  const onChangeStudent2Info = (field, validationField, value) => {
    setStudent2Info({
      ...student2Info,
      [field]: value,
      [validationField]: getValidation(field, value),
    });
  };

  const onChangeStudent3Info = (field, validationField, value) => {
    setStudent3Info({
      ...student3Info,
      [field]: value,
      [validationField]: getValidation(field, value),
    });
  };

  const onAddMoreStudents = () => {
    if (studentNumber <= 3)
      setStudentNumber(studentNumber + 1)
  }

  const onRemoveStudent = () => {
    if (studentNumber > 1)
      setStudentNumber(studentNumber - 1)
  }

  const checkDisableBtn = () => {
    if (
      !_.isEmpty(campCartInfo) &&
      NameValidation(parentInfo.firstName) &&
      NameValidation(parentInfo.lastName) &&
      EmailValidation(parentInfo.email) &&
      PasswordValidation(parentInfo.password) &&
      PhoneValidation(parentInfo.phone) &&

      NameValidation(student1Info.firstName) &&
      NameValidation(student1Info.lastName) &&
      student1Info.age &&
      student1Info.gradeLevel.name
    ) {
      if (studentNumber === 1) return false
      if (studentNumber === 2) {
        if (NameValidation(student2Info.firstName) &&
          NameValidation(student2Info.lastName) &&
          student2Info.age &&
          student2Info.gradeLevel.name
        ) return false
      }
      if (studentNumber === 3) {
        if (NameValidation(student3Info.firstName) &&
          NameValidation(student3Info.lastName) &&
          student3Info.age &&
          student3Info.gradeLevel.name
        ) return false
      }
    }
    return true;
  }

  const onContinue = () => {
    const students1 =
    {
      first_name: student1Info.firstName,
      last_name: student1Info.lastName,
      age: student1Info.age.split(" ")[0],
      grade_level: student1Info.gradeLevel.id
    }
    const students2 =
    {
      first_name: student2Info.firstName,
      last_name: student2Info.lastName,
      age: student2Info.age.split(" ")[0],
      grade_level: student2Info.gradeLevel.id
    }
    const students3 =
    {
      first_name: student3Info.firstName,
      last_name: student3Info.lastName,
      age: student3Info.age.split(" ")[0],
      grade_level: student3Info.gradeLevel.id
    }

    const studentsInfo =
      studentNumber === 1 ? [students1] :
        studentNumber === 2 ? [students1, students2] : [students1, students2, students3]

    const payload = {
      first_name: parentInfo.firstName,
      last_name: parentInfo.lastName,
      email: parentInfo.email,
      password: parentInfo.password,
      phone: parentInfo.phone,
      country: parentInfo.country,
      country_code: getCountryCode(parentInfo.country),
      camps: campCartInfo,
      students: studentsInfo,
      referrer_code: referral_code || ''
    }
    if (!checkDisableBtn()) {
      showSpinner();
      dispatch(campSignUp(payload));
    }
  };

  return (
    <SCSignupContainer
      title={'Complete Camp Registration'}
      className={'sc-registration-page'}
      isBack
    >
      <div className=''>
        <h3>Parent Information</h3>
        <ParentForms
          parentInfo={parentInfo}
          onChangeParentInfo={onChangeParentInfo}
        />

        <h3 className='mt-5'>Students Information</h3>
        {!_.isEmpty(levels) && (
          <>
            <StudentInfoForms
              levels={levels}
              studentNumber={1}
              studentInfo={student1Info}
              onChangeStudentInfo={onChangeStudent1Info}
              onRemove={onRemoveStudent}
            />
            {studentNumber > 1 && <StudentInfoForms
              className='mt-5'
              studentNumber={2}
              levels={levels}
              studentInfo={student2Info}
              onChangeStudentInfo={onChangeStudent2Info}
              onRemove={onRemoveStudent}
            />}
            {studentNumber > 2 && <StudentInfoForms
              className='mt-5'
              studentNumber={3}
              levels={levels}
              studentInfo={student3Info}
              onChangeStudentInfo={onChangeStudent3Info}
              onRemove={onRemoveStudent}
            />}
          </>
        )}

        {studentNumber < 3 &&
          <div
            className='btn-2 add-more-btn center'
            onClick={onAddMoreStudents}>
            Add More Students
          </div>
        }
        <div
          className={`btn-2 center continue-btn ${checkDisableBtn() ? 'disable' : ''}`}
          onClick={onContinue}>
          CONTINUE
        </div>
      </div>
    </SCSignupContainer >
  );
}