import HeaderIcon1 from 'src/Assets/IGenius/header-age-7-8.svg';
import HeaderIcon2 from 'src/Assets/IGenius/header-age-9-10.svg';
import HeaderIcon3 from 'src/Assets/IGenius/header-age-11-12.svg';
import HeaderIcon4 from 'src/Assets/IGenius/header-age-13.svg';
import FooterIcon1 from 'src/Assets/IGenius/footer-age-7-8.svg';
import FooterIcon2 from 'src/Assets/IGenius/footer-age-9-10.svg';
import FooterIcon3 from 'src/Assets/IGenius/footer-age-11-12.svg';
import FooterIcon4 from 'src/Assets/IGenius/footer-age-13.svg';

export const CampInfo = [
  {
    id: "1",
    headerIcon: HeaderIcon1,
    footerIcon: FooterIcon1,
    listInfo: [
      { id: "1", name: "Minecraft Edu", categoryId: "1" },
      { id: "2", name: "Coding Music", categoryId: "2" },
      { id: "3", name: "Digital Art(2D)", categoryId: "2" },
      { id: "4", name: "Storytelling", categoryId: "2" },
    ]
  },
  {
    id: "2",
    headerIcon: HeaderIcon2,
    footerIcon: FooterIcon2,
    listInfo: [
      { id: "1", name: "Minecraft Edu", categoryId: "1" },
      { id: "2", name: "Game Design", categoryId: "2" },
      { id: "3", name: "Roblox Studio", categoryId: "3" },
      { id: "4", name: "Web Design \n (ChatGPT)", categoryId: "3" },
      { id: "5", name: "A.I. (blocks)", categoryId: "4" },
      { id: "6", name: "Crypto Builders", categoryId: "6" },
    ]
  },
  {
    id: "3",
    headerIcon: HeaderIcon3,
    footerIcon: FooterIcon3,
    listInfo: [
      { id: "1", name: "Digital Art 3D", categoryId: "3" },
      { id: "2", name: "Virtual Reality", categoryId: "3" },
      { id: "3", name: "Robolox Studio", categoryId: "3" },
      { id: "4", name: "Web Design \n (ChatGPT)", categoryId: "3" },
      { id: "5", name: "A.I. (...+ ChatGPT)", categoryId: "4" },
      { id: "5", name: "ChatGPT Start-Up", categoryId: "5" },
      { id: "6", name: "Crypto Builders", categoryId: "6" },
      { id: "7", name: "Python Beginner", categoryId: "7" },
      { id: "8", name: "Python Intermediate", categoryId: "7" },
    ]
  },
  {
    id: "4",
    headerIcon: HeaderIcon4,
    footerIcon: FooterIcon4,
    listInfo: [
      { id: "1", name: "Digital Art 3D", categoryId: "3" },
      { id: "2", name: "Cybersecurity", categoryId: "3" },
      { id: "3", name: "3D Game Design \n (Unity)", categoryId: "3" },
      { id: "4", name: "Web Design \n (ChatGPT)", categoryId: "3" },
      { id: "5", name: "A.I. (...+ Python)", categoryId: "4" },
      { id: "5", name: "ChatGPT Start-Up", categoryId: "5" },
      { id: "6", name: "Crypto Builders", categoryId: "6" },
      { id: "7", name: "Python Beginner", categoryId: "7" },
      { id: "8", name: "Python Intermediate", categoryId: "7" },
      { id: "8", name: "Python Advance", categoryId: "7" },
      { id: "8", name: "Java", categoryId: "8" },
      { id: "8", name: "Java Script Beginner", categoryId: "8" },
      { id: "8", name: "Java Script Intermediate", categoryId: "8" },
      { id: "8", name: "Java Script Advance", categoryId: "8" },
      { id: "8", name: "AP Computer Science", categoryId: "9" },
      { id: "8", name: "Virtual Reality", categoryId: "10" },
    ]
  },
]