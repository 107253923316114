export const ReviewsInfo = [
  { 
    id: "1", 
    rating: 5, 
    student: { 
      country_code: 'US', 
      name: "Karen G.", 
    }, 
    review_content: "The final project presentations at iCode iGenius Coding were such a highlight for my son. He had worked so hard on his project, and it was amazing to see him present it to the instructors and his peers. The virtual format was really impressive too. It didn't feel like he was missing out on anything by attending online, and the instructors were able to provide him with feedback and support just like they would in person. We were so impressed by the level of creativity and innovation that the students demonstrated in their final projects. It was a fantastic way to wrap up a fun and engaging coding experience." 
  },
  { 
    id: "2", 
    rating: 5, 
    student: { 
      country_code: 'US', 
      name: "Michelle T.", 
    }, 
    review_content: "My son, had an absolute blast at iCode iGenius Coding. At first, he was a little hesitant because it was virtual, but the instructors made it engaging and fun. The small group setting made it easy for him to connect with other kids who shared his interests, and he was able to learn coding skills that he still uses. We were impressed by the caliber of the instructors from top US universities like MIT and Stanford. They were so knowledgeable and made coding approachable for kids like my son. We recommend iCode iGenius Coding to other parents looking for a fun and educational summer activity." 
  },
  { 
    id: "3", 
    rating: 5, 
    student: { 
      country_code: 'US', 
      name: "Peter W.", 
    }, 
    review_content: "My daughter, Charlotte, attended iCode iGenius Coding last year, and she had such a great time. It was her first time learning to code, and she was a little nervous, but the instructors made her feel comfortable and supported. She loved being able to work with other kids from all over the world and learn from experts at top US universities like Yale and Columbia. The small group setting was perfect for her because she was able to get individual attention from her instructors and connect with other kids who were at her skill level. We're so glad we found iCode iGenius Coding and would recommend it to anyone looking for a fun and engaging way to learn coding." 
  },
  { 
    id: "4", 
    rating: 5, 
    student: { 
      country_code: 'US', 
      name: "Vivian A.", 
    }, 
    review_content: "The small group format at iCode iGenius Coding allowed my son to receive personalized attention and support from his instructors. He loved every moment of it." 
  },
  { 
    id: "5", 
    rating: 5, 
    student: { 
      country_code: 'US', 
      name: "Adeola Z.", 
    }, 
    review_content: "The instructors at iCode iGenius Coding were knowledgeable and supportive. My daughter felt empowered to tackle coding challenges and learn new skills." 
  },
  { 
    id: "6", 
    rating: 5, 
    student: { 
      country_code: 'US', 
      name: "John A.", 
    }, 
    review_content: "The iCode iGenius Coding was an amazing experience for my son, Ethan. He's always been interested in coding. This was the perfect opportunity for him to explore that interest further. We were blown away by the caliber of the instructors, who were all from top US universities like MIT and Stanford. They made coding fun and approachable, and my son was able to learn skills that he's already using in his own coding projects. The virtual format was really impressive too. It didn't feel like he was missing out on anything by attending online, and he loved being able to connect with other kids from around the world. We highly recommend iCode iGenius Coding to other parents and kids." 
  },
  { 
    id: "7", 
    rating: 5, 
    student: { 
      country_code: 'US', 
      name: "Katherine S.", 
    }, 
    review_content: "My daughter, attended iCode iGenius Coding, and we couldn't have been happier with the experience. The instructors were so engaging and supportive, and they really took the time to get to know each student and their unique learning needs. The small group setting was perfect for Brianna because she was able to get individual attention and connect with other kids who shared her interests. She loved being able to learn coding from experts at top US universities like TCU and Columbia, and she made so many new friends from all over the world. We're grateful for the opportunity that iCode iGenius Coding provided and would definitely recommend it to other parents." 
  },
  { 
    id: "8", 
    rating: 5, 
    student: { 
      country_code: 'US', 
      name: "Lauren H.", 
    }, 
    review_content: "Our son loved every moment of iCode iGenius Coding. He's always been interested in technology, and this was the perfect way for him to explore that interest further. The instructors were knowledgeable and engaging, and they made coding fun and approachable. George loved working with kids from all over the world, and the small group setting made it easy for him to connect with other students and learn from them. We were really impressed by the quality of the virtual format too. It felt like he was there in person, even though he attended from home. We would definitely recommend iCode iGenius Coding to other parents." 
  },
  { 
    id: "9", 
    rating: 5, 
    student: { 
      country_code: 'US', 
      name: "Sarah L.", 
    }, 
    review_content: "The iCode iGenius Coding was a game-changer for my son, Thomas. He's always loved technology, and this was the perfect way for him to explore coding in a fun and engaging way. The instructors were amazing, and they made coding approachable and exciting for him. The small group setting was perfect because he was able to get individual attention and connect with other kids who shared his interests. We're so grateful for the opportunity that iCode iGenius Coding provided and would recommend it to anyone looking for a fun and educational summer activity." 
  },
  { 
    id: "10", 
    rating: 5, 
    student: { 
      country_code: 'US', 
      name: "Nicole J.", 
    }, 
    review_content: "The personalized attention that my son, received at iCode iGenius Coding was truly exceptional. The instructors took the time to understand his strengths and weaknesses and helped him excel in coding. He loved being able to work with other kids who shared his interests, and the small group setting made it easy for him to connect with them. The virtual format was really impressive too. It didn't feel like he was missing out on anything by attending online. We would highly recommend iCode iGenius Coding to other parents and kids." 
  },
  { 
    id: "11", 
    rating: 5, 
    student: { 
      country_code: 'US', 
      name: "Laura D.", 
    }, 
    review_content: "The iCode iGenius Coding provided Peter our son with an exceptional introduction to the world of coding. He loved being able to work with other kids and learn from instructors who were experts in their field. The small group format was perfect for him because it allowed him to get individual attention and connect with other students who were at his skill level. The virtual format was really impressive too. It felt like he was there in person, even though he was attending from home. We would highly recommend iCode iGenius Coding to other parents and kids." 
  },
]