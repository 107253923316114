import React from 'react';
import './style.scss';
import { SummerCampStartArea } from 'src/Components';
import USStates from 'src/Assets/Common/usstates.json';

const description = `
  Welcome to our Best Summer Camp Guide for 2023! Many families are beginning to plan their children's summer activities as summer approaches, and we are here to help. Our guide provides a comprehensive list of the best summer camps available for children of all ages and interests. 
  
  Whether your child is interested in sports, art, music, or science, we have something for everyone. Our team has carefully researched and handpicked the top summer camps nationwide to ensure your child has the best summer camp experience possible.
  
  Our guide includes a detailed camp description and essential information such as camp dates, pricing, and location. We understand that choosing the right summer camp can be overwhelming, so we have created this guide to make the process easier for parents and children. 
  
  We hope our Best Summer Camp Guide for 2023 will be a valuable resource for families looking to make the most of their summer vacation. Get ready to create unforgettable memories and experience new adventures at the best summer camps of 2023!
`

export default function SummerCampPage() {

  return (
    <div className="summer-camp-page">
      <SummerCampStartArea />
      <div className="container summer-camp-container">
        <div className="categories-area">
          <div
            className='description'
            dangerouslySetInnerHTML={{ __html: description }} />
          <h2>Camps Organized By States</h2>
          <div className="category-list row">
            {Object.keys(USStates).map((item) => {
              return (
                <div
                  key={item}
                  className="col-lg-3">
                  <a href={"/summer-camps/state/" + item}>{item} Summer Camps</a>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
