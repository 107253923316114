import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SCSignupContainer, InputBox, CheckBox } from 'src/Components';
import { showSpinner, hideSpinner, showToast } from 'src/Utils/Helper';
import { expDateFormat } from 'src/Utils/Format';
import {
  CardNumberValidation,
  CardExpDateValidation,
  CsvValidation,
  ZipCodeValidation
} from 'src/Utils/Validation';
import { getBraintreekey, updateCard, SCPayment, campHubspot } from 'src/Redux/Actions';
import { braintainService,  } from 'src/Utils/paymentServices';

export default function SCPaymentPage() {
  let history = useHistory();
  const dispatch = useDispatch();

  const getLoadingRef = useRef(false);
  const getPayLoadingRef = useRef(false);
  const getHubspotLoadingRef = useRef(false);

  const [isPolicy, setIsPolicy] = useState(false);

  const [cardInfo, setCardInfo] = useState({
    cardNumber: '',
    expDate: '',
    csv: '',
    zipCode: '',
  });

  const [cardInfoValidation, setCardInfoValidation] = useState({
    cardNumberValid: null,
    expDateValid: null,
    csvValid: null,
    zipCodeValid: null,
  });

  const {
    braintreeKey,
  } = useSelector(
    state => state.Payment,
  );

  const {
    user    
  } = useSelector(
    state => state.SummerCamp,
  );

  const {
    update_card_res, update_card_success, update_card_loading,
    sc_payment_res, sc_payment_success, sc_payment_loading,    
  } = useSelector(
    state => state.Payment,
  );

  const {
    camp_hubspot_res, camp_hubspot_success, camp_hubspot_loading,
  } = useSelector(
    state => state.SummerCamp,
  );

  useEffect(() => {
    dispatch(getBraintreekey());
  }, [dispatch]);

  //Update Card
  useEffect(() => {
    if (getLoadingRef.current && !update_card_loading) {
      if (update_card_success) {
        showToast('success', 3000, update_card_res);
        onSCPayment()
      } else {
        hideSpinner();
        showToast('error', 3000, update_card_res);
      };
    }
    getLoadingRef.current = update_card_loading;
  }, [update_card_loading, update_card_success, update_card_res, history]);

  //Camp Payment
  useEffect(() => {
    if (getPayLoadingRef.current && !sc_payment_loading) {
      if (sc_payment_success) {
        showToast('success', 3000, sc_payment_res);
        onHubspot();
      } else {
        hideSpinner();
        showToast('error', 3000, sc_payment_res);
      };
    }
    getPayLoadingRef.current = sc_payment_loading;
  }, [sc_payment_loading, sc_payment_success, sc_payment_res, history]);

  //Payment HubSpot
  useEffect(() => {
    if (getHubspotLoadingRef.current && !camp_hubspot_loading) {
      hideSpinner();
      if (camp_hubspot_success) {
        showToast('success', 3000, camp_hubspot_res);
        history.push('/summer-camp-registration-success');
      } else {
        showToast('error', 3000, camp_hubspot_res);
      };
    }
    getHubspotLoadingRef.current = camp_hubspot_loading;
  }, [camp_hubspot_loading, camp_hubspot_success, camp_hubspot_res, history]);

  const onHubspot = () => {
    const payload = {
      first_name: `${user.name}`,
      last_name: '',
      email: user.email,
      phone: user.phone,
      camps: [],
      camp_enrollment_status: 'Payment',
      contact_type: '',
    }
    dispatch(campHubspot(payload));
  };

  const onSCPayment = () => {
    const payload = {
      make_real_payment: "true"
    }
    dispatch(SCPayment(payload));
  };

  const onChangeCardInfo = (field, validationField, value) => {
    if (field === 'expDate') {
      setCardInfo({
        ...cardInfo,
        [field]: expDateFormat(value)
      });
    } else {
      setCardInfo({
        ...cardInfo,
        [field]: value,
      });
    }

    setCardInfoValidation({
      ...cardInfoValidation,
      [validationField]: getValidation(field, value)
    });
  };

  const getValidation = (field, value) => {
    if (field === 'cardNumber')
      return CardNumberValidation(value)
    if (field === 'expDate')
      return CardExpDateValidation(value)
    if (field === 'csv')
      return CsvValidation(value)
    return ZipCodeValidation(value)
  };

  const checkDisableBtn = () => {
    if (
      CardNumberValidation(cardInfo.cardNumber) &&
      CardExpDateValidation(cardInfo.expDate) &&
      CsvValidation(cardInfo.csv) &&
      ZipCodeValidation(cardInfo.zipCode) &&
      isPolicy
    )
      return false;
    return true;
  };

  const onTogglePolicy = () => setIsPolicy(!isPolicy);

  const onPayment = async () => {
    const cardDetail = {
      card_number: cardInfo.cardNumber,
      expDate: cardInfo.expDate,
      cvv2: cardInfo.csv,
      zip_code: cardInfo.zipCode
    };

    if (!checkDisableBtn()) {
      showSpinner()
      try {
        const nonce = await braintainService(braintreeKey, cardDetail);
        const formData = new FormData();
        formData.append('todo', 'new');
        formData.append('nonce', nonce);
        formData.append('payment_method_token', braintreeKey);
        dispatch(updateCard(formData));
      } catch (e) {
        hideSpinner()
        alert(e.details.originalError.error.message);
      }
    }
  };

  return (
    <SCSignupContainer
      title={'Payment'}
      className={'sc-payment-page'}
    >
      {/* <InputBox
        className="input-box"
        placeholder={"Card Name"}
        value={cardInfo.cardName}
        onChange={e => onChangeCardInfo('cardName', 'cardNameValid', e)}
      /> */}
      <InputBox
        className="input-box"
        type={'number'}
        placeholder={"Card Number"}
        value={cardInfo.cardNumber}
        validation={cardInfoValidation.cardNumberValid}
        onChange={e => onChangeCardInfo('cardNumber', 'cardNumberValid', e)}
      />
      <div className='row pv-3'>
        <div className='col-lg-6 col-md-6 col-sm-12 col-12' >
          <InputBox
            className="input-box"
            placeholder={"Expire date"}
            value={cardInfo.expDate}
            validation={cardInfoValidation.expDateValid}
            onChange={e => onChangeCardInfo('expDate', 'expDateValid', e)}
          />
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 col-12' >
          <InputBox
            className="input-box"
            placeholder={"CSV"}
            type={'number'}
            value={cardInfo.csv}
            validation={cardInfoValidation.csvValid}
            onChange={e => onChangeCardInfo('csv', 'csvValid', e)}
          />
        </div>
      </div>
      <InputBox
        className="input-box"
        type={'number'}
        placeholder={"Zip Code"}
        value={cardInfo.zipCode}
        validation={cardInfoValidation.zipCodeValid}
        onChange={e => onChangeCardInfo('zipCode', 'zipCodeValid', e)}
      />

      <div
        className={`btn-2 center payment-btn ${checkDisableBtn() ? 'disable' : ''}`}
        onClick={onPayment}>
        Make Payment
      </div>

      <div className="check-area mt-3">
        <CheckBox className='check-box' value={isPolicy} onChange={onTogglePolicy} />
        <div className="check-label">
          Before completing your payment for iCode iGenius Summer Coding Camp, please note that payment is due in full at the time of registration. If you cancel your registration at least three weeks before the start of the camp, you will receive a full refund. No refunds will be issued for cancellations made less than three weeks before the start of the camp. Thank you for choosing iCode iGenius Summer Coding Camp.
        </div>
      </div>
    </SCSignupContainer>
  );
}