import React from 'react';
import './CoursePage.scss';
import { CourseDetail } from 'src/Components';

export default class CoursePage extends React.Component {
  render() {
    return (
      <div className="course-page v-r">
        <div className="container">
          <CourseDetail
            id={this.props.match.params.id ? parseInt(this.props.match.params.id, 10) : 1}
            isSideBar={false}
          />
        </div>
      </div>
    )
  };
};