import React from 'react';
import './style.scss';
import CapIcon from 'src/Assets/SummerCamp/cap.svg';

export default function Instructor({
  info
}) {

  const {
    photo,
    subject,
    name,
    university,
    hours,
    role,
    other,
  } = info

  return (
    <div className="instructor-component">
      <img src={photo} alt='instructor-img' />
      <div className='detail-section'>
        <div className='subject-name-university'>
          <div>
            <div className='subject'>{subject}</div>
            <div className='name'>{name}</div>
          </div>
          <div className='university-img-container'>
            <img src={university} alt='university-img' className='university-img' />
          </div>
        </div>
        <div className='v-c mt-2'>
          <img src={CapIcon} alt='cap-icon' className='cap-icon' />
          <div className='ml-1'>
            {other && <div className='other'>{other}</div>}
            {hours && <div className='hours'>{`${hours} Hrs`}</div>}
            {role && <div className='role'>{role}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}
