import {
  GET_BRAINTREE_KEY,
  UPDATE_CARD,
  SC_PAYMENT
} from '../Type';

export const getBraintreekey = () => {
  return {
    type: GET_BRAINTREE_KEY,
    data: {}
  }
}
export const updateCard = (data) => {
  return {
    type: UPDATE_CARD,
    data: data
  }
}

export const SCPayment = (data) => {
  return {
    type: SC_PAYMENT,
    data
  }
}

