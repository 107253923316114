import React from 'react';
import './style.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import logoInactive from 'src/Assets/Icon/logo-inactive.png';

export default function IGeniusFooter() {

  return (
    <div className='iGenius-footer-component'>
      <div className='container'>
        <div className='iGenius-footer-container'>
          <img src={logoInactive} alt="logo-active" />
          <AnchorLink
            className="signUp-btn center"
            href={"#section-enroll"}
          >
            Enroll Now
          </AnchorLink>
          <div className='description'>© Frog Tutoring Company. All rights reserved</div>
        </div>
      </div>
    </div>
  );
}
