import {
    GET_LANDING_DATA, GET_LANDING_DATA_SUCCESS, GET_LANDING_DATA_FAIL,
    USER_SUBMIT, USER_SUBMIT_SUCCESS, USER_SUBMIT_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    getLandingData as getLandingDataApi,
    userSubmit as userSubmitApi
} from '../../Api';

function* getLandingData(){
    try {
        const result = yield getLandingDataApi()
        if (result) {
            yield put({ type: GET_LANDING_DATA_SUCCESS, data: result })
        } else {
            yield put({ type: GET_LANDING_DATA_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_LANDING_DATA_FAIL, data: [] })
    }
}

export function* watchgetLanding(){
    yield takeLatest(GET_LANDING_DATA, getLandingData)
}


function* userSubmit(payload){
    try {
        const result = yield userSubmitApi(payload.data)
        if (result) {
            yield put({ type: USER_SUBMIT_SUCCESS, data: result.message })
        } else {
            yield put({ type: USER_SUBMIT_FAIL, data: result.message })
        }
    } catch (err) {
        yield put({ type: USER_SUBMIT_FAIL, data: [] })
    }
}

export function* watchUserSubmit(){
    yield takeLatest(USER_SUBMIT, userSubmit)
}