import { GET_ALL_REVIEWS, GET_ALL_REVIEWS_FAIL, GET_ALL_REVIEWS_SUCCESS } from '../Type';

const INITIAL = {
    review_list: [],
    get_review_success: false,
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case GET_ALL_REVIEWS:
        case GET_ALL_REVIEWS_FAIL: {
            return {
                review_list: [],
                get_review_success: false,
            }
        }
        case GET_ALL_REVIEWS_SUCCESS: {
            let review_list = action.data || [];
            return {
                review_list: review_list,
                get_review_success: true,
            }
        }

        default:
            return state;
    }
}