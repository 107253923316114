import React from 'react';
import './WhyStudyCoding.scss';
//import why_coding_img from '../../../Assets/StudentSignup/why_coding_img.png';
import Bill_Gates from '../../../Assets/StudentSignup/WhyStudy/Bill-Gates.png';
import Mark_Zuckerberg from '../../../Assets/StudentSignup/WhyStudy/Mark-Zuckerberg.png';
import Steve_Jobs from '../../../Assets/StudentSignup/WhyStudy/Steve-Jobs.png';
import Elon_Musk from '../../../Assets/StudentSignup/WhyStudy/Elon-Musk.png';

export default class WhyStudyCoding extends React.Component {

    request = () => {
        window.scrollTo(0, 0);
    };

    render() {
        return (
            <div className="whyStudyCoding-component">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 v-r sub-col">
                            <h1>Why Study Coding</h1>
                            <h2>These world changers started coding at a young age:</h2>
                            <p>The world is changing and the workplace of the future will be unrecognizable from the workplace of today. Most jobs will be automated and the economy will be driven by Al, Machine Learning, Big Data, and coders. Those with skill in programming will reap the greatest reward.</p>
                            <p>To survive in this economy, most job-holders will need coding and creative technology skills. As a parent, if you have kids in elementary, middle, and high schools then you might have realized that coding is not often being taught in schools.</p>
                            <div className="btn-link shadow-object" onClick={this.request}>REQUEST FREE LESSON</div>
                        </div>
                        <div className="col-lg-8">
                            <div className="row man-content">
                            <div className=''>
                                <div className='bill-gates'>
                                    <img src={Bill_Gates} className="man-img" alt="man_img" />
                                    <div className='man-name show-web'>Bill Gates</div>
                                </div>
                                <div className='steve-jobs'>
                                    <img src={Steve_Jobs} className="man-img" alt="man_img" />
                                    <div className='man-name show-web'>Steve Jobs</div>
                                </div>
                            </div>
                            <div >
                                <div className='mark-zuckerberg'>
                                    <img src={Mark_Zuckerberg} className="man-img" alt="man_img" /> 
                                    <div className='man-name show-web'>Mark Zuckerberg</div>
                                </div>
                                <div className='elon-musk'>
                                    <img src={Elon_Musk} className="man-img" alt="man_img" />
                                    <div className='man-name show-web'>Elon Musk</div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};