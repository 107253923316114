import React, { useState } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import CloseIcon from 'src/Assets/Icon/close.svg';
import headerImg from 'src/Assets/Course/Icode_5.png';
import { toggleScNotificationModal } from 'src/Redux/Actions';

export default function AppNotification() {
  let history = useHistory();
  const dispatch = useDispatch();

  const {
    isScModalOpen,
  } = useSelector(
    state => state.Modal,
  );

  const onLearnMore = () => {
    onCloseNotification();
    history.push('freecodingchallenge')
  };

  const onCloseNotification = () => dispatch(toggleScNotificationModal(false))


  return (
    <Modal show={isScModalOpen}>
      <div
        className={`
            app-notification-component
            ${isScModalOpen ? '' : 'hidden'}
          `}
      >
        <div className='close-header'>
          <img src={CloseIcon} alt='close-icon' className='close-icon' onClick={onCloseNotification} />
        </div>

        <div className='notification-content'>
          <img src={headerImg} alt="bg" className="header-img" />
          <div className='title' >iGenius Summer Coding Camp</div>
          <div className='price' >SAVE $100</div>
          <div className='notification-text'>
            {`ON EARLY \nREGISTRATION`}
          </div>
          <div className='learn-more-btn center' onClick={onLearnMore}>
            LEARN MORE
          </div>
        </div>
      </div>
    </Modal>
  );
}
