import React from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg';
import { NavLink } from 'react-router-dom';

import mortarboard from 'src/Assets/Icon/mortarboard.svg';
import bank from 'src/Assets/Icon/bank.svg';

export default function SummerCampDetailHeader({
  tags, 
  title, 
  bg = "https://api.icodeedu.com/storage/blogs/lqI43qpIGabeiHx7mqSKoie1UGX3aSJwTBMPffyY.jpeg"
}) {

  return (
    <div className="summer-camp-detail-header-component">
      <div className="bg-area" style={{ backgroundImage: `url(${bg})` }} />
      <div className="bg-hide show-web" />
      <div className="container header-container">
        <div className="article-info shadow-object">
          <div className="article-info-bg" />
          <div className="category-area">
            {tags.map((item, index) => (
              <NavLink
                key={index}
                className="category mr-1"
                to={item.link}>
                {item.name}
              </NavLink>
            ))}
          </div>
          <h1>{title}</h1>
          <div className="tutor-info">
            <img src="/logo2.jpg" alt="tutor_photo" className="photo" />
            <div className="tutor-details">
              <h1>Roland Omene</h1>
              <div className="tutor-detail-sub">
                <ReactSVG src={mortarboard} className="svg-icon" />
                <span className="sub-span">Texas Christian University</span>
              </div>
              <div className="tutor-detail-sub sub2">
                <ReactSVG src={bank} className="svg-icon" />
                <span className="sub-span">Co Founder iCode Edu</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
