import React from 'react';
import './PricingTables.scss';

const kindleLink = 'https://www.amazon.com/Parenting-Age-4th-Industrial-Revolution-ebook/dp/B09QNRFQG1/ref=sr_1_1?crid=1164X2UPKGOAQ&keywords=parenting+in+the+age+of+the+4th+industrial+revolution&qid=1643011040&sprefix=pARENTING+IN+THE+AGE+OF+THE+%2Caps%2C531&sr=8-1';
const paperbackLinke= 'https://www.amazon.com/Parenting-Age-4th-Industrial-Revolution/dp/B09QP2GWRJ/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1643011040&sr=8-1';

const TableData = [
    {id: 1, title: 'Ebook', price: '$9.99', description: '', btnText: 'BUY NOW!', link: kindleLink},
    {id: 2, title: 'Paperback', price: '$20.00', description: '', btnText: 'BUY NOW!', link: paperbackLinke},
    // {id: 3, title: '2 VERSIONS', price: '$38.60', description: 'Epub and Kindle versions.', btnText: 'BUY NOW!'},
    // {id: 4, title: '3 VERSIONS', price: '$38.60', description: 'PDF, Epub and Kindle versions.', btnText: 'BUY NOW!'},
]

export default class PricingTables extends React.Component {

    buy = (item) => {
        window.open(item.link);
    };

    render() {
        return (
            <div className="pricingTables-component">
                <div className="container">
                    <div className='row'>
                        {TableData.map((item, index)=> (
                            <div className='col-lg-6 h-c' key={index}>
                                <div className='item-content' onClick={()=>this.buy(item)}>
                                    <div className='title-content v-c h-c'>
                                        <h3>{item.title}</h3>
                                    </div>
                                    <div className={`${index === 3? 'price-active' : ''} price-content v-c h-c`}>
                                        {item.price}
                                    </div>
                                    <div className='description-content'>
                                        {item.description}
                                    </div>
                                    <div className='btn-content v-c h-c'>
                                        <div className='buy-button v-c h-c'>
                                            {item.btnText}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    };
};
