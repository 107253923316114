import {
  GET_SC_STATE, GET_SC_STATE_SUCCESS, GET_SC_STATE_FAIL,
  GET_SC_STATE_TYPE, GET_SC_STATE_TYPE_SUCCESS, GET_SC_STATE_TYPE_FAIL,
  GET_SC_STATE_CITY, GET_SC_STATE_CITY_SUCCESS, GET_SC_STATE_CITY_FAIL,
  GET_SC_STATE_CATEGORY, GET_SC_STATE_CATEGORY_SUCCESS, GET_SC_STATE_CATEGORY_FAIL,
  GET_SC_STATE_CITY_TYPE, GET_SC_STATE_CITY_TYPE_SUCCESS, GET_SC_STATE_CITY_TYPE_FAIL,
  GET_SC_STATE_CITY_CATEGORY, GET_SC_STATE_CITY_CATEGORY_SUCCESS, GET_SC_STATE_CITY_CATEGORY_FAIL,
  CAMP_SIGNUP, CAMP_SIGNUP_SUCCESS, CAMP_SIGNUP_FAIL,
  CAMP_HUBSPOT, CAMP_HUBSPOT_SUCCESS, CAMP_HUBSPOT_FAIL,
} from '../Type';

import _ from 'lodash'
import { takeLatest, put } from 'redux-saga/effects';
import {
  getSCState as getSCStateApi,
  getSCStateType as getSCStateTypeApi,
  getSCStateCity as getSCStateCityApi,
  getSCStateCategory as getSCStateCategoryApi,
  getSCStateCityType as getSCStateCityTypeApi,
  getSCStateCityCategory as getSCStateCityCategoryApi,
  campSignUp as campSignUpApi,
  campHubspot as campHubspotApi,
} from '../../Api';

/**
 * GET Summer Camp State
 */
function* getSCState(payload) {
  try {
    const result = yield getSCStateApi(payload.data);
    if (result) {
      yield put({ type: GET_SC_STATE_SUCCESS, data: result })
    } else {
      yield put({ type: GET_SC_STATE_FAIL, data: result })
    }
  } catch (err) {
    yield put({ type: GET_SC_STATE_FAIL, data: { msg: 'NET ERROR' } })
  }
}

export function* watchgetSCState() {
  yield takeLatest(GET_SC_STATE, getSCState)
}

/**
 * GET Summer Camp State Type
 */
function* getSCStateType(payload) {
  try {
    const result = yield getSCStateTypeApi(payload.data);
    if (result && result.data) {
      yield put({ type: GET_SC_STATE_TYPE_SUCCESS, data: result.data })
    } else {
      yield put({ type: GET_SC_STATE_TYPE_FAIL, data: result })
    }
  } catch (err) {
    yield put({ type: GET_SC_STATE_TYPE_FAIL, data: { msg: 'NET ERROR' } })
  }
}

export function* watchgetSCStateType() {
  yield takeLatest(GET_SC_STATE_TYPE, getSCStateType)
}

/**
 * GET Summer Camp State City
 */
function* getSCStateCity(payload) {
  try {
    const result = yield getSCStateCityApi(payload.data);
    if (result && result.data) {
      yield put({ type: GET_SC_STATE_CITY_SUCCESS, data: result.data })
    } else {
      yield put({ type: GET_SC_STATE_CITY_FAIL, data: result })
    }
  } catch (err) {
    yield put({ type: GET_SC_STATE_CITY_FAIL, data: { msg: 'NET ERROR' } })
  }
}

export function* watchgetSCStateCity() {
  yield takeLatest(GET_SC_STATE_CITY, getSCStateCity)
}

/**
 * GET Summer Camp State Category
 */
function* getSCStateCategory(payload) {
  try {
    const result = yield getSCStateCategoryApi(payload.data);
    if (result && result.data) {
      yield put({ type: GET_SC_STATE_CATEGORY_SUCCESS, data: result.data })
    } else {
      yield put({ type: GET_SC_STATE_CATEGORY_FAIL, data: result })
    }
  } catch (err) {
    yield put({ type: GET_SC_STATE_CATEGORY_FAIL, data: { msg: 'NET ERROR' } })
  }
}

export function* watchgetSCStateCategory() {
  yield takeLatest(GET_SC_STATE_CATEGORY, getSCStateCategory)
}

/**
 * GET Summer Camp State City Type
 */
function* getSCStateCityType(payload) {
  try {
    const result = yield getSCStateCityTypeApi(payload.data);
    if (result && result.data) {
      yield put({ type: GET_SC_STATE_CITY_TYPE_SUCCESS, data: result.data })
    } else {
      yield put({ type: GET_SC_STATE_CITY_TYPE_FAIL, data: result })
    }
  } catch (err) {
    yield put({ type: GET_SC_STATE_CITY_TYPE_FAIL, data: { msg: 'NET ERROR' } })
  }
}

export function* watchGetSCStateCityType() {
  yield takeLatest(GET_SC_STATE_CITY_TYPE, getSCStateCityType)
}

/**
 * GET Summer Camp State City Category
 */
function* getSCStateCityCategory(payload) {
  try {
    const result = yield getSCStateCityCategoryApi(payload.data);
    if (result && result.data) {
      yield put({ type: GET_SC_STATE_CITY_CATEGORY_SUCCESS, data: result })
    } else {
      yield put({ type: GET_SC_STATE_CITY_CATEGORY_FAIL, data: result })
    }
  } catch (err) {
    yield put({ type: GET_SC_STATE_CITY_CATEGORY_FAIL, data: { msg: 'NET ERROR' } })
  }
}

export function* watchGetSCStateCityCategory() {
  yield takeLatest(GET_SC_STATE_CITY_CATEGORY, getSCStateCityCategory)
}

/**
 * Camp SignUp
 */
function* campSignUp(payload) {
  try {
    const result = yield campSignUpApi(payload.data);
    if (result && (result.token && result.token.length > 0)) {
      yield put({ type: CAMP_SIGNUP_SUCCESS, data: result })
    } else {
      let message = result.message;
      let errors = result.errors || {};
      for (let item of Object.keys(errors)) {
        let error_part = errors[item];
        for (let msg of error_part) {
          message += ' ' + msg;
        }
      }
      yield put({ type: CAMP_SIGNUP_FAIL, data: { msg: message } })
    }
  } catch (err) {
    yield put({ type: CAMP_SIGNUP_FAIL, data: { msg: "NET ERROR" } });
  }
}
export function* watchCampSignUp() {
  yield takeLatest(CAMP_SIGNUP, campSignUp)
}

/**
 * Summer Camp Hubspot
 */
 function* campHubspot(payload) {
  try {
    const result = yield campHubspotApi(payload.data);
    console.log("====camp hubspot res===", result)
    if (result && _.isEmpty(result.errors)) {
      yield put({ type: CAMP_HUBSPOT_SUCCESS, data: result })
    } else {
      let message = result.message;
      let errors = result.errors || {};
      for (let item of Object.keys(errors)) {
        let error_part = errors[item];
        for (let msg of error_part) {
          message += ' ' + msg;
        }
      }
      yield put({ type: CAMP_HUBSPOT_FAIL, data: { message } })
    }
  } catch (err) {
    yield put({ type: CAMP_HUBSPOT_FAIL, data: { msg: 'NET ERROR' } })
  }
}

export function* watchCampHubspot() {
  yield takeLatest(CAMP_HUBSPOT, campHubspot)
}