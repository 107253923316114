import React, { useState, useRef, useEffect } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  NameValidation,
  EmailValidation,
  PhoneValidation,
} from 'src/Utils/Validation';
import { InputBox } from 'src/Components';
import AvatarIcon from 'src/Assets/Icon/avatar.svg';
import EmailIcon from 'src/Assets/Icon/email.svg';
import { showSpinner, hideSpinner, showToast } from 'src/Utils/Helper';
import { masterClassSignUp, campHubspot } from 'src/Redux/Actions';
import { getCountryCode } from 'src/Utils/Other';

export default function RegistrationForm() {
  const queryParameters = new URLSearchParams(window.location.search)
  const referrer_code = queryParameters.get("referrer_code") || ""

  let history = useHistory();
  const dispatch = useDispatch();
  const getLoadingRef = useRef(false);
  const getHubspotLoadingRef = useRef(false);

  const {
    masterclass_signup_res, masterclass_signup_success, masterclass_signup_loading,
  } = useSelector(
    state => state.IGenius,
  );

  const {
    camp_hubspot_res, camp_hubspot_success, camp_hubspot_loading,
  } = useSelector(
    state => state.SummerCamp,
  );

  //Camp Registration
  useEffect(() => {
    if (getLoadingRef.current && !masterclass_signup_loading) {
      if (masterclass_signup_success) {
        showToast('success', 3000, masterclass_signup_res);
        onHubspot()
      } else {
        hideSpinner();
        showToast('error', 3000, masterclass_signup_res);
      };
    }
    getLoadingRef.current = masterclass_signup_loading;
  }, [masterclass_signup_loading, masterclass_signup_success, masterclass_signup_res, history]);

  //Camp HubSpot
  useEffect(() => {
    if (getHubspotLoadingRef.current && !camp_hubspot_loading) {
      hideSpinner();
      if (camp_hubspot_success) {
        showToast('success', 3000, camp_hubspot_res);
        history.push('/iCode-iGenius-summer-camps/masterclass')
      } else {
        showToast('error', 3000, camp_hubspot_res);
      };
    }
    getHubspotLoadingRef.current = camp_hubspot_loading;
  }, [camp_hubspot_loading, camp_hubspot_success, camp_hubspot_res, history]);

  const onHubspot = () => {
    const payload = {
      first_name: `${userInfo.name}`,
      last_name: '',
      email: userInfo.email,
      phone: userInfo.phone,
      // country: userInfo.country,
      // country_code: getCountryCode(userInfo.country),
      camps: [],
      camp_enrollment_status: 'MasterClass',
      contact_type: 'Camp Lead',
    }
    console.log("===hubspot payload===", payload)
    dispatch(campHubspot(payload));
  }

  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    phone: '',
    country: 'US',
    referrer_code
  });

  const onChangeUserInfo = (field, validationField, value) => {
    setUserInfo({
      ...userInfo,
      [field]: value,
      [validationField]: getValidation(field, value),
    });
  };

  const getValidation = (field, value) => {
    if (field === 'name')
      return NameValidation(value);
    if (field === 'email') return EmailValidation(value);
    if (field === 'phone') return PhoneValidation(value);
    return !!value;
  };

  const getDisableBtn = () => {
    if (NameValidation(userInfo.name) && EmailValidation(userInfo.email) && PhoneValidation(userInfo.phone)) return false
    return true
  }

  const onRegistration = () => {
    if (!getDisableBtn()) {
      showSpinner();
      const payload = {
        name: userInfo.email,
        email: userInfo.email,
        phone: userInfo.phone,
        country: userInfo.country,
        country_code: getCountryCode(userInfo.country),
      }
      console.log("===registration payload===", payload)
      dispatch(masterClassSignUp(payload));
    }
  }

  return (
    <div className='registration-form-component' id="section-enroll">
      <div className='registration-form-container'>
        <div className='container'>
          <div className='registration-form-content'>
            <h2 className='mx-auto'>
              Register For The <strong>FREE</strong> 1-on-1 Kids Coding Lesson
            </h2>
            <h3 className='mx-auto'>
              5000 students already completed this challenge
            </h3>
            <div className='register-form-container mx-auto'>
              <InputBox
                className="input-box"
                icon={AvatarIcon}
                placeholder={"Full Name"}
                value={userInfo.name}
                validation={userInfo.nameValid}
                onChange={e => onChangeUserInfo('name', 'nameValid', e)}
              />
              <InputBox
                className="input-box"
                icon={EmailIcon}
                placeholder={"Email Address"}
                value={userInfo.email}
                validation={userInfo.emailValid}
                onChange={e => onChangeUserInfo('email', 'emailValid', e)}
              />
              <InputBox
                className="input-box"
                phone_input
                type="number"
                placeholder={"Phone Number"}
                value={userInfo.phone}
                validation={userInfo.phoneValid}
                country={userInfo.country}
                onSelect={e => onChangeUserInfo('country', 'phoneValid', e)}
                onChange={e => onChangeUserInfo('phone', 'phoneValid', e)}
              />
              <div
                className='btn-2 registration-btn center'
                onClick={onRegistration}>
                Get Free Coding Lesson & Bonuses
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
