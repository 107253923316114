import React from 'react';
import './style.scss';
import ArrowIcon from 'src/Assets/IGenius/arrow-icon.svg';
import Guru from 'src/Assets/IGenius/guru.png';

export default function Transform() {

  return (
    <div className='transform-component'>
      <div className='container'>
        <div className='row transform-container mx-auto'>
          <div className="col-lg-6 left-content">
            <h2><strong>Transform</strong> your child’s unproductive screen time into a valuable learning <strong>experience</strong></h2>
            <h3>by enrolling them in our FREE 1:1 coding challenge</h3>
            <p>
              By doing so, you can enhance their problem-solving skills, creativity, computational thinking, and confidence to excel in exams. Our system prepares them to become future leaders, innovators, and builders in the 21st-century economy, which is driven by an ecosystem of codes that powers cutting-edge technologies such as AI (like ChatGPT), automation, and machine learning.
            </p>
            <p>
              In other words, they could become the <strong>Bill Gates, Elon Musks,
                Mark Zuckerbergs, and Steve Jobs</strong> of their generation.
            </p>
            <img src={ArrowIcon} alt='arrow-icon' className='arrow-icon show-web' />
          </div>
          <div className="col-lg-6 h-c v-r right-content">
            <img src={Guru} alt='guru' className='guru-img' />
          </div>
        </div>
      </div>
    </div>
  );
}
