import React from 'react';
import './Availability.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';
import moment from 'moment';
import bin from '../../Assets/Icon/rubbish-bin.svg';
import Helper from '../../Utils/Helper';
import {
    tutorProfileUpdate,
} from "../../Redux/Actions";

const HelpParams = [
    'available', 'unavailable'
];

const Hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
const WeekData = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];


class Availability extends React.Component {
    state = {
        schedule_data: [],
              
    }

    componentDidUpdate(prevProps, PrevState) {
        if (prevProps.tutor_profile_updating_loading && !this.props.tutor_profile_updating_loading) {
            const { schedule_data } = this.state;
            const { tutor_profile_updating_success, tutor_profile_updating_error_msg } = this.props;
            Helper.hideSpinner();

            if(tutor_profile_updating_success){
                Helper.showToast('success', 3000, "Successfully updating!")
                this.props.availabilityStatus(schedule_data)               
            } else {
                Helper.showToast('error', 3000, tutor_profile_updating_error_msg)
            }
        }
    }

    checkSelectedCount = () => {
        let { schedule_data } = this.state;
        return (schedule_data.filter(temp => temp.selected === true) || []).length;
    };

    deleteSelected = () => {
        let { schedule_data } = this.state;
        let filtered = schedule_data.filter(temp => temp.selected === true) || [];
        if (filtered.length > 0) {
            for (let item of filtered) {
                let index = schedule_data.findIndex(temp => temp.day === item.day && temp.hour === item.hour);
                schedule_data.splice(index, 1);
            }
        }
        this.setState({schedule_data});
    };

    getHour = (hour) => {
        return `${hour <= 12 ? hour : hour === 24 ? 12 : hour - 12} ${hour < 12 ? 'am' : hour === 24 ? 'am' : 'pm'}`;
    };

    checkSchedule = (d, h) => {
        let { schedule_data } = this.state;
        let currentIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === h);
        let prevIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === h - 1);
        let nextIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === h + 1);

        let last_time = null;
        let current = currentIndex === -1 ? '' : schedule_data[currentIndex].status;
        let prev = prevIndex === -1 ? '' : schedule_data[prevIndex].status;
        let next = nextIndex === -1 ? '' : schedule_data[nextIndex].status;
        if (current !== prev && current === next && current.length > 0) {
            for (let i = h + 1; i < 24; i++) {
                let tempIndex = schedule_data.findIndex(temp => temp.day === d && temp.hour === i);
                let tempVal = tempIndex === -1 ? '' : schedule_data[tempIndex].status;
                if (current === tempVal) {
                    last_time = i;
                } else {
                    break;
                };
            };
        };
        return {
            current, prev, next, last_time,
            selected: currentIndex === -1 ? false : schedule_data[currentIndex].selected,
        };
    };


    updateSchedule = (d, h, current) => {
        if (current.length > 0) return;
        let { schedule_data } = this.state;
        schedule_data.push({
            day: d,
            hour: h,
            status: 'unavailable',
            selected: false
        })
        this.setState({schedule_data})
        this.updateProfile()
    };

    selectSchedule = (e, d, h, current) => {
        e.stopPropagation();
        let { schedule_data } = this.state;
        let index = schedule_data.findIndex(temp => temp.day === d && temp.hour === h);
        schedule_data[index].selected = !schedule_data[index].selected;
        this.setState({schedule_data});
    };

    delSchedule = (d, h) => {
        let { schedule_data } = this.state;
        let index = schedule_data.findIndex(temp => temp.day === d && temp.hour === h);
        schedule_data.splice(index, 1);
        this.setState({schedule_data});
        this.updateProfile()
    };

    updateProfile = () => {
        Helper.showSpinner();
        const { schedule_data } = this.state;
        var deploy = {
            schedule: schedule_data
        };
        this.props.tutorProfileUpdate(deploy)
    };

    render() {
       
        return (
            <div id="section-profile" className="availavbility-section">
                <div className="pre-setting">
                    <div className="help-area">
                        {HelpParams.map((item, index) => <div className="help-item v-c" key={index}>
                            <div className={`help-color ${item}`} />
                            <p>{item}</p>
                        </div>)}
                        {this.checkSelectedCount() > 0 && <div className="v-c delete-all" onClick={e => this.deleteSelected()}>
                            <ReactSVG src={bin} className="svg-icon" />
                            <p>Delete Selected</p>
                        </div>}
                    </div>
                    
                    <p className="pre-explain">This tab allows you to set your weekly availability schedule. White means you’re available and blue means you are not available” ...If you are available to tutor all days with the exception of Monday and Wednesday</p>
                </div>
                <div className="setting-area">
                    <table className="setting-table">
                        <thead>
                            <tr>
                                <th className="start-col"></th>
                                {WeekData.map((item, index) => <th key={index} className={`header-col ${item === moment(new Date()).format("ddd") ? 'active' : ''}`}>
                                    <p>{item}</p>
                                </th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {Hours.map((hour, hrIndex) => <tr key={hrIndex}>
                                <td className="start-col">{this.getHour(hour)}</td>
                                {WeekData.map((item, index) => {
                                    let data = this.checkSchedule(item, hour);
                                    return (
                                    <td key={index} className={`${data.current}`} onClick={e => this.updateSchedule(item, hour, data.current)}>
                                        {data.current.length > 0 && <div onClick={e => this.selectSchedule(e, item, hour, data.current)}
                                            className={`inner-schedule ${data.current} ${data.current === data.prev ? 'has-prev' : ''} ${data.current === data.next ? 'has-next' : ''} ${data.selected ? 'selected' : ''} `} >
                                            {data.current !== data.prev && <p>
                                                <span>{this.getHour(hour)} - </span>
                                                <span>{this.getHour(data.last_time ? data.last_time + 1 : hour + 1)}</span>
                                            </p>}
                                            {data.selected && <div className="del-btn" onMouseDown={e => this.delSchedule(item, hour)}><ReactSVG className="svg-icon" src={bin}/></div>}
                                        </div>}
                                    </td>)
                                })}
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    tutor_profile_updating_loading: state.Auth.tutor_profile_updating_loading,
    tutor_profile_updating_success: state.Auth.tutor_profile_updating_success,
    tutor_profile_updating_error_msg: state.Auth.tutor_profile_updating_error_msg,    
});

export default connect(mapStateToProps, {
    tutorProfileUpdate,
})(withRouter(Availability));