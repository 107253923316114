import React from 'react';
import './ParentingStart.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import startRightImg from '../../../Assets/Parenting/startRightImg.png';

const videoEmbedCode = '0orAHGs8NKk';
const kindleLink = 'https://www.amazon.com/Parenting-Age-4th-Industrial-Revolution-ebook/dp/B09QNRFQG1/ref=sr_1_1?crid=1164X2UPKGOAQ&keywords=parenting+in+the+age+of+the+4th+industrial+revolution&qid=1643011040&sprefix=pARENTING+IN+THE+AGE+OF+THE+%2Caps%2C531&sr=8-1';
const paperbackLinke= 'https://www.amazon.com/Parenting-Age-4th-Industrial-Revolution/dp/B09QP2GWRJ/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1643011040&sr=8-1';

class ParentingStart extends React.Component {
    state = {}

    kindle = () => {
        window.open(kindleLink);
    };

    paperback = () => {
        window.open(paperbackLinke);
    };

    render() {
        return (
            <div className="parentingstart-component">
                <div className="container">
                    <div className='email-content'>
                        info@parentingthefuturebook.com
                    </div>
                    <div className='comment-area mb-3'>
                        Nearly two-thirds of primary school children will hold jobs that don't yet exist.
                        <strong>The World Economic Forum reports</strong>
                    </div>
                    <h1>Concerning statistics right? If you are concerned as a parent, you should be!!</h1>
                    <div className="row">
                        <div className="col-lg-7 v-c h-c">
                            <iframe
                                title="video"
                                width="100%" 
                                height="350" 
                                src={`https://www.youtube.com/embed/${videoEmbedCode}`} 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>              
                            </iframe>
                        </div>
                        <div className="col-lg-5 v-c h-c">
                            <div className="right-area">
                                <img src={startRightImg} className="right-image" alt="right_img" />
                            </div>
                        </div>
                    </div>
                    <div className='description-content'>
                        <h2>All Proceeds from the sales of this work will be donated to <strong className='strong-text'>Parenting The Future Foundation</strong> towards achieving its vision of ;</h2>
                        <div className='h-r description-item'>
                            <div className='description'>1.</div>
                            <div className='description ml-2'>Giving digital access to every kid in Africa by parenting the future in Education.</div>
                        </div>
                        <div className='h-r description-item'>
                            <div className='description'>2.</div>
                            <div className='description ml-2'>Creating awareness and supporting  Parents navigate parenting in a digital age.</div>
                        </div>
                        <div className='h-r description-item'>
                            <div className='description'>3.</div>
                            <div className='description ml-2'>Advocating for a more responsive public education policy that is aligned to the needs of future job markets.</div>
                        </div>
                    </div>
                    <div className='buy-button-content'>
                        <div className='buy-button' onClick={this.kindle}>
                            <div className='price text-center'>
                                Ebook
                            </div>
                            <div className='price-des text-center'>
                                $9.99
                            </div>
                        </div>
                        <div className='buy-button Paperback-button' onClick={this.paperback}>
                            <div className='price text-center'>
                                Paperback
                            </div>
                            <div className='price-des text-center'>
                                $20.00
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {

    }
}

export default connect(mapStateToProps, {})(withRouter(ParentingStart));
