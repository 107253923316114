import React from 'react';
import './MonthlyPlanArea.scss';
import star from '../../../Assets/Icon/star.png';
import money from '../../../Assets/Icon/money.png';
import camera from '../../../Assets/Icon/camera.png';
import date from '../../../Assets/Icon/date.png';
import code from '../../../Assets/Icon/code.png';
import vector from '../../../Assets/Icon/vector.png';
import price from '../../../Assets/Home/Icode_price.png';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { updatePlan } from '../../../Redux/Actions';

const Benefits = [
    { icon: star, text: 'You get free evaluation from an enrollment director. '},
    { icon: money, text: 'Your first month of payment is activated. '},
    { icon: camera, text: 'Video call and voice call.'},
    { icon: date, text: 'Each course can take 2 - 6 months to complete depending on the course, the student'},
    { icon: code, text: 'Dedication to complete activities and also previous coding experience.'}
];

const Prices = [
    'One 50 minutes lesson per week',  'Private class with a U.S instructor', 'Billed monthly', 'Pause and cancel at any time'
];

class MonthlyPlanArea extends React.Component {
    componentDidUpdate(prevProps) {
        if (!prevProps.update_success && this.props.update_success) {
            alert("Successfully Enrolled!");
            return;
        }
        if (prevProps.update_error_msg.length === 0 && this.props.update_error_msg.length > 0) {
            alert(this.props.update_error_msg);
            return;
        }
    }

    navigateToEnroll = () => {
        const { plan_id, token } = this.props;
        if (!token) {
            this.props.history.push('/enroll?id=1&pay=monthly');
        } else {
            if (plan_id === null) {
                this.props.history.push('/enroll2?id=1&pay=monthly');
            } else {
                this.props.updatePlan({
                    plan_id: 1,
                    pay_per: 'monthly',
                })
            }
        }
    }

    render() {
        const { plan_id, pay_per, update_loading } = this.props;
        return (
            <div className="monthlyplanarea-component">
                <h1>Our monthly plans starts at </h1>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 first-col">
                            <img src={price} alt="price-man" className="priceman show-web" />
                        </div>
                        <div className="col-lg-5">
                            <div className="price-container round-object v-r">
                                <div className="sub-title">Private Coding Class</div>
                                <div className="price">
                                    $250<span>/mo</span>
                                </div>
                                {Prices.map((item, index) => <div className="v-c price-row" key={index}>
                                    <img src={vector} alt="check" className="check-icon" />
                                    <p>{item}</p>
                                </div>)}
                                <button className={`btn-2 btn-link ${(plan_id === 1 && pay_per === 'monthly') || update_loading ? 'disabled' : ''}`} onClick={e => this.navigateToEnroll()}>
                                    {this.props.update_loading ? <div className="spinner" /> : 'ENROLL NOW'}
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-5 v-r benefit-col">
                            {Benefits.map((item, index) => <div className="v-c benefit" key={index}>
                                <div className="icon-area v-c h-c">
                                    <img src={item.icon} alt="icon" />
                                </div>
                                <p>{item.text}</p>
                            </div>)}
                            <NavLink to="/pricing" className="btn-link show-web-flex">SEE FULL PRICING</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        token: state.Auth.token,
        plan_id: state.Auth.plan_id,
        pay_per: state.Auth.pay_per,

        update_success: state.Plan.update_success,
        update_error_msg: state.Plan.update_error_msg,
        update_loading: state.Plan.update_loading,
    }
}

export default connect(mapStateToProps, { updatePlan })(withRouter(MonthlyPlanArea));