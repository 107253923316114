import {
  GET_SC_STATE,
  GET_SC_STATE_TYPE,
  GET_SC_STATE_CITY,
  GET_SC_STATE_CATEGORY,
  GET_SC_STATE_CITY_TYPE,
  GET_SC_STATE_CITY_CATEGORY,
  CAMP_SIGNUP,
  CAMP_HUBSPOT,
} from '../Type';

export const getSCState = (data) => {
  return {
    type: GET_SC_STATE,
    data
  }
}

export const getSCStateType = (data) => {
  return {
    type: GET_SC_STATE_TYPE,
    data
  }
}

export const getSCStateCity = (data) => {
  return {
    type: GET_SC_STATE_CITY,
    data
  }
}

export const getSCStateCategory = (data) => {
  return {
    type: GET_SC_STATE_CATEGORY,
    data
  }
}

export const getSCStateCityType = (data) => {
  return {
    type: GET_SC_STATE_CITY_TYPE,
    data
  }
}

export const getSCStateCityCategory = (data) => {
  return {
    type: GET_SC_STATE_CITY_CATEGORY,
    data
  }
}

export const campSignUp = (data) => {
  return {
    type: CAMP_SIGNUP,
    data
  }
}

export const campHubspot = (data) => {
  return {
    type: CAMP_HUBSPOT,
    data
  }
}
