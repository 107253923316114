import { 
    AUTH_SIGN_IN, AUTH_SIGN_IN_FAIL, AUTH_SIGN_IN_SUCCESS, AUTH_SIGN_OUT, AUTH_SIGN_OUT_SUCCESS, AUTH_SIGN_OUT_FAIL, AUTH_SIGN_UP, AUTH_SIGN_UP_FAIL, AUTH_SIGN_UP_SUCCESS, 
    AUTH_FORGOT_PASSWORD, AUTH_FORGOT_PASSWORD_SUCCESS, AUTH_FORGOT_PASSWORD_FAIL, AUTH_RESET_PASSWORD, AUTH_RESET_PASSWORD_SUCCESS, AUTH_RESET_PASSWORD_FAIL,
    TUTOR_SIGN_UP, TUTOR_SIGN_UP_FAIL, TUTOR_SIGN_UP_SUCCESS,
    TUTOR_PROFILE_UPDATE, TUTOR_PROFILE_UPDATE_FAIL, TUTOR_PROFILE_UPDATE_SUCCESS,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    signIn as signInApi,
    signOut as signOutApi,
    signUp as signUpApi,
    tutorSignUp as tutorSignUpApi,
    tutorProfileUpdate as tutorProfileUpdateApi,    
    forgotPassword as forgotPasswordApi,
    resetPassword as resetPasswordApi,
} from '../../Api';

function* signIn(payload){
    try {
        const { email, password } = payload.data;
        const result = yield signInApi(email, password);
        if (result.token && result.token.length > 0) {
            yield put({ type: AUTH_SIGN_IN_SUCCESS, data: result})
        } else {
            yield put({ type: AUTH_SIGN_IN_FAIL, data: {msg: result.error}})
        }
    } catch (err) {
        yield put({ type: AUTH_SIGN_IN_FAIL, data: {msg: 'NET ERROR'} })
    }
}

export function* watchSignIn(){
    yield takeLatest(AUTH_SIGN_IN, signIn)
}

function* signOut(payload) {
    try {
        const result = yield signOutApi();
        if (result && result.message === 'Successfully logged out') {
            yield put({ type: AUTH_SIGN_OUT_SUCCESS, data: result})
        } else {
            yield put({ type: AUTH_SIGN_OUT_FAIL, data: {msg: result.message}})
        }
    } catch (err) {
        yield put({ type: AUTH_SIGN_OUT_FAIL, data: {msg: "NET ERROR"}});
    }
}

export function* watchSignOut(){
    yield takeLatest(AUTH_SIGN_OUT, signOut)
}

function* signUp(payload) {
    try {
        const result = yield signUpApi(payload.data);
        if (result && (result.token && result.token.length > 0)) {
            yield put({ type: AUTH_SIGN_UP_SUCCESS, data: result})
        } else {
            let message = result.message;
            let errors = result.errors || {};
            for (let item of Object.keys(errors)) {
                let error_part = errors[item];
                for (let msg of error_part) {
                    message += ' ' + msg;
                }
            }
            yield put({ type: AUTH_SIGN_UP_FAIL, data: {msg: message}})
        }
    } catch (err) {
        yield put({ type: AUTH_SIGN_UP_FAIL, data: {msg: "NET ERROR"}});
    }
}
export function* watchSignUp(){
    yield takeLatest(AUTH_SIGN_UP, signUp)
}


function* tutorSignUp(payload) {
    try {
        const result = yield tutorSignUpApi(payload.data);
        if (result && (result.token && result.token.length > 0)) {
            yield put({ type: TUTOR_SIGN_UP_SUCCESS, data: result})
        } else {
            let message = result.message;
            let errors = result.errors || {};
            for (let item of Object.keys(errors)) {
                let error_part = errors[item];
                for (let msg of error_part) {
                    message += ' ' + msg;
                }
            }
            yield put({ type: TUTOR_SIGN_UP_FAIL, data: {msg: message}})
        }
    } catch (err) {
        yield put({ type: TUTOR_SIGN_UP_FAIL, data: {msg: "NET ERROR"}});
    }
}

export function* watchtutorSignUp(){
    yield takeLatest(TUTOR_SIGN_UP, tutorSignUp)
}

function* tutorProfileUpdate(payload) {
    try {
        const result = yield tutorProfileUpdateApi(payload.data);
        if (result) {
            yield put({ type: TUTOR_PROFILE_UPDATE_SUCCESS, data: result})
        } else {
            let message = result.message;
            let errors = result.errors || {};
            for (let item of Object.keys(errors)) {
                let error_part = errors[item];
                for (let msg of error_part) {
                    message += ' ' + msg;
                }
            }
            yield put({ type: TUTOR_PROFILE_UPDATE_FAIL, data: {msg: message}})
        }
    } catch (err) {
        yield put({ type: TUTOR_PROFILE_UPDATE_FAIL, data: {msg: "NET ERROR"}});
    }
}

export function* watchtutorProfileUpdate(){
    yield takeLatest(TUTOR_PROFILE_UPDATE, tutorProfileUpdate)
}

function* forgotPassword(payload) {
    try {
        const result = yield forgotPasswordApi(payload.data);
        if (result && result.message && result.message === 'Email sent') {
            yield put({ type: AUTH_FORGOT_PASSWORD_SUCCESS, data: result})
        } else {
            yield put({ type: AUTH_FORGOT_PASSWORD_FAIL, data: {msg: "Please try again."}})
        }
    } catch (err) {
        yield put({ type: AUTH_FORGOT_PASSWORD_FAIL, data: {msg: "NET ERROR"}});
    }
}

export function* watchForgotPassword(){
    yield takeLatest(AUTH_FORGOT_PASSWORD, forgotPassword)
}

function* resetPassword(payload) {
    try {
        const result = yield resetPasswordApi(payload.data);
        if (result && result.message && result.message === 'Password reset') {
            yield put({ type: AUTH_RESET_PASSWORD_SUCCESS, data: result})
        } else {
            yield put({ type: AUTH_RESET_PASSWORD_FAIL, data: {msg: "Please try again."}})
        }
    } catch (err) {
        yield put({ type: AUTH_RESET_PASSWORD_FAIL, data: {msg: "NET ERROR"}});
    }
}

export function* watchResetPassword(){
    yield takeLatest(AUTH_RESET_PASSWORD, resetPassword)
}