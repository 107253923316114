import React from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';

export default function PushNotification() {
  let history = useHistory();


  const showHeader = () => {
    const pathName = window.location.pathname.split('/')[1];
    if (
      pathName === '' ||
      pathName === 'pricing'
    ) {
      return true;
    } else {
      return false;
    }
  }

  const onEnroll = () => {
    history.push('freecodingchallenge')
  }

  return (
    <div
      className={`
        push-notification-component 
        ${showHeader() ? '' : 'hidden'}
      `}
      onClick={onEnroll}
    >
      <div className='push-notification-container container'>
        <div className='notification-text'>
          Enroll For The iCode iGenius Summer Coding Camp TODAY & Save $100! Limited Time Offer
        </div>
      </div>
    </div>
  );
}
