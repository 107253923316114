import React from 'react';
import './PricingPage.scss';
//import { Toggle } from '../../Components';
import confirm from '../../Assets/Icon/confirm.svg';
import { ReactSVG } from 'react-svg';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPlan } from '../../Redux/Actions';
import _ from 'lodash'
import Helper from '../../Utils/Helper';

const PeriodList = [
    { id: 1, period: 'Monthly', unit: 0}, 
    { id: 2, period: '6 Months', unit: 2}, 
    { id: 3, period: 'Yearly', unit: 4}
];

class PricingPage extends React.Component {
    state = {
        option: false,
        active_period: 1,
        update_btn: 0,

        lesson_per_week: 0,
        unit: 0,
    }

    componentDidMount() {
        
        if(_.isEmpty(this.props.plans)){
            Helper.showSpinner();
            this.props.getPlan();
        };
    };

    componentDidUpdate(prevProps) {       
        if (prevProps.get_plans_loading && !this.props.get_plans_loading) {
            Helper.hideSpinner();
        };
    }

    toggleLesson = (val) => {
        this.setState({option: val})
        if(val){
            this.setState({lesson_per_week: 6})
        } else {
            this.setState({lesson_per_week: 0})
        }
    }

    navigateToEnroll = (flag) => {
        // const {lesson_per_week, unit} = this.state;
        // var id = this.props.plans[flag + lesson_per_week + unit].id;
        // this.props.history.push(`/enroll?id=${id}`);    
        this.props.history.push(`/enroll`);
    }

    getOffPercent = (index) => {
        const { plans } = this.props;
        const discount_amount = plans[index * 2].discount_amount
        const original_amount = plans[index * 2].original_amount
        const offPercent = (1 - discount_amount/original_amount) *100
        if(offPercent > 0) {
            return (
                `(${Math.round(offPercent)} % Off)`
            )
        }
        return ''
    };

    render() {
        const { lesson_per_week, unit, /*option,*/ active_period } = this.state;
        const { plans } = this.props;
        return (
            <>
                {!_.isEmpty(plans) && <div className="pricing-page v-r">
                    <div className="pricing-header">
                        <div className="container v-r v-c">
                            <h1>Choose a plan that is right<br />for your student</h1>
                            {/* <div className="toggle-area v-c">
                                <p className="left-option v-c">One lesson&nbsp;<span className="show-web">per week</span> <span className="show-mobile">pw</span></p>
                                <Toggle className="option-toggle" value={option} onChange={val => this.toggleLesson(val)} />
                                <p className="v-c">Two lessons&nbsp;<span className="show-web">per week</span> <span className="show-mobile">pw</span></p>
                            </div> */}
                            <div className="period-row v-c">
                                {PeriodList.map((item, index) => <div className={`period v-c h-c ${active_period === item.id ? 'active' : ''}`} key={index} onClick={e=>this.setState({active_period: item.id, unit: item.unit})}>
                                    {`${item.period} ${this.getOffPercent(index)}`}
                                </div>)}
                            </div>
                        </div>
                    </div>
                    <div className="pricing-body">
                        <div className="container pricing-container">
                            <div className="coding-class round-object private">
                                <div className="sub-header v-r v-c">
                                    <h2>{plans[0 + lesson_per_week + unit].name}</h2>
                                    <h3>
                                        {plans[0 + lesson_per_week + unit].original_amount !== plans[0 + lesson_per_week + unit].discount_amount && <span className="ignore">{plans[0 + lesson_per_week + unit].original_amount}</span>}
                                        <span>${plans[0 + lesson_per_week + unit].discount_amount}<span className="unit">/{plans[0 + lesson_per_week + unit].pay_per}</span></span>
                                    </h3>
                                </div>
                                <div className="sub-body v-r">
                                    {plans[0 + lesson_per_week + unit].description.map((item, index) => <div className="sub-item v-c" key={index}>
                                        <ReactSVG src={confirm} className="svg-icon" />
                                        <p> {item} </p>
                                    </div>)}
                                    <button className={`btn-2 btn-link`} onClick={e => this.navigateToEnroll(0)}>
                                        {'ENROLL NOW'}
                                    </button>
                                </div>
                            </div>
                            <div className="coding-class round-object sibiling">
                                <div className="sub-header v-r v-c">
                                     <h2>{plans[1 + lesson_per_week + unit].name}</h2>
                                     <h3>
                                        {plans[1 + lesson_per_week + unit].original_amount !== plans[1 + lesson_per_week + unit].discount_amount && <span className="ignore">{plans[1 + lesson_per_week + unit].original_amount}</span>}
                                        <span>${plans[1 + lesson_per_week + unit].discount_amount}<span className="unit">/{plans[1 + lesson_per_week + unit].pay_per}</span></span>
                                    </h3>
                                </div>
                                <div className="sub-body v-r">
                                    {plans[1 + lesson_per_week + unit].description.map((item, index) => <div className="sub-item v-c" key={index}>
                                        <ReactSVG src={confirm} className="svg-icon" />
                                        <p> {item} </p>
                                    </div>)}
                                    <button className={`btn-2 btn-link `} onClick={e => this.navigateToEnroll(1)}>
                                        {'ENROLL NOW'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </>
        )
    };
};

function mapStateToProps(state) {
    return {
        token: state.Auth.token,
        plan_id: state.Auth.plan_id,
        pay_per: state.Auth.pay_per,

        update_success: state.Plan.update_success,
        update_error_msg: state.Plan.update_error_msg,
        update_loading: state.Plan.update_loading,

        plans: state.Plan.plans,
        get_plans_loading: state.Plan.get_plans_loading,  
    }
}

export default connect(mapStateToProps, { 
    getPlan
 })(withRouter(PricingPage));